import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Image from 'next/legacy/image';
import Container from '@mui/material/Container';

import { useAuthentication } from '@source/contexts/AuthenticationContext';
import Steps from '@components/Navbar/Steps';
import ProfileInformation from '../ProfileInformation';
import * as S from './styles';
import dynamic from 'next/dynamic';
import { useIsCartDrawerOpenState } from '@source/recoil/hooks';
const CartButton = dynamic(() => import('../CartButton'), {
  ssr: false
});

type ResponsiveAppBarProps = {
  steps?: { label: string; step: number; status: string }[];
  renderErrorPage?: boolean;
  routesPage?: boolean;
  voucherValue?: number;
};

const getPages = () => [
  { name: 'Home', path: '/', target: '_self' },
  { name: 'Desafios', path: '/challenges', target: '_self' },
  {
    name: 'Organizadores',
    path: '/organizacao-eventos-desafios-ciclismo',
    target: '_self'
  },
  {
    name: 'Crie seu evento',
    path: '/create-challenge',
    target: '_self'
  },
  {
    name: 'Lojas e oficinas',
    path: '/criar-loja-oficina-bicicletas',
    target: '_self'
  },
  { name: 'Blog', path: 'https://blog.riderize.com/', target: '_blank' }
];

const ResponsiveAppBar: React.FC<ResponsiveAppBarProps> = ({
  steps,
  renderErrorPage,
  routesPage,
  voucherValue = 150
}): JSX.Element => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const { isCartDrawerOpen, setIsCartDrawerOpen } = useIsCartDrawerOpenState();

  const { session } = useAuthentication();

  // const isStaff = !!session?.user?.staff;

  const router = useRouter();
  const { asPath } = router;
  const isCheckout = asPath.split('/').includes('checkout');
  const isCheckoutFinished = asPath.split('/').includes('finish');

  const bgcolor = asPath === '/challenges' ? '#0564ff' : '#fff';

  const fontColor = asPath === '/challenges' ? '#fff' : '#000';

  const riderizeLogo =
    asPath === '/challenges'
      ? '/images/logo-riderize-white.svg'
      : '/images/logo-riderize-blue.svg';

  const border =
    router.asPath === '/' || asPath === '/challenges' ? false : true;

  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  useEffect(() => {
    if (isCartDrawerOpen) {
      document.body.style.paddingTop = '8.3rem';
    }
    return () => {
      document.body.style.paddingTop = '0rem';
    };
  }, [isCartDrawerOpen]);

  return (
    <AppBar
      position={isCartDrawerOpen ? 'fixed' : 'static'}
      sx={{
        borderBottom: border ? '1px solid #E1E3E6' : 'none',
        boxShadow: 'none',
        display: { xs: routesPage ? 'none' : 'block', md: 'block' }
      }}
    >
      <Container maxWidth={false} sx={{ background: `${bgcolor}` }}>
        <Toolbar
          disableGutters
          sx={{
            padding: { xs: 0, sm: '1.6rem 5rem' },
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          {isCheckout && !isCheckoutFinished  ? (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '8rem'
              }}
            >
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <Image
                  src={riderizeLogo}
                  alt="Logo Riderize"
                  width={164}
                  height={22}
                />
              </Box>

              <Box
                sx={{
                  display: {
                    xs: 'flex',
                    md: 'none',
                    background: '#045AE5',
                    padding: '1rem'
                  }
                }}
              >
                <Image
                  height={24}
                  width={24}
                  src="/icons/riderize-R.svg"
                  alt="Logo da Riderize"
                />
              </Box>

              <Steps customSteps={steps} />

              <Box
                sx={{
                  display: {
                    xs: 'none',
                    md: 'block'
                  }
                }}
              />
            </Box>
          ) : (
            <>
              {asPath === '/checkout/finish' ? (
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                  <Link legacyBehavior href="/" passHref>
                    <a>
                      <Image
                        src={riderizeLogo}
                        alt="Logo Riderize"
                        width={164}
                        height={22}
                      />
                    </a>
                  </Link>
                </Box>
              ) : (
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                  <Image
                    src={riderizeLogo}
                    alt="Logo Riderize"
                    width={164}
                    height={22}
                  />
                </Box>
              )}

              <Box
                sx={{
                  display: { xs: 'flex', md: 'none', background: '#045AE5' }
                }}
              >
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <Image
                    height={24}
                    width={24}
                    src="/icons/riderize-R.svg"
                    alt="Logo da Riderize"
                  />
                </IconButton>

                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'flex', md: 'none' },
                    flexDirection: 'column'
                  }}
                >
                  {getPages().map(page => (
                    <Link
                      legacyBehavior
                      passHref
                      href={page.path}
                      key={page.name}
                    >
                      <S.AnchorTag
                        path={asPath}
                        page={page.path}
                        target={page.target}
                        rel="noreferrer"
                      >
                        {page.name}
                      </S.AnchorTag>
                    </Link>
                  ))}
                </Menu>
              </Box>

              <Box
                sx={{
                  flexGrow: 0.5,
                  display: {
                    xs: 'none',
                    md: 'flex',
                    justifyContent: 'space-around'
                  }
                }}
              >
                {getPages().map(page => (
                  <Link
                    legacyBehavior
                    passHref
                    href={page.path}
                    key={page.name}
                  >
                    <S.AnchorTag
                      fontColor={fontColor}
                      path={asPath}
                      page={page.path}
                      target={page.target}
                      rel="noreferrer"
                    >
                      {page.name}
                    </S.AnchorTag>
                  </Link>
                ))}
              </Box>

              {/* LOGIN BUTTON / USER NAME AND AVATAR */}
              <Box
                sx={{
                  flexGrow: 0,
                  display: 'flex',
                  gap: '1.6rem',
                  position: 'relative'
                }}
              >
                {session ? (
                  <ProfileInformation
                    session={session}
                    fontColor={fontColor}
                    voucherValue={voucherValue}
                  />
                ) : (
                  <>
                    <Link legacyBehavior href="/login" passHref>
                      <S.LoginButton
                        bgcolor={bgcolor}
                        onClick={handleCloseNavMenu}
                        visibility="hide-mobile"
                      >
                        <a>Entrar na plataforma</a>
                      </S.LoginButton>
                    </Link>
                    <Link legacyBehavior href="/login" passHref>
                      <S.LoginButton
                        bgcolor={bgcolor}
                        onClick={handleCloseNavMenu}
                        visibility="hide-desktop"
                      >
                        <a>Entrar</a>
                      </S.LoginButton>
                    </Link>
                  </>
                )}

                <CartButton
                  isCartDrawerOpen={isCartDrawerOpen}
                  setIsCartDrawerOpen={setIsCartDrawerOpen}
                  session={session}
                />
              </Box>
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default ResponsiveAppBar;
