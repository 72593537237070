import BaseTemplate from '@source/templates/BaseTemplate';

type MainLayoutProps = {
  renderErrorPage?: boolean;
  children: React.ReactNode;
};

const MainLayout = ({ children }: MainLayoutProps) => {
  return <BaseTemplate >{children}</BaseTemplate>;
};

export default MainLayout;
