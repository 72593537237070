import * as Types from './operations';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export const ChallengeFragmentDoc = gql`
    fragment Challenge on Challenge {
  id
  short_id
  name
  slug_name
  challenge_type
  start_date
  end_date
}
    `;
export const ChallengeCategoryFragmentDoc = gql`
    fragment ChallengeCategory on ChallengeCategories {
  age_from
  age_to
  gender
  id
  name
  description
  order
  challenge_category_parent_id
  number_of_subscriptions
  apply_senior_discount
  event_subscription_categories {
    event_subscription_id
  }
  category_configuration {
    altimetry_goal_value
    max_altimetry_goal_value
    distance_goal_value
    max_distance_goal_value
    minimum_time_goal_value
    maximum_time_goal_value
  }
}
    `;
export const ChallengeWithCompletedFragmentDoc = gql`
    fragment ChallengeWithCompleted on ChallengeWithCompleted {
  id
  name
  challenge_type
  start_date
  end_date
  physical_event
  image_avatar
  image_cover
  configuration {
    is_paid
  }
}
    `;
export const GroupVariationFragmentDoc = gql`
    fragment GroupVariation on GroupVariation {
  id
  name
  available
  product_variation_type {
    slug
  }
  product_variations {
    id
    available
    available_quantity
    text
    description
    product_id
    prices {
      id
      value
      date_initial
    }
    images {
      id
      link
      active
    }
    group_variations {
      id
      name
      available
      product_variation_type {
        slug
      }
      product_variations {
        id
        available
        available_quantity
        text
        description
        product_id
        prices {
          id
          value
          date_initial
        }
        images {
          id
          link
          active
        }
        group_variations {
          id
          name
          available
          product_variation_type {
            slug
          }
          product_variations {
            id
            available
            available_quantity
            text
            description
            product_id
            prices {
              id
              value
              date_initial
            }
            images {
              id
              link
              active
            }
            group_variations {
              id
              name
              available
              product_variation_type {
                slug
              }
              product_variations {
                id
                available
                available_quantity
                text
                description
                product_id
                prices {
                  id
                  value
                  date_initial
                }
                images {
                  id
                  link
                  active
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const PageInfoFragmentDoc = gql`
    fragment PageInfo on PaginationInfo {
  current_page
  offset
  total_item_count
  has_next_page
  has_previous_page
  __typename
}
    `;
export const ProfileFragmentDoc = gql`
    fragment Profile on Profile {
  id
  username
  followers_count
  following_count
  official
  profile_avatar
  profile_cover
  description
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  firstname
  lastname
  email
  gender
  ddi
  name
  phone
  access_token_facebook
  date_of_birth
  has_social_login
  city_id
  staff
  legal_registry_number
}
    `;
export const RegisterDocument = gql`
    mutation Register($data: RegisterUserInput!) {
  Register(data: $data) {
    id
    email
    ddi
    phone
    name
    staff
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<Types.RegisterMutation, Types.RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<Types.RegisterMutation, Types.RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RegisterMutation, Types.RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<Types.RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<Types.RegisterMutation, Types.RegisterMutationVariables>;
export const AddGearToUserBikeDocument = gql`
    mutation AddGearToUserBike($bike_gear_monitorings: [CreateBikeGearMonitoringInput!], $create_alternation: String, $change_gear: String, $bike_gear_monitoring: CreateBikeGearMonitoringInput, $bike_gear_configurations: [CreateBikeGearConfigurationsInput!], $gear: AddGearToUserBikeInput!) {
  addGearToUserBike(
    create_alternation: $create_alternation
    change_gear: $change_gear
    bike_gear_monitoring: $bike_gear_monitoring
    bike_gear_monitorings: $bike_gear_monitorings
    bike_gear_configurations: $bike_gear_configurations
    gear: $gear
  ) {
    id
  }
}
    `;
export type AddGearToUserBikeMutationFn = Apollo.MutationFunction<Types.AddGearToUserBikeMutation, Types.AddGearToUserBikeMutationVariables>;

/**
 * __useAddGearToUserBikeMutation__
 *
 * To run a mutation, you first call `useAddGearToUserBikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGearToUserBikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGearToUserBikeMutation, { data, loading, error }] = useAddGearToUserBikeMutation({
 *   variables: {
 *      bike_gear_monitorings: // value for 'bike_gear_monitorings'
 *      create_alternation: // value for 'create_alternation'
 *      change_gear: // value for 'change_gear'
 *      bike_gear_monitoring: // value for 'bike_gear_monitoring'
 *      bike_gear_configurations: // value for 'bike_gear_configurations'
 *      gear: // value for 'gear'
 *   },
 * });
 */
export function useAddGearToUserBikeMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddGearToUserBikeMutation, Types.AddGearToUserBikeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddGearToUserBikeMutation, Types.AddGearToUserBikeMutationVariables>(AddGearToUserBikeDocument, options);
      }
export type AddGearToUserBikeMutationHookResult = ReturnType<typeof useAddGearToUserBikeMutation>;
export type AddGearToUserBikeMutationResult = Apollo.MutationResult<Types.AddGearToUserBikeMutation>;
export type AddGearToUserBikeMutationOptions = Apollo.BaseMutationOptions<Types.AddGearToUserBikeMutation, Types.AddGearToUserBikeMutationVariables>;
export const AlternateBikeGearsDocument = gql`
    mutation AlternateBikeGears($data: AlternateBikeGearsInput!, $bike_gear_monitoring: CreateBikeGearMonitoringInput) {
  alternateBikeGears(data: $data, bike_gear_monitoring: $bike_gear_monitoring)
}
    `;
export type AlternateBikeGearsMutationFn = Apollo.MutationFunction<Types.AlternateBikeGearsMutation, Types.AlternateBikeGearsMutationVariables>;

/**
 * __useAlternateBikeGearsMutation__
 *
 * To run a mutation, you first call `useAlternateBikeGearsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlternateBikeGearsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alternateBikeGearsMutation, { data, loading, error }] = useAlternateBikeGearsMutation({
 *   variables: {
 *      data: // value for 'data'
 *      bike_gear_monitoring: // value for 'bike_gear_monitoring'
 *   },
 * });
 */
export function useAlternateBikeGearsMutation(baseOptions?: Apollo.MutationHookOptions<Types.AlternateBikeGearsMutation, Types.AlternateBikeGearsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AlternateBikeGearsMutation, Types.AlternateBikeGearsMutationVariables>(AlternateBikeGearsDocument, options);
      }
export type AlternateBikeGearsMutationHookResult = ReturnType<typeof useAlternateBikeGearsMutation>;
export type AlternateBikeGearsMutationResult = Apollo.MutationResult<Types.AlternateBikeGearsMutation>;
export type AlternateBikeGearsMutationOptions = Apollo.BaseMutationOptions<Types.AlternateBikeGearsMutation, Types.AlternateBikeGearsMutationVariables>;
export const AssignBikeToUserDocument = gql`
    mutation AssignBikeToUser($data: AssignBikeToUserInput!) {
  assignBikeToUser(data: $data)
}
    `;
export type AssignBikeToUserMutationFn = Apollo.MutationFunction<Types.AssignBikeToUserMutation, Types.AssignBikeToUserMutationVariables>;

/**
 * __useAssignBikeToUserMutation__
 *
 * To run a mutation, you first call `useAssignBikeToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignBikeToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignBikeToUserMutation, { data, loading, error }] = useAssignBikeToUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAssignBikeToUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.AssignBikeToUserMutation, Types.AssignBikeToUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AssignBikeToUserMutation, Types.AssignBikeToUserMutationVariables>(AssignBikeToUserDocument, options);
      }
export type AssignBikeToUserMutationHookResult = ReturnType<typeof useAssignBikeToUserMutation>;
export type AssignBikeToUserMutationResult = Apollo.MutationResult<Types.AssignBikeToUserMutation>;
export type AssignBikeToUserMutationOptions = Apollo.BaseMutationOptions<Types.AssignBikeToUserMutation, Types.AssignBikeToUserMutationVariables>;
export const CreateBikeGearMonitoringDocument = gql`
    mutation CreateBikeGearMonitoring($data: CreateBikeGearMonitoringInput!) {
  createBikeGearMonitoring(data: $data) {
    id
  }
}
    `;
export type CreateBikeGearMonitoringMutationFn = Apollo.MutationFunction<Types.CreateBikeGearMonitoringMutation, Types.CreateBikeGearMonitoringMutationVariables>;

/**
 * __useCreateBikeGearMonitoringMutation__
 *
 * To run a mutation, you first call `useCreateBikeGearMonitoringMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBikeGearMonitoringMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBikeGearMonitoringMutation, { data, loading, error }] = useCreateBikeGearMonitoringMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateBikeGearMonitoringMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateBikeGearMonitoringMutation, Types.CreateBikeGearMonitoringMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateBikeGearMonitoringMutation, Types.CreateBikeGearMonitoringMutationVariables>(CreateBikeGearMonitoringDocument, options);
      }
export type CreateBikeGearMonitoringMutationHookResult = ReturnType<typeof useCreateBikeGearMonitoringMutation>;
export type CreateBikeGearMonitoringMutationResult = Apollo.MutationResult<Types.CreateBikeGearMonitoringMutation>;
export type CreateBikeGearMonitoringMutationOptions = Apollo.BaseMutationOptions<Types.CreateBikeGearMonitoringMutation, Types.CreateBikeGearMonitoringMutationVariables>;
export const CreateBikeManufacturerDocument = gql`
    mutation CreateBikeManufacturer($data: CreateBikeManufacturerInput!) {
  createBikeManufacturer(data: $data) {
    id
    name
    description
    image_logo
  }
}
    `;
export type CreateBikeManufacturerMutationFn = Apollo.MutationFunction<Types.CreateBikeManufacturerMutation, Types.CreateBikeManufacturerMutationVariables>;

/**
 * __useCreateBikeManufacturerMutation__
 *
 * To run a mutation, you first call `useCreateBikeManufacturerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBikeManufacturerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBikeManufacturerMutation, { data, loading, error }] = useCreateBikeManufacturerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateBikeManufacturerMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateBikeManufacturerMutation, Types.CreateBikeManufacturerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateBikeManufacturerMutation, Types.CreateBikeManufacturerMutationVariables>(CreateBikeManufacturerDocument, options);
      }
export type CreateBikeManufacturerMutationHookResult = ReturnType<typeof useCreateBikeManufacturerMutation>;
export type CreateBikeManufacturerMutationResult = Apollo.MutationResult<Types.CreateBikeManufacturerMutation>;
export type CreateBikeManufacturerMutationOptions = Apollo.BaseMutationOptions<Types.CreateBikeManufacturerMutation, Types.CreateBikeManufacturerMutationVariables>;
export const CreateBikeModelDocument = gql`
    mutation CreateBikeModel($data: CreateBikeModelInput!) {
  createBikeModel(data: $data) {
    id
    name
    description
    image_main
  }
}
    `;
export type CreateBikeModelMutationFn = Apollo.MutationFunction<Types.CreateBikeModelMutation, Types.CreateBikeModelMutationVariables>;

/**
 * __useCreateBikeModelMutation__
 *
 * To run a mutation, you first call `useCreateBikeModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBikeModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBikeModelMutation, { data, loading, error }] = useCreateBikeModelMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateBikeModelMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateBikeModelMutation, Types.CreateBikeModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateBikeModelMutation, Types.CreateBikeModelMutationVariables>(CreateBikeModelDocument, options);
      }
export type CreateBikeModelMutationHookResult = ReturnType<typeof useCreateBikeModelMutation>;
export type CreateBikeModelMutationResult = Apollo.MutationResult<Types.CreateBikeModelMutation>;
export type CreateBikeModelMutationOptions = Apollo.BaseMutationOptions<Types.CreateBikeModelMutation, Types.CreateBikeModelMutationVariables>;
export const CreateRepairDocument = gql`
    mutation CreateRepair($integration_products: [CreateRepairItemIntegrationProductInput!], $create_quote: CreateQuoteInput, $sale_order_data: RepairSaleOrderInput, $attach_files: [CreateRepairAttachedFileInput!], $gears: [BikeGearsToRepairInput!]!, $add_bike_gear: [GearsToAddToBikeAndRepairInput!], $data: CreateRepairInput!) {
  createRepair(
    integration_products: $integration_products
    create_quote: $create_quote
    sale_order_data: $sale_order_data
    attach_files: $attach_files
    gears: $gears
    add_bike_gear: $add_bike_gear
    data: $data
  ) {
    id
  }
}
    `;
export type CreateRepairMutationFn = Apollo.MutationFunction<Types.CreateRepairMutation, Types.CreateRepairMutationVariables>;

/**
 * __useCreateRepairMutation__
 *
 * To run a mutation, you first call `useCreateRepairMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRepairMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRepairMutation, { data, loading, error }] = useCreateRepairMutation({
 *   variables: {
 *      integration_products: // value for 'integration_products'
 *      create_quote: // value for 'create_quote'
 *      sale_order_data: // value for 'sale_order_data'
 *      attach_files: // value for 'attach_files'
 *      gears: // value for 'gears'
 *      add_bike_gear: // value for 'add_bike_gear'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateRepairMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateRepairMutation, Types.CreateRepairMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateRepairMutation, Types.CreateRepairMutationVariables>(CreateRepairDocument, options);
      }
export type CreateRepairMutationHookResult = ReturnType<typeof useCreateRepairMutation>;
export type CreateRepairMutationResult = Apollo.MutationResult<Types.CreateRepairMutation>;
export type CreateRepairMutationOptions = Apollo.BaseMutationOptions<Types.CreateRepairMutation, Types.CreateRepairMutationVariables>;
export const CreateUserBikeManualUseDocument = gql`
    mutation CreateUserBikeManualUse($data: CreateUserBikeManualUseInput!) {
  createUserBikeManualUse(data: $data) {
    id
  }
}
    `;
export type CreateUserBikeManualUseMutationFn = Apollo.MutationFunction<Types.CreateUserBikeManualUseMutation, Types.CreateUserBikeManualUseMutationVariables>;

/**
 * __useCreateUserBikeManualUseMutation__
 *
 * To run a mutation, you first call `useCreateUserBikeManualUseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserBikeManualUseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserBikeManualUseMutation, { data, loading, error }] = useCreateUserBikeManualUseMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserBikeManualUseMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateUserBikeManualUseMutation, Types.CreateUserBikeManualUseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateUserBikeManualUseMutation, Types.CreateUserBikeManualUseMutationVariables>(CreateUserBikeManualUseDocument, options);
      }
export type CreateUserBikeManualUseMutationHookResult = ReturnType<typeof useCreateUserBikeManualUseMutation>;
export type CreateUserBikeManualUseMutationResult = Apollo.MutationResult<Types.CreateUserBikeManualUseMutation>;
export type CreateUserBikeManualUseMutationOptions = Apollo.BaseMutationOptions<Types.CreateUserBikeManualUseMutation, Types.CreateUserBikeManualUseMutationVariables>;
export const CreateUserBikeWithoutUserDocument = gql`
    mutation CreateUserBikeWithoutUser($create_client_from_integration: CreateWorkshopClientFromIntegrationClientInput, $gears: [AddGearsToUserBikeInput!], $create_bike: CreateBikeInput!, $user_bike: CreateUserBikeWithoutUserInput!, $create_client: CreateWorkshopClientInput) {
  createUserBikeWithoutUser(
    create_client_from_integration: $create_client_from_integration
    gears: $gears
    create_bike: $create_bike
    user_bike: $user_bike
    create_client: $create_client
  ) {
    id
    bike_id
    user {
      name
    }
    workshop_client {
      firstname
      lastname
    }
  }
}
    `;
export type CreateUserBikeWithoutUserMutationFn = Apollo.MutationFunction<Types.CreateUserBikeWithoutUserMutation, Types.CreateUserBikeWithoutUserMutationVariables>;

/**
 * __useCreateUserBikeWithoutUserMutation__
 *
 * To run a mutation, you first call `useCreateUserBikeWithoutUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserBikeWithoutUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserBikeWithoutUserMutation, { data, loading, error }] = useCreateUserBikeWithoutUserMutation({
 *   variables: {
 *      create_client_from_integration: // value for 'create_client_from_integration'
 *      gears: // value for 'gears'
 *      create_bike: // value for 'create_bike'
 *      user_bike: // value for 'user_bike'
 *      create_client: // value for 'create_client'
 *   },
 * });
 */
export function useCreateUserBikeWithoutUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateUserBikeWithoutUserMutation, Types.CreateUserBikeWithoutUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateUserBikeWithoutUserMutation, Types.CreateUserBikeWithoutUserMutationVariables>(CreateUserBikeWithoutUserDocument, options);
      }
export type CreateUserBikeWithoutUserMutationHookResult = ReturnType<typeof useCreateUserBikeWithoutUserMutation>;
export type CreateUserBikeWithoutUserMutationResult = Apollo.MutationResult<Types.CreateUserBikeWithoutUserMutation>;
export type CreateUserBikeWithoutUserMutationOptions = Apollo.BaseMutationOptions<Types.CreateUserBikeWithoutUserMutation, Types.CreateUserBikeWithoutUserMutationVariables>;
export const DeleteMonitoringDocument = gql`
    mutation DeleteMonitoring($data: DeleteGearMonitoringInput!) {
  deleteMonitoring(data: $data)
}
    `;
export type DeleteMonitoringMutationFn = Apollo.MutationFunction<Types.DeleteMonitoringMutation, Types.DeleteMonitoringMutationVariables>;

/**
 * __useDeleteMonitoringMutation__
 *
 * To run a mutation, you first call `useDeleteMonitoringMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMonitoringMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMonitoringMutation, { data, loading, error }] = useDeleteMonitoringMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteMonitoringMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteMonitoringMutation, Types.DeleteMonitoringMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteMonitoringMutation, Types.DeleteMonitoringMutationVariables>(DeleteMonitoringDocument, options);
      }
export type DeleteMonitoringMutationHookResult = ReturnType<typeof useDeleteMonitoringMutation>;
export type DeleteMonitoringMutationResult = Apollo.MutationResult<Types.DeleteMonitoringMutation>;
export type DeleteMonitoringMutationOptions = Apollo.BaseMutationOptions<Types.DeleteMonitoringMutation, Types.DeleteMonitoringMutationVariables>;
export const DeleteUserBikeManualUseDocument = gql`
    mutation DeleteUserBikeManualUse($id: String!) {
  deleteUserBikeManualUse(id: $id)
}
    `;
export type DeleteUserBikeManualUseMutationFn = Apollo.MutationFunction<Types.DeleteUserBikeManualUseMutation, Types.DeleteUserBikeManualUseMutationVariables>;

/**
 * __useDeleteUserBikeManualUseMutation__
 *
 * To run a mutation, you first call `useDeleteUserBikeManualUseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserBikeManualUseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserBikeManualUseMutation, { data, loading, error }] = useDeleteUserBikeManualUseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserBikeManualUseMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteUserBikeManualUseMutation, Types.DeleteUserBikeManualUseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteUserBikeManualUseMutation, Types.DeleteUserBikeManualUseMutationVariables>(DeleteUserBikeManualUseDocument, options);
      }
export type DeleteUserBikeManualUseMutationHookResult = ReturnType<typeof useDeleteUserBikeManualUseMutation>;
export type DeleteUserBikeManualUseMutationResult = Apollo.MutationResult<Types.DeleteUserBikeManualUseMutation>;
export type DeleteUserBikeManualUseMutationOptions = Apollo.BaseMutationOptions<Types.DeleteUserBikeManualUseMutation, Types.DeleteUserBikeManualUseMutationVariables>;
export const EndGroupAlternationDocument = gql`
    mutation EndGroupAlternation($group_alternation_id: String!) {
  endGroupAlternation(group_alternation_id: $group_alternation_id)
}
    `;
export type EndGroupAlternationMutationFn = Apollo.MutationFunction<Types.EndGroupAlternationMutation, Types.EndGroupAlternationMutationVariables>;

/**
 * __useEndGroupAlternationMutation__
 *
 * To run a mutation, you first call `useEndGroupAlternationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndGroupAlternationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endGroupAlternationMutation, { data, loading, error }] = useEndGroupAlternationMutation({
 *   variables: {
 *      group_alternation_id: // value for 'group_alternation_id'
 *   },
 * });
 */
export function useEndGroupAlternationMutation(baseOptions?: Apollo.MutationHookOptions<Types.EndGroupAlternationMutation, Types.EndGroupAlternationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EndGroupAlternationMutation, Types.EndGroupAlternationMutationVariables>(EndGroupAlternationDocument, options);
      }
export type EndGroupAlternationMutationHookResult = ReturnType<typeof useEndGroupAlternationMutation>;
export type EndGroupAlternationMutationResult = Apollo.MutationResult<Types.EndGroupAlternationMutation>;
export type EndGroupAlternationMutationOptions = Apollo.BaseMutationOptions<Types.EndGroupAlternationMutation, Types.EndGroupAlternationMutationVariables>;
export const MuteUserBikeOnWorkshopOpportunityDocument = gql`
    mutation MuteUserBikeOnWorkshopOpportunity($data: MuteUserBikeOnWorkshopOpportunityInput!) {
  muteUserBikeOnWorkshopOpportunity(data: $data) {
    user_bike_id
  }
}
    `;
export type MuteUserBikeOnWorkshopOpportunityMutationFn = Apollo.MutationFunction<Types.MuteUserBikeOnWorkshopOpportunityMutation, Types.MuteUserBikeOnWorkshopOpportunityMutationVariables>;

/**
 * __useMuteUserBikeOnWorkshopOpportunityMutation__
 *
 * To run a mutation, you first call `useMuteUserBikeOnWorkshopOpportunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMuteUserBikeOnWorkshopOpportunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [muteUserBikeOnWorkshopOpportunityMutation, { data, loading, error }] = useMuteUserBikeOnWorkshopOpportunityMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMuteUserBikeOnWorkshopOpportunityMutation(baseOptions?: Apollo.MutationHookOptions<Types.MuteUserBikeOnWorkshopOpportunityMutation, Types.MuteUserBikeOnWorkshopOpportunityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MuteUserBikeOnWorkshopOpportunityMutation, Types.MuteUserBikeOnWorkshopOpportunityMutationVariables>(MuteUserBikeOnWorkshopOpportunityDocument, options);
      }
export type MuteUserBikeOnWorkshopOpportunityMutationHookResult = ReturnType<typeof useMuteUserBikeOnWorkshopOpportunityMutation>;
export type MuteUserBikeOnWorkshopOpportunityMutationResult = Apollo.MutationResult<Types.MuteUserBikeOnWorkshopOpportunityMutation>;
export type MuteUserBikeOnWorkshopOpportunityMutationOptions = Apollo.BaseMutationOptions<Types.MuteUserBikeOnWorkshopOpportunityMutation, Types.MuteUserBikeOnWorkshopOpportunityMutationVariables>;
export const SendUserBikeRepairReminderDocument = gql`
    mutation SendUserBikeRepairReminder($data: SendUserBikeRepairReminderInput!) {
  sendUserBikeRepairReminder(data: $data) {
    sent
  }
}
    `;
export type SendUserBikeRepairReminderMutationFn = Apollo.MutationFunction<Types.SendUserBikeRepairReminderMutation, Types.SendUserBikeRepairReminderMutationVariables>;

/**
 * __useSendUserBikeRepairReminderMutation__
 *
 * To run a mutation, you first call `useSendUserBikeRepairReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendUserBikeRepairReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendUserBikeRepairReminderMutation, { data, loading, error }] = useSendUserBikeRepairReminderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendUserBikeRepairReminderMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendUserBikeRepairReminderMutation, Types.SendUserBikeRepairReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendUserBikeRepairReminderMutation, Types.SendUserBikeRepairReminderMutationVariables>(SendUserBikeRepairReminderDocument, options);
      }
export type SendUserBikeRepairReminderMutationHookResult = ReturnType<typeof useSendUserBikeRepairReminderMutation>;
export type SendUserBikeRepairReminderMutationResult = Apollo.MutationResult<Types.SendUserBikeRepairReminderMutation>;
export type SendUserBikeRepairReminderMutationOptions = Apollo.BaseMutationOptions<Types.SendUserBikeRepairReminderMutation, Types.SendUserBikeRepairReminderMutationVariables>;
export const SuggestBikeGearsDocument = gql`
    mutation SuggestBikeGears($data: [CreateGearSuggestionInput!]!) {
  suggestBikeGears(data: $data) {
    id
    gear_type_slug
  }
}
    `;
export type SuggestBikeGearsMutationFn = Apollo.MutationFunction<Types.SuggestBikeGearsMutation, Types.SuggestBikeGearsMutationVariables>;

/**
 * __useSuggestBikeGearsMutation__
 *
 * To run a mutation, you first call `useSuggestBikeGearsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSuggestBikeGearsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [suggestBikeGearsMutation, { data, loading, error }] = useSuggestBikeGearsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSuggestBikeGearsMutation(baseOptions?: Apollo.MutationHookOptions<Types.SuggestBikeGearsMutation, Types.SuggestBikeGearsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SuggestBikeGearsMutation, Types.SuggestBikeGearsMutationVariables>(SuggestBikeGearsDocument, options);
      }
export type SuggestBikeGearsMutationHookResult = ReturnType<typeof useSuggestBikeGearsMutation>;
export type SuggestBikeGearsMutationResult = Apollo.MutationResult<Types.SuggestBikeGearsMutation>;
export type SuggestBikeGearsMutationOptions = Apollo.BaseMutationOptions<Types.SuggestBikeGearsMutation, Types.SuggestBikeGearsMutationVariables>;
export const UnmuteUserBikeOnWorkshopOpportunityDocument = gql`
    mutation UnmuteUserBikeOnWorkshopOpportunity($data: UnmuteManyUserBikeOnWorkshopOpportunityInput!) {
  unmuteUserBikeOnWorkshopOpportunity(data: $data) {
    errors
  }
}
    `;
export type UnmuteUserBikeOnWorkshopOpportunityMutationFn = Apollo.MutationFunction<Types.UnmuteUserBikeOnWorkshopOpportunityMutation, Types.UnmuteUserBikeOnWorkshopOpportunityMutationVariables>;

/**
 * __useUnmuteUserBikeOnWorkshopOpportunityMutation__
 *
 * To run a mutation, you first call `useUnmuteUserBikeOnWorkshopOpportunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnmuteUserBikeOnWorkshopOpportunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unmuteUserBikeOnWorkshopOpportunityMutation, { data, loading, error }] = useUnmuteUserBikeOnWorkshopOpportunityMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUnmuteUserBikeOnWorkshopOpportunityMutation(baseOptions?: Apollo.MutationHookOptions<Types.UnmuteUserBikeOnWorkshopOpportunityMutation, Types.UnmuteUserBikeOnWorkshopOpportunityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UnmuteUserBikeOnWorkshopOpportunityMutation, Types.UnmuteUserBikeOnWorkshopOpportunityMutationVariables>(UnmuteUserBikeOnWorkshopOpportunityDocument, options);
      }
export type UnmuteUserBikeOnWorkshopOpportunityMutationHookResult = ReturnType<typeof useUnmuteUserBikeOnWorkshopOpportunityMutation>;
export type UnmuteUserBikeOnWorkshopOpportunityMutationResult = Apollo.MutationResult<Types.UnmuteUserBikeOnWorkshopOpportunityMutation>;
export type UnmuteUserBikeOnWorkshopOpportunityMutationOptions = Apollo.BaseMutationOptions<Types.UnmuteUserBikeOnWorkshopOpportunityMutation, Types.UnmuteUserBikeOnWorkshopOpportunityMutationVariables>;
export const UpdateBikeGearDocument = gql`
    mutation UpdateBikeGear($update_bike_gear_configuration: [UpdateBikeGearConfigurationInput!], $bike_gear_monitoring: UpdateBikeGearMonitoringInput, $gear: UpdateBikeGearInput!) {
  updateBikeGear(
    update_bike_gear_configuration: $update_bike_gear_configuration
    bike_gear_monitoring: $bike_gear_monitoring
    gear: $gear
  ) {
    id
  }
}
    `;
export type UpdateBikeGearMutationFn = Apollo.MutationFunction<Types.UpdateBikeGearMutation, Types.UpdateBikeGearMutationVariables>;

/**
 * __useUpdateBikeGearMutation__
 *
 * To run a mutation, you first call `useUpdateBikeGearMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBikeGearMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBikeGearMutation, { data, loading, error }] = useUpdateBikeGearMutation({
 *   variables: {
 *      update_bike_gear_configuration: // value for 'update_bike_gear_configuration'
 *      bike_gear_monitoring: // value for 'bike_gear_monitoring'
 *      gear: // value for 'gear'
 *   },
 * });
 */
export function useUpdateBikeGearMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateBikeGearMutation, Types.UpdateBikeGearMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateBikeGearMutation, Types.UpdateBikeGearMutationVariables>(UpdateBikeGearDocument, options);
      }
export type UpdateBikeGearMutationHookResult = ReturnType<typeof useUpdateBikeGearMutation>;
export type UpdateBikeGearMutationResult = Apollo.MutationResult<Types.UpdateBikeGearMutation>;
export type UpdateBikeGearMutationOptions = Apollo.BaseMutationOptions<Types.UpdateBikeGearMutation, Types.UpdateBikeGearMutationVariables>;
export const UpdateRepairDocument = gql`
    mutation UpdateRepair($integration_products: [CreateRepairItemIntegrationProductInput!], $update_quote: UpdateQuoteInput, $sale_order_data: RepairSaleOrderInput, $gears: [BikeGearsToRepairInput!], $add_bike_gear: [GearsToAddToBikeAndRepairInput!], $file_link_to_add: [CreateRepairAttachedFileInput!], $file_id_to_delete: [DeleteFileFromRepairInput!], $data: UpdateRepairInput!) {
  updateRepair(
    integration_products: $integration_products
    update_quote: $update_quote
    sale_order_data: $sale_order_data
    gears: $gears
    add_bike_gear: $add_bike_gear
    file_link_to_add: $file_link_to_add
    file_id_to_delete: $file_id_to_delete
    data: $data
  ) {
    id
  }
}
    `;
export type UpdateRepairMutationFn = Apollo.MutationFunction<Types.UpdateRepairMutation, Types.UpdateRepairMutationVariables>;

/**
 * __useUpdateRepairMutation__
 *
 * To run a mutation, you first call `useUpdateRepairMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRepairMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRepairMutation, { data, loading, error }] = useUpdateRepairMutation({
 *   variables: {
 *      integration_products: // value for 'integration_products'
 *      update_quote: // value for 'update_quote'
 *      sale_order_data: // value for 'sale_order_data'
 *      gears: // value for 'gears'
 *      add_bike_gear: // value for 'add_bike_gear'
 *      file_link_to_add: // value for 'file_link_to_add'
 *      file_id_to_delete: // value for 'file_id_to_delete'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateRepairMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateRepairMutation, Types.UpdateRepairMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateRepairMutation, Types.UpdateRepairMutationVariables>(UpdateRepairDocument, options);
      }
export type UpdateRepairMutationHookResult = ReturnType<typeof useUpdateRepairMutation>;
export type UpdateRepairMutationResult = Apollo.MutationResult<Types.UpdateRepairMutation>;
export type UpdateRepairMutationOptions = Apollo.BaseMutationOptions<Types.UpdateRepairMutation, Types.UpdateRepairMutationVariables>;
export const AddChallengeExternalLinksAttachedDocument = gql`
    mutation AddChallengeExternalLinksAttached($data: CreateChallengeExternalLinksAttachedInput!) {
  addChallengeExternalLinksAttached(data: $data) {
    id
    name
  }
}
    `;
export type AddChallengeExternalLinksAttachedMutationFn = Apollo.MutationFunction<Types.AddChallengeExternalLinksAttachedMutation, Types.AddChallengeExternalLinksAttachedMutationVariables>;

/**
 * __useAddChallengeExternalLinksAttachedMutation__
 *
 * To run a mutation, you first call `useAddChallengeExternalLinksAttachedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddChallengeExternalLinksAttachedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addChallengeExternalLinksAttachedMutation, { data, loading, error }] = useAddChallengeExternalLinksAttachedMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddChallengeExternalLinksAttachedMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddChallengeExternalLinksAttachedMutation, Types.AddChallengeExternalLinksAttachedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddChallengeExternalLinksAttachedMutation, Types.AddChallengeExternalLinksAttachedMutationVariables>(AddChallengeExternalLinksAttachedDocument, options);
      }
export type AddChallengeExternalLinksAttachedMutationHookResult = ReturnType<typeof useAddChallengeExternalLinksAttachedMutation>;
export type AddChallengeExternalLinksAttachedMutationResult = Apollo.MutationResult<Types.AddChallengeExternalLinksAttachedMutation>;
export type AddChallengeExternalLinksAttachedMutationOptions = Apollo.BaseMutationOptions<Types.AddChallengeExternalLinksAttachedMutation, Types.AddChallengeExternalLinksAttachedMutationVariables>;
export const AddChallengeFilesAttachedDocument = gql`
    mutation AddChallengeFilesAttached($data: CreateChallengeAttachedFilesInput!) {
  addChallengeFilesAttached(data: $data) {
    id
  }
}
    `;
export type AddChallengeFilesAttachedMutationFn = Apollo.MutationFunction<Types.AddChallengeFilesAttachedMutation, Types.AddChallengeFilesAttachedMutationVariables>;

/**
 * __useAddChallengeFilesAttachedMutation__
 *
 * To run a mutation, you first call `useAddChallengeFilesAttachedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddChallengeFilesAttachedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addChallengeFilesAttachedMutation, { data, loading, error }] = useAddChallengeFilesAttachedMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddChallengeFilesAttachedMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddChallengeFilesAttachedMutation, Types.AddChallengeFilesAttachedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddChallengeFilesAttachedMutation, Types.AddChallengeFilesAttachedMutationVariables>(AddChallengeFilesAttachedDocument, options);
      }
export type AddChallengeFilesAttachedMutationHookResult = ReturnType<typeof useAddChallengeFilesAttachedMutation>;
export type AddChallengeFilesAttachedMutationResult = Apollo.MutationResult<Types.AddChallengeFilesAttachedMutation>;
export type AddChallengeFilesAttachedMutationOptions = Apollo.BaseMutationOptions<Types.AddChallengeFilesAttachedMutation, Types.AddChallengeFilesAttachedMutationVariables>;
export const DeleteChallengeAttachedFilesDocument = gql`
    mutation DeleteChallengeAttachedFiles($ids: [String!]!) {
  deleteChallengeAttachedFiles(ids: $ids)
}
    `;
export type DeleteChallengeAttachedFilesMutationFn = Apollo.MutationFunction<Types.DeleteChallengeAttachedFilesMutation, Types.DeleteChallengeAttachedFilesMutationVariables>;

/**
 * __useDeleteChallengeAttachedFilesMutation__
 *
 * To run a mutation, you first call `useDeleteChallengeAttachedFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChallengeAttachedFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChallengeAttachedFilesMutation, { data, loading, error }] = useDeleteChallengeAttachedFilesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteChallengeAttachedFilesMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteChallengeAttachedFilesMutation, Types.DeleteChallengeAttachedFilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteChallengeAttachedFilesMutation, Types.DeleteChallengeAttachedFilesMutationVariables>(DeleteChallengeAttachedFilesDocument, options);
      }
export type DeleteChallengeAttachedFilesMutationHookResult = ReturnType<typeof useDeleteChallengeAttachedFilesMutation>;
export type DeleteChallengeAttachedFilesMutationResult = Apollo.MutationResult<Types.DeleteChallengeAttachedFilesMutation>;
export type DeleteChallengeAttachedFilesMutationOptions = Apollo.BaseMutationOptions<Types.DeleteChallengeAttachedFilesMutation, Types.DeleteChallengeAttachedFilesMutationVariables>;
export const DeleteExternalLinkAttachedDocument = gql`
    mutation DeleteExternalLinkAttached($id: String!) {
  deleteExternalLinkAttached(id: $id)
}
    `;
export type DeleteExternalLinkAttachedMutationFn = Apollo.MutationFunction<Types.DeleteExternalLinkAttachedMutation, Types.DeleteExternalLinkAttachedMutationVariables>;

/**
 * __useDeleteExternalLinkAttachedMutation__
 *
 * To run a mutation, you first call `useDeleteExternalLinkAttachedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExternalLinkAttachedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExternalLinkAttachedMutation, { data, loading, error }] = useDeleteExternalLinkAttachedMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteExternalLinkAttachedMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteExternalLinkAttachedMutation, Types.DeleteExternalLinkAttachedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteExternalLinkAttachedMutation, Types.DeleteExternalLinkAttachedMutationVariables>(DeleteExternalLinkAttachedDocument, options);
      }
export type DeleteExternalLinkAttachedMutationHookResult = ReturnType<typeof useDeleteExternalLinkAttachedMutation>;
export type DeleteExternalLinkAttachedMutationResult = Apollo.MutationResult<Types.DeleteExternalLinkAttachedMutation>;
export type DeleteExternalLinkAttachedMutationOptions = Apollo.BaseMutationOptions<Types.DeleteExternalLinkAttachedMutation, Types.DeleteExternalLinkAttachedMutationVariables>;
export const AddFavoriteChallengeDocument = gql`
    mutation AddFavoriteChallenge($data: FavoriteUserChallengeInput!) {
  addfavoriteChallenge(data: $data) {
    challenge_id
  }
}
    `;
export type AddFavoriteChallengeMutationFn = Apollo.MutationFunction<Types.AddFavoriteChallengeMutation, Types.AddFavoriteChallengeMutationVariables>;

/**
 * __useAddFavoriteChallengeMutation__
 *
 * To run a mutation, you first call `useAddFavoriteChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFavoriteChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFavoriteChallengeMutation, { data, loading, error }] = useAddFavoriteChallengeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddFavoriteChallengeMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddFavoriteChallengeMutation, Types.AddFavoriteChallengeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddFavoriteChallengeMutation, Types.AddFavoriteChallengeMutationVariables>(AddFavoriteChallengeDocument, options);
      }
export type AddFavoriteChallengeMutationHookResult = ReturnType<typeof useAddFavoriteChallengeMutation>;
export type AddFavoriteChallengeMutationResult = Apollo.MutationResult<Types.AddFavoriteChallengeMutation>;
export type AddFavoriteChallengeMutationOptions = Apollo.BaseMutationOptions<Types.AddFavoriteChallengeMutation, Types.AddFavoriteChallengeMutationVariables>;
export const DeleteFavoriteChallengeDocument = gql`
    mutation deleteFavoriteChallenge($data: FavoriteUserChallengeInput!) {
  deleteFavoriteChallenge(data: $data)
}
    `;
export type DeleteFavoriteChallengeMutationFn = Apollo.MutationFunction<Types.DeleteFavoriteChallengeMutation, Types.DeleteFavoriteChallengeMutationVariables>;

/**
 * __useDeleteFavoriteChallengeMutation__
 *
 * To run a mutation, you first call `useDeleteFavoriteChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFavoriteChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFavoriteChallengeMutation, { data, loading, error }] = useDeleteFavoriteChallengeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteFavoriteChallengeMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteFavoriteChallengeMutation, Types.DeleteFavoriteChallengeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteFavoriteChallengeMutation, Types.DeleteFavoriteChallengeMutationVariables>(DeleteFavoriteChallengeDocument, options);
      }
export type DeleteFavoriteChallengeMutationHookResult = ReturnType<typeof useDeleteFavoriteChallengeMutation>;
export type DeleteFavoriteChallengeMutationResult = Apollo.MutationResult<Types.DeleteFavoriteChallengeMutation>;
export type DeleteFavoriteChallengeMutationOptions = Apollo.BaseMutationOptions<Types.DeleteFavoriteChallengeMutation, Types.DeleteFavoriteChallengeMutationVariables>;
export const UnsubscribeUserChallengeDocument = gql`
    mutation UnsubscribeUserChallenge($challenge_id: String!) {
  unsubscribeUserChallenge(challenge_id: $challenge_id)
}
    `;
export type UnsubscribeUserChallengeMutationFn = Apollo.MutationFunction<Types.UnsubscribeUserChallengeMutation, Types.UnsubscribeUserChallengeMutationVariables>;

/**
 * __useUnsubscribeUserChallengeMutation__
 *
 * To run a mutation, you first call `useUnsubscribeUserChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeUserChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeUserChallengeMutation, { data, loading, error }] = useUnsubscribeUserChallengeMutation({
 *   variables: {
 *      challenge_id: // value for 'challenge_id'
 *   },
 * });
 */
export function useUnsubscribeUserChallengeMutation(baseOptions?: Apollo.MutationHookOptions<Types.UnsubscribeUserChallengeMutation, Types.UnsubscribeUserChallengeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UnsubscribeUserChallengeMutation, Types.UnsubscribeUserChallengeMutationVariables>(UnsubscribeUserChallengeDocument, options);
      }
export type UnsubscribeUserChallengeMutationHookResult = ReturnType<typeof useUnsubscribeUserChallengeMutation>;
export type UnsubscribeUserChallengeMutationResult = Apollo.MutationResult<Types.UnsubscribeUserChallengeMutation>;
export type UnsubscribeUserChallengeMutationOptions = Apollo.BaseMutationOptions<Types.UnsubscribeUserChallengeMutation, Types.UnsubscribeUserChallengeMutationVariables>;
export const UpdateChallengeDocument = gql`
    mutation UpdateChallenge($data: UpdateChallengeInput!, $id: String!) {
  updateChallenge(data: $data, id: $id) {
    id
  }
}
    `;
export type UpdateChallengeMutationFn = Apollo.MutationFunction<Types.UpdateChallengeMutation, Types.UpdateChallengeMutationVariables>;

/**
 * __useUpdateChallengeMutation__
 *
 * To run a mutation, you first call `useUpdateChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChallengeMutation, { data, loading, error }] = useUpdateChallengeMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateChallengeMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateChallengeMutation, Types.UpdateChallengeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateChallengeMutation, Types.UpdateChallengeMutationVariables>(UpdateChallengeDocument, options);
      }
export type UpdateChallengeMutationHookResult = ReturnType<typeof useUpdateChallengeMutation>;
export type UpdateChallengeMutationResult = Apollo.MutationResult<Types.UpdateChallengeMutation>;
export type UpdateChallengeMutationOptions = Apollo.BaseMutationOptions<Types.UpdateChallengeMutation, Types.UpdateChallengeMutationVariables>;
export const UpdateChallengeConfigurationDocument = gql`
    mutation UpdateChallengeConfiguration($data: UpdateChallengeConfigurationInput!) {
  updateChallengeConfiguration(data: $data) {
    id
  }
}
    `;
export type UpdateChallengeConfigurationMutationFn = Apollo.MutationFunction<Types.UpdateChallengeConfigurationMutation, Types.UpdateChallengeConfigurationMutationVariables>;

/**
 * __useUpdateChallengeConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateChallengeConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChallengeConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChallengeConfigurationMutation, { data, loading, error }] = useUpdateChallengeConfigurationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateChallengeConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateChallengeConfigurationMutation, Types.UpdateChallengeConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateChallengeConfigurationMutation, Types.UpdateChallengeConfigurationMutationVariables>(UpdateChallengeConfigurationDocument, options);
      }
export type UpdateChallengeConfigurationMutationHookResult = ReturnType<typeof useUpdateChallengeConfigurationMutation>;
export type UpdateChallengeConfigurationMutationResult = Apollo.MutationResult<Types.UpdateChallengeConfigurationMutation>;
export type UpdateChallengeConfigurationMutationOptions = Apollo.BaseMutationOptions<Types.UpdateChallengeConfigurationMutation, Types.UpdateChallengeConfigurationMutationVariables>;
export const CreateClapDocument = gql`
    mutation CreateClap($data: CreateClapInput!) {
  createClap(data: $data) {
    challenge_id
    profile_id
    count
  }
}
    `;
export type CreateClapMutationFn = Apollo.MutationFunction<Types.CreateClapMutation, Types.CreateClapMutationVariables>;

/**
 * __useCreateClapMutation__
 *
 * To run a mutation, you first call `useCreateClapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClapMutation, { data, loading, error }] = useCreateClapMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateClapMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateClapMutation, Types.CreateClapMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateClapMutation, Types.CreateClapMutationVariables>(CreateClapDocument, options);
      }
export type CreateClapMutationHookResult = ReturnType<typeof useCreateClapMutation>;
export type CreateClapMutationResult = Apollo.MutationResult<Types.CreateClapMutation>;
export type CreateClapMutationOptions = Apollo.BaseMutationOptions<Types.CreateClapMutation, Types.CreateClapMutationVariables>;
export const CodeRecoveryDocument = gql`
    mutation CodeRecovery($data: SmsCodeInput!) {
  codeRecovery(data: $data) {
    user {
      ...User
    }
    codeStatus
  }
}
    ${UserFragmentDoc}`;
export type CodeRecoveryMutationFn = Apollo.MutationFunction<Types.CodeRecoveryMutation, Types.CodeRecoveryMutationVariables>;

/**
 * __useCodeRecoveryMutation__
 *
 * To run a mutation, you first call `useCodeRecoveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCodeRecoveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [codeRecoveryMutation, { data, loading, error }] = useCodeRecoveryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCodeRecoveryMutation(baseOptions?: Apollo.MutationHookOptions<Types.CodeRecoveryMutation, Types.CodeRecoveryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CodeRecoveryMutation, Types.CodeRecoveryMutationVariables>(CodeRecoveryDocument, options);
      }
export type CodeRecoveryMutationHookResult = ReturnType<typeof useCodeRecoveryMutation>;
export type CodeRecoveryMutationResult = Apollo.MutationResult<Types.CodeRecoveryMutation>;
export type CodeRecoveryMutationOptions = Apollo.BaseMutationOptions<Types.CodeRecoveryMutation, Types.CodeRecoveryMutationVariables>;
export const CreateCommentDocument = gql`
    mutation CreateComment($data: CreateCommentInput!) {
  createComment(data: $data) {
    id
    challenge_id
    profile_id
    claps_count
    text
    active
    created_at
    parent_challenge_comment_id
    profile {
      id
      username
      profile_avatar
      user {
        name
      }
    }
    claps {
      challenge_comment_id
      profile_id
      count
    }
    childComments {
      id
      challenge_id
      profile_id
      claps_count
      text
      active
      created_at
      profile {
        id
        username
        profile_avatar
        user {
          name
        }
      }
    }
  }
}
    `;
export type CreateCommentMutationFn = Apollo.MutationFunction<Types.CreateCommentMutation, Types.CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateCommentMutation, Types.CreateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateCommentMutation, Types.CreateCommentMutationVariables>(CreateCommentDocument, options);
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<Types.CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<Types.CreateCommentMutation, Types.CreateCommentMutationVariables>;
export const CreateCommentClapDocument = gql`
    mutation CreateCommentClap($data: CreateCommentClapInput!) {
  createCommentClap(data: $data) {
    count
  }
}
    `;
export type CreateCommentClapMutationFn = Apollo.MutationFunction<Types.CreateCommentClapMutation, Types.CreateCommentClapMutationVariables>;

/**
 * __useCreateCommentClapMutation__
 *
 * To run a mutation, you first call `useCreateCommentClapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentClapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentClapMutation, { data, loading, error }] = useCreateCommentClapMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCommentClapMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateCommentClapMutation, Types.CreateCommentClapMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateCommentClapMutation, Types.CreateCommentClapMutationVariables>(CreateCommentClapDocument, options);
      }
export type CreateCommentClapMutationHookResult = ReturnType<typeof useCreateCommentClapMutation>;
export type CreateCommentClapMutationResult = Apollo.MutationResult<Types.CreateCommentClapMutation>;
export type CreateCommentClapMutationOptions = Apollo.BaseMutationOptions<Types.CreateCommentClapMutation, Types.CreateCommentClapMutationVariables>;
export const DeleteCommentDocument = gql`
    mutation DeleteComment($id: String!) {
  deleteComment(id: $id) {
    challenge_id
  }
}
    `;
export type DeleteCommentMutationFn = Apollo.MutationFunction<Types.DeleteCommentMutation, Types.DeleteCommentMutationVariables>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommentMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCommentMutation, Types.DeleteCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCommentMutation, Types.DeleteCommentMutationVariables>(DeleteCommentDocument, options);
      }
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = Apollo.MutationResult<Types.DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCommentMutation, Types.DeleteCommentMutationVariables>;
export const AssociateUserToCompanyDocument = gql`
    mutation AssociateUserToCompany($data: AssociateUserWithCompanyInput!) {
  associateUserToCompany(data: $data) {
    company_function_id
    company_id
    creator
    user_id
  }
}
    `;
export type AssociateUserToCompanyMutationFn = Apollo.MutationFunction<Types.AssociateUserToCompanyMutation, Types.AssociateUserToCompanyMutationVariables>;

/**
 * __useAssociateUserToCompanyMutation__
 *
 * To run a mutation, you first call `useAssociateUserToCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssociateUserToCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [associateUserToCompanyMutation, { data, loading, error }] = useAssociateUserToCompanyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAssociateUserToCompanyMutation(baseOptions?: Apollo.MutationHookOptions<Types.AssociateUserToCompanyMutation, Types.AssociateUserToCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AssociateUserToCompanyMutation, Types.AssociateUserToCompanyMutationVariables>(AssociateUserToCompanyDocument, options);
      }
export type AssociateUserToCompanyMutationHookResult = ReturnType<typeof useAssociateUserToCompanyMutation>;
export type AssociateUserToCompanyMutationResult = Apollo.MutationResult<Types.AssociateUserToCompanyMutation>;
export type AssociateUserToCompanyMutationOptions = Apollo.BaseMutationOptions<Types.AssociateUserToCompanyMutation, Types.AssociateUserToCompanyMutationVariables>;
export const CreateCompanyDocument = gql`
    mutation CreateCompany($data: CreateCompanyInput!) {
  createCompany(data: $data) {
    id
  }
}
    `;
export type CreateCompanyMutationFn = Apollo.MutationFunction<Types.CreateCompanyMutation, Types.CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateCompanyMutation, Types.CreateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateCompanyMutation, Types.CreateCompanyMutationVariables>(CreateCompanyDocument, options);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<Types.CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<Types.CreateCompanyMutation, Types.CreateCompanyMutationVariables>;
export const CreateCompanyAddressDocument = gql`
    mutation CreateCompanyAddress($data: CreateCompanyAddressInput!) {
  createCompanyAddress(data: $data) {
    id
  }
}
    `;
export type CreateCompanyAddressMutationFn = Apollo.MutationFunction<Types.CreateCompanyAddressMutation, Types.CreateCompanyAddressMutationVariables>;

/**
 * __useCreateCompanyAddressMutation__
 *
 * To run a mutation, you first call `useCreateCompanyAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyAddressMutation, { data, loading, error }] = useCreateCompanyAddressMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCompanyAddressMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateCompanyAddressMutation, Types.CreateCompanyAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateCompanyAddressMutation, Types.CreateCompanyAddressMutationVariables>(CreateCompanyAddressDocument, options);
      }
export type CreateCompanyAddressMutationHookResult = ReturnType<typeof useCreateCompanyAddressMutation>;
export type CreateCompanyAddressMutationResult = Apollo.MutationResult<Types.CreateCompanyAddressMutation>;
export type CreateCompanyAddressMutationOptions = Apollo.BaseMutationOptions<Types.CreateCompanyAddressMutation, Types.CreateCompanyAddressMutationVariables>;
export const CreateDocumentAdvantageDocument = gql`
    mutation createDocumentAdvantage($data: [CreateDocumentAdvantageInput!]!) {
  createDocumentAdvantage(data: $data) {
    document_advantages {
      challenge_id
    }
  }
}
    `;
export type CreateDocumentAdvantageMutationFn = Apollo.MutationFunction<Types.CreateDocumentAdvantageMutation, Types.CreateDocumentAdvantageMutationVariables>;

/**
 * __useCreateDocumentAdvantageMutation__
 *
 * To run a mutation, you first call `useCreateDocumentAdvantageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentAdvantageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentAdvantageMutation, { data, loading, error }] = useCreateDocumentAdvantageMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDocumentAdvantageMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateDocumentAdvantageMutation, Types.CreateDocumentAdvantageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateDocumentAdvantageMutation, Types.CreateDocumentAdvantageMutationVariables>(CreateDocumentAdvantageDocument, options);
      }
export type CreateDocumentAdvantageMutationHookResult = ReturnType<typeof useCreateDocumentAdvantageMutation>;
export type CreateDocumentAdvantageMutationResult = Apollo.MutationResult<Types.CreateDocumentAdvantageMutation>;
export type CreateDocumentAdvantageMutationOptions = Apollo.BaseMutationOptions<Types.CreateDocumentAdvantageMutation, Types.CreateDocumentAdvantageMutationVariables>;
export const DeleteAssociationUserWithCompanyDocument = gql`
    mutation DeleteAssociationUserWithCompany($data: DeleteAssociationUserWithCompanyInput!) {
  deleteAssociationUserWithCompany(data: $data)
}
    `;
export type DeleteAssociationUserWithCompanyMutationFn = Apollo.MutationFunction<Types.DeleteAssociationUserWithCompanyMutation, Types.DeleteAssociationUserWithCompanyMutationVariables>;

/**
 * __useDeleteAssociationUserWithCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteAssociationUserWithCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssociationUserWithCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssociationUserWithCompanyMutation, { data, loading, error }] = useDeleteAssociationUserWithCompanyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteAssociationUserWithCompanyMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteAssociationUserWithCompanyMutation, Types.DeleteAssociationUserWithCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteAssociationUserWithCompanyMutation, Types.DeleteAssociationUserWithCompanyMutationVariables>(DeleteAssociationUserWithCompanyDocument, options);
      }
export type DeleteAssociationUserWithCompanyMutationHookResult = ReturnType<typeof useDeleteAssociationUserWithCompanyMutation>;
export type DeleteAssociationUserWithCompanyMutationResult = Apollo.MutationResult<Types.DeleteAssociationUserWithCompanyMutation>;
export type DeleteAssociationUserWithCompanyMutationOptions = Apollo.BaseMutationOptions<Types.DeleteAssociationUserWithCompanyMutation, Types.DeleteAssociationUserWithCompanyMutationVariables>;
export const UpdateCompanyAddressDocument = gql`
    mutation UpdateCompanyAddress($data: UpdateCompanyAddressInput!) {
  updateCompanyAddress(data: $data) {
    id
  }
}
    `;
export type UpdateCompanyAddressMutationFn = Apollo.MutationFunction<Types.UpdateCompanyAddressMutation, Types.UpdateCompanyAddressMutationVariables>;

/**
 * __useUpdateCompanyAddressMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyAddressMutation, { data, loading, error }] = useUpdateCompanyAddressMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCompanyAddressMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCompanyAddressMutation, Types.UpdateCompanyAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCompanyAddressMutation, Types.UpdateCompanyAddressMutationVariables>(UpdateCompanyAddressDocument, options);
      }
export type UpdateCompanyAddressMutationHookResult = ReturnType<typeof useUpdateCompanyAddressMutation>;
export type UpdateCompanyAddressMutationResult = Apollo.MutationResult<Types.UpdateCompanyAddressMutation>;
export type UpdateCompanyAddressMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCompanyAddressMutation, Types.UpdateCompanyAddressMutationVariables>;
export const UpdateProfileCompanyDocument = gql`
    mutation updateProfileCompany($data: UpdateProfileCompanyInput!) {
  updateProfileCompany(data: $data) {
    id
  }
}
    `;
export type UpdateProfileCompanyMutationFn = Apollo.MutationFunction<Types.UpdateProfileCompanyMutation, Types.UpdateProfileCompanyMutationVariables>;

/**
 * __useUpdateProfileCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateProfileCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileCompanyMutation, { data, loading, error }] = useUpdateProfileCompanyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProfileCompanyMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateProfileCompanyMutation, Types.UpdateProfileCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateProfileCompanyMutation, Types.UpdateProfileCompanyMutationVariables>(UpdateProfileCompanyDocument, options);
      }
export type UpdateProfileCompanyMutationHookResult = ReturnType<typeof useUpdateProfileCompanyMutation>;
export type UpdateProfileCompanyMutationResult = Apollo.MutationResult<Types.UpdateProfileCompanyMutation>;
export type UpdateProfileCompanyMutationOptions = Apollo.BaseMutationOptions<Types.UpdateProfileCompanyMutation, Types.UpdateProfileCompanyMutationVariables>;
export const CreateBlacklistedEmailDocument = gql`
    mutation CreateBlacklistedEmail($data: BlackListEmailInput!) {
  createBlacklistedEmail(data: $data) {
    email
  }
}
    `;
export type CreateBlacklistedEmailMutationFn = Apollo.MutationFunction<Types.CreateBlacklistedEmailMutation, Types.CreateBlacklistedEmailMutationVariables>;

/**
 * __useCreateBlacklistedEmailMutation__
 *
 * To run a mutation, you first call `useCreateBlacklistedEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlacklistedEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlacklistedEmailMutation, { data, loading, error }] = useCreateBlacklistedEmailMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateBlacklistedEmailMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateBlacklistedEmailMutation, Types.CreateBlacklistedEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateBlacklistedEmailMutation, Types.CreateBlacklistedEmailMutationVariables>(CreateBlacklistedEmailDocument, options);
      }
export type CreateBlacklistedEmailMutationHookResult = ReturnType<typeof useCreateBlacklistedEmailMutation>;
export type CreateBlacklistedEmailMutationResult = Apollo.MutationResult<Types.CreateBlacklistedEmailMutation>;
export type CreateBlacklistedEmailMutationOptions = Apollo.BaseMutationOptions<Types.CreateBlacklistedEmailMutation, Types.CreateBlacklistedEmailMutationVariables>;
export const CreateCardDocument = gql`
    mutation CreateCard($data: CreateCardInput!) {
  createCard(data: $data) {
    id
    name
    external_id
    brand
    holder_name
    first_digits
    last_digits
    valid
    expiration_date
    legal_holder_number
  }
}
    `;
export type CreateCardMutationFn = Apollo.MutationFunction<Types.CreateCardMutation, Types.CreateCardMutationVariables>;

/**
 * __useCreateCardMutation__
 *
 * To run a mutation, you first call `useCreateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCardMutation, { data, loading, error }] = useCreateCardMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCardMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateCardMutation, Types.CreateCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateCardMutation, Types.CreateCardMutationVariables>(CreateCardDocument, options);
      }
export type CreateCardMutationHookResult = ReturnType<typeof useCreateCardMutation>;
export type CreateCardMutationResult = Apollo.MutationResult<Types.CreateCardMutation>;
export type CreateCardMutationOptions = Apollo.BaseMutationOptions<Types.CreateCardMutation, Types.CreateCardMutationVariables>;
export const CreateSimplifiedChallengeDocument = gql`
    mutation CreateSimplifiedChallenge($company: CreateCompanyInput, $data: CreateSimplifiedChallengeInput!) {
  createSimplifiedChallenge(company: $company, data: $data) {
    id
    name
    image_avatar
    image_cover
    slug_name
    short_id
    company {
      profile {
        username
        profile_avatar
      }
    }
  }
}
    `;
export type CreateSimplifiedChallengeMutationFn = Apollo.MutationFunction<Types.CreateSimplifiedChallengeMutation, Types.CreateSimplifiedChallengeMutationVariables>;

/**
 * __useCreateSimplifiedChallengeMutation__
 *
 * To run a mutation, you first call `useCreateSimplifiedChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSimplifiedChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSimplifiedChallengeMutation, { data, loading, error }] = useCreateSimplifiedChallengeMutation({
 *   variables: {
 *      company: // value for 'company'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSimplifiedChallengeMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateSimplifiedChallengeMutation, Types.CreateSimplifiedChallengeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateSimplifiedChallengeMutation, Types.CreateSimplifiedChallengeMutationVariables>(CreateSimplifiedChallengeDocument, options);
      }
export type CreateSimplifiedChallengeMutationHookResult = ReturnType<typeof useCreateSimplifiedChallengeMutation>;
export type CreateSimplifiedChallengeMutationResult = Apollo.MutationResult<Types.CreateSimplifiedChallengeMutation>;
export type CreateSimplifiedChallengeMutationOptions = Apollo.BaseMutationOptions<Types.CreateSimplifiedChallengeMutation, Types.CreateSimplifiedChallengeMutationVariables>;
export const CreateUserAddressDocument = gql`
    mutation CreateUserAddress($data: CreateAddressInput!) {
  createUserAddress(data: $data) {
    id
    name
    zip_code
    street_address
    street_number
    neighborhood
    complement
    reference_point
    city_id
    is_billing_address
    user_id
  }
}
    `;
export type CreateUserAddressMutationFn = Apollo.MutationFunction<Types.CreateUserAddressMutation, Types.CreateUserAddressMutationVariables>;

/**
 * __useCreateUserAddressMutation__
 *
 * To run a mutation, you first call `useCreateUserAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserAddressMutation, { data, loading, error }] = useCreateUserAddressMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserAddressMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateUserAddressMutation, Types.CreateUserAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateUserAddressMutation, Types.CreateUserAddressMutationVariables>(CreateUserAddressDocument, options);
      }
export type CreateUserAddressMutationHookResult = ReturnType<typeof useCreateUserAddressMutation>;
export type CreateUserAddressMutationResult = Apollo.MutationResult<Types.CreateUserAddressMutation>;
export type CreateUserAddressMutationOptions = Apollo.BaseMutationOptions<Types.CreateUserAddressMutation, Types.CreateUserAddressMutationVariables>;
export const CreateUserEventExtraordinaryActionDocument = gql`
    mutation CreateUserEventExtraordinaryAction($data: CreateUserEventExtraordinaryActionInput!) {
  createUserEventExtraordinaryAction(data: $data) {
    active
  }
}
    `;
export type CreateUserEventExtraordinaryActionMutationFn = Apollo.MutationFunction<Types.CreateUserEventExtraordinaryActionMutation, Types.CreateUserEventExtraordinaryActionMutationVariables>;

/**
 * __useCreateUserEventExtraordinaryActionMutation__
 *
 * To run a mutation, you first call `useCreateUserEventExtraordinaryActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserEventExtraordinaryActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserEventExtraordinaryActionMutation, { data, loading, error }] = useCreateUserEventExtraordinaryActionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserEventExtraordinaryActionMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateUserEventExtraordinaryActionMutation, Types.CreateUserEventExtraordinaryActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateUserEventExtraordinaryActionMutation, Types.CreateUserEventExtraordinaryActionMutationVariables>(CreateUserEventExtraordinaryActionDocument, options);
      }
export type CreateUserEventExtraordinaryActionMutationHookResult = ReturnType<typeof useCreateUserEventExtraordinaryActionMutation>;
export type CreateUserEventExtraordinaryActionMutationResult = Apollo.MutationResult<Types.CreateUserEventExtraordinaryActionMutation>;
export type CreateUserEventExtraordinaryActionMutationOptions = Apollo.BaseMutationOptions<Types.CreateUserEventExtraordinaryActionMutation, Types.CreateUserEventExtraordinaryActionMutationVariables>;
export const DeleteCardDocument = gql`
    mutation DeleteCard($id: String!) {
  deleteCard(id: $id)
}
    `;
export type DeleteCardMutationFn = Apollo.MutationFunction<Types.DeleteCardMutation, Types.DeleteCardMutationVariables>;

/**
 * __useDeleteCardMutation__
 *
 * To run a mutation, you first call `useDeleteCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCardMutation, { data, loading, error }] = useDeleteCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCardMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCardMutation, Types.DeleteCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCardMutation, Types.DeleteCardMutationVariables>(DeleteCardDocument, options);
      }
export type DeleteCardMutationHookResult = ReturnType<typeof useDeleteCardMutation>;
export type DeleteCardMutationResult = Apollo.MutationResult<Types.DeleteCardMutation>;
export type DeleteCardMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCardMutation, Types.DeleteCardMutationVariables>;
export const DeleteUserAddressDocument = gql`
    mutation deleteUserAddress($data: DeleteAddressInput!) {
  deleteUserAddress(data: $data)
}
    `;
export type DeleteUserAddressMutationFn = Apollo.MutationFunction<Types.DeleteUserAddressMutation, Types.DeleteUserAddressMutationVariables>;

/**
 * __useDeleteUserAddressMutation__
 *
 * To run a mutation, you first call `useDeleteUserAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserAddressMutation, { data, loading, error }] = useDeleteUserAddressMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteUserAddressMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteUserAddressMutation, Types.DeleteUserAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteUserAddressMutation, Types.DeleteUserAddressMutationVariables>(DeleteUserAddressDocument, options);
      }
export type DeleteUserAddressMutationHookResult = ReturnType<typeof useDeleteUserAddressMutation>;
export type DeleteUserAddressMutationResult = Apollo.MutationResult<Types.DeleteUserAddressMutation>;
export type DeleteUserAddressMutationOptions = Apollo.BaseMutationOptions<Types.DeleteUserAddressMutation, Types.DeleteUserAddressMutationVariables>;
export const DisconnectGarminDocument = gql`
    mutation DisconnectGarmin($data: DisconnectThirdPartyProviderInput!) {
  disconnectGarmin(data: $data)
}
    `;
export type DisconnectGarminMutationFn = Apollo.MutationFunction<Types.DisconnectGarminMutation, Types.DisconnectGarminMutationVariables>;

/**
 * __useDisconnectGarminMutation__
 *
 * To run a mutation, you first call `useDisconnectGarminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectGarminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectGarminMutation, { data, loading, error }] = useDisconnectGarminMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDisconnectGarminMutation(baseOptions?: Apollo.MutationHookOptions<Types.DisconnectGarminMutation, Types.DisconnectGarminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DisconnectGarminMutation, Types.DisconnectGarminMutationVariables>(DisconnectGarminDocument, options);
      }
export type DisconnectGarminMutationHookResult = ReturnType<typeof useDisconnectGarminMutation>;
export type DisconnectGarminMutationResult = Apollo.MutationResult<Types.DisconnectGarminMutation>;
export type DisconnectGarminMutationOptions = Apollo.BaseMutationOptions<Types.DisconnectGarminMutation, Types.DisconnectGarminMutationVariables>;
export const DisconnectPolarDocument = gql`
    mutation DisconnectPolar($data: DisconnectThirdPartyProviderInput!) {
  disconnectPolar(data: $data)
}
    `;
export type DisconnectPolarMutationFn = Apollo.MutationFunction<Types.DisconnectPolarMutation, Types.DisconnectPolarMutationVariables>;

/**
 * __useDisconnectPolarMutation__
 *
 * To run a mutation, you first call `useDisconnectPolarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectPolarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectPolarMutation, { data, loading, error }] = useDisconnectPolarMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDisconnectPolarMutation(baseOptions?: Apollo.MutationHookOptions<Types.DisconnectPolarMutation, Types.DisconnectPolarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DisconnectPolarMutation, Types.DisconnectPolarMutationVariables>(DisconnectPolarDocument, options);
      }
export type DisconnectPolarMutationHookResult = ReturnType<typeof useDisconnectPolarMutation>;
export type DisconnectPolarMutationResult = Apollo.MutationResult<Types.DisconnectPolarMutation>;
export type DisconnectPolarMutationOptions = Apollo.BaseMutationOptions<Types.DisconnectPolarMutation, Types.DisconnectPolarMutationVariables>;
export const DisconnectWahooDocument = gql`
    mutation DisconnectWahoo {
  disconnectWahoo
}
    `;
export type DisconnectWahooMutationFn = Apollo.MutationFunction<Types.DisconnectWahooMutation, Types.DisconnectWahooMutationVariables>;

/**
 * __useDisconnectWahooMutation__
 *
 * To run a mutation, you first call `useDisconnectWahooMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectWahooMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectWahooMutation, { data, loading, error }] = useDisconnectWahooMutation({
 *   variables: {
 *   },
 * });
 */
export function useDisconnectWahooMutation(baseOptions?: Apollo.MutationHookOptions<Types.DisconnectWahooMutation, Types.DisconnectWahooMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DisconnectWahooMutation, Types.DisconnectWahooMutationVariables>(DisconnectWahooDocument, options);
      }
export type DisconnectWahooMutationHookResult = ReturnType<typeof useDisconnectWahooMutation>;
export type DisconnectWahooMutationResult = Apollo.MutationResult<Types.DisconnectWahooMutation>;
export type DisconnectWahooMutationOptions = Apollo.BaseMutationOptions<Types.DisconnectWahooMutation, Types.DisconnectWahooMutationVariables>;
export const DeleteEventRouteDocument = gql`
    mutation DeleteEventRoute($event_route_id: String!) {
  deleteEventRoute(event_route_id: $event_route_id)
}
    `;
export type DeleteEventRouteMutationFn = Apollo.MutationFunction<Types.DeleteEventRouteMutation, Types.DeleteEventRouteMutationVariables>;

/**
 * __useDeleteEventRouteMutation__
 *
 * To run a mutation, you first call `useDeleteEventRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventRouteMutation, { data, loading, error }] = useDeleteEventRouteMutation({
 *   variables: {
 *      event_route_id: // value for 'event_route_id'
 *   },
 * });
 */
export function useDeleteEventRouteMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteEventRouteMutation, Types.DeleteEventRouteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteEventRouteMutation, Types.DeleteEventRouteMutationVariables>(DeleteEventRouteDocument, options);
      }
export type DeleteEventRouteMutationHookResult = ReturnType<typeof useDeleteEventRouteMutation>;
export type DeleteEventRouteMutationResult = Apollo.MutationResult<Types.DeleteEventRouteMutation>;
export type DeleteEventRouteMutationOptions = Apollo.BaseMutationOptions<Types.DeleteEventRouteMutation, Types.DeleteEventRouteMutationVariables>;
export const GetShortenedUrlDetailDocument = gql`
    mutation GetShortenedUrlDetail($code: String!) {
  getShortenedUrlDetail(code: $code) {
    url
  }
}
    `;
export type GetShortenedUrlDetailMutationFn = Apollo.MutationFunction<Types.GetShortenedUrlDetailMutation, Types.GetShortenedUrlDetailMutationVariables>;

/**
 * __useGetShortenedUrlDetailMutation__
 *
 * To run a mutation, you first call `useGetShortenedUrlDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetShortenedUrlDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getShortenedUrlDetailMutation, { data, loading, error }] = useGetShortenedUrlDetailMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetShortenedUrlDetailMutation(baseOptions?: Apollo.MutationHookOptions<Types.GetShortenedUrlDetailMutation, Types.GetShortenedUrlDetailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.GetShortenedUrlDetailMutation, Types.GetShortenedUrlDetailMutationVariables>(GetShortenedUrlDetailDocument, options);
      }
export type GetShortenedUrlDetailMutationHookResult = ReturnType<typeof useGetShortenedUrlDetailMutation>;
export type GetShortenedUrlDetailMutationResult = Apollo.MutationResult<Types.GetShortenedUrlDetailMutation>;
export type GetShortenedUrlDetailMutationOptions = Apollo.BaseMutationOptions<Types.GetShortenedUrlDetailMutation, Types.GetShortenedUrlDetailMutationVariables>;
export const PaySplitPaymentDocument = gql`
    mutation PaySplitPayment($personal_update_data: UpdateProfilePersonalInput, $address_data: AddressesInput, $create_card: CreateCardInput, $payment_data: PaymentInput!, $user_challenge_id: String!) {
  paySplitPayment(
    personal_update_data: $personal_update_data
    address_data: $address_data
    create_card: $create_card
    payment_data: $payment_data
    user_challenge_id: $user_challenge_id
  ) {
    bill_link
    picpay_link
    picpay_qrcode
    picpay_expiration_date
    pix_qrcode
    pix_expiration_date
    installments
    refuse_reason
    is_paid
    card {
      brand
      first_digits
      last_digits
    }
  }
}
    `;
export type PaySplitPaymentMutationFn = Apollo.MutationFunction<Types.PaySplitPaymentMutation, Types.PaySplitPaymentMutationVariables>;

/**
 * __usePaySplitPaymentMutation__
 *
 * To run a mutation, you first call `usePaySplitPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaySplitPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paySplitPaymentMutation, { data, loading, error }] = usePaySplitPaymentMutation({
 *   variables: {
 *      personal_update_data: // value for 'personal_update_data'
 *      address_data: // value for 'address_data'
 *      create_card: // value for 'create_card'
 *      payment_data: // value for 'payment_data'
 *      user_challenge_id: // value for 'user_challenge_id'
 *   },
 * });
 */
export function usePaySplitPaymentMutation(baseOptions?: Apollo.MutationHookOptions<Types.PaySplitPaymentMutation, Types.PaySplitPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.PaySplitPaymentMutation, Types.PaySplitPaymentMutationVariables>(PaySplitPaymentDocument, options);
      }
export type PaySplitPaymentMutationHookResult = ReturnType<typeof usePaySplitPaymentMutation>;
export type PaySplitPaymentMutationResult = Apollo.MutationResult<Types.PaySplitPaymentMutation>;
export type PaySplitPaymentMutationOptions = Apollo.BaseMutationOptions<Types.PaySplitPaymentMutation, Types.PaySplitPaymentMutationVariables>;
export const RegisterUserDocument = gql`
    mutation RegisterUser($data: RegisterUserInput!) {
  Register(data: $data) {
    id
    firstname
    lastname
    email
    ddi
    phone
    name
    staff
  }
}
    `;
export type RegisterUserMutationFn = Apollo.MutationFunction<Types.RegisterUserMutation, Types.RegisterUserMutationVariables>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegisterUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.RegisterUserMutation, Types.RegisterUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RegisterUserMutation, Types.RegisterUserMutationVariables>(RegisterUserDocument, options);
      }
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = Apollo.MutationResult<Types.RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<Types.RegisterUserMutation, Types.RegisterUserMutationVariables>;
export const SaveCheckoutEntrieDocument = gql`
    mutation SaveCheckoutEntrie($data: SaveUserCheckoutEntrieInput!) {
  saveCheckoutEntrie(data: $data) {
    id
    user_id
  }
}
    `;
export type SaveCheckoutEntrieMutationFn = Apollo.MutationFunction<Types.SaveCheckoutEntrieMutation, Types.SaveCheckoutEntrieMutationVariables>;

/**
 * __useSaveCheckoutEntrieMutation__
 *
 * To run a mutation, you first call `useSaveCheckoutEntrieMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCheckoutEntrieMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCheckoutEntrieMutation, { data, loading, error }] = useSaveCheckoutEntrieMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSaveCheckoutEntrieMutation(baseOptions?: Apollo.MutationHookOptions<Types.SaveCheckoutEntrieMutation, Types.SaveCheckoutEntrieMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SaveCheckoutEntrieMutation, Types.SaveCheckoutEntrieMutationVariables>(SaveCheckoutEntrieDocument, options);
      }
export type SaveCheckoutEntrieMutationHookResult = ReturnType<typeof useSaveCheckoutEntrieMutation>;
export type SaveCheckoutEntrieMutationResult = Apollo.MutationResult<Types.SaveCheckoutEntrieMutation>;
export type SaveCheckoutEntrieMutationOptions = Apollo.BaseMutationOptions<Types.SaveCheckoutEntrieMutation, Types.SaveCheckoutEntrieMutationVariables>;
export const SendGenericMailDocument = gql`
    mutation sendGenericMail($data: SendGenericMailInput!) {
  sendGenericMail(data: $data)
}
    `;
export type SendGenericMailMutationFn = Apollo.MutationFunction<Types.SendGenericMailMutation, Types.SendGenericMailMutationVariables>;

/**
 * __useSendGenericMailMutation__
 *
 * To run a mutation, you first call `useSendGenericMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendGenericMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendGenericMailMutation, { data, loading, error }] = useSendGenericMailMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendGenericMailMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendGenericMailMutation, Types.SendGenericMailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendGenericMailMutation, Types.SendGenericMailMutationVariables>(SendGenericMailDocument, options);
      }
export type SendGenericMailMutationHookResult = ReturnType<typeof useSendGenericMailMutation>;
export type SendGenericMailMutationResult = Apollo.MutationResult<Types.SendGenericMailMutation>;
export type SendGenericMailMutationOptions = Apollo.BaseMutationOptions<Types.SendGenericMailMutation, Types.SendGenericMailMutationVariables>;
export const SendGenericSmsDocument = gql`
    mutation SendGenericSMS($data: SendGenericSMSInput!) {
  sendGenericSMS(data: $data)
}
    `;
export type SendGenericSmsMutationFn = Apollo.MutationFunction<Types.SendGenericSmsMutation, Types.SendGenericSmsMutationVariables>;

/**
 * __useSendGenericSmsMutation__
 *
 * To run a mutation, you first call `useSendGenericSmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendGenericSmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendGenericSmsMutation, { data, loading, error }] = useSendGenericSmsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendGenericSmsMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendGenericSmsMutation, Types.SendGenericSmsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendGenericSmsMutation, Types.SendGenericSmsMutationVariables>(SendGenericSmsDocument, options);
      }
export type SendGenericSmsMutationHookResult = ReturnType<typeof useSendGenericSmsMutation>;
export type SendGenericSmsMutationResult = Apollo.MutationResult<Types.SendGenericSmsMutation>;
export type SendGenericSmsMutationOptions = Apollo.BaseMutationOptions<Types.SendGenericSmsMutation, Types.SendGenericSmsMutationVariables>;
export const SendMailDocument = gql`
    mutation SendMail($data: SendEmailInput!) {
  sendMail(data: $data)
}
    `;
export type SendMailMutationFn = Apollo.MutationFunction<Types.SendMailMutation, Types.SendMailMutationVariables>;

/**
 * __useSendMailMutation__
 *
 * To run a mutation, you first call `useSendMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMailMutation, { data, loading, error }] = useSendMailMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendMailMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendMailMutation, Types.SendMailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendMailMutation, Types.SendMailMutationVariables>(SendMailDocument, options);
      }
export type SendMailMutationHookResult = ReturnType<typeof useSendMailMutation>;
export type SendMailMutationResult = Apollo.MutationResult<Types.SendMailMutation>;
export type SendMailMutationOptions = Apollo.BaseMutationOptions<Types.SendMailMutation, Types.SendMailMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($data: ChangePasswordInput!) {
  changePassword(data: $data)
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<Types.ChangePasswordMutation, Types.ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<Types.ChangePasswordMutation, Types.ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ChangePasswordMutation, Types.ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<Types.ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<Types.ChangePasswordMutation, Types.ChangePasswordMutationVariables>;
export const ChangeUserPasswordDocument = gql`
    mutation ChangeUserPassword($data: RecoverUserPasswordInput!) {
  changeUserPassword(data: $data) {
    active
  }
}
    `;
export type ChangeUserPasswordMutationFn = Apollo.MutationFunction<Types.ChangeUserPasswordMutation, Types.ChangeUserPasswordMutationVariables>;

/**
 * __useChangeUserPasswordMutation__
 *
 * To run a mutation, you first call `useChangeUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserPasswordMutation, { data, loading, error }] = useChangeUserPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChangeUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<Types.ChangeUserPasswordMutation, Types.ChangeUserPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ChangeUserPasswordMutation, Types.ChangeUserPasswordMutationVariables>(ChangeUserPasswordDocument, options);
      }
export type ChangeUserPasswordMutationHookResult = ReturnType<typeof useChangeUserPasswordMutation>;
export type ChangeUserPasswordMutationResult = Apollo.MutationResult<Types.ChangeUserPasswordMutation>;
export type ChangeUserPasswordMutationOptions = Apollo.BaseMutationOptions<Types.ChangeUserPasswordMutation, Types.ChangeUserPasswordMutationVariables>;
export const LoginDocument = gql`
    mutation Login($data: LoginUserInput!) {
  login(data: $data) {
    user {
      id
      firstname
      lastname
      name
      email
      date_of_birth
      gender
      ddi
      phone
      legal_registry_number
      staff
      profile {
        id
        username
        user_id
        profile_avatar
        profile_cover
      }
    }
    profile {
      id
      username
      user_id
      profile_avatar
      profile_cover
    }
    accessToken
    refreshToken
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<Types.LoginMutation, Types.LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<Types.LoginMutation, Types.LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.LoginMutation, Types.LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<Types.LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<Types.LoginMutation, Types.LoginMutationVariables>;
export const RecoverPasswordDocument = gql`
    mutation RecoverPassword($email: String!) {
  recoverPassord(email: $email)
}
    `;
export type RecoverPasswordMutationFn = Apollo.MutationFunction<Types.RecoverPasswordMutation, Types.RecoverPasswordMutationVariables>;

/**
 * __useRecoverPasswordMutation__
 *
 * To run a mutation, you first call `useRecoverPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecoverPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recoverPasswordMutation, { data, loading, error }] = useRecoverPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRecoverPasswordMutation(baseOptions?: Apollo.MutationHookOptions<Types.RecoverPasswordMutation, Types.RecoverPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RecoverPasswordMutation, Types.RecoverPasswordMutationVariables>(RecoverPasswordDocument, options);
      }
export type RecoverPasswordMutationHookResult = ReturnType<typeof useRecoverPasswordMutation>;
export type RecoverPasswordMutationResult = Apollo.MutationResult<Types.RecoverPasswordMutation>;
export type RecoverPasswordMutationOptions = Apollo.BaseMutationOptions<Types.RecoverPasswordMutation, Types.RecoverPasswordMutationVariables>;
export const RegisterAppleUserDocument = gql`
    mutation RegisterAppleUser($data: CreateAppleUserInput!) {
  registerAppleUser(data: $data) {
    user {
      id
      firstname
      lastname
      email
      name
      ddi
      phone
      date_of_birth
      legal_registry_number
      staff
    }
    profile {
      id
      username
      profile_avatar
      profile_cover
    }
    accessToken
    refreshToken
  }
}
    `;
export type RegisterAppleUserMutationFn = Apollo.MutationFunction<Types.RegisterAppleUserMutation, Types.RegisterAppleUserMutationVariables>;

/**
 * __useRegisterAppleUserMutation__
 *
 * To run a mutation, you first call `useRegisterAppleUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterAppleUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerAppleUserMutation, { data, loading, error }] = useRegisterAppleUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegisterAppleUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.RegisterAppleUserMutation, Types.RegisterAppleUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RegisterAppleUserMutation, Types.RegisterAppleUserMutationVariables>(RegisterAppleUserDocument, options);
      }
export type RegisterAppleUserMutationHookResult = ReturnType<typeof useRegisterAppleUserMutation>;
export type RegisterAppleUserMutationResult = Apollo.MutationResult<Types.RegisterAppleUserMutation>;
export type RegisterAppleUserMutationOptions = Apollo.BaseMutationOptions<Types.RegisterAppleUserMutation, Types.RegisterAppleUserMutationVariables>;
export const RegisterFacebookUserDocument = gql`
    mutation RegisterFacebookUser($data: CreateFacebookUserInput!) {
  registerFacebookUser(data: $data) {
    user {
      ...User
    }
    profile {
      ...Profile
    }
    accessToken
    refreshToken
  }
}
    ${UserFragmentDoc}
${ProfileFragmentDoc}`;
export type RegisterFacebookUserMutationFn = Apollo.MutationFunction<Types.RegisterFacebookUserMutation, Types.RegisterFacebookUserMutationVariables>;

/**
 * __useRegisterFacebookUserMutation__
 *
 * To run a mutation, you first call `useRegisterFacebookUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterFacebookUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerFacebookUserMutation, { data, loading, error }] = useRegisterFacebookUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegisterFacebookUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.RegisterFacebookUserMutation, Types.RegisterFacebookUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RegisterFacebookUserMutation, Types.RegisterFacebookUserMutationVariables>(RegisterFacebookUserDocument, options);
      }
export type RegisterFacebookUserMutationHookResult = ReturnType<typeof useRegisterFacebookUserMutation>;
export type RegisterFacebookUserMutationResult = Apollo.MutationResult<Types.RegisterFacebookUserMutation>;
export type RegisterFacebookUserMutationOptions = Apollo.BaseMutationOptions<Types.RegisterFacebookUserMutation, Types.RegisterFacebookUserMutationVariables>;
export const RegisterGoogleUserDocument = gql`
    mutation RegisterGoogleUser($data: CreateGoogleUserInput!) {
  registerGoogleUser(data: $data) {
    user {
      id
      name
      firstname
      lastname
      email
      ddi
      phone
      date_of_birth
      legal_registry_number
      staff
    }
    profile {
      id
      username
      profile_avatar
      profile_cover
    }
    accessToken
    refreshToken
  }
}
    `;
export type RegisterGoogleUserMutationFn = Apollo.MutationFunction<Types.RegisterGoogleUserMutation, Types.RegisterGoogleUserMutationVariables>;

/**
 * __useRegisterGoogleUserMutation__
 *
 * To run a mutation, you first call `useRegisterGoogleUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterGoogleUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerGoogleUserMutation, { data, loading, error }] = useRegisterGoogleUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegisterGoogleUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.RegisterGoogleUserMutation, Types.RegisterGoogleUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RegisterGoogleUserMutation, Types.RegisterGoogleUserMutationVariables>(RegisterGoogleUserDocument, options);
      }
export type RegisterGoogleUserMutationHookResult = ReturnType<typeof useRegisterGoogleUserMutation>;
export type RegisterGoogleUserMutationResult = Apollo.MutationResult<Types.RegisterGoogleUserMutation>;
export type RegisterGoogleUserMutationOptions = Apollo.BaseMutationOptions<Types.RegisterGoogleUserMutation, Types.RegisterGoogleUserMutationVariables>;
export const SubscribeUserChallengeDocument = gql`
    mutation SubscribeUserChallenge($billing_address_id: String, $shipping_address_id: String, $products_purchased: [ProductPurchasedInput!], $award_data: ChallengePaymentInput, $challenge_award_requests: [ChallengeAwardAdditionalRequestInput!], $data: SubscribeUserChallengeInput!) {
  subscribeUserChallenge(
    billing_address_id: $billing_address_id
    shipping_address_id: $shipping_address_id
    products_purchased: $products_purchased
    award_data: $award_data
    challenge_award_requests: $challenge_award_requests
    data: $data
  ) {
    registration_date
    payment_id
    id
    user_id
    classification
    paid
    amount_paid
    amount_to_pay
    ready_to_withdraw
    withdrawal_date
    short_id
    created_at
    last_payment_id
  }
}
    `;
export type SubscribeUserChallengeMutationFn = Apollo.MutationFunction<Types.SubscribeUserChallengeMutation, Types.SubscribeUserChallengeMutationVariables>;

/**
 * __useSubscribeUserChallengeMutation__
 *
 * To run a mutation, you first call `useSubscribeUserChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeUserChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeUserChallengeMutation, { data, loading, error }] = useSubscribeUserChallengeMutation({
 *   variables: {
 *      billing_address_id: // value for 'billing_address_id'
 *      shipping_address_id: // value for 'shipping_address_id'
 *      products_purchased: // value for 'products_purchased'
 *      award_data: // value for 'award_data'
 *      challenge_award_requests: // value for 'challenge_award_requests'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSubscribeUserChallengeMutation(baseOptions?: Apollo.MutationHookOptions<Types.SubscribeUserChallengeMutation, Types.SubscribeUserChallengeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SubscribeUserChallengeMutation, Types.SubscribeUserChallengeMutationVariables>(SubscribeUserChallengeDocument, options);
      }
export type SubscribeUserChallengeMutationHookResult = ReturnType<typeof useSubscribeUserChallengeMutation>;
export type SubscribeUserChallengeMutationResult = Apollo.MutationResult<Types.SubscribeUserChallengeMutation>;
export type SubscribeUserChallengeMutationOptions = Apollo.BaseMutationOptions<Types.SubscribeUserChallengeMutation, Types.SubscribeUserChallengeMutationVariables>;
export const BuyProductUserAlreadySubscribedDocument = gql`
    mutation BuyProductUserAlreadySubscribed($products_purchased: [ProductPurchasedInput!]!, $user_id: String!, $user_challenge_id: String!, $payment_data: ProductsPurchasedPaymentInput, $data: BuyProductUserAlreadySubscribedInput!) {
  buyProductUserAlreadySubscribed(
    products_purchased: $products_purchased
    user_id: $user_id
    user_challenge_id: $user_challenge_id
    payment_data: $payment_data
    data: $data
  ) {
    products_payment {
      active
      payment {
        is_paid
        refuse_reason
        bill_link
        picpay_link
        paypal_link
        picpay_qrcode
        picpay_expiration_date
        pix_qrcode
        pix_expiration_date
        installments
        card {
          brand
          first_digits
          last_digits
        }
      }
    }
  }
}
    `;
export type BuyProductUserAlreadySubscribedMutationFn = Apollo.MutationFunction<Types.BuyProductUserAlreadySubscribedMutation, Types.BuyProductUserAlreadySubscribedMutationVariables>;

/**
 * __useBuyProductUserAlreadySubscribedMutation__
 *
 * To run a mutation, you first call `useBuyProductUserAlreadySubscribedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuyProductUserAlreadySubscribedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buyProductUserAlreadySubscribedMutation, { data, loading, error }] = useBuyProductUserAlreadySubscribedMutation({
 *   variables: {
 *      products_purchased: // value for 'products_purchased'
 *      user_id: // value for 'user_id'
 *      user_challenge_id: // value for 'user_challenge_id'
 *      payment_data: // value for 'payment_data'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useBuyProductUserAlreadySubscribedMutation(baseOptions?: Apollo.MutationHookOptions<Types.BuyProductUserAlreadySubscribedMutation, Types.BuyProductUserAlreadySubscribedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.BuyProductUserAlreadySubscribedMutation, Types.BuyProductUserAlreadySubscribedMutationVariables>(BuyProductUserAlreadySubscribedDocument, options);
      }
export type BuyProductUserAlreadySubscribedMutationHookResult = ReturnType<typeof useBuyProductUserAlreadySubscribedMutation>;
export type BuyProductUserAlreadySubscribedMutationResult = Apollo.MutationResult<Types.BuyProductUserAlreadySubscribedMutation>;
export type BuyProductUserAlreadySubscribedMutationOptions = Apollo.BaseMutationOptions<Types.BuyProductUserAlreadySubscribedMutation, Types.BuyProductUserAlreadySubscribedMutationVariables>;
export const BuyVouchersDocument = gql`
    mutation BuyVouchers($payment_data: BuyVoucherInput!, $vouchers: [VoucherInput!]!) {
  buyVouchers(payment_data: $payment_data, vouchers: $vouchers) {
    id
    code
    payment {
      id
      value
      installments
      bill_link
      bill_barcode
      pix_qrcode
      pix_expiration_date
      picpay_qrcode
      picpay_expiration_date
      picpay_link
      paypal_link
      card {
        brand
        first_digits
        last_digits
      }
    }
  }
}
    `;
export type BuyVouchersMutationFn = Apollo.MutationFunction<Types.BuyVouchersMutation, Types.BuyVouchersMutationVariables>;

/**
 * __useBuyVouchersMutation__
 *
 * To run a mutation, you first call `useBuyVouchersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuyVouchersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buyVouchersMutation, { data, loading, error }] = useBuyVouchersMutation({
 *   variables: {
 *      payment_data: // value for 'payment_data'
 *      vouchers: // value for 'vouchers'
 *   },
 * });
 */
export function useBuyVouchersMutation(baseOptions?: Apollo.MutationHookOptions<Types.BuyVouchersMutation, Types.BuyVouchersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.BuyVouchersMutation, Types.BuyVouchersMutationVariables>(BuyVouchersDocument, options);
      }
export type BuyVouchersMutationHookResult = ReturnType<typeof useBuyVouchersMutation>;
export type BuyVouchersMutationResult = Apollo.MutationResult<Types.BuyVouchersMutation>;
export type BuyVouchersMutationOptions = Apollo.BaseMutationOptions<Types.BuyVouchersMutation, Types.BuyVouchersMutationVariables>;
export const CreateChallengeSubscriptionsDocument = gql`
    mutation CreateChallengeSubscriptions($card_creation_data: CreateCardInput, $personal_update_data: UpdateProfilePersonalInput, $address_data: AddressesInput, $payment_data: PaymentInput, $data: [SubscribeManyUsersInput!]!, $split_payment: [SplitPaymentInput!], $challenge_id: String!) {
  createChallengeSubscriptions(
    card_creation_data: $card_creation_data
    personal_update_data: $personal_update_data
    address_data: $address_data
    payment_data: $payment_data
    data: $data
    split_payment: $split_payment
    challenge_id: $challenge_id
  ) {
    allSubscriptions {
      id
      challenge_id
      user_id
      subscribed_persons {
        id
        name
        legal_registry_number
        riderize_user_id
      }
      challenge {
        challenge_categories {
          id
          name
          challenge_category_up_selling {
            challenge_category {
              name
            }
            challenge {
              name
              image_avatar
              id
            }
            desktop_banner_link
            mobile_banner_link
            challenge_id
            fixed_discount
            percentage_discount
          }
        }
      }
      challenge_category_id
      short_id
      update_subscription_url
      amount_to_pay
      last_payment {
        is_paid
        refuse_reason
        bill_link
        picpay_link
        picpay_qrcode
        picpay_expiration_date
        pix_qrcode
        pix_expiration_date
        paypal_link
        installments
        card {
          brand
          first_digits
          last_digits
        }
      }
      payment_split {
        user_payment_splits {
          value
        }
      }
      user {
        id
        firstname
        lastname
        email
        profile {
          profile_avatar
        }
      }
    }
    errors
  }
}
    `;
export type CreateChallengeSubscriptionsMutationFn = Apollo.MutationFunction<Types.CreateChallengeSubscriptionsMutation, Types.CreateChallengeSubscriptionsMutationVariables>;

/**
 * __useCreateChallengeSubscriptionsMutation__
 *
 * To run a mutation, you first call `useCreateChallengeSubscriptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChallengeSubscriptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChallengeSubscriptionsMutation, { data, loading, error }] = useCreateChallengeSubscriptionsMutation({
 *   variables: {
 *      card_creation_data: // value for 'card_creation_data'
 *      personal_update_data: // value for 'personal_update_data'
 *      address_data: // value for 'address_data'
 *      payment_data: // value for 'payment_data'
 *      data: // value for 'data'
 *      split_payment: // value for 'split_payment'
 *      challenge_id: // value for 'challenge_id'
 *   },
 * });
 */
export function useCreateChallengeSubscriptionsMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateChallengeSubscriptionsMutation, Types.CreateChallengeSubscriptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateChallengeSubscriptionsMutation, Types.CreateChallengeSubscriptionsMutationVariables>(CreateChallengeSubscriptionsDocument, options);
      }
export type CreateChallengeSubscriptionsMutationHookResult = ReturnType<typeof useCreateChallengeSubscriptionsMutation>;
export type CreateChallengeSubscriptionsMutationResult = Apollo.MutationResult<Types.CreateChallengeSubscriptionsMutation>;
export type CreateChallengeSubscriptionsMutationOptions = Apollo.BaseMutationOptions<Types.CreateChallengeSubscriptionsMutation, Types.CreateChallengeSubscriptionsMutationVariables>;
export const CreateSubscribedPersonsDocument = gql`
    mutation CreateSubscribedPersons($data: CreateSubscribedPersonsInput!) {
  createSubscribedPersons(data: $data) {
    id
    first_name
    last_name
    name
    date_of_birth
    gender
  }
}
    `;
export type CreateSubscribedPersonsMutationFn = Apollo.MutationFunction<Types.CreateSubscribedPersonsMutation, Types.CreateSubscribedPersonsMutationVariables>;

/**
 * __useCreateSubscribedPersonsMutation__
 *
 * To run a mutation, you first call `useCreateSubscribedPersonsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscribedPersonsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscribedPersonsMutation, { data, loading, error }] = useCreateSubscribedPersonsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSubscribedPersonsMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateSubscribedPersonsMutation, Types.CreateSubscribedPersonsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateSubscribedPersonsMutation, Types.CreateSubscribedPersonsMutationVariables>(CreateSubscribedPersonsDocument, options);
      }
export type CreateSubscribedPersonsMutationHookResult = ReturnType<typeof useCreateSubscribedPersonsMutation>;
export type CreateSubscribedPersonsMutationResult = Apollo.MutationResult<Types.CreateSubscribedPersonsMutation>;
export type CreateSubscribedPersonsMutationOptions = Apollo.BaseMutationOptions<Types.CreateSubscribedPersonsMutation, Types.CreateSubscribedPersonsMutationVariables>;
export const SubscribedPersonDetailDocument = gql`
    query SubscribedPersonDetail($id: String!) {
  subscribedPersonDetail(id: $id) {
    id
    riderize_user_id
    first_name
    last_name
    name
    gender
    date_of_birth
    document_type
    legal_registry_number
    phone
    ddi
    email
    team_name
    country_id
    zip_code
    address_line_one
    address_line_two
    city
    state
    creator_id
  }
}
    `;

/**
 * __useSubscribedPersonDetailQuery__
 *
 * To run a query within a React component, call `useSubscribedPersonDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscribedPersonDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribedPersonDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubscribedPersonDetailQuery(baseOptions: Apollo.QueryHookOptions<Types.SubscribedPersonDetailQuery, Types.SubscribedPersonDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SubscribedPersonDetailQuery, Types.SubscribedPersonDetailQueryVariables>(SubscribedPersonDetailDocument, options);
      }
export function useSubscribedPersonDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SubscribedPersonDetailQuery, Types.SubscribedPersonDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SubscribedPersonDetailQuery, Types.SubscribedPersonDetailQueryVariables>(SubscribedPersonDetailDocument, options);
        }
export type SubscribedPersonDetailQueryHookResult = ReturnType<typeof useSubscribedPersonDetailQuery>;
export type SubscribedPersonDetailLazyQueryHookResult = ReturnType<typeof useSubscribedPersonDetailLazyQuery>;
export type SubscribedPersonDetailQueryResult = Apollo.QueryResult<Types.SubscribedPersonDetailQuery, Types.SubscribedPersonDetailQueryVariables>;
export const SubscribedPersonDetailNotLoggedDocument = gql`
    query SubscribedPersonDetailNotLogged($id: String!) {
  subscribedPersonDetailNotLogged(id: $id) {
    id
    riderize_user_id
    first_name
    last_name
    name
    gender
    date_of_birth
    document_type
    legal_registry_number
    phone
    ddi
    email
    team_name
    country_id
    zip_code
    address_line_one
    address_line_two
    city
    state
    creator_id
  }
}
    `;

/**
 * __useSubscribedPersonDetailNotLoggedQuery__
 *
 * To run a query within a React component, call `useSubscribedPersonDetailNotLoggedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscribedPersonDetailNotLoggedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribedPersonDetailNotLoggedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubscribedPersonDetailNotLoggedQuery(baseOptions: Apollo.QueryHookOptions<Types.SubscribedPersonDetailNotLoggedQuery, Types.SubscribedPersonDetailNotLoggedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SubscribedPersonDetailNotLoggedQuery, Types.SubscribedPersonDetailNotLoggedQueryVariables>(SubscribedPersonDetailNotLoggedDocument, options);
      }
export function useSubscribedPersonDetailNotLoggedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SubscribedPersonDetailNotLoggedQuery, Types.SubscribedPersonDetailNotLoggedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SubscribedPersonDetailNotLoggedQuery, Types.SubscribedPersonDetailNotLoggedQueryVariables>(SubscribedPersonDetailNotLoggedDocument, options);
        }
export type SubscribedPersonDetailNotLoggedQueryHookResult = ReturnType<typeof useSubscribedPersonDetailNotLoggedQuery>;
export type SubscribedPersonDetailNotLoggedLazyQueryHookResult = ReturnType<typeof useSubscribedPersonDetailNotLoggedLazyQuery>;
export type SubscribedPersonDetailNotLoggedQueryResult = Apollo.QueryResult<Types.SubscribedPersonDetailNotLoggedQuery, Types.SubscribedPersonDetailNotLoggedQueryVariables>;
export const UpdateSubscribedPersonsDocument = gql`
    mutation UpdateSubscribedPersons($data: UpdateSubscribedPersonsInput!) {
  updateSubscribedPersons(data: $data) {
    id
    first_name
    last_name
    date_of_birth
    gender
  }
}
    `;
export type UpdateSubscribedPersonsMutationFn = Apollo.MutationFunction<Types.UpdateSubscribedPersonsMutation, Types.UpdateSubscribedPersonsMutationVariables>;

/**
 * __useUpdateSubscribedPersonsMutation__
 *
 * To run a mutation, you first call `useUpdateSubscribedPersonsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscribedPersonsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscribedPersonsMutation, { data, loading, error }] = useUpdateSubscribedPersonsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSubscribedPersonsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateSubscribedPersonsMutation, Types.UpdateSubscribedPersonsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateSubscribedPersonsMutation, Types.UpdateSubscribedPersonsMutationVariables>(UpdateSubscribedPersonsDocument, options);
      }
export type UpdateSubscribedPersonsMutationHookResult = ReturnType<typeof useUpdateSubscribedPersonsMutation>;
export type UpdateSubscribedPersonsMutationResult = Apollo.MutationResult<Types.UpdateSubscribedPersonsMutation>;
export type UpdateSubscribedPersonsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateSubscribedPersonsMutation, Types.UpdateSubscribedPersonsMutationVariables>;
export const UpdatePreRegisteredUserDocument = gql`
    mutation UpdatePreRegisteredUser($data: UpdatePreRegisteredUserInput!) {
  updatePreRegisteredUser(data: $data) {
    active
  }
}
    `;
export type UpdatePreRegisteredUserMutationFn = Apollo.MutationFunction<Types.UpdatePreRegisteredUserMutation, Types.UpdatePreRegisteredUserMutationVariables>;

/**
 * __useUpdatePreRegisteredUserMutation__
 *
 * To run a mutation, you first call `useUpdatePreRegisteredUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePreRegisteredUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePreRegisteredUserMutation, { data, loading, error }] = useUpdatePreRegisteredUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePreRegisteredUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePreRegisteredUserMutation, Types.UpdatePreRegisteredUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePreRegisteredUserMutation, Types.UpdatePreRegisteredUserMutationVariables>(UpdatePreRegisteredUserDocument, options);
      }
export type UpdatePreRegisteredUserMutationHookResult = ReturnType<typeof useUpdatePreRegisteredUserMutation>;
export type UpdatePreRegisteredUserMutationResult = Apollo.MutationResult<Types.UpdatePreRegisteredUserMutation>;
export type UpdatePreRegisteredUserMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePreRegisteredUserMutation, Types.UpdatePreRegisteredUserMutationVariables>;
export const UpdateProfilePersonalDocument = gql`
    mutation UpdateProfilePersonal($data: UpdateProfilePersonalInput!) {
  updateProfilePersonal(data: $data) {
    id
    username
  }
}
    `;
export type UpdateProfilePersonalMutationFn = Apollo.MutationFunction<Types.UpdateProfilePersonalMutation, Types.UpdateProfilePersonalMutationVariables>;

/**
 * __useUpdateProfilePersonalMutation__
 *
 * To run a mutation, you first call `useUpdateProfilePersonalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfilePersonalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfilePersonalMutation, { data, loading, error }] = useUpdateProfilePersonalMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProfilePersonalMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateProfilePersonalMutation, Types.UpdateProfilePersonalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateProfilePersonalMutation, Types.UpdateProfilePersonalMutationVariables>(UpdateProfilePersonalDocument, options);
      }
export type UpdateProfilePersonalMutationHookResult = ReturnType<typeof useUpdateProfilePersonalMutation>;
export type UpdateProfilePersonalMutationResult = Apollo.MutationResult<Types.UpdateProfilePersonalMutation>;
export type UpdateProfilePersonalMutationOptions = Apollo.BaseMutationOptions<Types.UpdateProfilePersonalMutation, Types.UpdateProfilePersonalMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($data: UpdateUserInput!, $id: String!) {
  updateUser(data: $data, id: $id) {
    id
    firstname
    lastname
    email
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<Types.UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>;
export const UpdateUserAddressDocument = gql`
    mutation UpdateUserAddress($data: UpdateAddressInput!) {
  updateUserAddress(data: $data) {
    id
    name
    zip_code
    street_address
    street_number
    neighborhood
    complement
    reference_point
    city_id
    is_billing_address
    user_id
    updated_at
  }
}
    `;
export type UpdateUserAddressMutationFn = Apollo.MutationFunction<Types.UpdateUserAddressMutation, Types.UpdateUserAddressMutationVariables>;

/**
 * __useUpdateUserAddressMutation__
 *
 * To run a mutation, you first call `useUpdateUserAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAddressMutation, { data, loading, error }] = useUpdateUserAddressMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserAddressMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserAddressMutation, Types.UpdateUserAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateUserAddressMutation, Types.UpdateUserAddressMutationVariables>(UpdateUserAddressDocument, options);
      }
export type UpdateUserAddressMutationHookResult = ReturnType<typeof useUpdateUserAddressMutation>;
export type UpdateUserAddressMutationResult = Apollo.MutationResult<Types.UpdateUserAddressMutation>;
export type UpdateUserAddressMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserAddressMutation, Types.UpdateUserAddressMutationVariables>;
export const VerifyEmailCodeDocument = gql`
    mutation VerifyEmailCode($data: VerifyEmailInput!) {
  verifyEmailCode(data: $data) {
    email
    user {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;
export type VerifyEmailCodeMutationFn = Apollo.MutationFunction<Types.VerifyEmailCodeMutation, Types.VerifyEmailCodeMutationVariables>;

/**
 * __useVerifyEmailCodeMutation__
 *
 * To run a mutation, you first call `useVerifyEmailCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailCodeMutation, { data, loading, error }] = useVerifyEmailCodeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useVerifyEmailCodeMutation(baseOptions?: Apollo.MutationHookOptions<Types.VerifyEmailCodeMutation, Types.VerifyEmailCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.VerifyEmailCodeMutation, Types.VerifyEmailCodeMutationVariables>(VerifyEmailCodeDocument, options);
      }
export type VerifyEmailCodeMutationHookResult = ReturnType<typeof useVerifyEmailCodeMutation>;
export type VerifyEmailCodeMutationResult = Apollo.MutationResult<Types.VerifyEmailCodeMutation>;
export type VerifyEmailCodeMutationOptions = Apollo.BaseMutationOptions<Types.VerifyEmailCodeMutation, Types.VerifyEmailCodeMutationVariables>;
export const VerifyPhoneNumberDocument = gql`
    mutation VerifyPhoneNumber($data: PhoneInput!) {
  verifyPhoneNumber(data: $data) {
    user {
      ...User
    }
    codeStatus
  }
}
    ${UserFragmentDoc}`;
export type VerifyPhoneNumberMutationFn = Apollo.MutationFunction<Types.VerifyPhoneNumberMutation, Types.VerifyPhoneNumberMutationVariables>;

/**
 * __useVerifyPhoneNumberMutation__
 *
 * To run a mutation, you first call `useVerifyPhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyPhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyPhoneNumberMutation, { data, loading, error }] = useVerifyPhoneNumberMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useVerifyPhoneNumberMutation(baseOptions?: Apollo.MutationHookOptions<Types.VerifyPhoneNumberMutation, Types.VerifyPhoneNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.VerifyPhoneNumberMutation, Types.VerifyPhoneNumberMutationVariables>(VerifyPhoneNumberDocument, options);
      }
export type VerifyPhoneNumberMutationHookResult = ReturnType<typeof useVerifyPhoneNumberMutation>;
export type VerifyPhoneNumberMutationResult = Apollo.MutationResult<Types.VerifyPhoneNumberMutation>;
export type VerifyPhoneNumberMutationOptions = Apollo.BaseMutationOptions<Types.VerifyPhoneNumberMutation, Types.VerifyPhoneNumberMutationVariables>;
export const RemoveWorkshopSubscriptionPlanFromSaleDocument = gql`
    mutation RemoveWorkshopSubscriptionPlanFromSale($data: DisableWorkshopSubscriptionPlanInput!) {
  removeWorkshopSubscriptionPlanFromSale(data: $data)
}
    `;
export type RemoveWorkshopSubscriptionPlanFromSaleMutationFn = Apollo.MutationFunction<Types.RemoveWorkshopSubscriptionPlanFromSaleMutation, Types.RemoveWorkshopSubscriptionPlanFromSaleMutationVariables>;

/**
 * __useRemoveWorkshopSubscriptionPlanFromSaleMutation__
 *
 * To run a mutation, you first call `useRemoveWorkshopSubscriptionPlanFromSaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWorkshopSubscriptionPlanFromSaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWorkshopSubscriptionPlanFromSaleMutation, { data, loading, error }] = useRemoveWorkshopSubscriptionPlanFromSaleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveWorkshopSubscriptionPlanFromSaleMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveWorkshopSubscriptionPlanFromSaleMutation, Types.RemoveWorkshopSubscriptionPlanFromSaleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveWorkshopSubscriptionPlanFromSaleMutation, Types.RemoveWorkshopSubscriptionPlanFromSaleMutationVariables>(RemoveWorkshopSubscriptionPlanFromSaleDocument, options);
      }
export type RemoveWorkshopSubscriptionPlanFromSaleMutationHookResult = ReturnType<typeof useRemoveWorkshopSubscriptionPlanFromSaleMutation>;
export type RemoveWorkshopSubscriptionPlanFromSaleMutationResult = Apollo.MutationResult<Types.RemoveWorkshopSubscriptionPlanFromSaleMutation>;
export type RemoveWorkshopSubscriptionPlanFromSaleMutationOptions = Apollo.BaseMutationOptions<Types.RemoveWorkshopSubscriptionPlanFromSaleMutation, Types.RemoveWorkshopSubscriptionPlanFromSaleMutationVariables>;
export const ActivateWorkshopSubscriptionsDocument = gql`
    mutation ActivateWorkshopSubscriptions($company_workshop_id: String!, $data: ActiveWorkshopSubscriptionInput!) {
  activateWorkshopSubscriptions(
    company_workshop_id: $company_workshop_id
    data: $data
  )
}
    `;
export type ActivateWorkshopSubscriptionsMutationFn = Apollo.MutationFunction<Types.ActivateWorkshopSubscriptionsMutation, Types.ActivateWorkshopSubscriptionsMutationVariables>;

/**
 * __useActivateWorkshopSubscriptionsMutation__
 *
 * To run a mutation, you first call `useActivateWorkshopSubscriptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateWorkshopSubscriptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateWorkshopSubscriptionsMutation, { data, loading, error }] = useActivateWorkshopSubscriptionsMutation({
 *   variables: {
 *      company_workshop_id: // value for 'company_workshop_id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useActivateWorkshopSubscriptionsMutation(baseOptions?: Apollo.MutationHookOptions<Types.ActivateWorkshopSubscriptionsMutation, Types.ActivateWorkshopSubscriptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ActivateWorkshopSubscriptionsMutation, Types.ActivateWorkshopSubscriptionsMutationVariables>(ActivateWorkshopSubscriptionsDocument, options);
      }
export type ActivateWorkshopSubscriptionsMutationHookResult = ReturnType<typeof useActivateWorkshopSubscriptionsMutation>;
export type ActivateWorkshopSubscriptionsMutationResult = Apollo.MutationResult<Types.ActivateWorkshopSubscriptionsMutation>;
export type ActivateWorkshopSubscriptionsMutationOptions = Apollo.BaseMutationOptions<Types.ActivateWorkshopSubscriptionsMutation, Types.ActivateWorkshopSubscriptionsMutationVariables>;
export const AssociateBikeToUserDocument = gql`
    mutation AssociateBikeToUser($data: AssociateBikeToUserInput!) {
  associateBikeToUser(data: $data)
}
    `;
export type AssociateBikeToUserMutationFn = Apollo.MutationFunction<Types.AssociateBikeToUserMutation, Types.AssociateBikeToUserMutationVariables>;

/**
 * __useAssociateBikeToUserMutation__
 *
 * To run a mutation, you first call `useAssociateBikeToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssociateBikeToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [associateBikeToUserMutation, { data, loading, error }] = useAssociateBikeToUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAssociateBikeToUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.AssociateBikeToUserMutation, Types.AssociateBikeToUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AssociateBikeToUserMutation, Types.AssociateBikeToUserMutationVariables>(AssociateBikeToUserDocument, options);
      }
export type AssociateBikeToUserMutationHookResult = ReturnType<typeof useAssociateBikeToUserMutation>;
export type AssociateBikeToUserMutationResult = Apollo.MutationResult<Types.AssociateBikeToUserMutation>;
export type AssociateBikeToUserMutationOptions = Apollo.BaseMutationOptions<Types.AssociateBikeToUserMutation, Types.AssociateBikeToUserMutationVariables>;
export const AssociateOrDissociateWorkshopClientFromUserBikeDocument = gql`
    mutation AssociateOrDissociateWorkshopClientFromUserBike($data: AssociateOrDissociateWorkshopClientFromUserBikeInput!) {
  associateOrDissociateWorkshopClientFromUserBike(data: $data)
}
    `;
export type AssociateOrDissociateWorkshopClientFromUserBikeMutationFn = Apollo.MutationFunction<Types.AssociateOrDissociateWorkshopClientFromUserBikeMutation, Types.AssociateOrDissociateWorkshopClientFromUserBikeMutationVariables>;

/**
 * __useAssociateOrDissociateWorkshopClientFromUserBikeMutation__
 *
 * To run a mutation, you first call `useAssociateOrDissociateWorkshopClientFromUserBikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssociateOrDissociateWorkshopClientFromUserBikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [associateOrDissociateWorkshopClientFromUserBikeMutation, { data, loading, error }] = useAssociateOrDissociateWorkshopClientFromUserBikeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAssociateOrDissociateWorkshopClientFromUserBikeMutation(baseOptions?: Apollo.MutationHookOptions<Types.AssociateOrDissociateWorkshopClientFromUserBikeMutation, Types.AssociateOrDissociateWorkshopClientFromUserBikeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AssociateOrDissociateWorkshopClientFromUserBikeMutation, Types.AssociateOrDissociateWorkshopClientFromUserBikeMutationVariables>(AssociateOrDissociateWorkshopClientFromUserBikeDocument, options);
      }
export type AssociateOrDissociateWorkshopClientFromUserBikeMutationHookResult = ReturnType<typeof useAssociateOrDissociateWorkshopClientFromUserBikeMutation>;
export type AssociateOrDissociateWorkshopClientFromUserBikeMutationResult = Apollo.MutationResult<Types.AssociateOrDissociateWorkshopClientFromUserBikeMutation>;
export type AssociateOrDissociateWorkshopClientFromUserBikeMutationOptions = Apollo.BaseMutationOptions<Types.AssociateOrDissociateWorkshopClientFromUserBikeMutation, Types.AssociateOrDissociateWorkshopClientFromUserBikeMutationVariables>;
export const AssociateUserBikeToWorkshopDocument = gql`
    mutation AssociateUserBikeToWorkshop($data: AssociateBikeToWorkshopInput!) {
  associateUserBikeToWorkshop(data: $data) {
    id
  }
}
    `;
export type AssociateUserBikeToWorkshopMutationFn = Apollo.MutationFunction<Types.AssociateUserBikeToWorkshopMutation, Types.AssociateUserBikeToWorkshopMutationVariables>;

/**
 * __useAssociateUserBikeToWorkshopMutation__
 *
 * To run a mutation, you first call `useAssociateUserBikeToWorkshopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssociateUserBikeToWorkshopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [associateUserBikeToWorkshopMutation, { data, loading, error }] = useAssociateUserBikeToWorkshopMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAssociateUserBikeToWorkshopMutation(baseOptions?: Apollo.MutationHookOptions<Types.AssociateUserBikeToWorkshopMutation, Types.AssociateUserBikeToWorkshopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AssociateUserBikeToWorkshopMutation, Types.AssociateUserBikeToWorkshopMutationVariables>(AssociateUserBikeToWorkshopDocument, options);
      }
export type AssociateUserBikeToWorkshopMutationHookResult = ReturnType<typeof useAssociateUserBikeToWorkshopMutation>;
export type AssociateUserBikeToWorkshopMutationResult = Apollo.MutationResult<Types.AssociateUserBikeToWorkshopMutation>;
export type AssociateUserBikeToWorkshopMutationOptions = Apollo.BaseMutationOptions<Types.AssociateUserBikeToWorkshopMutation, Types.AssociateUserBikeToWorkshopMutationVariables>;
export const ChangeContractPaymentMethodDocument = gql`
    mutation ChangeContractPaymentMethod($create_card: CreateCompanyCardInput, $data: ChangeContractPaymentMethodInput!) {
  changeContractPaymentMethod(create_card: $create_card, data: $data)
}
    `;
export type ChangeContractPaymentMethodMutationFn = Apollo.MutationFunction<Types.ChangeContractPaymentMethodMutation, Types.ChangeContractPaymentMethodMutationVariables>;

/**
 * __useChangeContractPaymentMethodMutation__
 *
 * To run a mutation, you first call `useChangeContractPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeContractPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeContractPaymentMethodMutation, { data, loading, error }] = useChangeContractPaymentMethodMutation({
 *   variables: {
 *      create_card: // value for 'create_card'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChangeContractPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<Types.ChangeContractPaymentMethodMutation, Types.ChangeContractPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ChangeContractPaymentMethodMutation, Types.ChangeContractPaymentMethodMutationVariables>(ChangeContractPaymentMethodDocument, options);
      }
export type ChangeContractPaymentMethodMutationHookResult = ReturnType<typeof useChangeContractPaymentMethodMutation>;
export type ChangeContractPaymentMethodMutationResult = Apollo.MutationResult<Types.ChangeContractPaymentMethodMutation>;
export type ChangeContractPaymentMethodMutationOptions = Apollo.BaseMutationOptions<Types.ChangeContractPaymentMethodMutation, Types.ChangeContractPaymentMethodMutationVariables>;
export const ChangeContractedWorkshopPlanDueDateDocument = gql`
    mutation ChangeContractedWorkshopPlanDueDate($data: ChangeContractedWorkshopPlanDueDateInput!) {
  changeContractedWorkshopPlanDueDate(data: $data) {
    proportional_payment_bill_url
    proportional_payment_billing_type
    proportional_payment_due_date
  }
}
    `;
export type ChangeContractedWorkshopPlanDueDateMutationFn = Apollo.MutationFunction<Types.ChangeContractedWorkshopPlanDueDateMutation, Types.ChangeContractedWorkshopPlanDueDateMutationVariables>;

/**
 * __useChangeContractedWorkshopPlanDueDateMutation__
 *
 * To run a mutation, you first call `useChangeContractedWorkshopPlanDueDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeContractedWorkshopPlanDueDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeContractedWorkshopPlanDueDateMutation, { data, loading, error }] = useChangeContractedWorkshopPlanDueDateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChangeContractedWorkshopPlanDueDateMutation(baseOptions?: Apollo.MutationHookOptions<Types.ChangeContractedWorkshopPlanDueDateMutation, Types.ChangeContractedWorkshopPlanDueDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ChangeContractedWorkshopPlanDueDateMutation, Types.ChangeContractedWorkshopPlanDueDateMutationVariables>(ChangeContractedWorkshopPlanDueDateDocument, options);
      }
export type ChangeContractedWorkshopPlanDueDateMutationHookResult = ReturnType<typeof useChangeContractedWorkshopPlanDueDateMutation>;
export type ChangeContractedWorkshopPlanDueDateMutationResult = Apollo.MutationResult<Types.ChangeContractedWorkshopPlanDueDateMutation>;
export type ChangeContractedWorkshopPlanDueDateMutationOptions = Apollo.BaseMutationOptions<Types.ChangeContractedWorkshopPlanDueDateMutation, Types.ChangeContractedWorkshopPlanDueDateMutationVariables>;
export const ChangeRepairStatusDocument = gql`
    mutation ChangeRepairStatus($data: ChangeRepairStatusInput!) {
  changeRepairStatus(data: $data)
}
    `;
export type ChangeRepairStatusMutationFn = Apollo.MutationFunction<Types.ChangeRepairStatusMutation, Types.ChangeRepairStatusMutationVariables>;

/**
 * __useChangeRepairStatusMutation__
 *
 * To run a mutation, you first call `useChangeRepairStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeRepairStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeRepairStatusMutation, { data, loading, error }] = useChangeRepairStatusMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChangeRepairStatusMutation(baseOptions?: Apollo.MutationHookOptions<Types.ChangeRepairStatusMutation, Types.ChangeRepairStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ChangeRepairStatusMutation, Types.ChangeRepairStatusMutationVariables>(ChangeRepairStatusDocument, options);
      }
export type ChangeRepairStatusMutationHookResult = ReturnType<typeof useChangeRepairStatusMutation>;
export type ChangeRepairStatusMutationResult = Apollo.MutationResult<Types.ChangeRepairStatusMutation>;
export type ChangeRepairStatusMutationOptions = Apollo.BaseMutationOptions<Types.ChangeRepairStatusMutation, Types.ChangeRepairStatusMutationVariables>;
export const ChangeStandardWithdrawProcedureDocument = gql`
    mutation ChangeStandardWithdrawProcedure($data: ChangeStandardWithdrawProcedureInput!) {
  changeStandardWithdrawProcedure(data: $data)
}
    `;
export type ChangeStandardWithdrawProcedureMutationFn = Apollo.MutationFunction<Types.ChangeStandardWithdrawProcedureMutation, Types.ChangeStandardWithdrawProcedureMutationVariables>;

/**
 * __useChangeStandardWithdrawProcedureMutation__
 *
 * To run a mutation, you first call `useChangeStandardWithdrawProcedureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeStandardWithdrawProcedureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeStandardWithdrawProcedureMutation, { data, loading, error }] = useChangeStandardWithdrawProcedureMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChangeStandardWithdrawProcedureMutation(baseOptions?: Apollo.MutationHookOptions<Types.ChangeStandardWithdrawProcedureMutation, Types.ChangeStandardWithdrawProcedureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ChangeStandardWithdrawProcedureMutation, Types.ChangeStandardWithdrawProcedureMutationVariables>(ChangeStandardWithdrawProcedureDocument, options);
      }
export type ChangeStandardWithdrawProcedureMutationHookResult = ReturnType<typeof useChangeStandardWithdrawProcedureMutation>;
export type ChangeStandardWithdrawProcedureMutationResult = Apollo.MutationResult<Types.ChangeStandardWithdrawProcedureMutation>;
export type ChangeStandardWithdrawProcedureMutationOptions = Apollo.BaseMutationOptions<Types.ChangeStandardWithdrawProcedureMutation, Types.ChangeStandardWithdrawProcedureMutationVariables>;
export const ChangeUserBikeFromRepairDocument = gql`
    mutation ChangeUserBikeFromRepair($data: ChangeUserBikeRepairInput!) {
  changeUserBikeFromRepair(data: $data)
}
    `;
export type ChangeUserBikeFromRepairMutationFn = Apollo.MutationFunction<Types.ChangeUserBikeFromRepairMutation, Types.ChangeUserBikeFromRepairMutationVariables>;

/**
 * __useChangeUserBikeFromRepairMutation__
 *
 * To run a mutation, you first call `useChangeUserBikeFromRepairMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserBikeFromRepairMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserBikeFromRepairMutation, { data, loading, error }] = useChangeUserBikeFromRepairMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChangeUserBikeFromRepairMutation(baseOptions?: Apollo.MutationHookOptions<Types.ChangeUserBikeFromRepairMutation, Types.ChangeUserBikeFromRepairMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ChangeUserBikeFromRepairMutation, Types.ChangeUserBikeFromRepairMutationVariables>(ChangeUserBikeFromRepairDocument, options);
      }
export type ChangeUserBikeFromRepairMutationHookResult = ReturnType<typeof useChangeUserBikeFromRepairMutation>;
export type ChangeUserBikeFromRepairMutationResult = Apollo.MutationResult<Types.ChangeUserBikeFromRepairMutation>;
export type ChangeUserBikeFromRepairMutationOptions = Apollo.BaseMutationOptions<Types.ChangeUserBikeFromRepairMutation, Types.ChangeUserBikeFromRepairMutationVariables>;
export const CheckWorkshopCouponApplicableDocument = gql`
    query CheckWorkshopCouponApplicable($data: CheckWorkshopCouponApplicableInput!) {
  checkWorkshopCouponApplicable(data: $data) {
    code
    id
    enable
    discounted_installments
    description
    discount_type_id
    maximum_uses
    minimum_purchase_amount
    total_uses
    valid_from
    valid_until
    value
    discount_type {
      active
      description
      id
      slug
      discount_type_translations {
        description
        language_code
        discount_type_id
      }
    }
  }
}
    `;

/**
 * __useCheckWorkshopCouponApplicableQuery__
 *
 * To run a query within a React component, call `useCheckWorkshopCouponApplicableQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckWorkshopCouponApplicableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckWorkshopCouponApplicableQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCheckWorkshopCouponApplicableQuery(baseOptions: Apollo.QueryHookOptions<Types.CheckWorkshopCouponApplicableQuery, Types.CheckWorkshopCouponApplicableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CheckWorkshopCouponApplicableQuery, Types.CheckWorkshopCouponApplicableQueryVariables>(CheckWorkshopCouponApplicableDocument, options);
      }
export function useCheckWorkshopCouponApplicableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CheckWorkshopCouponApplicableQuery, Types.CheckWorkshopCouponApplicableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CheckWorkshopCouponApplicableQuery, Types.CheckWorkshopCouponApplicableQueryVariables>(CheckWorkshopCouponApplicableDocument, options);
        }
export type CheckWorkshopCouponApplicableQueryHookResult = ReturnType<typeof useCheckWorkshopCouponApplicableQuery>;
export type CheckWorkshopCouponApplicableLazyQueryHookResult = ReturnType<typeof useCheckWorkshopCouponApplicableLazyQuery>;
export type CheckWorkshopCouponApplicableQueryResult = Apollo.QueryResult<Types.CheckWorkshopCouponApplicableQuery, Types.CheckWorkshopCouponApplicableQueryVariables>;
export const CheckWorkshopCouponDuplicatedDocument = gql`
    query CheckWorkshopCouponDuplicated($data: CheckWorkshopCouponDuplicatedInput!) {
  checkWorkshopCouponDuplicated(data: $data)
}
    `;

/**
 * __useCheckWorkshopCouponDuplicatedQuery__
 *
 * To run a query within a React component, call `useCheckWorkshopCouponDuplicatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckWorkshopCouponDuplicatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckWorkshopCouponDuplicatedQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCheckWorkshopCouponDuplicatedQuery(baseOptions: Apollo.QueryHookOptions<Types.CheckWorkshopCouponDuplicatedQuery, Types.CheckWorkshopCouponDuplicatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CheckWorkshopCouponDuplicatedQuery, Types.CheckWorkshopCouponDuplicatedQueryVariables>(CheckWorkshopCouponDuplicatedDocument, options);
      }
export function useCheckWorkshopCouponDuplicatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CheckWorkshopCouponDuplicatedQuery, Types.CheckWorkshopCouponDuplicatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CheckWorkshopCouponDuplicatedQuery, Types.CheckWorkshopCouponDuplicatedQueryVariables>(CheckWorkshopCouponDuplicatedDocument, options);
        }
export type CheckWorkshopCouponDuplicatedQueryHookResult = ReturnType<typeof useCheckWorkshopCouponDuplicatedQuery>;
export type CheckWorkshopCouponDuplicatedLazyQueryHookResult = ReturnType<typeof useCheckWorkshopCouponDuplicatedLazyQuery>;
export type CheckWorkshopCouponDuplicatedQueryResult = Apollo.QueryResult<Types.CheckWorkshopCouponDuplicatedQuery, Types.CheckWorkshopCouponDuplicatedQueryVariables>;
export const CreateCompanyWorkshopDocument = gql`
    mutation createCompanyWorkshop($data: CreateCompanyWorkshopInput!, $photos: [CreateCompanyWorkshopPhotosInput!], $create_contract: CreateContractedWorkshopPlanInput, $create_card: CreateCompanyCardInput) {
  createCompanyWorkshop(
    data: $data
    photos: $photos
    create_contract: $create_contract
    create_card: $create_card
  ) {
    id
  }
}
    `;
export type CreateCompanyWorkshopMutationFn = Apollo.MutationFunction<Types.CreateCompanyWorkshopMutation, Types.CreateCompanyWorkshopMutationVariables>;

/**
 * __useCreateCompanyWorkshopMutation__
 *
 * To run a mutation, you first call `useCreateCompanyWorkshopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyWorkshopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyWorkshopMutation, { data, loading, error }] = useCreateCompanyWorkshopMutation({
 *   variables: {
 *      data: // value for 'data'
 *      photos: // value for 'photos'
 *      create_contract: // value for 'create_contract'
 *      create_card: // value for 'create_card'
 *   },
 * });
 */
export function useCreateCompanyWorkshopMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateCompanyWorkshopMutation, Types.CreateCompanyWorkshopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateCompanyWorkshopMutation, Types.CreateCompanyWorkshopMutationVariables>(CreateCompanyWorkshopDocument, options);
      }
export type CreateCompanyWorkshopMutationHookResult = ReturnType<typeof useCreateCompanyWorkshopMutation>;
export type CreateCompanyWorkshopMutationResult = Apollo.MutationResult<Types.CreateCompanyWorkshopMutation>;
export type CreateCompanyWorkshopMutationOptions = Apollo.BaseMutationOptions<Types.CreateCompanyWorkshopMutation, Types.CreateCompanyWorkshopMutationVariables>;
export const CreateCompanyWorkshopExternalBankAccountDocument = gql`
    mutation CreateCompanyWorkshopExternalBankAccount($data: CreateCompanyWorkshopExternalBankAccountInput!) {
  createCompanyWorkshopExternalBankAccount(data: $data) {
    company_workshop_external_bank_account_id
  }
}
    `;
export type CreateCompanyWorkshopExternalBankAccountMutationFn = Apollo.MutationFunction<Types.CreateCompanyWorkshopExternalBankAccountMutation, Types.CreateCompanyWorkshopExternalBankAccountMutationVariables>;

/**
 * __useCreateCompanyWorkshopExternalBankAccountMutation__
 *
 * To run a mutation, you first call `useCreateCompanyWorkshopExternalBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyWorkshopExternalBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyWorkshopExternalBankAccountMutation, { data, loading, error }] = useCreateCompanyWorkshopExternalBankAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCompanyWorkshopExternalBankAccountMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateCompanyWorkshopExternalBankAccountMutation, Types.CreateCompanyWorkshopExternalBankAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateCompanyWorkshopExternalBankAccountMutation, Types.CreateCompanyWorkshopExternalBankAccountMutationVariables>(CreateCompanyWorkshopExternalBankAccountDocument, options);
      }
export type CreateCompanyWorkshopExternalBankAccountMutationHookResult = ReturnType<typeof useCreateCompanyWorkshopExternalBankAccountMutation>;
export type CreateCompanyWorkshopExternalBankAccountMutationResult = Apollo.MutationResult<Types.CreateCompanyWorkshopExternalBankAccountMutation>;
export type CreateCompanyWorkshopExternalBankAccountMutationOptions = Apollo.BaseMutationOptions<Types.CreateCompanyWorkshopExternalBankAccountMutation, Types.CreateCompanyWorkshopExternalBankAccountMutationVariables>;
export const CreateCompanyWorkshopIntegrationDocument = gql`
    mutation CreateCompanyWorkshopIntegration($data: CreateCompanyWorkshopIntegrationInput!) {
  createCompanyWorkshopIntegration(data: $data) {
    created_at
  }
}
    `;
export type CreateCompanyWorkshopIntegrationMutationFn = Apollo.MutationFunction<Types.CreateCompanyWorkshopIntegrationMutation, Types.CreateCompanyWorkshopIntegrationMutationVariables>;

/**
 * __useCreateCompanyWorkshopIntegrationMutation__
 *
 * To run a mutation, you first call `useCreateCompanyWorkshopIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyWorkshopIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyWorkshopIntegrationMutation, { data, loading, error }] = useCreateCompanyWorkshopIntegrationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCompanyWorkshopIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateCompanyWorkshopIntegrationMutation, Types.CreateCompanyWorkshopIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateCompanyWorkshopIntegrationMutation, Types.CreateCompanyWorkshopIntegrationMutationVariables>(CreateCompanyWorkshopIntegrationDocument, options);
      }
export type CreateCompanyWorkshopIntegrationMutationHookResult = ReturnType<typeof useCreateCompanyWorkshopIntegrationMutation>;
export type CreateCompanyWorkshopIntegrationMutationResult = Apollo.MutationResult<Types.CreateCompanyWorkshopIntegrationMutation>;
export type CreateCompanyWorkshopIntegrationMutationOptions = Apollo.BaseMutationOptions<Types.CreateCompanyWorkshopIntegrationMutation, Types.CreateCompanyWorkshopIntegrationMutationVariables>;
export const CreateCompanyWorkshopPhotosDocument = gql`
    mutation CreateCompanyWorkshopPhotos($photos: [CreateCompanyWorkshopPhotosInput!]!) {
  createCompanyWorkshopPhotos(photos: $photos) {
    id
  }
}
    `;
export type CreateCompanyWorkshopPhotosMutationFn = Apollo.MutationFunction<Types.CreateCompanyWorkshopPhotosMutation, Types.CreateCompanyWorkshopPhotosMutationVariables>;

/**
 * __useCreateCompanyWorkshopPhotosMutation__
 *
 * To run a mutation, you first call `useCreateCompanyWorkshopPhotosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyWorkshopPhotosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyWorkshopPhotosMutation, { data, loading, error }] = useCreateCompanyWorkshopPhotosMutation({
 *   variables: {
 *      photos: // value for 'photos'
 *   },
 * });
 */
export function useCreateCompanyWorkshopPhotosMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateCompanyWorkshopPhotosMutation, Types.CreateCompanyWorkshopPhotosMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateCompanyWorkshopPhotosMutation, Types.CreateCompanyWorkshopPhotosMutationVariables>(CreateCompanyWorkshopPhotosDocument, options);
      }
export type CreateCompanyWorkshopPhotosMutationHookResult = ReturnType<typeof useCreateCompanyWorkshopPhotosMutation>;
export type CreateCompanyWorkshopPhotosMutationResult = Apollo.MutationResult<Types.CreateCompanyWorkshopPhotosMutation>;
export type CreateCompanyWorkshopPhotosMutationOptions = Apollo.BaseMutationOptions<Types.CreateCompanyWorkshopPhotosMutation, Types.CreateCompanyWorkshopPhotosMutationVariables>;
export const CreateContractedWorkshopPlanDocument = gql`
    mutation CreateContractedWorkshopPlan($data: CreateContractedWorkshopPlanInput!, $create_card: CreateCompanyCardInput) {
  createContractedWorkshopPlan(data: $data, create_card: $create_card) {
    id
  }
}
    `;
export type CreateContractedWorkshopPlanMutationFn = Apollo.MutationFunction<Types.CreateContractedWorkshopPlanMutation, Types.CreateContractedWorkshopPlanMutationVariables>;

/**
 * __useCreateContractedWorkshopPlanMutation__
 *
 * To run a mutation, you first call `useCreateContractedWorkshopPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractedWorkshopPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractedWorkshopPlanMutation, { data, loading, error }] = useCreateContractedWorkshopPlanMutation({
 *   variables: {
 *      data: // value for 'data'
 *      create_card: // value for 'create_card'
 *   },
 * });
 */
export function useCreateContractedWorkshopPlanMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateContractedWorkshopPlanMutation, Types.CreateContractedWorkshopPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateContractedWorkshopPlanMutation, Types.CreateContractedWorkshopPlanMutationVariables>(CreateContractedWorkshopPlanDocument, options);
      }
export type CreateContractedWorkshopPlanMutationHookResult = ReturnType<typeof useCreateContractedWorkshopPlanMutation>;
export type CreateContractedWorkshopPlanMutationResult = Apollo.MutationResult<Types.CreateContractedWorkshopPlanMutation>;
export type CreateContractedWorkshopPlanMutationOptions = Apollo.BaseMutationOptions<Types.CreateContractedWorkshopPlanMutation, Types.CreateContractedWorkshopPlanMutationVariables>;
export const CreateStandardBikeSetupDocument = gql`
    mutation CreateStandardBikeSetup($data: CreateStandardBikeSetupInput!) {
  createStandardBikeSetup(data: $data) {
    id
  }
}
    `;
export type CreateStandardBikeSetupMutationFn = Apollo.MutationFunction<Types.CreateStandardBikeSetupMutation, Types.CreateStandardBikeSetupMutationVariables>;

/**
 * __useCreateStandardBikeSetupMutation__
 *
 * To run a mutation, you first call `useCreateStandardBikeSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStandardBikeSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStandardBikeSetupMutation, { data, loading, error }] = useCreateStandardBikeSetupMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateStandardBikeSetupMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateStandardBikeSetupMutation, Types.CreateStandardBikeSetupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateStandardBikeSetupMutation, Types.CreateStandardBikeSetupMutationVariables>(CreateStandardBikeSetupDocument, options);
      }
export type CreateStandardBikeSetupMutationHookResult = ReturnType<typeof useCreateStandardBikeSetupMutation>;
export type CreateStandardBikeSetupMutationResult = Apollo.MutationResult<Types.CreateStandardBikeSetupMutation>;
export type CreateStandardBikeSetupMutationOptions = Apollo.BaseMutationOptions<Types.CreateStandardBikeSetupMutation, Types.CreateStandardBikeSetupMutationVariables>;
export const CreateStandardBikeSetupGearDocument = gql`
    mutation CreateStandardBikeSetupGear($data: CreateStandardBikeSetupGearApartInput!) {
  createStandardBikeSetupGear(data: $data) {
    updated_at
  }
}
    `;
export type CreateStandardBikeSetupGearMutationFn = Apollo.MutationFunction<Types.CreateStandardBikeSetupGearMutation, Types.CreateStandardBikeSetupGearMutationVariables>;

/**
 * __useCreateStandardBikeSetupGearMutation__
 *
 * To run a mutation, you first call `useCreateStandardBikeSetupGearMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStandardBikeSetupGearMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStandardBikeSetupGearMutation, { data, loading, error }] = useCreateStandardBikeSetupGearMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateStandardBikeSetupGearMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateStandardBikeSetupGearMutation, Types.CreateStandardBikeSetupGearMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateStandardBikeSetupGearMutation, Types.CreateStandardBikeSetupGearMutationVariables>(CreateStandardBikeSetupGearDocument, options);
      }
export type CreateStandardBikeSetupGearMutationHookResult = ReturnType<typeof useCreateStandardBikeSetupGearMutation>;
export type CreateStandardBikeSetupGearMutationResult = Apollo.MutationResult<Types.CreateStandardBikeSetupGearMutation>;
export type CreateStandardBikeSetupGearMutationOptions = Apollo.BaseMutationOptions<Types.CreateStandardBikeSetupGearMutation, Types.CreateStandardBikeSetupGearMutationVariables>;
export const CreateUserWorkshopSubscriptionPlanAdvantageRegisterDocument = gql`
    mutation CreateUserWorkshopSubscriptionPlanAdvantageRegister($data: CreateUserWorkshopSubscriptionPlanAdvantageRegisterInput!) {
  createUserWorkshopSubscriptionPlanAdvantageRegister(data: $data) {
    id
  }
}
    `;
export type CreateUserWorkshopSubscriptionPlanAdvantageRegisterMutationFn = Apollo.MutationFunction<Types.CreateUserWorkshopSubscriptionPlanAdvantageRegisterMutation, Types.CreateUserWorkshopSubscriptionPlanAdvantageRegisterMutationVariables>;

/**
 * __useCreateUserWorkshopSubscriptionPlanAdvantageRegisterMutation__
 *
 * To run a mutation, you first call `useCreateUserWorkshopSubscriptionPlanAdvantageRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserWorkshopSubscriptionPlanAdvantageRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserWorkshopSubscriptionPlanAdvantageRegisterMutation, { data, loading, error }] = useCreateUserWorkshopSubscriptionPlanAdvantageRegisterMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserWorkshopSubscriptionPlanAdvantageRegisterMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateUserWorkshopSubscriptionPlanAdvantageRegisterMutation, Types.CreateUserWorkshopSubscriptionPlanAdvantageRegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateUserWorkshopSubscriptionPlanAdvantageRegisterMutation, Types.CreateUserWorkshopSubscriptionPlanAdvantageRegisterMutationVariables>(CreateUserWorkshopSubscriptionPlanAdvantageRegisterDocument, options);
      }
export type CreateUserWorkshopSubscriptionPlanAdvantageRegisterMutationHookResult = ReturnType<typeof useCreateUserWorkshopSubscriptionPlanAdvantageRegisterMutation>;
export type CreateUserWorkshopSubscriptionPlanAdvantageRegisterMutationResult = Apollo.MutationResult<Types.CreateUserWorkshopSubscriptionPlanAdvantageRegisterMutation>;
export type CreateUserWorkshopSubscriptionPlanAdvantageRegisterMutationOptions = Apollo.BaseMutationOptions<Types.CreateUserWorkshopSubscriptionPlanAdvantageRegisterMutation, Types.CreateUserWorkshopSubscriptionPlanAdvantageRegisterMutationVariables>;
export const CreateWorkshopClientDocument = gql`
    mutation CreateWorkshopClient($data: CreateWorkshopClientInput!) {
  createWorkshopClient(data: $data) {
    id
  }
}
    `;
export type CreateWorkshopClientMutationFn = Apollo.MutationFunction<Types.CreateWorkshopClientMutation, Types.CreateWorkshopClientMutationVariables>;

/**
 * __useCreateWorkshopClientMutation__
 *
 * To run a mutation, you first call `useCreateWorkshopClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkshopClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkshopClientMutation, { data, loading, error }] = useCreateWorkshopClientMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateWorkshopClientMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateWorkshopClientMutation, Types.CreateWorkshopClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateWorkshopClientMutation, Types.CreateWorkshopClientMutationVariables>(CreateWorkshopClientDocument, options);
      }
export type CreateWorkshopClientMutationHookResult = ReturnType<typeof useCreateWorkshopClientMutation>;
export type CreateWorkshopClientMutationResult = Apollo.MutationResult<Types.CreateWorkshopClientMutation>;
export type CreateWorkshopClientMutationOptions = Apollo.BaseMutationOptions<Types.CreateWorkshopClientMutation, Types.CreateWorkshopClientMutationVariables>;
export const CreateWorkshopDiscountCouponDocument = gql`
    mutation CreateWorkshopDiscountCoupon($data: CreateWorkshopDiscountCouponInput!) {
  createWorkshopDiscountCoupon(data: $data) {
    code
  }
}
    `;
export type CreateWorkshopDiscountCouponMutationFn = Apollo.MutationFunction<Types.CreateWorkshopDiscountCouponMutation, Types.CreateWorkshopDiscountCouponMutationVariables>;

/**
 * __useCreateWorkshopDiscountCouponMutation__
 *
 * To run a mutation, you first call `useCreateWorkshopDiscountCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkshopDiscountCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkshopDiscountCouponMutation, { data, loading, error }] = useCreateWorkshopDiscountCouponMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateWorkshopDiscountCouponMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateWorkshopDiscountCouponMutation, Types.CreateWorkshopDiscountCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateWorkshopDiscountCouponMutation, Types.CreateWorkshopDiscountCouponMutationVariables>(CreateWorkshopDiscountCouponDocument, options);
      }
export type CreateWorkshopDiscountCouponMutationHookResult = ReturnType<typeof useCreateWorkshopDiscountCouponMutation>;
export type CreateWorkshopDiscountCouponMutationResult = Apollo.MutationResult<Types.CreateWorkshopDiscountCouponMutation>;
export type CreateWorkshopDiscountCouponMutationOptions = Apollo.BaseMutationOptions<Types.CreateWorkshopDiscountCouponMutation, Types.CreateWorkshopDiscountCouponMutationVariables>;
export const CreateWorkshopInviteDocument = gql`
    mutation CreateWorkshopInvite($data: CreateCompanyWorkshopInviteInput!) {
  createWorkshopInvite(data: $data) {
    id
  }
}
    `;
export type CreateWorkshopInviteMutationFn = Apollo.MutationFunction<Types.CreateWorkshopInviteMutation, Types.CreateWorkshopInviteMutationVariables>;

/**
 * __useCreateWorkshopInviteMutation__
 *
 * To run a mutation, you first call `useCreateWorkshopInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkshopInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkshopInviteMutation, { data, loading, error }] = useCreateWorkshopInviteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateWorkshopInviteMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateWorkshopInviteMutation, Types.CreateWorkshopInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateWorkshopInviteMutation, Types.CreateWorkshopInviteMutationVariables>(CreateWorkshopInviteDocument, options);
      }
export type CreateWorkshopInviteMutationHookResult = ReturnType<typeof useCreateWorkshopInviteMutation>;
export type CreateWorkshopInviteMutationResult = Apollo.MutationResult<Types.CreateWorkshopInviteMutation>;
export type CreateWorkshopInviteMutationOptions = Apollo.BaseMutationOptions<Types.CreateWorkshopInviteMutation, Types.CreateWorkshopInviteMutationVariables>;
export const CreateWorkshopReceiptMethodDocument = gql`
    mutation CreateWorkshopReceiptMethod($data: CreateWorkshopReceiptMethodInput!) {
  createWorkshopReceiptMethod(data: $data) {
    id
  }
}
    `;
export type CreateWorkshopReceiptMethodMutationFn = Apollo.MutationFunction<Types.CreateWorkshopReceiptMethodMutation, Types.CreateWorkshopReceiptMethodMutationVariables>;

/**
 * __useCreateWorkshopReceiptMethodMutation__
 *
 * To run a mutation, you first call `useCreateWorkshopReceiptMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkshopReceiptMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkshopReceiptMethodMutation, { data, loading, error }] = useCreateWorkshopReceiptMethodMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateWorkshopReceiptMethodMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateWorkshopReceiptMethodMutation, Types.CreateWorkshopReceiptMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateWorkshopReceiptMethodMutation, Types.CreateWorkshopReceiptMethodMutationVariables>(CreateWorkshopReceiptMethodDocument, options);
      }
export type CreateWorkshopReceiptMethodMutationHookResult = ReturnType<typeof useCreateWorkshopReceiptMethodMutation>;
export type CreateWorkshopReceiptMethodMutationResult = Apollo.MutationResult<Types.CreateWorkshopReceiptMethodMutation>;
export type CreateWorkshopReceiptMethodMutationOptions = Apollo.BaseMutationOptions<Types.CreateWorkshopReceiptMethodMutation, Types.CreateWorkshopReceiptMethodMutationVariables>;
export const CreateWorkshopServiceDocument = gql`
    mutation CreateWorkshopService($data: CreateWorkshopServicesInput!) {
  createWorkshopService(data: $data) {
    id
  }
}
    `;
export type CreateWorkshopServiceMutationFn = Apollo.MutationFunction<Types.CreateWorkshopServiceMutation, Types.CreateWorkshopServiceMutationVariables>;

/**
 * __useCreateWorkshopServiceMutation__
 *
 * To run a mutation, you first call `useCreateWorkshopServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkshopServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkshopServiceMutation, { data, loading, error }] = useCreateWorkshopServiceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateWorkshopServiceMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateWorkshopServiceMutation, Types.CreateWorkshopServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateWorkshopServiceMutation, Types.CreateWorkshopServiceMutationVariables>(CreateWorkshopServiceDocument, options);
      }
export type CreateWorkshopServiceMutationHookResult = ReturnType<typeof useCreateWorkshopServiceMutation>;
export type CreateWorkshopServiceMutationResult = Apollo.MutationResult<Types.CreateWorkshopServiceMutation>;
export type CreateWorkshopServiceMutationOptions = Apollo.BaseMutationOptions<Types.CreateWorkshopServiceMutation, Types.CreateWorkshopServiceMutationVariables>;
export const CreateWorkshopSubscriptionPlanDocument = gql`
    mutation CreateWorkshopSubscriptionPlan($data: CreateWorkshopSubscriptionPlanInput!) {
  createWorkshopSubscriptionPlan(data: $data) {
    workshop_subscription_plan_id
  }
}
    `;
export type CreateWorkshopSubscriptionPlanMutationFn = Apollo.MutationFunction<Types.CreateWorkshopSubscriptionPlanMutation, Types.CreateWorkshopSubscriptionPlanMutationVariables>;

/**
 * __useCreateWorkshopSubscriptionPlanMutation__
 *
 * To run a mutation, you first call `useCreateWorkshopSubscriptionPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkshopSubscriptionPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkshopSubscriptionPlanMutation, { data, loading, error }] = useCreateWorkshopSubscriptionPlanMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateWorkshopSubscriptionPlanMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateWorkshopSubscriptionPlanMutation, Types.CreateWorkshopSubscriptionPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateWorkshopSubscriptionPlanMutation, Types.CreateWorkshopSubscriptionPlanMutationVariables>(CreateWorkshopSubscriptionPlanDocument, options);
      }
export type CreateWorkshopSubscriptionPlanMutationHookResult = ReturnType<typeof useCreateWorkshopSubscriptionPlanMutation>;
export type CreateWorkshopSubscriptionPlanMutationResult = Apollo.MutationResult<Types.CreateWorkshopSubscriptionPlanMutation>;
export type CreateWorkshopSubscriptionPlanMutationOptions = Apollo.BaseMutationOptions<Types.CreateWorkshopSubscriptionPlanMutation, Types.CreateWorkshopSubscriptionPlanMutationVariables>;
export const CreateWorkshopSubscriptionPlanAdvantagesDocument = gql`
    mutation CreateWorkshopSubscriptionPlanAdvantages($data: CreateWorkshopSubscriptionPlanAdvantageInput!) {
  createWorkshopSubscriptionPlanAdvantages(data: $data)
}
    `;
export type CreateWorkshopSubscriptionPlanAdvantagesMutationFn = Apollo.MutationFunction<Types.CreateWorkshopSubscriptionPlanAdvantagesMutation, Types.CreateWorkshopSubscriptionPlanAdvantagesMutationVariables>;

/**
 * __useCreateWorkshopSubscriptionPlanAdvantagesMutation__
 *
 * To run a mutation, you first call `useCreateWorkshopSubscriptionPlanAdvantagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkshopSubscriptionPlanAdvantagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkshopSubscriptionPlanAdvantagesMutation, { data, loading, error }] = useCreateWorkshopSubscriptionPlanAdvantagesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateWorkshopSubscriptionPlanAdvantagesMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateWorkshopSubscriptionPlanAdvantagesMutation, Types.CreateWorkshopSubscriptionPlanAdvantagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateWorkshopSubscriptionPlanAdvantagesMutation, Types.CreateWorkshopSubscriptionPlanAdvantagesMutationVariables>(CreateWorkshopSubscriptionPlanAdvantagesDocument, options);
      }
export type CreateWorkshopSubscriptionPlanAdvantagesMutationHookResult = ReturnType<typeof useCreateWorkshopSubscriptionPlanAdvantagesMutation>;
export type CreateWorkshopSubscriptionPlanAdvantagesMutationResult = Apollo.MutationResult<Types.CreateWorkshopSubscriptionPlanAdvantagesMutation>;
export type CreateWorkshopSubscriptionPlanAdvantagesMutationOptions = Apollo.BaseMutationOptions<Types.CreateWorkshopSubscriptionPlanAdvantagesMutation, Types.CreateWorkshopSubscriptionPlanAdvantagesMutationVariables>;
export const DeleteCompanyWorkshopCardDocument = gql`
    mutation DeleteCompanyWorkshopCard($data: DeleteCompanyWorkshopCardInput!) {
  deleteCompanyWorkshopCard(data: $data)
}
    `;
export type DeleteCompanyWorkshopCardMutationFn = Apollo.MutationFunction<Types.DeleteCompanyWorkshopCardMutation, Types.DeleteCompanyWorkshopCardMutationVariables>;

/**
 * __useDeleteCompanyWorkshopCardMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyWorkshopCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyWorkshopCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyWorkshopCardMutation, { data, loading, error }] = useDeleteCompanyWorkshopCardMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteCompanyWorkshopCardMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCompanyWorkshopCardMutation, Types.DeleteCompanyWorkshopCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCompanyWorkshopCardMutation, Types.DeleteCompanyWorkshopCardMutationVariables>(DeleteCompanyWorkshopCardDocument, options);
      }
export type DeleteCompanyWorkshopCardMutationHookResult = ReturnType<typeof useDeleteCompanyWorkshopCardMutation>;
export type DeleteCompanyWorkshopCardMutationResult = Apollo.MutationResult<Types.DeleteCompanyWorkshopCardMutation>;
export type DeleteCompanyWorkshopCardMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCompanyWorkshopCardMutation, Types.DeleteCompanyWorkshopCardMutationVariables>;
export const DeleteCompanyWorkshopExternalBankAccountsDocument = gql`
    mutation DeleteCompanyWorkshopExternalBankAccounts($data: DeleteCompanyWorkshopExternalBankAccountsInput!) {
  deleteCompanyWorkshopExternalBankAccounts(data: $data)
}
    `;
export type DeleteCompanyWorkshopExternalBankAccountsMutationFn = Apollo.MutationFunction<Types.DeleteCompanyWorkshopExternalBankAccountsMutation, Types.DeleteCompanyWorkshopExternalBankAccountsMutationVariables>;

/**
 * __useDeleteCompanyWorkshopExternalBankAccountsMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyWorkshopExternalBankAccountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyWorkshopExternalBankAccountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyWorkshopExternalBankAccountsMutation, { data, loading, error }] = useDeleteCompanyWorkshopExternalBankAccountsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteCompanyWorkshopExternalBankAccountsMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCompanyWorkshopExternalBankAccountsMutation, Types.DeleteCompanyWorkshopExternalBankAccountsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCompanyWorkshopExternalBankAccountsMutation, Types.DeleteCompanyWorkshopExternalBankAccountsMutationVariables>(DeleteCompanyWorkshopExternalBankAccountsDocument, options);
      }
export type DeleteCompanyWorkshopExternalBankAccountsMutationHookResult = ReturnType<typeof useDeleteCompanyWorkshopExternalBankAccountsMutation>;
export type DeleteCompanyWorkshopExternalBankAccountsMutationResult = Apollo.MutationResult<Types.DeleteCompanyWorkshopExternalBankAccountsMutation>;
export type DeleteCompanyWorkshopExternalBankAccountsMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCompanyWorkshopExternalBankAccountsMutation, Types.DeleteCompanyWorkshopExternalBankAccountsMutationVariables>;
export const DeleteCompanyWorkshopIntegrationDocument = gql`
    mutation DeleteCompanyWorkshopIntegration($data: DeleteCompanyWorkshopIntegrationInput!) {
  deleteCompanyWorkshopIntegration(data: $data) {
    created_at
  }
}
    `;
export type DeleteCompanyWorkshopIntegrationMutationFn = Apollo.MutationFunction<Types.DeleteCompanyWorkshopIntegrationMutation, Types.DeleteCompanyWorkshopIntegrationMutationVariables>;

/**
 * __useDeleteCompanyWorkshopIntegrationMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyWorkshopIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyWorkshopIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyWorkshopIntegrationMutation, { data, loading, error }] = useDeleteCompanyWorkshopIntegrationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteCompanyWorkshopIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCompanyWorkshopIntegrationMutation, Types.DeleteCompanyWorkshopIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCompanyWorkshopIntegrationMutation, Types.DeleteCompanyWorkshopIntegrationMutationVariables>(DeleteCompanyWorkshopIntegrationDocument, options);
      }
export type DeleteCompanyWorkshopIntegrationMutationHookResult = ReturnType<typeof useDeleteCompanyWorkshopIntegrationMutation>;
export type DeleteCompanyWorkshopIntegrationMutationResult = Apollo.MutationResult<Types.DeleteCompanyWorkshopIntegrationMutation>;
export type DeleteCompanyWorkshopIntegrationMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCompanyWorkshopIntegrationMutation, Types.DeleteCompanyWorkshopIntegrationMutationVariables>;
export const DeleteCompanyWorkshopPhotosDocument = gql`
    mutation DeleteCompanyWorkshopPhotos($photos: [String!]!) {
  deleteCompanyWorkshopPhotos(photos: $photos)
}
    `;
export type DeleteCompanyWorkshopPhotosMutationFn = Apollo.MutationFunction<Types.DeleteCompanyWorkshopPhotosMutation, Types.DeleteCompanyWorkshopPhotosMutationVariables>;

/**
 * __useDeleteCompanyWorkshopPhotosMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyWorkshopPhotosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyWorkshopPhotosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyWorkshopPhotosMutation, { data, loading, error }] = useDeleteCompanyWorkshopPhotosMutation({
 *   variables: {
 *      photos: // value for 'photos'
 *   },
 * });
 */
export function useDeleteCompanyWorkshopPhotosMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCompanyWorkshopPhotosMutation, Types.DeleteCompanyWorkshopPhotosMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCompanyWorkshopPhotosMutation, Types.DeleteCompanyWorkshopPhotosMutationVariables>(DeleteCompanyWorkshopPhotosDocument, options);
      }
export type DeleteCompanyWorkshopPhotosMutationHookResult = ReturnType<typeof useDeleteCompanyWorkshopPhotosMutation>;
export type DeleteCompanyWorkshopPhotosMutationResult = Apollo.MutationResult<Types.DeleteCompanyWorkshopPhotosMutation>;
export type DeleteCompanyWorkshopPhotosMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCompanyWorkshopPhotosMutation, Types.DeleteCompanyWorkshopPhotosMutationVariables>;
export const DeleteManyWorkshopSubscriptionPlansDocument = gql`
    mutation DeleteManyWorkshopSubscriptionPlans($data: DeleteWorkshopSubscriptionPlanInput!) {
  deleteManyWorkshopSubscriptionPlans(data: $data) {
    errors
    deleted_ids
  }
}
    `;
export type DeleteManyWorkshopSubscriptionPlansMutationFn = Apollo.MutationFunction<Types.DeleteManyWorkshopSubscriptionPlansMutation, Types.DeleteManyWorkshopSubscriptionPlansMutationVariables>;

/**
 * __useDeleteManyWorkshopSubscriptionPlansMutation__
 *
 * To run a mutation, you first call `useDeleteManyWorkshopSubscriptionPlansMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyWorkshopSubscriptionPlansMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyWorkshopSubscriptionPlansMutation, { data, loading, error }] = useDeleteManyWorkshopSubscriptionPlansMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteManyWorkshopSubscriptionPlansMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteManyWorkshopSubscriptionPlansMutation, Types.DeleteManyWorkshopSubscriptionPlansMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteManyWorkshopSubscriptionPlansMutation, Types.DeleteManyWorkshopSubscriptionPlansMutationVariables>(DeleteManyWorkshopSubscriptionPlansDocument, options);
      }
export type DeleteManyWorkshopSubscriptionPlansMutationHookResult = ReturnType<typeof useDeleteManyWorkshopSubscriptionPlansMutation>;
export type DeleteManyWorkshopSubscriptionPlansMutationResult = Apollo.MutationResult<Types.DeleteManyWorkshopSubscriptionPlansMutation>;
export type DeleteManyWorkshopSubscriptionPlansMutationOptions = Apollo.BaseMutationOptions<Types.DeleteManyWorkshopSubscriptionPlansMutation, Types.DeleteManyWorkshopSubscriptionPlansMutationVariables>;
export const DeleteStandardBikeSetupDocument = gql`
    mutation DeleteStandardBikeSetup($data: DeleteStandardBikeSetupsInput!) {
  deleteStandardBikeSetup(data: $data) {
    id
  }
}
    `;
export type DeleteStandardBikeSetupMutationFn = Apollo.MutationFunction<Types.DeleteStandardBikeSetupMutation, Types.DeleteStandardBikeSetupMutationVariables>;

/**
 * __useDeleteStandardBikeSetupMutation__
 *
 * To run a mutation, you first call `useDeleteStandardBikeSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStandardBikeSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStandardBikeSetupMutation, { data, loading, error }] = useDeleteStandardBikeSetupMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteStandardBikeSetupMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteStandardBikeSetupMutation, Types.DeleteStandardBikeSetupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteStandardBikeSetupMutation, Types.DeleteStandardBikeSetupMutationVariables>(DeleteStandardBikeSetupDocument, options);
      }
export type DeleteStandardBikeSetupMutationHookResult = ReturnType<typeof useDeleteStandardBikeSetupMutation>;
export type DeleteStandardBikeSetupMutationResult = Apollo.MutationResult<Types.DeleteStandardBikeSetupMutation>;
export type DeleteStandardBikeSetupMutationOptions = Apollo.BaseMutationOptions<Types.DeleteStandardBikeSetupMutation, Types.DeleteStandardBikeSetupMutationVariables>;
export const DeleteStandardBikeSetupGearDocument = gql`
    mutation DeleteStandardBikeSetupGear($data: DeleteStandardBikeSetupGearInput!) {
  deleteStandardBikeSetupGear(data: $data) {
    updated_at
  }
}
    `;
export type DeleteStandardBikeSetupGearMutationFn = Apollo.MutationFunction<Types.DeleteStandardBikeSetupGearMutation, Types.DeleteStandardBikeSetupGearMutationVariables>;

/**
 * __useDeleteStandardBikeSetupGearMutation__
 *
 * To run a mutation, you first call `useDeleteStandardBikeSetupGearMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStandardBikeSetupGearMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStandardBikeSetupGearMutation, { data, loading, error }] = useDeleteStandardBikeSetupGearMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteStandardBikeSetupGearMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteStandardBikeSetupGearMutation, Types.DeleteStandardBikeSetupGearMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteStandardBikeSetupGearMutation, Types.DeleteStandardBikeSetupGearMutationVariables>(DeleteStandardBikeSetupGearDocument, options);
      }
export type DeleteStandardBikeSetupGearMutationHookResult = ReturnType<typeof useDeleteStandardBikeSetupGearMutation>;
export type DeleteStandardBikeSetupGearMutationResult = Apollo.MutationResult<Types.DeleteStandardBikeSetupGearMutation>;
export type DeleteStandardBikeSetupGearMutationOptions = Apollo.BaseMutationOptions<Types.DeleteStandardBikeSetupGearMutation, Types.DeleteStandardBikeSetupGearMutationVariables>;
export const DeleteUserWorkshopSubscriptionPlanAdvantageRegisterDocument = gql`
    mutation DeleteUserWorkshopSubscriptionPlanAdvantageRegister($data: DeleteUserWorkshopSubscriptionPlanAdvantageRegisterInput!) {
  deleteUserWorkshopSubscriptionPlanAdvantageRegister(data: $data)
}
    `;
export type DeleteUserWorkshopSubscriptionPlanAdvantageRegisterMutationFn = Apollo.MutationFunction<Types.DeleteUserWorkshopSubscriptionPlanAdvantageRegisterMutation, Types.DeleteUserWorkshopSubscriptionPlanAdvantageRegisterMutationVariables>;

/**
 * __useDeleteUserWorkshopSubscriptionPlanAdvantageRegisterMutation__
 *
 * To run a mutation, you first call `useDeleteUserWorkshopSubscriptionPlanAdvantageRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserWorkshopSubscriptionPlanAdvantageRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserWorkshopSubscriptionPlanAdvantageRegisterMutation, { data, loading, error }] = useDeleteUserWorkshopSubscriptionPlanAdvantageRegisterMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteUserWorkshopSubscriptionPlanAdvantageRegisterMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteUserWorkshopSubscriptionPlanAdvantageRegisterMutation, Types.DeleteUserWorkshopSubscriptionPlanAdvantageRegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteUserWorkshopSubscriptionPlanAdvantageRegisterMutation, Types.DeleteUserWorkshopSubscriptionPlanAdvantageRegisterMutationVariables>(DeleteUserWorkshopSubscriptionPlanAdvantageRegisterDocument, options);
      }
export type DeleteUserWorkshopSubscriptionPlanAdvantageRegisterMutationHookResult = ReturnType<typeof useDeleteUserWorkshopSubscriptionPlanAdvantageRegisterMutation>;
export type DeleteUserWorkshopSubscriptionPlanAdvantageRegisterMutationResult = Apollo.MutationResult<Types.DeleteUserWorkshopSubscriptionPlanAdvantageRegisterMutation>;
export type DeleteUserWorkshopSubscriptionPlanAdvantageRegisterMutationOptions = Apollo.BaseMutationOptions<Types.DeleteUserWorkshopSubscriptionPlanAdvantageRegisterMutation, Types.DeleteUserWorkshopSubscriptionPlanAdvantageRegisterMutationVariables>;
export const DeleteWorkshopDiscountCouponDocument = gql`
    mutation DeleteWorkshopDiscountCoupon($id: String!) {
  deleteWorkshopDiscountCoupon(id: $id)
}
    `;
export type DeleteWorkshopDiscountCouponMutationFn = Apollo.MutationFunction<Types.DeleteWorkshopDiscountCouponMutation, Types.DeleteWorkshopDiscountCouponMutationVariables>;

/**
 * __useDeleteWorkshopDiscountCouponMutation__
 *
 * To run a mutation, you first call `useDeleteWorkshopDiscountCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkshopDiscountCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkshopDiscountCouponMutation, { data, loading, error }] = useDeleteWorkshopDiscountCouponMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWorkshopDiscountCouponMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteWorkshopDiscountCouponMutation, Types.DeleteWorkshopDiscountCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteWorkshopDiscountCouponMutation, Types.DeleteWorkshopDiscountCouponMutationVariables>(DeleteWorkshopDiscountCouponDocument, options);
      }
export type DeleteWorkshopDiscountCouponMutationHookResult = ReturnType<typeof useDeleteWorkshopDiscountCouponMutation>;
export type DeleteWorkshopDiscountCouponMutationResult = Apollo.MutationResult<Types.DeleteWorkshopDiscountCouponMutation>;
export type DeleteWorkshopDiscountCouponMutationOptions = Apollo.BaseMutationOptions<Types.DeleteWorkshopDiscountCouponMutation, Types.DeleteWorkshopDiscountCouponMutationVariables>;
export const DeleteWorkshopReceiptMethodDocument = gql`
    mutation DeleteWorkshopReceiptMethod($data: DeleteWorkshopReceiptMethodInput!) {
  deleteWorkshopReceiptMethod(data: $data)
}
    `;
export type DeleteWorkshopReceiptMethodMutationFn = Apollo.MutationFunction<Types.DeleteWorkshopReceiptMethodMutation, Types.DeleteWorkshopReceiptMethodMutationVariables>;

/**
 * __useDeleteWorkshopReceiptMethodMutation__
 *
 * To run a mutation, you first call `useDeleteWorkshopReceiptMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkshopReceiptMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkshopReceiptMethodMutation, { data, loading, error }] = useDeleteWorkshopReceiptMethodMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteWorkshopReceiptMethodMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteWorkshopReceiptMethodMutation, Types.DeleteWorkshopReceiptMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteWorkshopReceiptMethodMutation, Types.DeleteWorkshopReceiptMethodMutationVariables>(DeleteWorkshopReceiptMethodDocument, options);
      }
export type DeleteWorkshopReceiptMethodMutationHookResult = ReturnType<typeof useDeleteWorkshopReceiptMethodMutation>;
export type DeleteWorkshopReceiptMethodMutationResult = Apollo.MutationResult<Types.DeleteWorkshopReceiptMethodMutation>;
export type DeleteWorkshopReceiptMethodMutationOptions = Apollo.BaseMutationOptions<Types.DeleteWorkshopReceiptMethodMutation, Types.DeleteWorkshopReceiptMethodMutationVariables>;
export const DeleteWorkshopServiceDocument = gql`
    mutation DeleteWorkshopService($id: String!) {
  deleteWorkshopService(id: $id)
}
    `;
export type DeleteWorkshopServiceMutationFn = Apollo.MutationFunction<Types.DeleteWorkshopServiceMutation, Types.DeleteWorkshopServiceMutationVariables>;

/**
 * __useDeleteWorkshopServiceMutation__
 *
 * To run a mutation, you first call `useDeleteWorkshopServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkshopServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkshopServiceMutation, { data, loading, error }] = useDeleteWorkshopServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWorkshopServiceMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteWorkshopServiceMutation, Types.DeleteWorkshopServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteWorkshopServiceMutation, Types.DeleteWorkshopServiceMutationVariables>(DeleteWorkshopServiceDocument, options);
      }
export type DeleteWorkshopServiceMutationHookResult = ReturnType<typeof useDeleteWorkshopServiceMutation>;
export type DeleteWorkshopServiceMutationResult = Apollo.MutationResult<Types.DeleteWorkshopServiceMutation>;
export type DeleteWorkshopServiceMutationOptions = Apollo.BaseMutationOptions<Types.DeleteWorkshopServiceMutation, Types.DeleteWorkshopServiceMutationVariables>;
export const DeleteWorkshopSubscriptionPlanAdvantagesDocument = gql`
    mutation DeleteWorkshopSubscriptionPlanAdvantages($data: DeleteWorkshopSubscriptionPlanAdvantagesInput!) {
  deleteWorkshopSubscriptionPlanAdvantages(data: $data)
}
    `;
export type DeleteWorkshopSubscriptionPlanAdvantagesMutationFn = Apollo.MutationFunction<Types.DeleteWorkshopSubscriptionPlanAdvantagesMutation, Types.DeleteWorkshopSubscriptionPlanAdvantagesMutationVariables>;

/**
 * __useDeleteWorkshopSubscriptionPlanAdvantagesMutation__
 *
 * To run a mutation, you first call `useDeleteWorkshopSubscriptionPlanAdvantagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkshopSubscriptionPlanAdvantagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkshopSubscriptionPlanAdvantagesMutation, { data, loading, error }] = useDeleteWorkshopSubscriptionPlanAdvantagesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteWorkshopSubscriptionPlanAdvantagesMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteWorkshopSubscriptionPlanAdvantagesMutation, Types.DeleteWorkshopSubscriptionPlanAdvantagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteWorkshopSubscriptionPlanAdvantagesMutation, Types.DeleteWorkshopSubscriptionPlanAdvantagesMutationVariables>(DeleteWorkshopSubscriptionPlanAdvantagesDocument, options);
      }
export type DeleteWorkshopSubscriptionPlanAdvantagesMutationHookResult = ReturnType<typeof useDeleteWorkshopSubscriptionPlanAdvantagesMutation>;
export type DeleteWorkshopSubscriptionPlanAdvantagesMutationResult = Apollo.MutationResult<Types.DeleteWorkshopSubscriptionPlanAdvantagesMutation>;
export type DeleteWorkshopSubscriptionPlanAdvantagesMutationOptions = Apollo.BaseMutationOptions<Types.DeleteWorkshopSubscriptionPlanAdvantagesMutation, Types.DeleteWorkshopSubscriptionPlanAdvantagesMutationVariables>;
export const EnableOrDisableWorkshopServiceDocument = gql`
    mutation EnableOrDisableWorkshopService($data: EnableOrDisableWorkshopServicesInput!) {
  enableOrDisableWorkshopService(data: $data)
}
    `;
export type EnableOrDisableWorkshopServiceMutationFn = Apollo.MutationFunction<Types.EnableOrDisableWorkshopServiceMutation, Types.EnableOrDisableWorkshopServiceMutationVariables>;

/**
 * __useEnableOrDisableWorkshopServiceMutation__
 *
 * To run a mutation, you first call `useEnableOrDisableWorkshopServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableOrDisableWorkshopServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableOrDisableWorkshopServiceMutation, { data, loading, error }] = useEnableOrDisableWorkshopServiceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEnableOrDisableWorkshopServiceMutation(baseOptions?: Apollo.MutationHookOptions<Types.EnableOrDisableWorkshopServiceMutation, Types.EnableOrDisableWorkshopServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EnableOrDisableWorkshopServiceMutation, Types.EnableOrDisableWorkshopServiceMutationVariables>(EnableOrDisableWorkshopServiceDocument, options);
      }
export type EnableOrDisableWorkshopServiceMutationHookResult = ReturnType<typeof useEnableOrDisableWorkshopServiceMutation>;
export type EnableOrDisableWorkshopServiceMutationResult = Apollo.MutationResult<Types.EnableOrDisableWorkshopServiceMutation>;
export type EnableOrDisableWorkshopServiceMutationOptions = Apollo.BaseMutationOptions<Types.EnableOrDisableWorkshopServiceMutation, Types.EnableOrDisableWorkshopServiceMutationVariables>;
export const FinishContractedWorkshopPlanDocument = gql`
    mutation FinishContractedWorkshopPlan($data: FinishContractedWorkshopPlanInput!, $create_card: CreateCompanyCardInput) {
  finishContractedWorkshopPlan(data: $data, create_card: $create_card)
}
    `;
export type FinishContractedWorkshopPlanMutationFn = Apollo.MutationFunction<Types.FinishContractedWorkshopPlanMutation, Types.FinishContractedWorkshopPlanMutationVariables>;

/**
 * __useFinishContractedWorkshopPlanMutation__
 *
 * To run a mutation, you first call `useFinishContractedWorkshopPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishContractedWorkshopPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishContractedWorkshopPlanMutation, { data, loading, error }] = useFinishContractedWorkshopPlanMutation({
 *   variables: {
 *      data: // value for 'data'
 *      create_card: // value for 'create_card'
 *   },
 * });
 */
export function useFinishContractedWorkshopPlanMutation(baseOptions?: Apollo.MutationHookOptions<Types.FinishContractedWorkshopPlanMutation, Types.FinishContractedWorkshopPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.FinishContractedWorkshopPlanMutation, Types.FinishContractedWorkshopPlanMutationVariables>(FinishContractedWorkshopPlanDocument, options);
      }
export type FinishContractedWorkshopPlanMutationHookResult = ReturnType<typeof useFinishContractedWorkshopPlanMutation>;
export type FinishContractedWorkshopPlanMutationResult = Apollo.MutationResult<Types.FinishContractedWorkshopPlanMutation>;
export type FinishContractedWorkshopPlanMutationOptions = Apollo.BaseMutationOptions<Types.FinishContractedWorkshopPlanMutation, Types.FinishContractedWorkshopPlanMutationVariables>;
export const GetIntegrationProductAmountInStockDocument = gql`
    query GetIntegrationProductAmountInStock($data: GetIntegrationProductAmountInStockInput!) {
  getIntegrationProductAmountInStock(data: $data) {
    amount_in_stock
    external_id
    unit
  }
}
    `;

/**
 * __useGetIntegrationProductAmountInStockQuery__
 *
 * To run a query within a React component, call `useGetIntegrationProductAmountInStockQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntegrationProductAmountInStockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntegrationProductAmountInStockQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetIntegrationProductAmountInStockQuery(baseOptions: Apollo.QueryHookOptions<Types.GetIntegrationProductAmountInStockQuery, Types.GetIntegrationProductAmountInStockQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetIntegrationProductAmountInStockQuery, Types.GetIntegrationProductAmountInStockQueryVariables>(GetIntegrationProductAmountInStockDocument, options);
      }
export function useGetIntegrationProductAmountInStockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetIntegrationProductAmountInStockQuery, Types.GetIntegrationProductAmountInStockQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetIntegrationProductAmountInStockQuery, Types.GetIntegrationProductAmountInStockQueryVariables>(GetIntegrationProductAmountInStockDocument, options);
        }
export type GetIntegrationProductAmountInStockQueryHookResult = ReturnType<typeof useGetIntegrationProductAmountInStockQuery>;
export type GetIntegrationProductAmountInStockLazyQueryHookResult = ReturnType<typeof useGetIntegrationProductAmountInStockLazyQuery>;
export type GetIntegrationProductAmountInStockQueryResult = Apollo.QueryResult<Types.GetIntegrationProductAmountInStockQuery, Types.GetIntegrationProductAmountInStockQueryVariables>;
export const GetValidatedIntegrationRepairsDocument = gql`
    mutation GetValidatedIntegrationRepairs($data: GetValidatedIntegrationRepairsInput!) {
  getValidatedIntegrationRepairs(data: $data)
}
    `;
export type GetValidatedIntegrationRepairsMutationFn = Apollo.MutationFunction<Types.GetValidatedIntegrationRepairsMutation, Types.GetValidatedIntegrationRepairsMutationVariables>;

/**
 * __useGetValidatedIntegrationRepairsMutation__
 *
 * To run a mutation, you first call `useGetValidatedIntegrationRepairsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetValidatedIntegrationRepairsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getValidatedIntegrationRepairsMutation, { data, loading, error }] = useGetValidatedIntegrationRepairsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetValidatedIntegrationRepairsMutation(baseOptions?: Apollo.MutationHookOptions<Types.GetValidatedIntegrationRepairsMutation, Types.GetValidatedIntegrationRepairsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.GetValidatedIntegrationRepairsMutation, Types.GetValidatedIntegrationRepairsMutationVariables>(GetValidatedIntegrationRepairsDocument, options);
      }
export type GetValidatedIntegrationRepairsMutationHookResult = ReturnType<typeof useGetValidatedIntegrationRepairsMutation>;
export type GetValidatedIntegrationRepairsMutationResult = Apollo.MutationResult<Types.GetValidatedIntegrationRepairsMutation>;
export type GetValidatedIntegrationRepairsMutationOptions = Apollo.BaseMutationOptions<Types.GetValidatedIntegrationRepairsMutation, Types.GetValidatedIntegrationRepairsMutationVariables>;
export const RemoveBikeWithoutUserDocument = gql`
    mutation RemoveBikeWithoutUser($user_bike_id: String!) {
  removeBikeWithoutUser(user_bike_id: $user_bike_id)
}
    `;
export type RemoveBikeWithoutUserMutationFn = Apollo.MutationFunction<Types.RemoveBikeWithoutUserMutation, Types.RemoveBikeWithoutUserMutationVariables>;

/**
 * __useRemoveBikeWithoutUserMutation__
 *
 * To run a mutation, you first call `useRemoveBikeWithoutUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBikeWithoutUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBikeWithoutUserMutation, { data, loading, error }] = useRemoveBikeWithoutUserMutation({
 *   variables: {
 *      user_bike_id: // value for 'user_bike_id'
 *   },
 * });
 */
export function useRemoveBikeWithoutUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveBikeWithoutUserMutation, Types.RemoveBikeWithoutUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveBikeWithoutUserMutation, Types.RemoveBikeWithoutUserMutationVariables>(RemoveBikeWithoutUserDocument, options);
      }
export type RemoveBikeWithoutUserMutationHookResult = ReturnType<typeof useRemoveBikeWithoutUserMutation>;
export type RemoveBikeWithoutUserMutationResult = Apollo.MutationResult<Types.RemoveBikeWithoutUserMutation>;
export type RemoveBikeWithoutUserMutationOptions = Apollo.BaseMutationOptions<Types.RemoveBikeWithoutUserMutation, Types.RemoveBikeWithoutUserMutationVariables>;
export const RestrictWorkshopCouponToSubscriptionPlanDocument = gql`
    mutation RestrictWorkshopCouponToSubscriptionPlan($data: AssociateOrDissociateWorkshopCouponToWorkshopPlanInput!) {
  restrictWorkshopCouponToSubscriptionPlan(data: $data)
}
    `;
export type RestrictWorkshopCouponToSubscriptionPlanMutationFn = Apollo.MutationFunction<Types.RestrictWorkshopCouponToSubscriptionPlanMutation, Types.RestrictWorkshopCouponToSubscriptionPlanMutationVariables>;

/**
 * __useRestrictWorkshopCouponToSubscriptionPlanMutation__
 *
 * To run a mutation, you first call `useRestrictWorkshopCouponToSubscriptionPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestrictWorkshopCouponToSubscriptionPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restrictWorkshopCouponToSubscriptionPlanMutation, { data, loading, error }] = useRestrictWorkshopCouponToSubscriptionPlanMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRestrictWorkshopCouponToSubscriptionPlanMutation(baseOptions?: Apollo.MutationHookOptions<Types.RestrictWorkshopCouponToSubscriptionPlanMutation, Types.RestrictWorkshopCouponToSubscriptionPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RestrictWorkshopCouponToSubscriptionPlanMutation, Types.RestrictWorkshopCouponToSubscriptionPlanMutationVariables>(RestrictWorkshopCouponToSubscriptionPlanDocument, options);
      }
export type RestrictWorkshopCouponToSubscriptionPlanMutationHookResult = ReturnType<typeof useRestrictWorkshopCouponToSubscriptionPlanMutation>;
export type RestrictWorkshopCouponToSubscriptionPlanMutationResult = Apollo.MutationResult<Types.RestrictWorkshopCouponToSubscriptionPlanMutation>;
export type RestrictWorkshopCouponToSubscriptionPlanMutationOptions = Apollo.BaseMutationOptions<Types.RestrictWorkshopCouponToSubscriptionPlanMutation, Types.RestrictWorkshopCouponToSubscriptionPlanMutationVariables>;
export const ReturnWorkshopSubscriptionPlanForSaleDocument = gql`
    mutation ReturnWorkshopSubscriptionPlanForSale($data: EnableWorkshopSubscriptionPlanInput!) {
  returnWorkshopSubscriptionPlanForSale(data: $data)
}
    `;
export type ReturnWorkshopSubscriptionPlanForSaleMutationFn = Apollo.MutationFunction<Types.ReturnWorkshopSubscriptionPlanForSaleMutation, Types.ReturnWorkshopSubscriptionPlanForSaleMutationVariables>;

/**
 * __useReturnWorkshopSubscriptionPlanForSaleMutation__
 *
 * To run a mutation, you first call `useReturnWorkshopSubscriptionPlanForSaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReturnWorkshopSubscriptionPlanForSaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [returnWorkshopSubscriptionPlanForSaleMutation, { data, loading, error }] = useReturnWorkshopSubscriptionPlanForSaleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useReturnWorkshopSubscriptionPlanForSaleMutation(baseOptions?: Apollo.MutationHookOptions<Types.ReturnWorkshopSubscriptionPlanForSaleMutation, Types.ReturnWorkshopSubscriptionPlanForSaleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ReturnWorkshopSubscriptionPlanForSaleMutation, Types.ReturnWorkshopSubscriptionPlanForSaleMutationVariables>(ReturnWorkshopSubscriptionPlanForSaleDocument, options);
      }
export type ReturnWorkshopSubscriptionPlanForSaleMutationHookResult = ReturnType<typeof useReturnWorkshopSubscriptionPlanForSaleMutation>;
export type ReturnWorkshopSubscriptionPlanForSaleMutationResult = Apollo.MutationResult<Types.ReturnWorkshopSubscriptionPlanForSaleMutation>;
export type ReturnWorkshopSubscriptionPlanForSaleMutationOptions = Apollo.BaseMutationOptions<Types.ReturnWorkshopSubscriptionPlanForSaleMutation, Types.ReturnWorkshopSubscriptionPlanForSaleMutationVariables>;
export const SearchProductInCompanyWorkshopIntegrationStockDocument = gql`
    query SearchProductInCompanyWorkshopIntegrationStock($data: SearchProductInCompanyWorkshopIntegrationStockInput!) {
  searchProductInCompanyWorkshopIntegrationStock(data: $data) {
    amount_in_stock
    external_id
    external_reference
    image
    name
    price
    unit
    type
    variation_type
  }
}
    `;

/**
 * __useSearchProductInCompanyWorkshopIntegrationStockQuery__
 *
 * To run a query within a React component, call `useSearchProductInCompanyWorkshopIntegrationStockQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProductInCompanyWorkshopIntegrationStockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProductInCompanyWorkshopIntegrationStockQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSearchProductInCompanyWorkshopIntegrationStockQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchProductInCompanyWorkshopIntegrationStockQuery, Types.SearchProductInCompanyWorkshopIntegrationStockQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchProductInCompanyWorkshopIntegrationStockQuery, Types.SearchProductInCompanyWorkshopIntegrationStockQueryVariables>(SearchProductInCompanyWorkshopIntegrationStockDocument, options);
      }
export function useSearchProductInCompanyWorkshopIntegrationStockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchProductInCompanyWorkshopIntegrationStockQuery, Types.SearchProductInCompanyWorkshopIntegrationStockQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchProductInCompanyWorkshopIntegrationStockQuery, Types.SearchProductInCompanyWorkshopIntegrationStockQueryVariables>(SearchProductInCompanyWorkshopIntegrationStockDocument, options);
        }
export type SearchProductInCompanyWorkshopIntegrationStockQueryHookResult = ReturnType<typeof useSearchProductInCompanyWorkshopIntegrationStockQuery>;
export type SearchProductInCompanyWorkshopIntegrationStockLazyQueryHookResult = ReturnType<typeof useSearchProductInCompanyWorkshopIntegrationStockLazyQuery>;
export type SearchProductInCompanyWorkshopIntegrationStockQueryResult = Apollo.QueryResult<Types.SearchProductInCompanyWorkshopIntegrationStockQuery, Types.SearchProductInCompanyWorkshopIntegrationStockQueryVariables>;
export const SearchWorkshopIntegrationSellerDocument = gql`
    query SearchWorkshopIntegrationSeller($data: SearchWorkshopIntegrationSellerInput!) {
  searchWorkshopIntegrationSeller(data: $data) {
    external_id
    name
  }
}
    `;

/**
 * __useSearchWorkshopIntegrationSellerQuery__
 *
 * To run a query within a React component, call `useSearchWorkshopIntegrationSellerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchWorkshopIntegrationSellerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchWorkshopIntegrationSellerQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSearchWorkshopIntegrationSellerQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchWorkshopIntegrationSellerQuery, Types.SearchWorkshopIntegrationSellerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchWorkshopIntegrationSellerQuery, Types.SearchWorkshopIntegrationSellerQueryVariables>(SearchWorkshopIntegrationSellerDocument, options);
      }
export function useSearchWorkshopIntegrationSellerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchWorkshopIntegrationSellerQuery, Types.SearchWorkshopIntegrationSellerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchWorkshopIntegrationSellerQuery, Types.SearchWorkshopIntegrationSellerQueryVariables>(SearchWorkshopIntegrationSellerDocument, options);
        }
export type SearchWorkshopIntegrationSellerQueryHookResult = ReturnType<typeof useSearchWorkshopIntegrationSellerQuery>;
export type SearchWorkshopIntegrationSellerLazyQueryHookResult = ReturnType<typeof useSearchWorkshopIntegrationSellerLazyQuery>;
export type SearchWorkshopIntegrationSellerQueryResult = Apollo.QueryResult<Types.SearchWorkshopIntegrationSellerQuery, Types.SearchWorkshopIntegrationSellerQueryVariables>;
export const SendInviteForAllWorkshopPlansDocument = gql`
    mutation SendInviteForAllWorkshopPlans($data: SendInviteForAllWorkshopPlansInput!) {
  sendInviteForAllWorkshopPlans(data: $data)
}
    `;
export type SendInviteForAllWorkshopPlansMutationFn = Apollo.MutationFunction<Types.SendInviteForAllWorkshopPlansMutation, Types.SendInviteForAllWorkshopPlansMutationVariables>;

/**
 * __useSendInviteForAllWorkshopPlansMutation__
 *
 * To run a mutation, you first call `useSendInviteForAllWorkshopPlansMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInviteForAllWorkshopPlansMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInviteForAllWorkshopPlansMutation, { data, loading, error }] = useSendInviteForAllWorkshopPlansMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendInviteForAllWorkshopPlansMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendInviteForAllWorkshopPlansMutation, Types.SendInviteForAllWorkshopPlansMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendInviteForAllWorkshopPlansMutation, Types.SendInviteForAllWorkshopPlansMutationVariables>(SendInviteForAllWorkshopPlansDocument, options);
      }
export type SendInviteForAllWorkshopPlansMutationHookResult = ReturnType<typeof useSendInviteForAllWorkshopPlansMutation>;
export type SendInviteForAllWorkshopPlansMutationResult = Apollo.MutationResult<Types.SendInviteForAllWorkshopPlansMutation>;
export type SendInviteForAllWorkshopPlansMutationOptions = Apollo.BaseMutationOptions<Types.SendInviteForAllWorkshopPlansMutation, Types.SendInviteForAllWorkshopPlansMutationVariables>;
export const SendWorkshopPlanInviteDocument = gql`
    mutation SendWorkshopPlanInvite($data: SendCompanyWorkshopPlanInviteInput!) {
  sendWorkshopPlanInvite(data: $data)
}
    `;
export type SendWorkshopPlanInviteMutationFn = Apollo.MutationFunction<Types.SendWorkshopPlanInviteMutation, Types.SendWorkshopPlanInviteMutationVariables>;

/**
 * __useSendWorkshopPlanInviteMutation__
 *
 * To run a mutation, you first call `useSendWorkshopPlanInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendWorkshopPlanInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendWorkshopPlanInviteMutation, { data, loading, error }] = useSendWorkshopPlanInviteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendWorkshopPlanInviteMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendWorkshopPlanInviteMutation, Types.SendWorkshopPlanInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendWorkshopPlanInviteMutation, Types.SendWorkshopPlanInviteMutationVariables>(SendWorkshopPlanInviteDocument, options);
      }
export type SendWorkshopPlanInviteMutationHookResult = ReturnType<typeof useSendWorkshopPlanInviteMutation>;
export type SendWorkshopPlanInviteMutationResult = Apollo.MutationResult<Types.SendWorkshopPlanInviteMutation>;
export type SendWorkshopPlanInviteMutationOptions = Apollo.BaseMutationOptions<Types.SendWorkshopPlanInviteMutation, Types.SendWorkshopPlanInviteMutationVariables>;
export const SubscribeUserToWorkshopPlanDocument = gql`
    mutation SubscribeUserToWorkshopPlan($create_card: CreateCardInput, $data: SubscribeUserToWorkshopPlanInput!) {
  subscribeUserToWorkshopPlan(create_card: $create_card, data: $data)
}
    `;
export type SubscribeUserToWorkshopPlanMutationFn = Apollo.MutationFunction<Types.SubscribeUserToWorkshopPlanMutation, Types.SubscribeUserToWorkshopPlanMutationVariables>;

/**
 * __useSubscribeUserToWorkshopPlanMutation__
 *
 * To run a mutation, you first call `useSubscribeUserToWorkshopPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeUserToWorkshopPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeUserToWorkshopPlanMutation, { data, loading, error }] = useSubscribeUserToWorkshopPlanMutation({
 *   variables: {
 *      create_card: // value for 'create_card'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSubscribeUserToWorkshopPlanMutation(baseOptions?: Apollo.MutationHookOptions<Types.SubscribeUserToWorkshopPlanMutation, Types.SubscribeUserToWorkshopPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SubscribeUserToWorkshopPlanMutation, Types.SubscribeUserToWorkshopPlanMutationVariables>(SubscribeUserToWorkshopPlanDocument, options);
      }
export type SubscribeUserToWorkshopPlanMutationHookResult = ReturnType<typeof useSubscribeUserToWorkshopPlanMutation>;
export type SubscribeUserToWorkshopPlanMutationResult = Apollo.MutationResult<Types.SubscribeUserToWorkshopPlanMutation>;
export type SubscribeUserToWorkshopPlanMutationOptions = Apollo.BaseMutationOptions<Types.SubscribeUserToWorkshopPlanMutation, Types.SubscribeUserToWorkshopPlanMutationVariables>;
export const UnrestrictWorkshopCouponFromSubscriptionPlanDocument = gql`
    mutation unrestrictWorkshopCouponFromSubscriptionPlan($data: AssociateOrDissociateWorkshopCouponToWorkshopPlanInput!) {
  unrestrictWorkshopCouponFromSubscriptionPlan(data: $data)
}
    `;
export type UnrestrictWorkshopCouponFromSubscriptionPlanMutationFn = Apollo.MutationFunction<Types.UnrestrictWorkshopCouponFromSubscriptionPlanMutation, Types.UnrestrictWorkshopCouponFromSubscriptionPlanMutationVariables>;

/**
 * __useUnrestrictWorkshopCouponFromSubscriptionPlanMutation__
 *
 * To run a mutation, you first call `useUnrestrictWorkshopCouponFromSubscriptionPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnrestrictWorkshopCouponFromSubscriptionPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unrestrictWorkshopCouponFromSubscriptionPlanMutation, { data, loading, error }] = useUnrestrictWorkshopCouponFromSubscriptionPlanMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUnrestrictWorkshopCouponFromSubscriptionPlanMutation(baseOptions?: Apollo.MutationHookOptions<Types.UnrestrictWorkshopCouponFromSubscriptionPlanMutation, Types.UnrestrictWorkshopCouponFromSubscriptionPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UnrestrictWorkshopCouponFromSubscriptionPlanMutation, Types.UnrestrictWorkshopCouponFromSubscriptionPlanMutationVariables>(UnrestrictWorkshopCouponFromSubscriptionPlanDocument, options);
      }
export type UnrestrictWorkshopCouponFromSubscriptionPlanMutationHookResult = ReturnType<typeof useUnrestrictWorkshopCouponFromSubscriptionPlanMutation>;
export type UnrestrictWorkshopCouponFromSubscriptionPlanMutationResult = Apollo.MutationResult<Types.UnrestrictWorkshopCouponFromSubscriptionPlanMutation>;
export type UnrestrictWorkshopCouponFromSubscriptionPlanMutationOptions = Apollo.BaseMutationOptions<Types.UnrestrictWorkshopCouponFromSubscriptionPlanMutation, Types.UnrestrictWorkshopCouponFromSubscriptionPlanMutationVariables>;
export const UnsubscribeUserFromWorkshopPlanDocument = gql`
    mutation UnsubscribeUserFromWorkshopPlan($data: UnsubscribeUserFromWorkshopPlanInput!) {
  unsubscribeUserFromWorkshopPlan(data: $data)
}
    `;
export type UnsubscribeUserFromWorkshopPlanMutationFn = Apollo.MutationFunction<Types.UnsubscribeUserFromWorkshopPlanMutation, Types.UnsubscribeUserFromWorkshopPlanMutationVariables>;

/**
 * __useUnsubscribeUserFromWorkshopPlanMutation__
 *
 * To run a mutation, you first call `useUnsubscribeUserFromWorkshopPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeUserFromWorkshopPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeUserFromWorkshopPlanMutation, { data, loading, error }] = useUnsubscribeUserFromWorkshopPlanMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUnsubscribeUserFromWorkshopPlanMutation(baseOptions?: Apollo.MutationHookOptions<Types.UnsubscribeUserFromWorkshopPlanMutation, Types.UnsubscribeUserFromWorkshopPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UnsubscribeUserFromWorkshopPlanMutation, Types.UnsubscribeUserFromWorkshopPlanMutationVariables>(UnsubscribeUserFromWorkshopPlanDocument, options);
      }
export type UnsubscribeUserFromWorkshopPlanMutationHookResult = ReturnType<typeof useUnsubscribeUserFromWorkshopPlanMutation>;
export type UnsubscribeUserFromWorkshopPlanMutationResult = Apollo.MutationResult<Types.UnsubscribeUserFromWorkshopPlanMutation>;
export type UnsubscribeUserFromWorkshopPlanMutationOptions = Apollo.BaseMutationOptions<Types.UnsubscribeUserFromWorkshopPlanMutation, Types.UnsubscribeUserFromWorkshopPlanMutationVariables>;
export const UpdateCompanyWorkshopDocument = gql`
    mutation UpdateCompanyWorkshop($data: UpdateCompanyWorkshopInput!) {
  updateCompanyWorkshop(data: $data) {
    id
  }
}
    `;
export type UpdateCompanyWorkshopMutationFn = Apollo.MutationFunction<Types.UpdateCompanyWorkshopMutation, Types.UpdateCompanyWorkshopMutationVariables>;

/**
 * __useUpdateCompanyWorkshopMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyWorkshopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyWorkshopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyWorkshopMutation, { data, loading, error }] = useUpdateCompanyWorkshopMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCompanyWorkshopMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCompanyWorkshopMutation, Types.UpdateCompanyWorkshopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCompanyWorkshopMutation, Types.UpdateCompanyWorkshopMutationVariables>(UpdateCompanyWorkshopDocument, options);
      }
export type UpdateCompanyWorkshopMutationHookResult = ReturnType<typeof useUpdateCompanyWorkshopMutation>;
export type UpdateCompanyWorkshopMutationResult = Apollo.MutationResult<Types.UpdateCompanyWorkshopMutation>;
export type UpdateCompanyWorkshopMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCompanyWorkshopMutation, Types.UpdateCompanyWorkshopMutationVariables>;
export const UpdateCompanyWorkshopIntegrationDocument = gql`
    mutation UpdateCompanyWorkshopIntegration($data: UpdateCompanyWorkshopIntegrationInput!) {
  updateCompanyWorkshopIntegration(data: $data) {
    created_at
  }
}
    `;
export type UpdateCompanyWorkshopIntegrationMutationFn = Apollo.MutationFunction<Types.UpdateCompanyWorkshopIntegrationMutation, Types.UpdateCompanyWorkshopIntegrationMutationVariables>;

/**
 * __useUpdateCompanyWorkshopIntegrationMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyWorkshopIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyWorkshopIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyWorkshopIntegrationMutation, { data, loading, error }] = useUpdateCompanyWorkshopIntegrationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCompanyWorkshopIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCompanyWorkshopIntegrationMutation, Types.UpdateCompanyWorkshopIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCompanyWorkshopIntegrationMutation, Types.UpdateCompanyWorkshopIntegrationMutationVariables>(UpdateCompanyWorkshopIntegrationDocument, options);
      }
export type UpdateCompanyWorkshopIntegrationMutationHookResult = ReturnType<typeof useUpdateCompanyWorkshopIntegrationMutation>;
export type UpdateCompanyWorkshopIntegrationMutationResult = Apollo.MutationResult<Types.UpdateCompanyWorkshopIntegrationMutation>;
export type UpdateCompanyWorkshopIntegrationMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCompanyWorkshopIntegrationMutation, Types.UpdateCompanyWorkshopIntegrationMutationVariables>;
export const UpdateCompanyWorkshopPhotosDocument = gql`
    mutation UpdateCompanyWorkshopPhotos($photos: [UpdateCompanyWorkshopPhotosInput!]!) {
  updateCompanyWorkshopPhotos(photos: $photos) {
    id
  }
}
    `;
export type UpdateCompanyWorkshopPhotosMutationFn = Apollo.MutationFunction<Types.UpdateCompanyWorkshopPhotosMutation, Types.UpdateCompanyWorkshopPhotosMutationVariables>;

/**
 * __useUpdateCompanyWorkshopPhotosMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyWorkshopPhotosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyWorkshopPhotosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyWorkshopPhotosMutation, { data, loading, error }] = useUpdateCompanyWorkshopPhotosMutation({
 *   variables: {
 *      photos: // value for 'photos'
 *   },
 * });
 */
export function useUpdateCompanyWorkshopPhotosMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCompanyWorkshopPhotosMutation, Types.UpdateCompanyWorkshopPhotosMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCompanyWorkshopPhotosMutation, Types.UpdateCompanyWorkshopPhotosMutationVariables>(UpdateCompanyWorkshopPhotosDocument, options);
      }
export type UpdateCompanyWorkshopPhotosMutationHookResult = ReturnType<typeof useUpdateCompanyWorkshopPhotosMutation>;
export type UpdateCompanyWorkshopPhotosMutationResult = Apollo.MutationResult<Types.UpdateCompanyWorkshopPhotosMutation>;
export type UpdateCompanyWorkshopPhotosMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCompanyWorkshopPhotosMutation, Types.UpdateCompanyWorkshopPhotosMutationVariables>;
export const UpdateCompanyWorkshopSettingDocument = gql`
    mutation UpdateCompanyWorkshopSetting($data: ChangeCompanyWorkshopErpIntegrationInput!) {
  updateCompanyWorkshopSetting(data: $data) {
    company_workshop_id
  }
}
    `;
export type UpdateCompanyWorkshopSettingMutationFn = Apollo.MutationFunction<Types.UpdateCompanyWorkshopSettingMutation, Types.UpdateCompanyWorkshopSettingMutationVariables>;

/**
 * __useUpdateCompanyWorkshopSettingMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyWorkshopSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyWorkshopSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyWorkshopSettingMutation, { data, loading, error }] = useUpdateCompanyWorkshopSettingMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCompanyWorkshopSettingMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCompanyWorkshopSettingMutation, Types.UpdateCompanyWorkshopSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCompanyWorkshopSettingMutation, Types.UpdateCompanyWorkshopSettingMutationVariables>(UpdateCompanyWorkshopSettingDocument, options);
      }
export type UpdateCompanyWorkshopSettingMutationHookResult = ReturnType<typeof useUpdateCompanyWorkshopSettingMutation>;
export type UpdateCompanyWorkshopSettingMutationResult = Apollo.MutationResult<Types.UpdateCompanyWorkshopSettingMutation>;
export type UpdateCompanyWorkshopSettingMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCompanyWorkshopSettingMutation, Types.UpdateCompanyWorkshopSettingMutationVariables>;
export const UpdateContractedWorkshopPlanDocument = gql`
    mutation UpdateContractedWorkshopPlan($data: UpdateContractedWorkshopPlanInput!) {
  updateContractedWorkshopPlan(data: $data) {
    id
  }
}
    `;
export type UpdateContractedWorkshopPlanMutationFn = Apollo.MutationFunction<Types.UpdateContractedWorkshopPlanMutation, Types.UpdateContractedWorkshopPlanMutationVariables>;

/**
 * __useUpdateContractedWorkshopPlanMutation__
 *
 * To run a mutation, you first call `useUpdateContractedWorkshopPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractedWorkshopPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractedWorkshopPlanMutation, { data, loading, error }] = useUpdateContractedWorkshopPlanMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateContractedWorkshopPlanMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateContractedWorkshopPlanMutation, Types.UpdateContractedWorkshopPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateContractedWorkshopPlanMutation, Types.UpdateContractedWorkshopPlanMutationVariables>(UpdateContractedWorkshopPlanDocument, options);
      }
export type UpdateContractedWorkshopPlanMutationHookResult = ReturnType<typeof useUpdateContractedWorkshopPlanMutation>;
export type UpdateContractedWorkshopPlanMutationResult = Apollo.MutationResult<Types.UpdateContractedWorkshopPlanMutation>;
export type UpdateContractedWorkshopPlanMutationOptions = Apollo.BaseMutationOptions<Types.UpdateContractedWorkshopPlanMutation, Types.UpdateContractedWorkshopPlanMutationVariables>;
export const UpdateStandardBikeSetupDocument = gql`
    mutation UpdateStandardBikeSetup($data: UpdateStandardBikeSetupDataInput!) {
  updateStandardBikeSetup(data: $data) {
    id
  }
}
    `;
export type UpdateStandardBikeSetupMutationFn = Apollo.MutationFunction<Types.UpdateStandardBikeSetupMutation, Types.UpdateStandardBikeSetupMutationVariables>;

/**
 * __useUpdateStandardBikeSetupMutation__
 *
 * To run a mutation, you first call `useUpdateStandardBikeSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStandardBikeSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStandardBikeSetupMutation, { data, loading, error }] = useUpdateStandardBikeSetupMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateStandardBikeSetupMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateStandardBikeSetupMutation, Types.UpdateStandardBikeSetupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateStandardBikeSetupMutation, Types.UpdateStandardBikeSetupMutationVariables>(UpdateStandardBikeSetupDocument, options);
      }
export type UpdateStandardBikeSetupMutationHookResult = ReturnType<typeof useUpdateStandardBikeSetupMutation>;
export type UpdateStandardBikeSetupMutationResult = Apollo.MutationResult<Types.UpdateStandardBikeSetupMutation>;
export type UpdateStandardBikeSetupMutationOptions = Apollo.BaseMutationOptions<Types.UpdateStandardBikeSetupMutation, Types.UpdateStandardBikeSetupMutationVariables>;
export const UpdateWorkshopClientDocument = gql`
    mutation UpdateWorkshopClient($data: UpdateWorkshopClientInput!) {
  updateWorkshopClient(data: $data) {
    id
  }
}
    `;
export type UpdateWorkshopClientMutationFn = Apollo.MutationFunction<Types.UpdateWorkshopClientMutation, Types.UpdateWorkshopClientMutationVariables>;

/**
 * __useUpdateWorkshopClientMutation__
 *
 * To run a mutation, you first call `useUpdateWorkshopClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkshopClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkshopClientMutation, { data, loading, error }] = useUpdateWorkshopClientMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateWorkshopClientMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateWorkshopClientMutation, Types.UpdateWorkshopClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateWorkshopClientMutation, Types.UpdateWorkshopClientMutationVariables>(UpdateWorkshopClientDocument, options);
      }
export type UpdateWorkshopClientMutationHookResult = ReturnType<typeof useUpdateWorkshopClientMutation>;
export type UpdateWorkshopClientMutationResult = Apollo.MutationResult<Types.UpdateWorkshopClientMutation>;
export type UpdateWorkshopClientMutationOptions = Apollo.BaseMutationOptions<Types.UpdateWorkshopClientMutation, Types.UpdateWorkshopClientMutationVariables>;
export const UpdateWorkshopDiscountCouponDocument = gql`
    mutation UpdateWorkshopDiscountCoupon($data: UpdateWorkshopDiscountCouponInput!) {
  updateWorkshopDiscountCoupon(data: $data) {
    code
  }
}
    `;
export type UpdateWorkshopDiscountCouponMutationFn = Apollo.MutationFunction<Types.UpdateWorkshopDiscountCouponMutation, Types.UpdateWorkshopDiscountCouponMutationVariables>;

/**
 * __useUpdateWorkshopDiscountCouponMutation__
 *
 * To run a mutation, you first call `useUpdateWorkshopDiscountCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkshopDiscountCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkshopDiscountCouponMutation, { data, loading, error }] = useUpdateWorkshopDiscountCouponMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateWorkshopDiscountCouponMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateWorkshopDiscountCouponMutation, Types.UpdateWorkshopDiscountCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateWorkshopDiscountCouponMutation, Types.UpdateWorkshopDiscountCouponMutationVariables>(UpdateWorkshopDiscountCouponDocument, options);
      }
export type UpdateWorkshopDiscountCouponMutationHookResult = ReturnType<typeof useUpdateWorkshopDiscountCouponMutation>;
export type UpdateWorkshopDiscountCouponMutationResult = Apollo.MutationResult<Types.UpdateWorkshopDiscountCouponMutation>;
export type UpdateWorkshopDiscountCouponMutationOptions = Apollo.BaseMutationOptions<Types.UpdateWorkshopDiscountCouponMutation, Types.UpdateWorkshopDiscountCouponMutationVariables>;
export const UpdateWorkshopReceiptMethodDocument = gql`
    mutation UpdateWorkshopReceiptMethod($data: UpdateWorkshopReceiptMethodInput!) {
  updateWorkshopReceiptMethod(data: $data) {
    id
  }
}
    `;
export type UpdateWorkshopReceiptMethodMutationFn = Apollo.MutationFunction<Types.UpdateWorkshopReceiptMethodMutation, Types.UpdateWorkshopReceiptMethodMutationVariables>;

/**
 * __useUpdateWorkshopReceiptMethodMutation__
 *
 * To run a mutation, you first call `useUpdateWorkshopReceiptMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkshopReceiptMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkshopReceiptMethodMutation, { data, loading, error }] = useUpdateWorkshopReceiptMethodMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateWorkshopReceiptMethodMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateWorkshopReceiptMethodMutation, Types.UpdateWorkshopReceiptMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateWorkshopReceiptMethodMutation, Types.UpdateWorkshopReceiptMethodMutationVariables>(UpdateWorkshopReceiptMethodDocument, options);
      }
export type UpdateWorkshopReceiptMethodMutationHookResult = ReturnType<typeof useUpdateWorkshopReceiptMethodMutation>;
export type UpdateWorkshopReceiptMethodMutationResult = Apollo.MutationResult<Types.UpdateWorkshopReceiptMethodMutation>;
export type UpdateWorkshopReceiptMethodMutationOptions = Apollo.BaseMutationOptions<Types.UpdateWorkshopReceiptMethodMutation, Types.UpdateWorkshopReceiptMethodMutationVariables>;
export const UpdateWorkshopServiceDocument = gql`
    mutation UpdateWorkshopService($data: UpdateWorkshopServicesInput!) {
  updateWorkshopService(data: $data) {
    id
  }
}
    `;
export type UpdateWorkshopServiceMutationFn = Apollo.MutationFunction<Types.UpdateWorkshopServiceMutation, Types.UpdateWorkshopServiceMutationVariables>;

/**
 * __useUpdateWorkshopServiceMutation__
 *
 * To run a mutation, you first call `useUpdateWorkshopServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkshopServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkshopServiceMutation, { data, loading, error }] = useUpdateWorkshopServiceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateWorkshopServiceMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateWorkshopServiceMutation, Types.UpdateWorkshopServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateWorkshopServiceMutation, Types.UpdateWorkshopServiceMutationVariables>(UpdateWorkshopServiceDocument, options);
      }
export type UpdateWorkshopServiceMutationHookResult = ReturnType<typeof useUpdateWorkshopServiceMutation>;
export type UpdateWorkshopServiceMutationResult = Apollo.MutationResult<Types.UpdateWorkshopServiceMutation>;
export type UpdateWorkshopServiceMutationOptions = Apollo.BaseMutationOptions<Types.UpdateWorkshopServiceMutation, Types.UpdateWorkshopServiceMutationVariables>;
export const UpdateWorkshopSubscriptionPlanDocument = gql`
    mutation UpdateWorkshopSubscriptionPlan($data: UpdateWorkshopSubscriptionPlanInput!) {
  updateWorkshopSubscriptionPlan(data: $data)
}
    `;
export type UpdateWorkshopSubscriptionPlanMutationFn = Apollo.MutationFunction<Types.UpdateWorkshopSubscriptionPlanMutation, Types.UpdateWorkshopSubscriptionPlanMutationVariables>;

/**
 * __useUpdateWorkshopSubscriptionPlanMutation__
 *
 * To run a mutation, you first call `useUpdateWorkshopSubscriptionPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkshopSubscriptionPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkshopSubscriptionPlanMutation, { data, loading, error }] = useUpdateWorkshopSubscriptionPlanMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateWorkshopSubscriptionPlanMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateWorkshopSubscriptionPlanMutation, Types.UpdateWorkshopSubscriptionPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateWorkshopSubscriptionPlanMutation, Types.UpdateWorkshopSubscriptionPlanMutationVariables>(UpdateWorkshopSubscriptionPlanDocument, options);
      }
export type UpdateWorkshopSubscriptionPlanMutationHookResult = ReturnType<typeof useUpdateWorkshopSubscriptionPlanMutation>;
export type UpdateWorkshopSubscriptionPlanMutationResult = Apollo.MutationResult<Types.UpdateWorkshopSubscriptionPlanMutation>;
export type UpdateWorkshopSubscriptionPlanMutationOptions = Apollo.BaseMutationOptions<Types.UpdateWorkshopSubscriptionPlanMutation, Types.UpdateWorkshopSubscriptionPlanMutationVariables>;
export const UpdateWorkshopSubscriptionPlanAdvantageDocument = gql`
    mutation UpdateWorkshopSubscriptionPlanAdvantage($data: UpdateWorkshopSubscriptionPlanAdvantageInput!) {
  updateWorkshopSubscriptionPlanAdvantage(data: $data)
}
    `;
export type UpdateWorkshopSubscriptionPlanAdvantageMutationFn = Apollo.MutationFunction<Types.UpdateWorkshopSubscriptionPlanAdvantageMutation, Types.UpdateWorkshopSubscriptionPlanAdvantageMutationVariables>;

/**
 * __useUpdateWorkshopSubscriptionPlanAdvantageMutation__
 *
 * To run a mutation, you first call `useUpdateWorkshopSubscriptionPlanAdvantageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkshopSubscriptionPlanAdvantageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkshopSubscriptionPlanAdvantageMutation, { data, loading, error }] = useUpdateWorkshopSubscriptionPlanAdvantageMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateWorkshopSubscriptionPlanAdvantageMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateWorkshopSubscriptionPlanAdvantageMutation, Types.UpdateWorkshopSubscriptionPlanAdvantageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateWorkshopSubscriptionPlanAdvantageMutation, Types.UpdateWorkshopSubscriptionPlanAdvantageMutationVariables>(UpdateWorkshopSubscriptionPlanAdvantageDocument, options);
      }
export type UpdateWorkshopSubscriptionPlanAdvantageMutationHookResult = ReturnType<typeof useUpdateWorkshopSubscriptionPlanAdvantageMutation>;
export type UpdateWorkshopSubscriptionPlanAdvantageMutationResult = Apollo.MutationResult<Types.UpdateWorkshopSubscriptionPlanAdvantageMutation>;
export type UpdateWorkshopSubscriptionPlanAdvantageMutationOptions = Apollo.BaseMutationOptions<Types.UpdateWorkshopSubscriptionPlanAdvantageMutation, Types.UpdateWorkshopSubscriptionPlanAdvantageMutationVariables>;
export const ValidateIntegrationRepairsFileDocument = gql`
    mutation ValidateIntegrationRepairsFile($data: ValidateIntegrationRepairsFileInput!) {
  validateIntegrationRepairsFile(data: $data) {
    inside_error
    invalid_values
    missing_columns
    success
    warnings
    rows {
      bike_name
      client_date_of_birth
      client_document_type
      client_email
      client_gender
      client_legal_registry_number
      client_name
      client_phone_number
      cost
      cost_without_discount
      description
      end_date
      errors
      general_discount
      initial_date
      internal_description
      mechanic_name
      payment_condition
      status
      workshop_receipt_method
      line
    }
  }
}
    `;
export type ValidateIntegrationRepairsFileMutationFn = Apollo.MutationFunction<Types.ValidateIntegrationRepairsFileMutation, Types.ValidateIntegrationRepairsFileMutationVariables>;

/**
 * __useValidateIntegrationRepairsFileMutation__
 *
 * To run a mutation, you first call `useValidateIntegrationRepairsFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateIntegrationRepairsFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateIntegrationRepairsFileMutation, { data, loading, error }] = useValidateIntegrationRepairsFileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useValidateIntegrationRepairsFileMutation(baseOptions?: Apollo.MutationHookOptions<Types.ValidateIntegrationRepairsFileMutation, Types.ValidateIntegrationRepairsFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ValidateIntegrationRepairsFileMutation, Types.ValidateIntegrationRepairsFileMutationVariables>(ValidateIntegrationRepairsFileDocument, options);
      }
export type ValidateIntegrationRepairsFileMutationHookResult = ReturnType<typeof useValidateIntegrationRepairsFileMutation>;
export type ValidateIntegrationRepairsFileMutationResult = Apollo.MutationResult<Types.ValidateIntegrationRepairsFileMutation>;
export type ValidateIntegrationRepairsFileMutationOptions = Apollo.BaseMutationOptions<Types.ValidateIntegrationRepairsFileMutation, Types.ValidateIntegrationRepairsFileMutationVariables>;
export const WithdrawalWorkshopFundsDocument = gql`
    mutation WithdrawalWorkshopFunds($data: WithdrawalWorkshopFundsInput!) {
  withdrawalWorkshopFunds(data: $data)
}
    `;
export type WithdrawalWorkshopFundsMutationFn = Apollo.MutationFunction<Types.WithdrawalWorkshopFundsMutation, Types.WithdrawalWorkshopFundsMutationVariables>;

/**
 * __useWithdrawalWorkshopFundsMutation__
 *
 * To run a mutation, you first call `useWithdrawalWorkshopFundsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawalWorkshopFundsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawalWorkshopFundsMutation, { data, loading, error }] = useWithdrawalWorkshopFundsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useWithdrawalWorkshopFundsMutation(baseOptions?: Apollo.MutationHookOptions<Types.WithdrawalWorkshopFundsMutation, Types.WithdrawalWorkshopFundsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WithdrawalWorkshopFundsMutation, Types.WithdrawalWorkshopFundsMutationVariables>(WithdrawalWorkshopFundsDocument, options);
      }
export type WithdrawalWorkshopFundsMutationHookResult = ReturnType<typeof useWithdrawalWorkshopFundsMutation>;
export type WithdrawalWorkshopFundsMutationResult = Apollo.MutationResult<Types.WithdrawalWorkshopFundsMutation>;
export type WithdrawalWorkshopFundsMutationOptions = Apollo.BaseMutationOptions<Types.WithdrawalWorkshopFundsMutation, Types.WithdrawalWorkshopFundsMutationVariables>;
export const WorkshopInvitesDocument = gql`
    query WorkshopInvites($pagination: PaginationInput!, $company_workshop_id: String!) {
  workshopInvites(
    pagination: $pagination
    company_workshop_id: $company_workshop_id
  ) {
    mail
    phone_number
    ddi
    user_id
    id
    accepted
    created_at
  }
}
    `;

/**
 * __useWorkshopInvitesQuery__
 *
 * To run a query within a React component, call `useWorkshopInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkshopInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkshopInvitesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      company_workshop_id: // value for 'company_workshop_id'
 *   },
 * });
 */
export function useWorkshopInvitesQuery(baseOptions: Apollo.QueryHookOptions<Types.WorkshopInvitesQuery, Types.WorkshopInvitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WorkshopInvitesQuery, Types.WorkshopInvitesQueryVariables>(WorkshopInvitesDocument, options);
      }
export function useWorkshopInvitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WorkshopInvitesQuery, Types.WorkshopInvitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WorkshopInvitesQuery, Types.WorkshopInvitesQueryVariables>(WorkshopInvitesDocument, options);
        }
export type WorkshopInvitesQueryHookResult = ReturnType<typeof useWorkshopInvitesQuery>;
export type WorkshopInvitesLazyQueryHookResult = ReturnType<typeof useWorkshopInvitesLazyQuery>;
export type WorkshopInvitesQueryResult = Apollo.QueryResult<Types.WorkshopInvitesQuery, Types.WorkshopInvitesQueryVariables>;
export const BikeGearDetailDocument = gql`
    query BikeGearDetail($bike_gear_id: String!) {
  bikeGearDetail(bike_gear_id: $bike_gear_id) {
    id
    bike_id
    current
    created_at
    usage_percentage
    total_distance
    total_moving_time
    total_altimetry
    actual_monitoring_distance
    actual_monitoring_altimetry
    actual_monitoring_moving_time
    nickname
    brand
    model
    gear_type_slug
    group_alternation_id
    estimated_weeks_for_maintenance
    bike_gear_configuration {
      id
      free_field_content
      gear_type_configuration {
        id
        name
        free_field
      }
    }
    gear_type {
      alternation
      avatar
      name
      slug
      description
      gear_type_configuration {
        id
        name
        free_field
      }
    }
    bike_gear_monitoring {
      distance_goal
      altimetry_goal
      seconds_goal
      date_goal
      days_goal
      initial_date
      description
    }
    bike {
      id
      brand_name
      model_name
      weight
      wheel_size
      color_main
      frame_size
      frame_material
      created_at
      repairs {
        id
        date
      }
    }
  }
}
    `;

/**
 * __useBikeGearDetailQuery__
 *
 * To run a query within a React component, call `useBikeGearDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useBikeGearDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBikeGearDetailQuery({
 *   variables: {
 *      bike_gear_id: // value for 'bike_gear_id'
 *   },
 * });
 */
export function useBikeGearDetailQuery(baseOptions: Apollo.QueryHookOptions<Types.BikeGearDetailQuery, Types.BikeGearDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BikeGearDetailQuery, Types.BikeGearDetailQueryVariables>(BikeGearDetailDocument, options);
      }
export function useBikeGearDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BikeGearDetailQuery, Types.BikeGearDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BikeGearDetailQuery, Types.BikeGearDetailQueryVariables>(BikeGearDetailDocument, options);
        }
export type BikeGearDetailQueryHookResult = ReturnType<typeof useBikeGearDetailQuery>;
export type BikeGearDetailLazyQueryHookResult = ReturnType<typeof useBikeGearDetailLazyQuery>;
export type BikeGearDetailQueryResult = Apollo.QueryResult<Types.BikeGearDetailQuery, Types.BikeGearDetailQueryVariables>;
export const DeleteRepairDocument = gql`
    mutation DeleteRepair($repair_id: String!) {
  deleteRepair(repair_id: $repair_id)
}
    `;
export type DeleteRepairMutationFn = Apollo.MutationFunction<Types.DeleteRepairMutation, Types.DeleteRepairMutationVariables>;

/**
 * __useDeleteRepairMutation__
 *
 * To run a mutation, you first call `useDeleteRepairMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRepairMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRepairMutation, { data, loading, error }] = useDeleteRepairMutation({
 *   variables: {
 *      repair_id: // value for 'repair_id'
 *   },
 * });
 */
export function useDeleteRepairMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteRepairMutation, Types.DeleteRepairMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteRepairMutation, Types.DeleteRepairMutationVariables>(DeleteRepairDocument, options);
      }
export type DeleteRepairMutationHookResult = ReturnType<typeof useDeleteRepairMutation>;
export type DeleteRepairMutationResult = Apollo.MutationResult<Types.DeleteRepairMutation>;
export type DeleteRepairMutationOptions = Apollo.BaseMutationOptions<Types.DeleteRepairMutation, Types.DeleteRepairMutationVariables>;
export const GetBikeRepairDetailDocument = gql`
    query GetBikeRepairDetail($repair_id: String!) {
  getBikeRepairDetail(repair_id: $repair_id) {
    id
    cost
    cost_without_discount
    date
    description
    created_at
    client_name
    link_avatar_client
    accepted_repair_information
    informed_by_the_workshop
    company_workshop_id
    repair_status_slug
    initial_date
    end_date
    internal_description
    mechanic_name
    order_number
    payment_condition
    general_discount
    integration_seller_id
    integration_seller_name
    repair_item_integration_products {
      amount
      external_id
      external_reference
      name
      type
      unit_cost
    }
    quote_id
    quote {
      id
      accept_credit_card
      accept_pix
      active
      approved
      created_at
      immediate_payment_discount_credit_card
      immediate_payment_discount_pix
      immediate_payment_discount_type
      installment_limit
      paid
      payment {
        id
        value
        created_at
        effective_payment_date
        status
        origin_payment {
          slug
          origin_payments_translation {
            description
            language_code
            name
            origin_payment_id
          }
        }
        user {
          id
          name
          profile {
            profile_avatar
          }
        }
      }
      pix_qrcode
      sending_date
      updated_at
      value
    }
    workshop_receipt_method {
      name
      id
    }
    bike {
      id
      created_at
      bike_type {
        name
        avatar
      }
    }
    user_bike {
      id
      name
      bike_id
      workshop_client {
        id
        firstname
        lastname
      }
      user {
        id
        name
        profile {
          profile_avatar
        }
        workshop_clients {
          id
        }
      }
    }
    repair_attached_files {
      id
      link
    }
    repair_items {
      id
      repair_type_slug
      bike_gear_id
      amount
      cost
      cost_without_discount
      discount_percentage
      workshop_service_id
      bike_gear {
        id
        gear_type {
          avatar
          name
          slug
        }
      }
      workshop_service {
        id
        name
      }
    }
    gears_json {
      add_bike_gear {
        amount
        cost
        cost_without_discount
        discount_percentage
        repair_type_slug
        reset_alerts
        end_date_monitoring
        model
        brand
        nickname
        addition_date
        service_description
        repair_item_product_stock {
          type
          decrease
          external_id
          external_reference
          integration_return
          product_name
          price
        }
        new_monitoring {
          initial_date
          description
          distance_goal
          altimetry_goal
          days_goal
          seconds_goal
          date_goal
        }
        bike_gear_configurations {
          free_field
          gear_type_configuration_free_field_content
          gear_type_configuration_id
        }
        gear_type {
          avatar
          slug
          name
        }
        gear_type_configurations {
          free_field
          id
          name
        }
      }
      repair_items {
        amount
        cost
        cost_without_discount
        discount_percentage
        repair_type_slug
        reset_alerts
        bike_gear_id
        end_date_monitoring
        service_description
        group_alternation_id
        workshop_service_id
        workshop_service {
          name
        }
        repair_item_product_stock {
          type
          decrease
          external_id
          external_reference
          integration_return
          product_name
          price
        }
        new_monitoring {
          initial_date
          description
          distance_goal
          altimetry_goal
          days_goal
          seconds_goal
          date_goal
        }
        bike_gear_configurations {
          free_field
          gear_type_configuration_free_field_content
          gear_type_configuration_id
        }
        gear_type {
          avatar
          slug
          name
          alternation
        }
        gear_type_configurations {
          free_field
          id
          name
        }
        gear_change_new_data {
          addition_date
          gear_type_slug
          suggested_by_the_workshop
          fixed_on_top
          nickname
          brand
          model
        }
        add_gear_and_end_alternation_group {
          end_group_alternation_id
          new_bike_gear_data {
            addition_date
            gear_type_slug
            suggested_by_the_workshop
            fixed_on_top
            nickname
            brand
            model
          }
        }
        add_gear_to_alternation {
          addition_date
          gear_type_slug
          suggested_by_the_workshop
          fixed_on_top
          nickname
          brand
          model
        }
        alternate_bike_gear {
          new_gear_id
        }
      }
    }
  }
}
    `;

/**
 * __useGetBikeRepairDetailQuery__
 *
 * To run a query within a React component, call `useGetBikeRepairDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBikeRepairDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBikeRepairDetailQuery({
 *   variables: {
 *      repair_id: // value for 'repair_id'
 *   },
 * });
 */
export function useGetBikeRepairDetailQuery(baseOptions: Apollo.QueryHookOptions<Types.GetBikeRepairDetailQuery, Types.GetBikeRepairDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetBikeRepairDetailQuery, Types.GetBikeRepairDetailQueryVariables>(GetBikeRepairDetailDocument, options);
      }
export function useGetBikeRepairDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBikeRepairDetailQuery, Types.GetBikeRepairDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetBikeRepairDetailQuery, Types.GetBikeRepairDetailQueryVariables>(GetBikeRepairDetailDocument, options);
        }
export type GetBikeRepairDetailQueryHookResult = ReturnType<typeof useGetBikeRepairDetailQuery>;
export type GetBikeRepairDetailLazyQueryHookResult = ReturnType<typeof useGetBikeRepairDetailLazyQuery>;
export type GetBikeRepairDetailQueryResult = Apollo.QueryResult<Types.GetBikeRepairDetailQuery, Types.GetBikeRepairDetailQueryVariables>;
export const GetUserBikeDetailDocument = gql`
    query GetUserBikeDetail($filter_bike_gears: String, $user_bike_id: String!) {
  getUserBikeDetail(
    filter_bike_gears: $filter_bike_gears
    user_bike_id: $user_bike_id
  ) {
    id
    bike_id
    name
    purchase_date
    description
    created_at
    estimated_weeks_for_maintenance
    highest_percentage_monitoring_initial_date
    user {
      id
      name
      ddi
      phone
      profile {
        profile_avatar
      }
      workshop_clients {
        id
        firstname
        lastname
        ddi_phone
        phone_number
      }
    }
    workshop_client_id
    workshop_client {
      id
      firstname
      lastname
      ddi_phone
      phone_number
      user {
        id
        name
        ddi
        phone
        profile {
          profile_avatar
        }
      }
    }
    summary {
      bike_month_data {
        carbon_saving
        distance
        month
      }
      total_altimetry
      total_carbon_saving
      total_distance
      total_moving_time
      last_30_days_moving_time
    }
    bike {
      id
      brand_name
      model_name
      weight
      wheel_size
      color_main
      frame_size
      frame_material
      created_at
      year
      bike_type_slug
      bike_type {
        name
        avatar
      }
      repairs {
        id
        date
      }
      bike_model {
        id
        name
        image_main
      }
      bike_manufacturer {
        id
        image_logo
        name
      }
    }
    user_bike_photos {
      id
      link
      order
    }
    user_bike_workshops {
      company_workshop_id
      last_service
      total_amount_spent
      created_at
      company_workshop {
        name
      }
    }
  }
}
    `;

/**
 * __useGetUserBikeDetailQuery__
 *
 * To run a query within a React component, call `useGetUserBikeDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserBikeDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserBikeDetailQuery({
 *   variables: {
 *      filter_bike_gears: // value for 'filter_bike_gears'
 *      user_bike_id: // value for 'user_bike_id'
 *   },
 * });
 */
export function useGetUserBikeDetailQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUserBikeDetailQuery, Types.GetUserBikeDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserBikeDetailQuery, Types.GetUserBikeDetailQueryVariables>(GetUserBikeDetailDocument, options);
      }
export function useGetUserBikeDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserBikeDetailQuery, Types.GetUserBikeDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserBikeDetailQuery, Types.GetUserBikeDetailQueryVariables>(GetUserBikeDetailDocument, options);
        }
export type GetUserBikeDetailQueryHookResult = ReturnType<typeof useGetUserBikeDetailQuery>;
export type GetUserBikeDetailLazyQueryHookResult = ReturnType<typeof useGetUserBikeDetailLazyQuery>;
export type GetUserBikeDetailQueryResult = Apollo.QueryResult<Types.GetUserBikeDetailQuery, Types.GetUserBikeDetailQueryVariables>;
export const GetUserBikeDetailNotLoggedDocument = gql`
    query GetUserBikeDetailNotLogged($user_bike_id: String!) {
  getUserBikeDetailNotLogged(user_bike_id: $user_bike_id) {
    id
    name
    purchase_date
    user_id
    bike_id
    bike {
      bike_model {
        id
        name
        image_main
      }
      bike_manufacturer {
        id
        image_logo
        name
      }
      bike_type {
        name
        avatar
      }
    }
  }
}
    `;

/**
 * __useGetUserBikeDetailNotLoggedQuery__
 *
 * To run a query within a React component, call `useGetUserBikeDetailNotLoggedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserBikeDetailNotLoggedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserBikeDetailNotLoggedQuery({
 *   variables: {
 *      user_bike_id: // value for 'user_bike_id'
 *   },
 * });
 */
export function useGetUserBikeDetailNotLoggedQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUserBikeDetailNotLoggedQuery, Types.GetUserBikeDetailNotLoggedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserBikeDetailNotLoggedQuery, Types.GetUserBikeDetailNotLoggedQueryVariables>(GetUserBikeDetailNotLoggedDocument, options);
      }
export function useGetUserBikeDetailNotLoggedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserBikeDetailNotLoggedQuery, Types.GetUserBikeDetailNotLoggedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserBikeDetailNotLoggedQuery, Types.GetUserBikeDetailNotLoggedQueryVariables>(GetUserBikeDetailNotLoggedDocument, options);
        }
export type GetUserBikeDetailNotLoggedQueryHookResult = ReturnType<typeof useGetUserBikeDetailNotLoggedQuery>;
export type GetUserBikeDetailNotLoggedLazyQueryHookResult = ReturnType<typeof useGetUserBikeDetailNotLoggedLazyQuery>;
export type GetUserBikeDetailNotLoggedQueryResult = Apollo.QueryResult<Types.GetUserBikeDetailNotLoggedQuery, Types.GetUserBikeDetailNotLoggedQueryVariables>;
export const GetUserBikesDocument = gql`
    query GetUserBikes($user_id: String!) {
  getUserBikes(user_id: $user_id) {
    id
    name
    created_at
    bike {
      model_name
      brand_name
      bike_type_slug
      bike_type {
        name
        avatar
      }
    }
    user {
      name
      firstname
      lastname
      profile {
        profile_avatar
      }
    }
    user_bike_workshops {
      company_workshop_id
      company_workshop {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetUserBikesQuery__
 *
 * To run a query within a React component, call `useGetUserBikesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserBikesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserBikesQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetUserBikesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUserBikesQuery, Types.GetUserBikesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserBikesQuery, Types.GetUserBikesQueryVariables>(GetUserBikesDocument, options);
      }
export function useGetUserBikesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserBikesQuery, Types.GetUserBikesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserBikesQuery, Types.GetUserBikesQueryVariables>(GetUserBikesDocument, options);
        }
export type GetUserBikesQueryHookResult = ReturnType<typeof useGetUserBikesQuery>;
export type GetUserBikesLazyQueryHookResult = ReturnType<typeof useGetUserBikesLazyQuery>;
export type GetUserBikesQueryResult = Apollo.QueryResult<Types.GetUserBikesQuery, Types.GetUserBikesQueryVariables>;
export const GroupAlternationDetailDocument = gql`
    query GroupAlternationDetail($group_alternation_id: String!) {
  groupAlternationDetail(group_alternation_id: $group_alternation_id) {
    finished
    id
    bike_gears_on_group {
      id
      nickname
      brand
      model
      bike_gear_configuration {
        id
        gear_type_configuration {
          id
          name
        }
      }
      gear_type {
        name
      }
      bike_gear_alternations {
        id
        initial_date
        end_date
        total_altimetry
        total_distance
        total_moving_time
        bike_gear {
          nickname
          gear_type {
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGroupAlternationDetailQuery__
 *
 * To run a query within a React component, call `useGroupAlternationDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupAlternationDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupAlternationDetailQuery({
 *   variables: {
 *      group_alternation_id: // value for 'group_alternation_id'
 *   },
 * });
 */
export function useGroupAlternationDetailQuery(baseOptions: Apollo.QueryHookOptions<Types.GroupAlternationDetailQuery, Types.GroupAlternationDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GroupAlternationDetailQuery, Types.GroupAlternationDetailQueryVariables>(GroupAlternationDetailDocument, options);
      }
export function useGroupAlternationDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GroupAlternationDetailQuery, Types.GroupAlternationDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GroupAlternationDetailQuery, Types.GroupAlternationDetailQueryVariables>(GroupAlternationDetailDocument, options);
        }
export type GroupAlternationDetailQueryHookResult = ReturnType<typeof useGroupAlternationDetailQuery>;
export type GroupAlternationDetailLazyQueryHookResult = ReturnType<typeof useGroupAlternationDetailLazyQuery>;
export type GroupAlternationDetailQueryResult = Apollo.QueryResult<Types.GroupAlternationDetailQuery, Types.GroupAlternationDetailQueryVariables>;
export const ListActivitiesForBikeGearDocument = gql`
    query ListActivitiesForBikeGear($bike_gear_id: String!) {
  listActivitiesForBikeGear(bike_gear_id: $bike_gear_id) {
    id
    name
    total_elevation_gain
    distance
    created_at
  }
}
    `;

/**
 * __useListActivitiesForBikeGearQuery__
 *
 * To run a query within a React component, call `useListActivitiesForBikeGearQuery` and pass it any options that fit your needs.
 * When your component renders, `useListActivitiesForBikeGearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListActivitiesForBikeGearQuery({
 *   variables: {
 *      bike_gear_id: // value for 'bike_gear_id'
 *   },
 * });
 */
export function useListActivitiesForBikeGearQuery(baseOptions: Apollo.QueryHookOptions<Types.ListActivitiesForBikeGearQuery, Types.ListActivitiesForBikeGearQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListActivitiesForBikeGearQuery, Types.ListActivitiesForBikeGearQueryVariables>(ListActivitiesForBikeGearDocument, options);
      }
export function useListActivitiesForBikeGearLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListActivitiesForBikeGearQuery, Types.ListActivitiesForBikeGearQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListActivitiesForBikeGearQuery, Types.ListActivitiesForBikeGearQueryVariables>(ListActivitiesForBikeGearDocument, options);
        }
export type ListActivitiesForBikeGearQueryHookResult = ReturnType<typeof useListActivitiesForBikeGearQuery>;
export type ListActivitiesForBikeGearLazyQueryHookResult = ReturnType<typeof useListActivitiesForBikeGearLazyQuery>;
export type ListActivitiesForBikeGearQueryResult = Apollo.QueryResult<Types.ListActivitiesForBikeGearQuery, Types.ListActivitiesForBikeGearQueryVariables>;
export const ListBikeRepairsDocument = gql`
    query ListBikeRepairs($gear_type_slugs: [ListBikeRepairInput!], $filter: String, $bike_id: String) {
  listBikeRepairs(
    gear_type_slugs: $gear_type_slugs
    filter: $filter
    bike_id: $bike_id
  ) {
    id
    bike_id
    cost
    date
    company_workshop_id
    informed_by_the_workshop
    bike {
      bike_type {
        avatar
      }
    }
    repair_items {
      id
      repair_type_slug
      workshop_service {
        id
        name
      }
      bike_gear {
        gear_type {
          avatar
          name
        }
      }
    }
  }
}
    `;

/**
 * __useListBikeRepairsQuery__
 *
 * To run a query within a React component, call `useListBikeRepairsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBikeRepairsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBikeRepairsQuery({
 *   variables: {
 *      gear_type_slugs: // value for 'gear_type_slugs'
 *      filter: // value for 'filter'
 *      bike_id: // value for 'bike_id'
 *   },
 * });
 */
export function useListBikeRepairsQuery(baseOptions?: Apollo.QueryHookOptions<Types.ListBikeRepairsQuery, Types.ListBikeRepairsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListBikeRepairsQuery, Types.ListBikeRepairsQueryVariables>(ListBikeRepairsDocument, options);
      }
export function useListBikeRepairsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListBikeRepairsQuery, Types.ListBikeRepairsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListBikeRepairsQuery, Types.ListBikeRepairsQueryVariables>(ListBikeRepairsDocument, options);
        }
export type ListBikeRepairsQueryHookResult = ReturnType<typeof useListBikeRepairsQuery>;
export type ListBikeRepairsLazyQueryHookResult = ReturnType<typeof useListBikeRepairsLazyQuery>;
export type ListBikeRepairsQueryResult = Apollo.QueryResult<Types.ListBikeRepairsQuery, Types.ListBikeRepairsQueryVariables>;
export const ListBikeRepairsOnWorkshopDocument = gql`
    query ListBikeRepairsOnWorkshop($data: ListBikeRepairsOnWorkshopInput!) {
  listBikeRepairsOnWorkshop(data: $data) {
    id
    bike_id
    cost
    date
    company_workshop_id
    informed_by_the_workshop
    bike {
      model_name
      brand_name
      bike_type {
        avatar
      }
      user_bikes {
        bike_id
        id
        name
        user {
          id
        }
      }
    }
    repair_items {
      id
      bike_gear {
        gear_type {
          avatar
          name
        }
      }
      workshop_service {
        name
      }
      repair_type {
        name
        slug
      }
    }
  }
}
    `;

/**
 * __useListBikeRepairsOnWorkshopQuery__
 *
 * To run a query within a React component, call `useListBikeRepairsOnWorkshopQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBikeRepairsOnWorkshopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBikeRepairsOnWorkshopQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useListBikeRepairsOnWorkshopQuery(baseOptions: Apollo.QueryHookOptions<Types.ListBikeRepairsOnWorkshopQuery, Types.ListBikeRepairsOnWorkshopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListBikeRepairsOnWorkshopQuery, Types.ListBikeRepairsOnWorkshopQueryVariables>(ListBikeRepairsOnWorkshopDocument, options);
      }
export function useListBikeRepairsOnWorkshopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListBikeRepairsOnWorkshopQuery, Types.ListBikeRepairsOnWorkshopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListBikeRepairsOnWorkshopQuery, Types.ListBikeRepairsOnWorkshopQueryVariables>(ListBikeRepairsOnWorkshopDocument, options);
        }
export type ListBikeRepairsOnWorkshopQueryHookResult = ReturnType<typeof useListBikeRepairsOnWorkshopQuery>;
export type ListBikeRepairsOnWorkshopLazyQueryHookResult = ReturnType<typeof useListBikeRepairsOnWorkshopLazyQuery>;
export type ListBikeRepairsOnWorkshopQueryResult = Apollo.QueryResult<Types.ListBikeRepairsOnWorkshopQuery, Types.ListBikeRepairsOnWorkshopQueryVariables>;
export const ListBikeTypesDocument = gql`
    query ListBikeTypes {
  listBikeTypes {
    active
    avatar
    created_at
    description
    eletric
    name
    slug
  }
}
    `;

/**
 * __useListBikeTypesQuery__
 *
 * To run a query within a React component, call `useListBikeTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBikeTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBikeTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListBikeTypesQuery(baseOptions?: Apollo.QueryHookOptions<Types.ListBikeTypesQuery, Types.ListBikeTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListBikeTypesQuery, Types.ListBikeTypesQueryVariables>(ListBikeTypesDocument, options);
      }
export function useListBikeTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListBikeTypesQuery, Types.ListBikeTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListBikeTypesQuery, Types.ListBikeTypesQueryVariables>(ListBikeTypesDocument, options);
        }
export type ListBikeTypesQueryHookResult = ReturnType<typeof useListBikeTypesQuery>;
export type ListBikeTypesLazyQueryHookResult = ReturnType<typeof useListBikeTypesLazyQuery>;
export type ListBikeTypesQueryResult = Apollo.QueryResult<Types.ListBikeTypesQuery, Types.ListBikeTypesQueryVariables>;
export const ListGearTypesDocument = gql`
    query ListGearTypes {
  listGearTypes {
    avatar
    name
    slug
    description
    highlighted
    gear_type_configuration {
      id
      name
      gear_type_slug
    }
  }
}
    `;

/**
 * __useListGearTypesQuery__
 *
 * To run a query within a React component, call `useListGearTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListGearTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGearTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListGearTypesQuery(baseOptions?: Apollo.QueryHookOptions<Types.ListGearTypesQuery, Types.ListGearTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListGearTypesQuery, Types.ListGearTypesQueryVariables>(ListGearTypesDocument, options);
      }
export function useListGearTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListGearTypesQuery, Types.ListGearTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListGearTypesQuery, Types.ListGearTypesQueryVariables>(ListGearTypesDocument, options);
        }
export type ListGearTypesQueryHookResult = ReturnType<typeof useListGearTypesQuery>;
export type ListGearTypesLazyQueryHookResult = ReturnType<typeof useListGearTypesLazyQuery>;
export type ListGearTypesQueryResult = Apollo.QueryResult<Types.ListGearTypesQuery, Types.ListGearTypesQueryVariables>;
export const ListUserBikeGearsDocument = gql`
    query ListUserBikeGears($filter: String, $bike_id: String!) {
  listUserBikeGears(filter: $filter, bike_id: $bike_id) {
    id
    bike_id
    current
    created_at
    usage_percentage
    total_distance
    actual_monitoring_distance
    actual_monitoring_altimetry
    actual_monitoring_moving_time
    nickname
    brand
    addition_date
    creator_id
    model
    suggested_by_the_workshop
    suggestion_accepted
    gear_type_slug
    group_alternation_id
    bike {
      created_at
    }
    gear_type {
      avatar
      name
      slug
      alternation
      gear_type_configuration {
        id
        name
        free_field
      }
      gear_type_group {
        slug
        name
        gear_type_group_translations {
          language_code
          name
        }
      }
    }
    bike_gear_configuration {
      free_field_content
      gear_type_configuration {
        id
        name
        free_field
      }
    }
    bike_gear_monitoring {
      id
      distance_goal
      altimetry_goal
      seconds_goal
      date_goal
      days_goal
      initial_date
      description
      actual_altimetry
      actual_distance
      actual_seconds
      old_monitoring {
        id
      }
    }
  }
}
    `;

/**
 * __useListUserBikeGearsQuery__
 *
 * To run a query within a React component, call `useListUserBikeGearsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserBikeGearsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserBikeGearsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      bike_id: // value for 'bike_id'
 *   },
 * });
 */
export function useListUserBikeGearsQuery(baseOptions: Apollo.QueryHookOptions<Types.ListUserBikeGearsQuery, Types.ListUserBikeGearsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListUserBikeGearsQuery, Types.ListUserBikeGearsQueryVariables>(ListUserBikeGearsDocument, options);
      }
export function useListUserBikeGearsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListUserBikeGearsQuery, Types.ListUserBikeGearsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListUserBikeGearsQuery, Types.ListUserBikeGearsQueryVariables>(ListUserBikeGearsDocument, options);
        }
export type ListUserBikeGearsQueryHookResult = ReturnType<typeof useListUserBikeGearsQuery>;
export type ListUserBikeGearsLazyQueryHookResult = ReturnType<typeof useListUserBikeGearsLazyQuery>;
export type ListUserBikeGearsQueryResult = Apollo.QueryResult<Types.ListUserBikeGearsQuery, Types.ListUserBikeGearsQueryVariables>;
export const ListUserBikeManualUseDocument = gql`
    query ListUserBikeManualUse($user_bike_id: String!) {
  listUserBikeManualUse(user_bike_id: $user_bike_id) {
    id
    date
    accepted_use_suggestion
    company_workshop_id
    suggestion_by_the_workshop
    description
    distance
    elevation_gain
    total_distance
    time
  }
}
    `;

/**
 * __useListUserBikeManualUseQuery__
 *
 * To run a query within a React component, call `useListUserBikeManualUseQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserBikeManualUseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserBikeManualUseQuery({
 *   variables: {
 *      user_bike_id: // value for 'user_bike_id'
 *   },
 * });
 */
export function useListUserBikeManualUseQuery(baseOptions: Apollo.QueryHookOptions<Types.ListUserBikeManualUseQuery, Types.ListUserBikeManualUseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListUserBikeManualUseQuery, Types.ListUserBikeManualUseQueryVariables>(ListUserBikeManualUseDocument, options);
      }
export function useListUserBikeManualUseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListUserBikeManualUseQuery, Types.ListUserBikeManualUseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListUserBikeManualUseQuery, Types.ListUserBikeManualUseQueryVariables>(ListUserBikeManualUseDocument, options);
        }
export type ListUserBikeManualUseQueryHookResult = ReturnType<typeof useListUserBikeManualUseQuery>;
export type ListUserBikeManualUseLazyQueryHookResult = ReturnType<typeof useListUserBikeManualUseLazyQuery>;
export type ListUserBikeManualUseQueryResult = Apollo.QueryResult<Types.ListUserBikeManualUseQuery, Types.ListUserBikeManualUseQueryVariables>;
export const SearchBikeManufacturersDocument = gql`
    query SearchBikeManufacturers($search_text: String!) {
  searchBikeManufacturers(search_text: $search_text) {
    id
    name
    description
    image_logo
  }
}
    `;

/**
 * __useSearchBikeManufacturersQuery__
 *
 * To run a query within a React component, call `useSearchBikeManufacturersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchBikeManufacturersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBikeManufacturersQuery({
 *   variables: {
 *      search_text: // value for 'search_text'
 *   },
 * });
 */
export function useSearchBikeManufacturersQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchBikeManufacturersQuery, Types.SearchBikeManufacturersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchBikeManufacturersQuery, Types.SearchBikeManufacturersQueryVariables>(SearchBikeManufacturersDocument, options);
      }
export function useSearchBikeManufacturersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchBikeManufacturersQuery, Types.SearchBikeManufacturersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchBikeManufacturersQuery, Types.SearchBikeManufacturersQueryVariables>(SearchBikeManufacturersDocument, options);
        }
export type SearchBikeManufacturersQueryHookResult = ReturnType<typeof useSearchBikeManufacturersQuery>;
export type SearchBikeManufacturersLazyQueryHookResult = ReturnType<typeof useSearchBikeManufacturersLazyQuery>;
export type SearchBikeManufacturersQueryResult = Apollo.QueryResult<Types.SearchBikeManufacturersQuery, Types.SearchBikeManufacturersQueryVariables>;
export const SearchBikeModelsDocument = gql`
    query SearchBikeModels($search_text: String!) {
  searchBikeModels(search_text: $search_text) {
    id
    name
    description
    image_main
  }
}
    `;

/**
 * __useSearchBikeModelsQuery__
 *
 * To run a query within a React component, call `useSearchBikeModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchBikeModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBikeModelsQuery({
 *   variables: {
 *      search_text: // value for 'search_text'
 *   },
 * });
 */
export function useSearchBikeModelsQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchBikeModelsQuery, Types.SearchBikeModelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchBikeModelsQuery, Types.SearchBikeModelsQueryVariables>(SearchBikeModelsDocument, options);
      }
export function useSearchBikeModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchBikeModelsQuery, Types.SearchBikeModelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchBikeModelsQuery, Types.SearchBikeModelsQueryVariables>(SearchBikeModelsDocument, options);
        }
export type SearchBikeModelsQueryHookResult = ReturnType<typeof useSearchBikeModelsQuery>;
export type SearchBikeModelsLazyQueryHookResult = ReturnType<typeof useSearchBikeModelsLazyQuery>;
export type SearchBikeModelsQueryResult = Apollo.QueryResult<Types.SearchBikeModelsQuery, Types.SearchBikeModelsQueryVariables>;
export const SearchBikeModelsByManufacturerDocument = gql`
    query SearchBikeModelsByManufacturer($data: SearchBikeModelsBymanufacturerInput!) {
  searchBikeModelsByManufacturer(data: $data) {
    id
    name
    description
    image_main
  }
}
    `;

/**
 * __useSearchBikeModelsByManufacturerQuery__
 *
 * To run a query within a React component, call `useSearchBikeModelsByManufacturerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchBikeModelsByManufacturerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBikeModelsByManufacturerQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSearchBikeModelsByManufacturerQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchBikeModelsByManufacturerQuery, Types.SearchBikeModelsByManufacturerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchBikeModelsByManufacturerQuery, Types.SearchBikeModelsByManufacturerQueryVariables>(SearchBikeModelsByManufacturerDocument, options);
      }
export function useSearchBikeModelsByManufacturerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchBikeModelsByManufacturerQuery, Types.SearchBikeModelsByManufacturerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchBikeModelsByManufacturerQuery, Types.SearchBikeModelsByManufacturerQueryVariables>(SearchBikeModelsByManufacturerDocument, options);
        }
export type SearchBikeModelsByManufacturerQueryHookResult = ReturnType<typeof useSearchBikeModelsByManufacturerQuery>;
export type SearchBikeModelsByManufacturerLazyQueryHookResult = ReturnType<typeof useSearchBikeModelsByManufacturerLazyQuery>;
export type SearchBikeModelsByManufacturerQueryResult = Apollo.QueryResult<Types.SearchBikeModelsByManufacturerQuery, Types.SearchBikeModelsByManufacturerQueryVariables>;
export const UpdateUserBikeDocument = gql`
    mutation updateUserBike($bike_data: UpdateBikeInput!, $user_bike_data: UpdateUserBikeInput!) {
  updateUserBike(bike_data: $bike_data, user_bike_data: $user_bike_data) {
    id
    name
  }
}
    `;
export type UpdateUserBikeMutationFn = Apollo.MutationFunction<Types.UpdateUserBikeMutation, Types.UpdateUserBikeMutationVariables>;

/**
 * __useUpdateUserBikeMutation__
 *
 * To run a mutation, you first call `useUpdateUserBikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserBikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserBikeMutation, { data, loading, error }] = useUpdateUserBikeMutation({
 *   variables: {
 *      bike_data: // value for 'bike_data'
 *      user_bike_data: // value for 'user_bike_data'
 *   },
 * });
 */
export function useUpdateUserBikeMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserBikeMutation, Types.UpdateUserBikeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateUserBikeMutation, Types.UpdateUserBikeMutationVariables>(UpdateUserBikeDocument, options);
      }
export type UpdateUserBikeMutationHookResult = ReturnType<typeof useUpdateUserBikeMutation>;
export type UpdateUserBikeMutationResult = Apollo.MutationResult<Types.UpdateUserBikeMutation>;
export type UpdateUserBikeMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserBikeMutation, Types.UpdateUserBikeMutationVariables>;
export const CalculateInstallmentsDocument = gql`
    query CalculateInstallments($data: InstallmentsInput!) {
  calculateInstallments(data: $data) {
    installments
  }
}
    `;

/**
 * __useCalculateInstallmentsQuery__
 *
 * To run a query within a React component, call `useCalculateInstallmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateInstallmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateInstallmentsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCalculateInstallmentsQuery(baseOptions: Apollo.QueryHookOptions<Types.CalculateInstallmentsQuery, Types.CalculateInstallmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CalculateInstallmentsQuery, Types.CalculateInstallmentsQueryVariables>(CalculateInstallmentsDocument, options);
      }
export function useCalculateInstallmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CalculateInstallmentsQuery, Types.CalculateInstallmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CalculateInstallmentsQuery, Types.CalculateInstallmentsQueryVariables>(CalculateInstallmentsDocument, options);
        }
export type CalculateInstallmentsQueryHookResult = ReturnType<typeof useCalculateInstallmentsQuery>;
export type CalculateInstallmentsLazyQueryHookResult = ReturnType<typeof useCalculateInstallmentsLazyQuery>;
export type CalculateInstallmentsQueryResult = Apollo.QueryResult<Types.CalculateInstallmentsQuery, Types.CalculateInstallmentsQueryVariables>;
export const FindSubscriptionByUserAndDocumentDocument = gql`
    query FindSubscriptionByUserAndDocument($data: FindSubscriptionByUserAndDocumentInput!) {
  findSubscriptionByUserAndDocument(data: $data) {
    id
  }
}
    `;

/**
 * __useFindSubscriptionByUserAndDocumentQuery__
 *
 * To run a query within a React component, call `useFindSubscriptionByUserAndDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSubscriptionByUserAndDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSubscriptionByUserAndDocumentQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useFindSubscriptionByUserAndDocumentQuery(baseOptions: Apollo.QueryHookOptions<Types.FindSubscriptionByUserAndDocumentQuery, Types.FindSubscriptionByUserAndDocumentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FindSubscriptionByUserAndDocumentQuery, Types.FindSubscriptionByUserAndDocumentQueryVariables>(FindSubscriptionByUserAndDocumentDocument, options);
      }
export function useFindSubscriptionByUserAndDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FindSubscriptionByUserAndDocumentQuery, Types.FindSubscriptionByUserAndDocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FindSubscriptionByUserAndDocumentQuery, Types.FindSubscriptionByUserAndDocumentQueryVariables>(FindSubscriptionByUserAndDocumentDocument, options);
        }
export type FindSubscriptionByUserAndDocumentQueryHookResult = ReturnType<typeof useFindSubscriptionByUserAndDocumentQuery>;
export type FindSubscriptionByUserAndDocumentLazyQueryHookResult = ReturnType<typeof useFindSubscriptionByUserAndDocumentLazyQuery>;
export type FindSubscriptionByUserAndDocumentQueryResult = Apollo.QueryResult<Types.FindSubscriptionByUserAndDocumentQuery, Types.FindSubscriptionByUserAndDocumentQueryVariables>;
export const GetChallengeEventsParticipantsDocument = gql`
    query GetChallengeEventsParticipants($pagination: PaginationInput!, $data: GetChallengeEventsParticipantsInput!) {
  getChallengeEventsParticipants(pagination: $pagination, data: $data) {
    id
    challenge_id
    total_rides
    total_distance
    classification
    total_time
    total_altimetry
    short_id
    challenge_withdrawal_address_id
    user_id
    total_distance
    registration_date
    withdrawal_date
    total_time_seconds
    paid
    confirmed_subscription
    amount_paid
    amount_to_pay
    drawn
    last_payment_id
    completed
    challenge_category_id
    ready_to_withdraw
    user_marked_withdrawn
    event_route {
      id
      name
    }
    category {
      id
      name
    }
    user_challenge_group {
      group_name
      user_challenges {
        id
        confirmed_subscription
        category {
          id
          name
        }
        challenge_category_id
        user_challenge_group {
          group_name
        }
        user {
          name
          firstname
          lastname
          phone
          gender
          date_of_birth
          legal_registry_number
          email
          city_id
          street_number
          team_name
          profile {
            id
            profile_avatar
          }
          user_address {
            address {
              city {
                name
                state {
                  name
                  abbreviation
                }
              }
            }
          }
        }
        subscribed_persons {
          name
          city
          state
          team_name
          riderize_user {
            profile {
              profile_avatar
            }
          }
        }
      }
    }
    subscribed_persons {
      name
      city
      state
      team_name
      riderize_user {
        profile {
          profile_avatar
        }
      }
    }
    user {
      name
      firstname
      lastname
      phone
      gender
      date_of_birth
      legal_registry_number
      email
      city_id
      street_number
      team_name
      profile {
        id
        profile_avatar
      }
      user_address {
        address {
          city {
            name
            state {
              name
              abbreviation
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetChallengeEventsParticipantsQuery__
 *
 * To run a query within a React component, call `useGetChallengeEventsParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChallengeEventsParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChallengeEventsParticipantsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetChallengeEventsParticipantsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetChallengeEventsParticipantsQuery, Types.GetChallengeEventsParticipantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetChallengeEventsParticipantsQuery, Types.GetChallengeEventsParticipantsQueryVariables>(GetChallengeEventsParticipantsDocument, options);
      }
export function useGetChallengeEventsParticipantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetChallengeEventsParticipantsQuery, Types.GetChallengeEventsParticipantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetChallengeEventsParticipantsQuery, Types.GetChallengeEventsParticipantsQueryVariables>(GetChallengeEventsParticipantsDocument, options);
        }
export type GetChallengeEventsParticipantsQueryHookResult = ReturnType<typeof useGetChallengeEventsParticipantsQuery>;
export type GetChallengeEventsParticipantsLazyQueryHookResult = ReturnType<typeof useGetChallengeEventsParticipantsLazyQuery>;
export type GetChallengeEventsParticipantsQueryResult = Apollo.QueryResult<Types.GetChallengeEventsParticipantsQuery, Types.GetChallengeEventsParticipantsQueryVariables>;
export const GetChallengeRanksDocument = gql`
    query GetChallengeRanks($pagination: PaginationInput!, $data: GetRanksInput!) {
  getChallengeRanks(pagination: $pagination, data: $data) {
    id
    challenge_id
    total_rides
    total_distance
    classification
    total_time
    total_altimetry
    short_id
    challenge_withdrawal_address_id
    user_id
    total_distance
    registration_date
    withdrawal_date
    total_time_seconds
    paid
    confirmed_subscription
    amount_paid
    amount_to_pay
    drawn
    last_payment_id
    completed
    challenge_category_id
    ready_to_withdraw
    user_marked_withdrawn
    event_route {
      id
      name
    }
    category {
      id
      name
    }
    user_challenge_group {
      group_name
    }
    user {
      name
      firstname
      lastname
      phone
      gender
      date_of_birth
      legal_registry_number
      email
      city_id
      street_number
      team_name
      profile {
        id
        profile_avatar
      }
      user_address {
        address {
          city {
            name
            state {
              name
              abbreviation
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetChallengeRanksQuery__
 *
 * To run a query within a React component, call `useGetChallengeRanksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChallengeRanksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChallengeRanksQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetChallengeRanksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetChallengeRanksQuery, Types.GetChallengeRanksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetChallengeRanksQuery, Types.GetChallengeRanksQueryVariables>(GetChallengeRanksDocument, options);
      }
export function useGetChallengeRanksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetChallengeRanksQuery, Types.GetChallengeRanksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetChallengeRanksQuery, Types.GetChallengeRanksQueryVariables>(GetChallengeRanksDocument, options);
        }
export type GetChallengeRanksQueryHookResult = ReturnType<typeof useGetChallengeRanksQuery>;
export type GetChallengeRanksLazyQueryHookResult = ReturnType<typeof useGetChallengeRanksLazyQuery>;
export type GetChallengeRanksQueryResult = Apollo.QueryResult<Types.GetChallengeRanksQuery, Types.GetChallengeRanksQueryVariables>;
export const GetChallengesByCompanyDashboardDocument = gql`
    query GetChallengesByCompanyDashboard($company_id: String!, $pagination: PaginationInput!) {
  getChallengesByCompanyDashboard(
    company_id: $company_id
    pagination: $pagination
  ) {
    items {
      id
      count_subscribe
      name
      slug_name
      short_id
      image_avatar
      start_date
      end_date
      challenge_type
      published
      internal_test
      physical_event
      configuration {
        is_paid
      }
      company {
        profile {
          username
        }
      }
    }
    page_info {
      total_item_count
    }
  }
}
    `;

/**
 * __useGetChallengesByCompanyDashboardQuery__
 *
 * To run a query within a React component, call `useGetChallengesByCompanyDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChallengesByCompanyDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChallengesByCompanyDashboardQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetChallengesByCompanyDashboardQuery(baseOptions: Apollo.QueryHookOptions<Types.GetChallengesByCompanyDashboardQuery, Types.GetChallengesByCompanyDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetChallengesByCompanyDashboardQuery, Types.GetChallengesByCompanyDashboardQueryVariables>(GetChallengesByCompanyDashboardDocument, options);
      }
export function useGetChallengesByCompanyDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetChallengesByCompanyDashboardQuery, Types.GetChallengesByCompanyDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetChallengesByCompanyDashboardQuery, Types.GetChallengesByCompanyDashboardQueryVariables>(GetChallengesByCompanyDashboardDocument, options);
        }
export type GetChallengesByCompanyDashboardQueryHookResult = ReturnType<typeof useGetChallengesByCompanyDashboardQuery>;
export type GetChallengesByCompanyDashboardLazyQueryHookResult = ReturnType<typeof useGetChallengesByCompanyDashboardLazyQuery>;
export type GetChallengesByCompanyDashboardQueryResult = Apollo.QueryResult<Types.GetChallengesByCompanyDashboardQuery, Types.GetChallengesByCompanyDashboardQueryVariables>;
export const GetSubscriptionsOfAChallengeNotLoggedDocument = gql`
    query GetSubscriptionsOfAChallengeNotLogged($pagination: PaginationInput!, $data: ListChallengeSubscriptionsInput!) {
  getSubscriptionsOfAChallengeNotLogged(pagination: $pagination, data: $data) {
    subscriptions {
      user {
        id
        name
        profile {
          profile_avatar
        }
      }
    }
  }
}
    `;

/**
 * __useGetSubscriptionsOfAChallengeNotLoggedQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionsOfAChallengeNotLoggedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionsOfAChallengeNotLoggedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionsOfAChallengeNotLoggedQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetSubscriptionsOfAChallengeNotLoggedQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSubscriptionsOfAChallengeNotLoggedQuery, Types.GetSubscriptionsOfAChallengeNotLoggedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSubscriptionsOfAChallengeNotLoggedQuery, Types.GetSubscriptionsOfAChallengeNotLoggedQueryVariables>(GetSubscriptionsOfAChallengeNotLoggedDocument, options);
      }
export function useGetSubscriptionsOfAChallengeNotLoggedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSubscriptionsOfAChallengeNotLoggedQuery, Types.GetSubscriptionsOfAChallengeNotLoggedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSubscriptionsOfAChallengeNotLoggedQuery, Types.GetSubscriptionsOfAChallengeNotLoggedQueryVariables>(GetSubscriptionsOfAChallengeNotLoggedDocument, options);
        }
export type GetSubscriptionsOfAChallengeNotLoggedQueryHookResult = ReturnType<typeof useGetSubscriptionsOfAChallengeNotLoggedQuery>;
export type GetSubscriptionsOfAChallengeNotLoggedLazyQueryHookResult = ReturnType<typeof useGetSubscriptionsOfAChallengeNotLoggedLazyQuery>;
export type GetSubscriptionsOfAChallengeNotLoggedQueryResult = Apollo.QueryResult<Types.GetSubscriptionsOfAChallengeNotLoggedQuery, Types.GetSubscriptionsOfAChallengeNotLoggedQueryVariables>;
export const ListAllChallengeProgressiveDiscountsDocument = gql`
    query ListAllChallengeProgressiveDiscounts($data: ListAllChallengeProgressiveDiscountsInput!) {
  listAllChallengeProgressiveDiscounts(data: $data) {
    active
    challenge_id
    challenge {
      name
    }
    created_at
    discount_fixed
    discount_freight_fixed
    discount_freight_percentage
    discount_percentage
    from_amount_subscription
    id
    updated_at
  }
}
    `;

/**
 * __useListAllChallengeProgressiveDiscountsQuery__
 *
 * To run a query within a React component, call `useListAllChallengeProgressiveDiscountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllChallengeProgressiveDiscountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllChallengeProgressiveDiscountsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useListAllChallengeProgressiveDiscountsQuery(baseOptions: Apollo.QueryHookOptions<Types.ListAllChallengeProgressiveDiscountsQuery, Types.ListAllChallengeProgressiveDiscountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListAllChallengeProgressiveDiscountsQuery, Types.ListAllChallengeProgressiveDiscountsQueryVariables>(ListAllChallengeProgressiveDiscountsDocument, options);
      }
export function useListAllChallengeProgressiveDiscountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListAllChallengeProgressiveDiscountsQuery, Types.ListAllChallengeProgressiveDiscountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListAllChallengeProgressiveDiscountsQuery, Types.ListAllChallengeProgressiveDiscountsQueryVariables>(ListAllChallengeProgressiveDiscountsDocument, options);
        }
export type ListAllChallengeProgressiveDiscountsQueryHookResult = ReturnType<typeof useListAllChallengeProgressiveDiscountsQuery>;
export type ListAllChallengeProgressiveDiscountsLazyQueryHookResult = ReturnType<typeof useListAllChallengeProgressiveDiscountsLazyQuery>;
export type ListAllChallengeProgressiveDiscountsQueryResult = Apollo.QueryResult<Types.ListAllChallengeProgressiveDiscountsQuery, Types.ListAllChallengeProgressiveDiscountsQueryVariables>;
export const CheckCouponApplicableDocument = gql`
    query CheckCouponApplicable($data: CheckCouponApplicableInput!) {
  checkCouponApplicable(data: $data) {
    id
    code
    maximum_number_of_uses
    total_uses
    apply_on_products
    apply_only_to_products
    coupon_limit_type {
      slug
    }
    discount_type {
      slug
    }
    value
  }
}
    `;

/**
 * __useCheckCouponApplicableQuery__
 *
 * To run a query within a React component, call `useCheckCouponApplicableQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckCouponApplicableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckCouponApplicableQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCheckCouponApplicableQuery(baseOptions: Apollo.QueryHookOptions<Types.CheckCouponApplicableQuery, Types.CheckCouponApplicableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CheckCouponApplicableQuery, Types.CheckCouponApplicableQueryVariables>(CheckCouponApplicableDocument, options);
      }
export function useCheckCouponApplicableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CheckCouponApplicableQuery, Types.CheckCouponApplicableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CheckCouponApplicableQuery, Types.CheckCouponApplicableQueryVariables>(CheckCouponApplicableDocument, options);
        }
export type CheckCouponApplicableQueryHookResult = ReturnType<typeof useCheckCouponApplicableQuery>;
export type CheckCouponApplicableLazyQueryHookResult = ReturnType<typeof useCheckCouponApplicableLazyQuery>;
export type CheckCouponApplicableQueryResult = Apollo.QueryResult<Types.CheckCouponApplicableQuery, Types.CheckCouponApplicableQueryVariables>;
export const CheckDocumentAdvantagesDocument = gql`
    query CheckDocumentAdvantages($document: String!) {
  checkDocumentAdvantages(document: $document) {
    challenge_id
    free_shipping
    discount_percentage
    discount_fixed
    document
  }
}
    `;

/**
 * __useCheckDocumentAdvantagesQuery__
 *
 * To run a query within a React component, call `useCheckDocumentAdvantagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckDocumentAdvantagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckDocumentAdvantagesQuery({
 *   variables: {
 *      document: // value for 'document'
 *   },
 * });
 */
export function useCheckDocumentAdvantagesQuery(baseOptions: Apollo.QueryHookOptions<Types.CheckDocumentAdvantagesQuery, Types.CheckDocumentAdvantagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CheckDocumentAdvantagesQuery, Types.CheckDocumentAdvantagesQueryVariables>(CheckDocumentAdvantagesDocument, options);
      }
export function useCheckDocumentAdvantagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CheckDocumentAdvantagesQuery, Types.CheckDocumentAdvantagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CheckDocumentAdvantagesQuery, Types.CheckDocumentAdvantagesQueryVariables>(CheckDocumentAdvantagesDocument, options);
        }
export type CheckDocumentAdvantagesQueryHookResult = ReturnType<typeof useCheckDocumentAdvantagesQuery>;
export type CheckDocumentAdvantagesLazyQueryHookResult = ReturnType<typeof useCheckDocumentAdvantagesLazyQuery>;
export type CheckDocumentAdvantagesQueryResult = Apollo.QueryResult<Types.CheckDocumentAdvantagesQuery, Types.CheckDocumentAdvantagesQueryVariables>;
export const CheckEmailDocument = gql`
    query CheckEmail($email: String!) {
  checkEmail(email: $email) {
    id
    name
    email
    phone
    pre_registration
    profile {
      profile_avatar
    }
    user_challenges {
      id
      challenge {
        name
      }
    }
  }
}
    `;

/**
 * __useCheckEmailQuery__
 *
 * To run a query within a React component, call `useCheckEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCheckEmailQuery(baseOptions: Apollo.QueryHookOptions<Types.CheckEmailQuery, Types.CheckEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CheckEmailQuery, Types.CheckEmailQueryVariables>(CheckEmailDocument, options);
      }
export function useCheckEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CheckEmailQuery, Types.CheckEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CheckEmailQuery, Types.CheckEmailQueryVariables>(CheckEmailDocument, options);
        }
export type CheckEmailQueryHookResult = ReturnType<typeof useCheckEmailQuery>;
export type CheckEmailLazyQueryHookResult = ReturnType<typeof useCheckEmailLazyQuery>;
export type CheckEmailQueryResult = Apollo.QueryResult<Types.CheckEmailQuery, Types.CheckEmailQueryVariables>;
export const CheckLegalRegistryAdvantageDocument = gql`
    query CheckLegalRegistryAdvantage($legal_registry_number: String!) {
  checkLegalRegistryAdvantage(legal_registry_number: $legal_registry_number) {
    company_id
    free_shipping
    discount_percentage
    discount_fixed
    legal_registry_number
  }
}
    `;

/**
 * __useCheckLegalRegistryAdvantageQuery__
 *
 * To run a query within a React component, call `useCheckLegalRegistryAdvantageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckLegalRegistryAdvantageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckLegalRegistryAdvantageQuery({
 *   variables: {
 *      legal_registry_number: // value for 'legal_registry_number'
 *   },
 * });
 */
export function useCheckLegalRegistryAdvantageQuery(baseOptions: Apollo.QueryHookOptions<Types.CheckLegalRegistryAdvantageQuery, Types.CheckLegalRegistryAdvantageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CheckLegalRegistryAdvantageQuery, Types.CheckLegalRegistryAdvantageQueryVariables>(CheckLegalRegistryAdvantageDocument, options);
      }
export function useCheckLegalRegistryAdvantageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CheckLegalRegistryAdvantageQuery, Types.CheckLegalRegistryAdvantageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CheckLegalRegistryAdvantageQuery, Types.CheckLegalRegistryAdvantageQueryVariables>(CheckLegalRegistryAdvantageDocument, options);
        }
export type CheckLegalRegistryAdvantageQueryHookResult = ReturnType<typeof useCheckLegalRegistryAdvantageQuery>;
export type CheckLegalRegistryAdvantageLazyQueryHookResult = ReturnType<typeof useCheckLegalRegistryAdvantageLazyQuery>;
export type CheckLegalRegistryAdvantageQueryResult = Apollo.QueryResult<Types.CheckLegalRegistryAdvantageQuery, Types.CheckLegalRegistryAdvantageQueryVariables>;
export const GetChallengeCommentsDocument = gql`
    query GetChallengeComments($pagination: PaginationInput!, $challenge_id: String!) {
  getChallengeComments(pagination: $pagination, challenge_id: $challenge_id) {
    id
    challenge_id
    profile_id
    claps_count
    text
    active
    created_at
    parent_challenge_comment_id
    profile {
      id
      username
      profile_avatar
      user {
        name
      }
    }
    childComments {
      id
      challenge_id
      profile_id
      claps_count
      text
      active
      parent_challenge_comment_id
      created_at
      claps {
        challenge_comment_id
        profile_id
        count
      }
      profile {
        id
        username
        profile_avatar
        user {
          name
        }
      }
    }
    claps {
      challenge_comment_id
      profile_id
      count
    }
  }
}
    `;

/**
 * __useGetChallengeCommentsQuery__
 *
 * To run a query within a React component, call `useGetChallengeCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChallengeCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChallengeCommentsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      challenge_id: // value for 'challenge_id'
 *   },
 * });
 */
export function useGetChallengeCommentsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetChallengeCommentsQuery, Types.GetChallengeCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetChallengeCommentsQuery, Types.GetChallengeCommentsQueryVariables>(GetChallengeCommentsDocument, options);
      }
export function useGetChallengeCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetChallengeCommentsQuery, Types.GetChallengeCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetChallengeCommentsQuery, Types.GetChallengeCommentsQueryVariables>(GetChallengeCommentsDocument, options);
        }
export type GetChallengeCommentsQueryHookResult = ReturnType<typeof useGetChallengeCommentsQuery>;
export type GetChallengeCommentsLazyQueryHookResult = ReturnType<typeof useGetChallengeCommentsLazyQuery>;
export type GetChallengeCommentsQueryResult = Apollo.QueryResult<Types.GetChallengeCommentsQuery, Types.GetChallengeCommentsQueryVariables>;
export const ListCompanyUsersDocument = gql`
    query ListCompanyUsers($company_id: String!) {
  listCompanyUsers(company_id: $company_id) {
    creator
    company_id
    user_id
    company_function_id
    workshop_permissions
    company {
      id
      business_name
      fantasy_name
      email
    }
    user {
      id
      name
      firstname
      lastname
      email
      staff
      profile {
        profile_avatar
      }
    }
  }
}
    `;

/**
 * __useListCompanyUsersQuery__
 *
 * To run a query within a React component, call `useListCompanyUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompanyUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompanyUsersQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useListCompanyUsersQuery(baseOptions: Apollo.QueryHookOptions<Types.ListCompanyUsersQuery, Types.ListCompanyUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListCompanyUsersQuery, Types.ListCompanyUsersQueryVariables>(ListCompanyUsersDocument, options);
      }
export function useListCompanyUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListCompanyUsersQuery, Types.ListCompanyUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListCompanyUsersQuery, Types.ListCompanyUsersQueryVariables>(ListCompanyUsersDocument, options);
        }
export type ListCompanyUsersQueryHookResult = ReturnType<typeof useListCompanyUsersQuery>;
export type ListCompanyUsersLazyQueryHookResult = ReturnType<typeof useListCompanyUsersLazyQuery>;
export type ListCompanyUsersQueryResult = Apollo.QueryResult<Types.ListCompanyUsersQuery, Types.ListCompanyUsersQueryVariables>;
export const UpdateUserCompanyDocument = gql`
    mutation UpdateUserCompany($data: UpdateUserCompanyInput!) {
  updateUserCompany(data: $data)
}
    `;
export type UpdateUserCompanyMutationFn = Apollo.MutationFunction<Types.UpdateUserCompanyMutation, Types.UpdateUserCompanyMutationVariables>;

/**
 * __useUpdateUserCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateUserCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserCompanyMutation, { data, loading, error }] = useUpdateUserCompanyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserCompanyMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserCompanyMutation, Types.UpdateUserCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateUserCompanyMutation, Types.UpdateUserCompanyMutationVariables>(UpdateUserCompanyDocument, options);
      }
export type UpdateUserCompanyMutationHookResult = ReturnType<typeof useUpdateUserCompanyMutation>;
export type UpdateUserCompanyMutationResult = Apollo.MutationResult<Types.UpdateUserCompanyMutation>;
export type UpdateUserCompanyMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserCompanyMutation, Types.UpdateUserCompanyMutationVariables>;
export const UserCompaniesDocument = gql`
    query UserCompanies {
  userCompanies {
    id
    active
    business_name
    fantasy_name
    email
    register_number
    profile {
      profile_avatar
      profile_cover
    }
  }
}
    `;

/**
 * __useUserCompaniesQuery__
 *
 * To run a query within a React component, call `useUserCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<Types.UserCompaniesQuery, Types.UserCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserCompaniesQuery, Types.UserCompaniesQueryVariables>(UserCompaniesDocument, options);
      }
export function useUserCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserCompaniesQuery, Types.UserCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserCompaniesQuery, Types.UserCompaniesQueryVariables>(UserCompaniesDocument, options);
        }
export type UserCompaniesQueryHookResult = ReturnType<typeof useUserCompaniesQuery>;
export type UserCompaniesLazyQueryHookResult = ReturnType<typeof useUserCompaniesLazyQuery>;
export type UserCompaniesQueryResult = Apollo.QueryResult<Types.UserCompaniesQuery, Types.UserCompaniesQueryVariables>;
export const DiscountCouponDetailDocument = gql`
    query DiscountCouponDetail($id: String!) {
  discountCouponDetail(id: $id) {
    id
    code
    value
    discount_type_id
    apply_on_products
    apply_only_to_products
  }
}
    `;

/**
 * __useDiscountCouponDetailQuery__
 *
 * To run a query within a React component, call `useDiscountCouponDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscountCouponDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscountCouponDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDiscountCouponDetailQuery(baseOptions: Apollo.QueryHookOptions<Types.DiscountCouponDetailQuery, Types.DiscountCouponDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DiscountCouponDetailQuery, Types.DiscountCouponDetailQueryVariables>(DiscountCouponDetailDocument, options);
      }
export function useDiscountCouponDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DiscountCouponDetailQuery, Types.DiscountCouponDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DiscountCouponDetailQuery, Types.DiscountCouponDetailQueryVariables>(DiscountCouponDetailDocument, options);
        }
export type DiscountCouponDetailQueryHookResult = ReturnType<typeof useDiscountCouponDetailQuery>;
export type DiscountCouponDetailLazyQueryHookResult = ReturnType<typeof useDiscountCouponDetailLazyQuery>;
export type DiscountCouponDetailQueryResult = Apollo.QueryResult<Types.DiscountCouponDetailQuery, Types.DiscountCouponDetailQueryVariables>;
export const GetAllRoutesByChallengeDocument = gql`
    query GetAllRoutesByChallenge($challenge_id: String!) {
  getAllRoutesByChallenge(challenge_id: $challenge_id) {
    id
    challenge_id
    name
    distance
    altimetry
    polyline
    summary_polyline
    route_thumbnail_image
    min_bounds
    max_bounds
  }
}
    `;

/**
 * __useGetAllRoutesByChallengeQuery__
 *
 * To run a query within a React component, call `useGetAllRoutesByChallengeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRoutesByChallengeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRoutesByChallengeQuery({
 *   variables: {
 *      challenge_id: // value for 'challenge_id'
 *   },
 * });
 */
export function useGetAllRoutesByChallengeQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAllRoutesByChallengeQuery, Types.GetAllRoutesByChallengeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAllRoutesByChallengeQuery, Types.GetAllRoutesByChallengeQueryVariables>(GetAllRoutesByChallengeDocument, options);
      }
export function useGetAllRoutesByChallengeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllRoutesByChallengeQuery, Types.GetAllRoutesByChallengeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAllRoutesByChallengeQuery, Types.GetAllRoutesByChallengeQueryVariables>(GetAllRoutesByChallengeDocument, options);
        }
export type GetAllRoutesByChallengeQueryHookResult = ReturnType<typeof useGetAllRoutesByChallengeQuery>;
export type GetAllRoutesByChallengeLazyQueryHookResult = ReturnType<typeof useGetAllRoutesByChallengeLazyQuery>;
export type GetAllRoutesByChallengeQueryResult = Apollo.QueryResult<Types.GetAllRoutesByChallengeQuery, Types.GetAllRoutesByChallengeQueryVariables>;
export const ExploreChallengesNotLoggedDocument = gql`
    query ExploreChallengesNotLogged($showcases_pagination: PaginationInput!, $pagination: PaginationInput!) {
  exploreChallengesNotLogged(
    showcases_pagination: $showcases_pagination
    pagination: $pagination
  ) {
    items {
      name
      key
      type
      order
      content {
        ...ChallengeWithCompleted
        start_date_registration
        end_date_registration
        challenge_format_slug
        short_id
        slug_name
        company_id
        company {
          id
          business_name
          fantasy_name
          profile {
            id
            username
          }
        }
      }
    }
    page_info {
      ...PageInfo
    }
  }
}
    ${ChallengeWithCompletedFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useExploreChallengesNotLoggedQuery__
 *
 * To run a query within a React component, call `useExploreChallengesNotLoggedQuery` and pass it any options that fit your needs.
 * When your component renders, `useExploreChallengesNotLoggedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExploreChallengesNotLoggedQuery({
 *   variables: {
 *      showcases_pagination: // value for 'showcases_pagination'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useExploreChallengesNotLoggedQuery(baseOptions: Apollo.QueryHookOptions<Types.ExploreChallengesNotLoggedQuery, Types.ExploreChallengesNotLoggedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ExploreChallengesNotLoggedQuery, Types.ExploreChallengesNotLoggedQueryVariables>(ExploreChallengesNotLoggedDocument, options);
      }
export function useExploreChallengesNotLoggedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ExploreChallengesNotLoggedQuery, Types.ExploreChallengesNotLoggedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ExploreChallengesNotLoggedQuery, Types.ExploreChallengesNotLoggedQueryVariables>(ExploreChallengesNotLoggedDocument, options);
        }
export type ExploreChallengesNotLoggedQueryHookResult = ReturnType<typeof useExploreChallengesNotLoggedQuery>;
export type ExploreChallengesNotLoggedLazyQueryHookResult = ReturnType<typeof useExploreChallengesNotLoggedLazyQuery>;
export type ExploreChallengesNotLoggedQueryResult = Apollo.QueryResult<Types.ExploreChallengesNotLoggedQuery, Types.ExploreChallengesNotLoggedQueryVariables>;
export const ExploreChallengesV2Document = gql`
    query ExploreChallengesV2($profile_id: String!, $page: Float!) {
  exploreChallengesV2(profile_id: $profile_id, page: $page) {
    items {
      name
      key
      type
      order
      content {
        ...ChallengeWithCompleted
        start_date_registration
        end_date_registration
        challenge_format_slug
        short_id
        slug_name
        company_id
        company {
          id
          business_name
          fantasy_name
          profile {
            id
            username
          }
        }
      }
      __typename
    }
    page_info {
      ...PageInfo
    }
  }
}
    ${ChallengeWithCompletedFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useExploreChallengesV2Query__
 *
 * To run a query within a React component, call `useExploreChallengesV2Query` and pass it any options that fit your needs.
 * When your component renders, `useExploreChallengesV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExploreChallengesV2Query({
 *   variables: {
 *      profile_id: // value for 'profile_id'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useExploreChallengesV2Query(baseOptions: Apollo.QueryHookOptions<Types.ExploreChallengesV2Query, Types.ExploreChallengesV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ExploreChallengesV2Query, Types.ExploreChallengesV2QueryVariables>(ExploreChallengesV2Document, options);
      }
export function useExploreChallengesV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ExploreChallengesV2Query, Types.ExploreChallengesV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ExploreChallengesV2Query, Types.ExploreChallengesV2QueryVariables>(ExploreChallengesV2Document, options);
        }
export type ExploreChallengesV2QueryHookResult = ReturnType<typeof useExploreChallengesV2Query>;
export type ExploreChallengesV2LazyQueryHookResult = ReturnType<typeof useExploreChallengesV2LazyQuery>;
export type ExploreChallengesV2QueryResult = Apollo.QueryResult<Types.ExploreChallengesV2Query, Types.ExploreChallengesV2QueryVariables>;
export const FindPaymentNotLoggedDocument = gql`
    query FindPaymentNotLogged($id: String!) {
  findPaymentNotLogged(id: $id) {
    id
    resource_payment_id
    origin_payment_id
    payment_processor_id
    origin_resource_id
    status
    bill_link
    bill_barcode
    bill_expiration_date
    pix_qrcode
    pix_expiration_date
    picpay_link
    picpay_qrcode
    picpay_expiration_date
    origin_payment {
      id
      name
      slug
      description
    }
  }
}
    `;

/**
 * __useFindPaymentNotLoggedQuery__
 *
 * To run a query within a React component, call `useFindPaymentNotLoggedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPaymentNotLoggedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPaymentNotLoggedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindPaymentNotLoggedQuery(baseOptions: Apollo.QueryHookOptions<Types.FindPaymentNotLoggedQuery, Types.FindPaymentNotLoggedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FindPaymentNotLoggedQuery, Types.FindPaymentNotLoggedQueryVariables>(FindPaymentNotLoggedDocument, options);
      }
export function useFindPaymentNotLoggedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FindPaymentNotLoggedQuery, Types.FindPaymentNotLoggedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FindPaymentNotLoggedQuery, Types.FindPaymentNotLoggedQueryVariables>(FindPaymentNotLoggedDocument, options);
        }
export type FindPaymentNotLoggedQueryHookResult = ReturnType<typeof useFindPaymentNotLoggedQuery>;
export type FindPaymentNotLoggedLazyQueryHookResult = ReturnType<typeof useFindPaymentNotLoggedLazyQuery>;
export type FindPaymentNotLoggedQueryResult = Apollo.QueryResult<Types.FindPaymentNotLoggedQuery, Types.FindPaymentNotLoggedQueryVariables>;
export const GetAwardDocument = gql`
    query GetAward($award_id: String!) {
  getAward(award_id: $award_id) {
    id
    challenge_id
    name
    description
    position
    quantity
    only_for_draw
    subscribers_limit
    active
    price
    production_time
    expected_shipping_date
    free_shipping
    promotional_image_1_5x
    awards_remaining
    available
    award_type_slug
    first_subscribers_gift_order
    award_volumes {
      id
      award_id
      name
      description
      height
      width
      depth
      weight
      active
    }
    awardsImages {
      id
      link
      order
      active
    }
    awards_products {
      award_id
      product_id
      product {
        id
        name
        description
        date_initial
        date_end
        available
        order
        has_cost
        allow_buy_without_subscription
        free_field
        active
        only_award
        challenge_id
        images {
          id
          link
          order
        }
        variations {
          id
          product_id
          text
          description
          available_quantity
          available
          order
        }
      }
    }
  }
}
    `;

/**
 * __useGetAwardQuery__
 *
 * To run a query within a React component, call `useGetAwardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAwardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAwardQuery({
 *   variables: {
 *      award_id: // value for 'award_id'
 *   },
 * });
 */
export function useGetAwardQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAwardQuery, Types.GetAwardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAwardQuery, Types.GetAwardQueryVariables>(GetAwardDocument, options);
      }
export function useGetAwardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAwardQuery, Types.GetAwardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAwardQuery, Types.GetAwardQueryVariables>(GetAwardDocument, options);
        }
export type GetAwardQueryHookResult = ReturnType<typeof useGetAwardQuery>;
export type GetAwardLazyQueryHookResult = ReturnType<typeof useGetAwardLazyQuery>;
export type GetAwardQueryResult = Apollo.QueryResult<Types.GetAwardQuery, Types.GetAwardQueryVariables>;
export const GetBanksDocument = gql`
    query GetBanks($pagination: PaginationInput!) {
  getBanks(pagination: $pagination) {
    compe_brazil
    id
    ispb_brazil
    name_long
    name_short
    swift_code
  }
}
    `;

/**
 * __useGetBanksQuery__
 *
 * To run a query within a React component, call `useGetBanksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBanksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBanksQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetBanksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetBanksQuery, Types.GetBanksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetBanksQuery, Types.GetBanksQueryVariables>(GetBanksDocument, options);
      }
export function useGetBanksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBanksQuery, Types.GetBanksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetBanksQuery, Types.GetBanksQueryVariables>(GetBanksDocument, options);
        }
export type GetBanksQueryHookResult = ReturnType<typeof useGetBanksQuery>;
export type GetBanksLazyQueryHookResult = ReturnType<typeof useGetBanksLazyQuery>;
export type GetBanksQueryResult = Apollo.QueryResult<Types.GetBanksQuery, Types.GetBanksQueryVariables>;
export const GetCardsDocument = gql`
    query GetCards($pagination: PaginationInput!, $user_id: String!) {
  getCards(pagination: $pagination, user_id: $user_id) {
    id
    name
    external_id
    brand
    holder_name
    first_digits
    last_digits
    valid
    expiration_date
    legal_holder_number
  }
}
    `;

/**
 * __useGetCardsQuery__
 *
 * To run a query within a React component, call `useGetCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetCardsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCardsQuery, Types.GetCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCardsQuery, Types.GetCardsQueryVariables>(GetCardsDocument, options);
      }
export function useGetCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCardsQuery, Types.GetCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCardsQuery, Types.GetCardsQueryVariables>(GetCardsDocument, options);
        }
export type GetCardsQueryHookResult = ReturnType<typeof useGetCardsQuery>;
export type GetCardsLazyQueryHookResult = ReturnType<typeof useGetCardsLazyQuery>;
export type GetCardsQueryResult = Apollo.QueryResult<Types.GetCardsQuery, Types.GetCardsQueryVariables>;
export const GetChallengeDetailDocument = gql`
    query GetChallengeDetail($data: GetChallengeDetailInput!) {
  getChallengeDetail(data: $data) {
    id
    short_id
    name
    slug_name
    challenge_type
    challenge_format_slug
    company_id
    start_date
    end_date
    start_date_registration
    end_date_registration
    date_for_drawn
    location_city
    location_state
    location_country
    start_longitude
    start_latitude
    has_achievement
    has_categories
    internal_test
    company_id
    eligible_spotlight
    access_only_by_link
    description
    image_avatar
    image_spotlight
    image_cover
    isFavorite
    count_subscribe
    count_view
    claps_count
    count_comments
    launch_date
    physical_event
    slug_name
    published
    commissioning_model_id
    participant_benefits_external_link
    claps {
      challenge_id
      profile_id
      count
    }
    user_challenges {
      id
      registration_date
      challenge_id
      confirmed_subscription
      user_id
      payment_split {
        user_payment_splits {
          user_id
          value
        }
      }
      subscribed_persons {
        id
        name
        riderize_user_id
      }
      last_payment {
        bill_link
        bill_barcode
        bill_expiration_date
        paypal_link
      }
    }
    abandoned_subscriptions {
      id
      registration_date
      challenge_id
      challenge_category_id
      confirmed_subscription
      event_subscription_price {
        event_subscription_id
        event_subscription {
          name
        }
      }
    }
    configuration {
      accept_withdrawal
      award_at_address
      is_paid
      id
      has_paid_kit
      distance_goal_value
      fixed_shipping
      fixed_shipping_value
      max_distance_goal_value
      altimetry_goal_value
      max_altimetry_goal_value
      min_time_goal_value
      max_time_goal_value
      unique_ride
      days_to_complete
      accumulation
      managed_by_riderize
      deadline_bill_payment
      deadline_pix_payment
      require_address_registration
      require_full_registration
    }
    challenge_categories {
      ...ChallengeCategory
      challenge_category_children {
        ...ChallengeCategory
        challenge_category_children {
          ...ChallengeCategory
        }
      }
    }
    challenge_products {
      product {
        id
        name
        only_award
        available
        available_quantity
        old_price
        allow_shipping
        allow_withdrawal
        date_initial
        date_end
        installments {
          installments_quantity
          total_amount
          installment_value
        }
        prices {
          value
          active
          date_initial
        }
        images {
          id
          link
          order
        }
        products_volumes {
          id
          name
          height
          width
          depth
          weight
        }
        group_variations {
          ...GroupVariation
        }
      }
    }
    products {
      id
      name
      only_award
      available
      prices {
        value
        active
        date_initial
      }
      images {
        id
        link
        order
      }
      group_variations {
        ...GroupVariation
      }
    }
    awards {
      id
      name
      description
      price
      award_type_slug
      sent_or_withdrawn
      event_subscriptions_awards {
        award_id
        event_subscription_id
      }
      award_volumes {
        id
        award_id
        name
        height
        width
        depth
        weight
      }
      quantity
      only_for_draw
      available
      awardsImages {
        id
        link
        order
      }
      awards_products {
        award_id
        product {
          id
          name
          description
          available
          order
          active
          date_initial
          date_end
          images {
            id
            link
            active
          }
          prices {
            id
            value
            date_initial
          }
          group_variations {
            ...GroupVariation
          }
        }
      }
    }
    event_routes {
      id
      route_thumbnail_image
      altimetry_thumbnail_image
      active
      name
      distance
      altimetry
      polyline
      min_bounds
      max_bounds
      location_city
      location_state
      location_country
    }
    subscription_prices {
      id
      value
      date_initial
    }
    summary {
      count_subscribe
      count_paid_subscribe
      count_unconfirmed_subscribe
      count_feminine
      count_masculine
      count_view
      count_unsubscribed
      total_revenue
      total_paid
      total_to_pay
      count_bill_subscribe
      count_card_subscribe
      count_pix_subscribe
      count_bill_subscribe
    }
    challenge_additional_requests {
      additional_request {
        id
        required
        request
        possible_request_response {
          id
          response
        }
        free_field
      }
    }
    company {
      id
      fantasy_name
      email
      phone_number
      site
      service_whatsapp_number
      profile {
        profile_avatar
        username
        description
      }
      zip_code
      company_addresses {
        is_billing_address
        is_shipping_address
        address {
          zip_code
        }
      }
      company_settings {
        apply_discounts_seniors
        show_whatsapp_checkout
        service_information_whatsapp
      }
      company_integrations {
        integration_slug
        pixel
        token
      }
    }
    challenge_images {
      id
      link
      order
    }
    challenges_attached_files {
      id
      name
      description
      extension
      link
    }
    challenges_external_links_attached {
      id
      name
      description
      favicon_image_link
      link
    }
    user_extraordinary_actions {
      id
      user_id
      bonus_subscription
      buy_after_registration_closes
    }
    event_subscriptions {
      id
      name
      base_value
      subscriptions_limit
      total_subscriptions
      event_subscription_prices {
        id
        value
        date_initial
      }
    }
    subscriptions_created {
      id
      is_current
      confirmed_subscription
      payment_split_id
      payment_split {
        user_payment_splits {
          user_id
          value
        }
      }
      last_subscription_status {
        id
        code
        name
        translations {
          name
        }
      }
      user {
        id
        name
        profile {
          profile_avatar
        }
      }
      subscribed_persons {
        id
        name
        riderize_user_id
      }
    }
  }
}
    ${ChallengeCategoryFragmentDoc}
${GroupVariationFragmentDoc}`;

/**
 * __useGetChallengeDetailQuery__
 *
 * To run a query within a React component, call `useGetChallengeDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChallengeDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChallengeDetailQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetChallengeDetailQuery(baseOptions: Apollo.QueryHookOptions<Types.GetChallengeDetailQuery, Types.GetChallengeDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetChallengeDetailQuery, Types.GetChallengeDetailQueryVariables>(GetChallengeDetailDocument, options);
      }
export function useGetChallengeDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetChallengeDetailQuery, Types.GetChallengeDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetChallengeDetailQuery, Types.GetChallengeDetailQueryVariables>(GetChallengeDetailDocument, options);
        }
export type GetChallengeDetailQueryHookResult = ReturnType<typeof useGetChallengeDetailQuery>;
export type GetChallengeDetailLazyQueryHookResult = ReturnType<typeof useGetChallengeDetailLazyQuery>;
export type GetChallengeDetailQueryResult = Apollo.QueryResult<Types.GetChallengeDetailQuery, Types.GetChallengeDetailQueryVariables>;
export const GetChallengeDetailNotLoggedDocument = gql`
    query GetChallengeDetailNotLogged($data: GetChallengeDetailNotLoggedInput!) {
  getChallengeDetailNotLogged(data: $data) {
    id
    short_id
    name
    slug_name
    challenge_type
    challenge_format_slug
    start_date
    end_date
    start_date_registration
    end_date_registration
    date_for_drawn
    location_city
    location_state
    location_country
    start_longitude
    start_latitude
    has_achievement
    has_categories
    internal_test
    company_id
    eligible_spotlight
    access_only_by_link
    description
    image_avatar
    image_spotlight
    image_cover
    count_subscribe
    count_view
    claps_count
    count_comments
    launch_date
    physical_event
    published
    commissioning_model_id
    user_challenges {
      id
      registration_date
      challenge_id
    }
    configuration {
      accept_withdrawal
      is_paid
      id
      has_paid_kit
      distance_goal_value
      max_distance_goal_value
      altimetry_goal_value
      max_altimetry_goal_value
      min_time_goal_value
      max_time_goal_value
      unique_ride
      days_to_complete
      accumulation
      managed_by_riderize
      deadline_bill_payment
      deadline_pix_payment
      require_address_registration
      require_full_registration
    }
    challenge_categories {
      ...ChallengeCategory
      challenge_category_children {
        ...ChallengeCategory
        challenge_category_children {
          ...ChallengeCategory
        }
      }
    }
    challenge_products {
      product {
        id
        name
        only_award
        available
        available_quantity
        old_price
        allow_shipping
        allow_withdrawal
        date_initial
        date_end
        installments {
          installments_quantity
          total_amount
          installment_value
        }
        prices {
          value
          active
          date_initial
        }
        images {
          id
          link
          order
        }
        products_volumes {
          id
          name
          height
          width
          depth
          weight
        }
        group_variations {
          ...GroupVariation
        }
      }
    }
    products {
      id
      name
      description
      images {
        id
        link
        order
      }
    }
    awards {
      id
      name
      description
      price
      award_type_slug
      quantity
      only_for_draw
      available
      event_subscriptions_awards {
        award_id
        event_subscription_id
      }
      award_volumes {
        id
        award_id
        name
        height
        width
        depth
        weight
      }
      awardsImages {
        id
        link
        order
      }
      awards_products {
        product {
          id
          name
          description
          available
          order
          active
          date_initial
          date_end
          images {
            id
            link
            active
          }
          group_variations {
            id
            name
            available
            product_variation_type {
              slug
            }
            product_variations {
              id
              available
              available_quantity
              text
              description
              images {
                id
                link
                active
              }
              group_variations {
                id
                name
                available
                product_variation_type {
                  slug
                }
                product_variations {
                  id
                  available
                  available_quantity
                  text
                  description
                  images {
                    id
                    link
                    active
                  }
                  group_variations {
                    id
                    name
                    available
                    product_variation_type {
                      slug
                    }
                    product_variations {
                      id
                      available
                      available_quantity
                      text
                      description
                      images {
                        id
                        link
                        active
                      }
                      group_variations {
                        id
                        name
                        available
                        product_variation_type {
                          slug
                        }
                        product_variations {
                          id
                          available
                          available_quantity
                          text
                          description
                          images {
                            id
                            link
                            active
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    event_routes {
      id
      route_thumbnail_image
      altimetry_thumbnail_image
      active
      name
      distance
      altimetry
      polyline
      min_bounds
      max_bounds
      location_city
      location_state
      location_country
    }
    subscription_prices {
      id
      value
      date_initial
    }
    summary {
      count_subscribe
      count_paid_subscribe
      count_unconfirmed_subscribe
      count_feminine
      count_masculine
      count_view
      count_unsubscribed
      total_revenue
      total_paid
      total_to_pay
      count_bill_subscribe
      count_card_subscribe
      count_pix_subscribe
      count_bill_subscribe
    }
    company {
      id
      fantasy_name
      email
      phone_number
      site
      service_whatsapp_number
      profile {
        profile_avatar
        username
        description
      }
      company_settings {
        apply_discounts_seniors
        show_whatsapp_checkout
        service_information_whatsapp
      }
      company_integrations {
        integration_slug
        pixel
        token
      }
    }
    challenge_images {
      id
      link
      order
    }
    challenges_attached_files {
      id
      name
      description
      extension
      link
    }
    challenges_external_links_attached {
      id
      name
      description
      favicon_image_link
      link
    }
    event_subscriptions {
      id
      name
      base_value
      event_subscription_prices {
        date_initial
        value
      }
    }
  }
}
    ${ChallengeCategoryFragmentDoc}
${GroupVariationFragmentDoc}`;

/**
 * __useGetChallengeDetailNotLoggedQuery__
 *
 * To run a query within a React component, call `useGetChallengeDetailNotLoggedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChallengeDetailNotLoggedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChallengeDetailNotLoggedQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetChallengeDetailNotLoggedQuery(baseOptions: Apollo.QueryHookOptions<Types.GetChallengeDetailNotLoggedQuery, Types.GetChallengeDetailNotLoggedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetChallengeDetailNotLoggedQuery, Types.GetChallengeDetailNotLoggedQueryVariables>(GetChallengeDetailNotLoggedDocument, options);
      }
export function useGetChallengeDetailNotLoggedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetChallengeDetailNotLoggedQuery, Types.GetChallengeDetailNotLoggedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetChallengeDetailNotLoggedQuery, Types.GetChallengeDetailNotLoggedQueryVariables>(GetChallengeDetailNotLoggedDocument, options);
        }
export type GetChallengeDetailNotLoggedQueryHookResult = ReturnType<typeof useGetChallengeDetailNotLoggedQuery>;
export type GetChallengeDetailNotLoggedLazyQueryHookResult = ReturnType<typeof useGetChallengeDetailNotLoggedLazyQuery>;
export type GetChallengeDetailNotLoggedQueryResult = Apollo.QueryResult<Types.GetChallengeDetailNotLoggedQuery, Types.GetChallengeDetailNotLoggedQueryVariables>;
export const GetChallengeWithdrawalAddressesDocument = gql`
    query GetChallengeWithdrawalAddresses($data: FindWithdrawalAddressesInput!) {
  getChallengeWithdrawalAddresses(data: $data) {
    id
    name
    challenge_id
    zip_code
    address_line_one
    address_line_two
    reference_point
    city_id
    city {
      id
      name
      slug
      status
      state {
        id
        name
        slug
        abbreviation
        active
        country_id
        country {
          id
          name
          slug
          abreviation
          active
          iso_code_short
          iso_code_long
          dial_code
        }
      }
    }
  }
}
    `;

/**
 * __useGetChallengeWithdrawalAddressesQuery__
 *
 * To run a query within a React component, call `useGetChallengeWithdrawalAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChallengeWithdrawalAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChallengeWithdrawalAddressesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetChallengeWithdrawalAddressesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetChallengeWithdrawalAddressesQuery, Types.GetChallengeWithdrawalAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetChallengeWithdrawalAddressesQuery, Types.GetChallengeWithdrawalAddressesQueryVariables>(GetChallengeWithdrawalAddressesDocument, options);
      }
export function useGetChallengeWithdrawalAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetChallengeWithdrawalAddressesQuery, Types.GetChallengeWithdrawalAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetChallengeWithdrawalAddressesQuery, Types.GetChallengeWithdrawalAddressesQueryVariables>(GetChallengeWithdrawalAddressesDocument, options);
        }
export type GetChallengeWithdrawalAddressesQueryHookResult = ReturnType<typeof useGetChallengeWithdrawalAddressesQuery>;
export type GetChallengeWithdrawalAddressesLazyQueryHookResult = ReturnType<typeof useGetChallengeWithdrawalAddressesLazyQuery>;
export type GetChallengeWithdrawalAddressesQueryResult = Apollo.QueryResult<Types.GetChallengeWithdrawalAddressesQuery, Types.GetChallengeWithdrawalAddressesQueryVariables>;
export const GetChallengesByCompanyDocument = gql`
    query getChallengesByCompany($pagination: PaginationInput!, $company_id: String!) {
  getChallengesByCompany(pagination: $pagination, company_id: $company_id) {
    id
    short_id
    slug_name
    name
    image_avatar
    image_cover
    challenge_type
    start_date
    start_date_registration
    end_date
    end_date_registration
    physical_event
    configuration {
      is_paid
    }
    company {
      id
      fantasy_name
      email
      phone_number
      site
      profile {
        profile_avatar
        username
      }
    }
  }
}
    `;

/**
 * __useGetChallengesByCompanyQuery__
 *
 * To run a query within a React component, call `useGetChallengesByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChallengesByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChallengesByCompanyQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetChallengesByCompanyQuery(baseOptions: Apollo.QueryHookOptions<Types.GetChallengesByCompanyQuery, Types.GetChallengesByCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetChallengesByCompanyQuery, Types.GetChallengesByCompanyQueryVariables>(GetChallengesByCompanyDocument, options);
      }
export function useGetChallengesByCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetChallengesByCompanyQuery, Types.GetChallengesByCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetChallengesByCompanyQuery, Types.GetChallengesByCompanyQueryVariables>(GetChallengesByCompanyDocument, options);
        }
export type GetChallengesByCompanyQueryHookResult = ReturnType<typeof useGetChallengesByCompanyQuery>;
export type GetChallengesByCompanyLazyQueryHookResult = ReturnType<typeof useGetChallengesByCompanyLazyQuery>;
export type GetChallengesByCompanyQueryResult = Apollo.QueryResult<Types.GetChallengesByCompanyQuery, Types.GetChallengesByCompanyQueryVariables>;
export const GetChallengesByCompanyNotLoggedDocument = gql`
    query getChallengesByCompanyNotLogged($pagination: PaginationInput!, $company_id: String!) {
  getChallengesByCompanyNotLogged(
    pagination: $pagination
    company_id: $company_id
  ) {
    id
    short_id
    slug_name
    name
    image_avatar
    image_cover
    challenge_type
    start_date
    start_date_registration
    end_date
    end_date_registration
    physical_event
    configuration {
      is_paid
    }
    company {
      id
      fantasy_name
      email
      phone_number
      site
      profile {
        profile_avatar
        username
      }
    }
  }
}
    `;

/**
 * __useGetChallengesByCompanyNotLoggedQuery__
 *
 * To run a query within a React component, call `useGetChallengesByCompanyNotLoggedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChallengesByCompanyNotLoggedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChallengesByCompanyNotLoggedQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetChallengesByCompanyNotLoggedQuery(baseOptions: Apollo.QueryHookOptions<Types.GetChallengesByCompanyNotLoggedQuery, Types.GetChallengesByCompanyNotLoggedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetChallengesByCompanyNotLoggedQuery, Types.GetChallengesByCompanyNotLoggedQueryVariables>(GetChallengesByCompanyNotLoggedDocument, options);
      }
export function useGetChallengesByCompanyNotLoggedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetChallengesByCompanyNotLoggedQuery, Types.GetChallengesByCompanyNotLoggedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetChallengesByCompanyNotLoggedQuery, Types.GetChallengesByCompanyNotLoggedQueryVariables>(GetChallengesByCompanyNotLoggedDocument, options);
        }
export type GetChallengesByCompanyNotLoggedQueryHookResult = ReturnType<typeof useGetChallengesByCompanyNotLoggedQuery>;
export type GetChallengesByCompanyNotLoggedLazyQueryHookResult = ReturnType<typeof useGetChallengesByCompanyNotLoggedLazyQuery>;
export type GetChallengesByCompanyNotLoggedQueryResult = Apollo.QueryResult<Types.GetChallengesByCompanyNotLoggedQuery, Types.GetChallengesByCompanyNotLoggedQueryVariables>;
export const GetOAuthClientDocument = gql`
    query GetOAuthClient($data: GetOAuthClientInput!) {
  getOAuthClient(data: $data) {
    id
    link
    description
    name
    slug
    avatar
  }
}
    `;

/**
 * __useGetOAuthClientQuery__
 *
 * To run a query within a React component, call `useGetOAuthClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOAuthClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOAuthClientQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetOAuthClientQuery(baseOptions: Apollo.QueryHookOptions<Types.GetOAuthClientQuery, Types.GetOAuthClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetOAuthClientQuery, Types.GetOAuthClientQueryVariables>(GetOAuthClientDocument, options);
      }
export function useGetOAuthClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetOAuthClientQuery, Types.GetOAuthClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetOAuthClientQuery, Types.GetOAuthClientQueryVariables>(GetOAuthClientDocument, options);
        }
export type GetOAuthClientQueryHookResult = ReturnType<typeof useGetOAuthClientQuery>;
export type GetOAuthClientLazyQueryHookResult = ReturnType<typeof useGetOAuthClientLazyQuery>;
export type GetOAuthClientQueryResult = Apollo.QueryResult<Types.GetOAuthClientQuery, Types.GetOAuthClientQueryVariables>;
export const GetProfileDocument = gql`
    query GetProfile($data: GetProfileDetailInput!) {
  getProfile(data: $data) {
    id
    username
    followers_count
    following_count
    official
    profile_avatar
    profile_cover
    description
    user_id
    is_follower
    user {
      id
      name
      firstname
      lastname
      email
      gender
      stature
      weight
      phone
      ddi
      access_token_facebook
      date_of_birth
      has_social_login
      city_id
      active
      address_line_one
      address_line_two
      street_number
      legal_registry_number
      zip_code
      strava_permission_activities
      staff
      blacklist
      city {
        name
        state {
          name
          abbreviation
        }
      }
    }
  }
}
    `;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetProfileQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProfileQuery, Types.GetProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProfileQuery, Types.GetProfileQueryVariables>(GetProfileDocument, options);
      }
export function useGetProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileQuery, Types.GetProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProfileQuery, Types.GetProfileQueryVariables>(GetProfileDocument, options);
        }
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<typeof useGetProfileLazyQuery>;
export type GetProfileQueryResult = Apollo.QueryResult<Types.GetProfileQuery, Types.GetProfileQueryVariables>;
export const GetUserCheckoutDataDocument = gql`
    query GetUserCheckoutData($data: GetUserCheckoutDataInput!) {
  getUserCheckoutData(data: $data) {
    advantages_company {
      company_id
      free_shipping
      discount_percentage
      discount_fixed
      user_id
      user {
        name
      }
    }
    advantages_challenge {
      challenge_id
      free_shipping
      discount_percentage
      discount_fixed
      user_id
      user {
        name
      }
    }
    user {
      id
      nickname
      firstname
      lastname
      email
      gender
      phone
      date_of_birth
      legal_registry_number
      team_name
      ddi
      document_type
    }
    user_addresses {
      address_id
      user_id
      is_billing_address
      last_used_to_buy
      enabled
      address {
        id
        name
        zip_code
        street_address
        street_number
        neighborhood
        complement
        reference_point
        line_one
        line_two
        city_address
        state_address
        country {
          abreviation
          dial_code
          emoji
          emojiU
          id
          iso_code_long
          iso_code_short
          name
        }
        city {
          id
          name
          state {
            id
            name
            abbreviation
          }
        }
      }
    }
    user_cards {
      id
      name
      external_id
      brand
      holder_name
      first_digits
      last_digits
      valid
      expiration_date
      legal_holder_number
      asaas_id
    }
  }
}
    `;

/**
 * __useGetUserCheckoutDataQuery__
 *
 * To run a query within a React component, call `useGetUserCheckoutDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCheckoutDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCheckoutDataQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetUserCheckoutDataQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUserCheckoutDataQuery, Types.GetUserCheckoutDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserCheckoutDataQuery, Types.GetUserCheckoutDataQueryVariables>(GetUserCheckoutDataDocument, options);
      }
export function useGetUserCheckoutDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserCheckoutDataQuery, Types.GetUserCheckoutDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserCheckoutDataQuery, Types.GetUserCheckoutDataQueryVariables>(GetUserCheckoutDataDocument, options);
        }
export type GetUserCheckoutDataQueryHookResult = ReturnType<typeof useGetUserCheckoutDataQuery>;
export type GetUserCheckoutDataLazyQueryHookResult = ReturnType<typeof useGetUserCheckoutDataLazyQuery>;
export type GetUserCheckoutDataQueryResult = Apollo.QueryResult<Types.GetUserCheckoutDataQuery, Types.GetUserCheckoutDataQueryVariables>;
export const GetUserDataCompiledDocument = gql`
    query GetUserDataCompiled {
  getUserDataCompiled {
    data_compiled {
      integrated_with_garmin
      integrated_with_strava
      integrated_with_polar
      integrated_with_wahoo
      integrated_with_ride_with_gps
    }
    third_party_data {
      id_data_source
      user_id
      third_party_data_source {
        slug
      }
    }
  }
}
    `;

/**
 * __useGetUserDataCompiledQuery__
 *
 * To run a query within a React component, call `useGetUserDataCompiledQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDataCompiledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDataCompiledQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserDataCompiledQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetUserDataCompiledQuery, Types.GetUserDataCompiledQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserDataCompiledQuery, Types.GetUserDataCompiledQueryVariables>(GetUserDataCompiledDocument, options);
      }
export function useGetUserDataCompiledLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserDataCompiledQuery, Types.GetUserDataCompiledQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserDataCompiledQuery, Types.GetUserDataCompiledQueryVariables>(GetUserDataCompiledDocument, options);
        }
export type GetUserDataCompiledQueryHookResult = ReturnType<typeof useGetUserDataCompiledQuery>;
export type GetUserDataCompiledLazyQueryHookResult = ReturnType<typeof useGetUserDataCompiledLazyQuery>;
export type GetUserDataCompiledQueryResult = Apollo.QueryResult<Types.GetUserDataCompiledQuery, Types.GetUserDataCompiledQueryVariables>;
export const GetUserNotLoggedDocument = gql`
    query GetUserNotLogged($user_id: String!) {
  getUserNotLogged(user_id: $user_id) {
    id
    nickname
    firstname
    lastname
    email
    gender
    phone
    ddi
    date_of_birth
    document_type
    legal_registry_number
    team_name
    name
    pre_registration
    profile {
      profile_avatar
    }
    user_address {
      address_id
      user_id
      is_billing_address
      last_used_to_buy
      enabled
      address {
        id
        name
        zip_code
        street_address
        street_number
        neighborhood
        complement
        reference_point
        line_one
        line_two
        city_address
        state_address
        country_id
        country {
          abreviation
          dial_code
          emoji
          emojiU
          id
          iso_code_long
          iso_code_short
          name
        }
        city {
          id
          name
          state {
            id
            name
            abbreviation
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetUserNotLoggedQuery__
 *
 * To run a query within a React component, call `useGetUserNotLoggedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserNotLoggedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserNotLoggedQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetUserNotLoggedQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUserNotLoggedQuery, Types.GetUserNotLoggedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserNotLoggedQuery, Types.GetUserNotLoggedQueryVariables>(GetUserNotLoggedDocument, options);
      }
export function useGetUserNotLoggedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserNotLoggedQuery, Types.GetUserNotLoggedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserNotLoggedQuery, Types.GetUserNotLoggedQueryVariables>(GetUserNotLoggedDocument, options);
        }
export type GetUserNotLoggedQueryHookResult = ReturnType<typeof useGetUserNotLoggedQuery>;
export type GetUserNotLoggedLazyQueryHookResult = ReturnType<typeof useGetUserNotLoggedLazyQuery>;
export type GetUserNotLoggedQueryResult = Apollo.QueryResult<Types.GetUserNotLoggedQuery, Types.GetUserNotLoggedQueryVariables>;
export const ListAllShowcasesOfChallengesNotLoggedDocument = gql`
    query ListAllShowcasesOfChallengesNotLogged($pagination: PaginationInput!, $data: GetShowCasesOfChallengesNotLoggedInput!) {
  listAllShowcasesOfChallengesNotLogged(pagination: $pagination, data: $data) {
    items {
      id
      short_id
      name
      slug_name
      image_avatar
      image_cover
      company {
        profile {
          username
        }
      }
    }
    page_info {
      has_next_page
    }
  }
}
    `;

/**
 * __useListAllShowcasesOfChallengesNotLoggedQuery__
 *
 * To run a query within a React component, call `useListAllShowcasesOfChallengesNotLoggedQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllShowcasesOfChallengesNotLoggedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllShowcasesOfChallengesNotLoggedQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useListAllShowcasesOfChallengesNotLoggedQuery(baseOptions: Apollo.QueryHookOptions<Types.ListAllShowcasesOfChallengesNotLoggedQuery, Types.ListAllShowcasesOfChallengesNotLoggedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListAllShowcasesOfChallengesNotLoggedQuery, Types.ListAllShowcasesOfChallengesNotLoggedQueryVariables>(ListAllShowcasesOfChallengesNotLoggedDocument, options);
      }
export function useListAllShowcasesOfChallengesNotLoggedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListAllShowcasesOfChallengesNotLoggedQuery, Types.ListAllShowcasesOfChallengesNotLoggedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListAllShowcasesOfChallengesNotLoggedQuery, Types.ListAllShowcasesOfChallengesNotLoggedQueryVariables>(ListAllShowcasesOfChallengesNotLoggedDocument, options);
        }
export type ListAllShowcasesOfChallengesNotLoggedQueryHookResult = ReturnType<typeof useListAllShowcasesOfChallengesNotLoggedQuery>;
export type ListAllShowcasesOfChallengesNotLoggedLazyQueryHookResult = ReturnType<typeof useListAllShowcasesOfChallengesNotLoggedLazyQuery>;
export type ListAllShowcasesOfChallengesNotLoggedQueryResult = Apollo.QueryResult<Types.ListAllShowcasesOfChallengesNotLoggedQuery, Types.ListAllShowcasesOfChallengesNotLoggedQueryVariables>;
export const ListDiscountTypesDocument = gql`
    query ListDiscountTypes {
  listDiscountTypes {
    id
    slug
    description
    active
    created_at
    updated_at
    discount_type_translations {
      language_code
      discount_type_id
      description
      created_at
      updated_at
    }
  }
}
    `;

/**
 * __useListDiscountTypesQuery__
 *
 * To run a query within a React component, call `useListDiscountTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDiscountTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDiscountTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListDiscountTypesQuery(baseOptions?: Apollo.QueryHookOptions<Types.ListDiscountTypesQuery, Types.ListDiscountTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListDiscountTypesQuery, Types.ListDiscountTypesQueryVariables>(ListDiscountTypesDocument, options);
      }
export function useListDiscountTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListDiscountTypesQuery, Types.ListDiscountTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListDiscountTypesQuery, Types.ListDiscountTypesQueryVariables>(ListDiscountTypesDocument, options);
        }
export type ListDiscountTypesQueryHookResult = ReturnType<typeof useListDiscountTypesQuery>;
export type ListDiscountTypesLazyQueryHookResult = ReturnType<typeof useListDiscountTypesLazyQuery>;
export type ListDiscountTypesQueryResult = Apollo.QueryResult<Types.ListDiscountTypesQuery, Types.ListDiscountTypesQueryVariables>;
export const ListSimplifiedChallengesDocument = gql`
    query ListSimplifiedChallenges {
  listSimplifiedChallenges {
    short_id
    slug_name
    company {
      profile {
        username
      }
    }
  }
}
    `;

/**
 * __useListSimplifiedChallengesQuery__
 *
 * To run a query within a React component, call `useListSimplifiedChallengesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSimplifiedChallengesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSimplifiedChallengesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListSimplifiedChallengesQuery(baseOptions?: Apollo.QueryHookOptions<Types.ListSimplifiedChallengesQuery, Types.ListSimplifiedChallengesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListSimplifiedChallengesQuery, Types.ListSimplifiedChallengesQueryVariables>(ListSimplifiedChallengesDocument, options);
      }
export function useListSimplifiedChallengesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListSimplifiedChallengesQuery, Types.ListSimplifiedChallengesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListSimplifiedChallengesQuery, Types.ListSimplifiedChallengesQueryVariables>(ListSimplifiedChallengesDocument, options);
        }
export type ListSimplifiedChallengesQueryHookResult = ReturnType<typeof useListSimplifiedChallengesQuery>;
export type ListSimplifiedChallengesLazyQueryHookResult = ReturnType<typeof useListSimplifiedChallengesLazyQuery>;
export type ListSimplifiedChallengesQueryResult = Apollo.QueryResult<Types.ListSimplifiedChallengesQuery, Types.ListSimplifiedChallengesQueryVariables>;
export const GetCommissioningModelsAvailableDocument = gql`
    query GetCommissioningModelsAvailable {
  getCommissioningModelsAvailable {
    id
    name
    description
    active
    countries {
      country_id
      commissioning_model_id
      name
      description
      percentage
      minimum_installment_amount
      interest_rate
      max_installments
      created_at
      updated_at
    }
  }
}
    `;

/**
 * __useGetCommissioningModelsAvailableQuery__
 *
 * To run a query within a React component, call `useGetCommissioningModelsAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommissioningModelsAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommissioningModelsAvailableQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCommissioningModelsAvailableQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetCommissioningModelsAvailableQuery, Types.GetCommissioningModelsAvailableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCommissioningModelsAvailableQuery, Types.GetCommissioningModelsAvailableQueryVariables>(GetCommissioningModelsAvailableDocument, options);
      }
export function useGetCommissioningModelsAvailableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCommissioningModelsAvailableQuery, Types.GetCommissioningModelsAvailableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCommissioningModelsAvailableQuery, Types.GetCommissioningModelsAvailableQueryVariables>(GetCommissioningModelsAvailableDocument, options);
        }
export type GetCommissioningModelsAvailableQueryHookResult = ReturnType<typeof useGetCommissioningModelsAvailableQuery>;
export type GetCommissioningModelsAvailableLazyQueryHookResult = ReturnType<typeof useGetCommissioningModelsAvailableLazyQuery>;
export type GetCommissioningModelsAvailableQueryResult = Apollo.QueryResult<Types.GetCommissioningModelsAvailableQuery, Types.GetCommissioningModelsAvailableQueryVariables>;
export const GenerateQuotePixPaymentDocument = gql`
    mutation GenerateQuotePixPayment($data: GenerateRepairQuotePixPaymentInput!) {
  generateQuotePixPayment(data: $data) {
    pix_qrcode
  }
}
    `;
export type GenerateQuotePixPaymentMutationFn = Apollo.MutationFunction<Types.GenerateQuotePixPaymentMutation, Types.GenerateQuotePixPaymentMutationVariables>;

/**
 * __useGenerateQuotePixPaymentMutation__
 *
 * To run a mutation, you first call `useGenerateQuotePixPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateQuotePixPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateQuotePixPaymentMutation, { data, loading, error }] = useGenerateQuotePixPaymentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGenerateQuotePixPaymentMutation(baseOptions?: Apollo.MutationHookOptions<Types.GenerateQuotePixPaymentMutation, Types.GenerateQuotePixPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.GenerateQuotePixPaymentMutation, Types.GenerateQuotePixPaymentMutationVariables>(GenerateQuotePixPaymentDocument, options);
      }
export type GenerateQuotePixPaymentMutationHookResult = ReturnType<typeof useGenerateQuotePixPaymentMutation>;
export type GenerateQuotePixPaymentMutationResult = Apollo.MutationResult<Types.GenerateQuotePixPaymentMutation>;
export type GenerateQuotePixPaymentMutationOptions = Apollo.BaseMutationOptions<Types.GenerateQuotePixPaymentMutation, Types.GenerateQuotePixPaymentMutationVariables>;
export const GetQuoteDetailNotLoggedDocument = gql`
    query GetQuoteDetailNotLogged($data: GetQuoteDetailInput!) {
  getQuoteDetailNotLogged(data: $data) {
    accept_credit_card
    accept_pix
    active
    approved
    asaas_payment_id
    created_at
    id
    immediate_payment_discount_credit_card
    immediate_payment_discount_pix
    immediate_payment_discount_type
    installment_limit
    paid
    payment_id
    pix_qrcode
    repairs {
      id
      user_bike_id
      company_workshop_id
      description
      client_name
      user_bike {
        workshop_client {
          firstname
          lastname
        }
        user {
          name
        }
      }
      repair_attached_files {
        id
        link
      }
      repair_items {
        id
        repair_type_slug
        bike_gear_id
        amount
        cost
        cost_without_discount
        discount_percentage
        workshop_service_id
        bike_gear {
          id
          gear_type {
            avatar
            name
            slug
          }
        }
        workshop_service {
          id
          name
        }
      }
      repair_item_integration_products {
        amount
        external_id
        external_reference
        name
        type
        unit_cost
      }
    }
    sending_date
    updated_at
    user_who_approved {
      id
      name
      profile {
        profile_avatar
      }
    }
    user_who_approved_id
    value
  }
}
    `;

/**
 * __useGetQuoteDetailNotLoggedQuery__
 *
 * To run a query within a React component, call `useGetQuoteDetailNotLoggedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuoteDetailNotLoggedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuoteDetailNotLoggedQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetQuoteDetailNotLoggedQuery(baseOptions: Apollo.QueryHookOptions<Types.GetQuoteDetailNotLoggedQuery, Types.GetQuoteDetailNotLoggedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetQuoteDetailNotLoggedQuery, Types.GetQuoteDetailNotLoggedQueryVariables>(GetQuoteDetailNotLoggedDocument, options);
      }
export function useGetQuoteDetailNotLoggedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetQuoteDetailNotLoggedQuery, Types.GetQuoteDetailNotLoggedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetQuoteDetailNotLoggedQuery, Types.GetQuoteDetailNotLoggedQueryVariables>(GetQuoteDetailNotLoggedDocument, options);
        }
export type GetQuoteDetailNotLoggedQueryHookResult = ReturnType<typeof useGetQuoteDetailNotLoggedQuery>;
export type GetQuoteDetailNotLoggedLazyQueryHookResult = ReturnType<typeof useGetQuoteDetailNotLoggedLazyQuery>;
export type GetQuoteDetailNotLoggedQueryResult = Apollo.QueryResult<Types.GetQuoteDetailNotLoggedQuery, Types.GetQuoteDetailNotLoggedQueryVariables>;
export const PayQuoteWithCreditCardDocument = gql`
    mutation PayQuoteWithCreditCard($create_card: CreateCardInQuotePaymentInput, $data: PayQuoteWithCreditCardInput!) {
  payQuoteWithCreditCard(create_card: $create_card, data: $data)
}
    `;
export type PayQuoteWithCreditCardMutationFn = Apollo.MutationFunction<Types.PayQuoteWithCreditCardMutation, Types.PayQuoteWithCreditCardMutationVariables>;

/**
 * __usePayQuoteWithCreditCardMutation__
 *
 * To run a mutation, you first call `usePayQuoteWithCreditCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayQuoteWithCreditCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payQuoteWithCreditCardMutation, { data, loading, error }] = usePayQuoteWithCreditCardMutation({
 *   variables: {
 *      create_card: // value for 'create_card'
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePayQuoteWithCreditCardMutation(baseOptions?: Apollo.MutationHookOptions<Types.PayQuoteWithCreditCardMutation, Types.PayQuoteWithCreditCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.PayQuoteWithCreditCardMutation, Types.PayQuoteWithCreditCardMutationVariables>(PayQuoteWithCreditCardDocument, options);
      }
export type PayQuoteWithCreditCardMutationHookResult = ReturnType<typeof usePayQuoteWithCreditCardMutation>;
export type PayQuoteWithCreditCardMutationResult = Apollo.MutationResult<Types.PayQuoteWithCreditCardMutation>;
export type PayQuoteWithCreditCardMutationOptions = Apollo.BaseMutationOptions<Types.PayQuoteWithCreditCardMutation, Types.PayQuoteWithCreditCardMutationVariables>;
export const UpdateQuoteApprovedNotLoggedDocument = gql`
    mutation UpdateQuoteApprovedNotLogged($data: ApproveQuoteInput!) {
  updateQuoteApprovedNotLogged(data: $data) {
    id
    approved
  }
}
    `;
export type UpdateQuoteApprovedNotLoggedMutationFn = Apollo.MutationFunction<Types.UpdateQuoteApprovedNotLoggedMutation, Types.UpdateQuoteApprovedNotLoggedMutationVariables>;

/**
 * __useUpdateQuoteApprovedNotLoggedMutation__
 *
 * To run a mutation, you first call `useUpdateQuoteApprovedNotLoggedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuoteApprovedNotLoggedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuoteApprovedNotLoggedMutation, { data, loading, error }] = useUpdateQuoteApprovedNotLoggedMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateQuoteApprovedNotLoggedMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateQuoteApprovedNotLoggedMutation, Types.UpdateQuoteApprovedNotLoggedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateQuoteApprovedNotLoggedMutation, Types.UpdateQuoteApprovedNotLoggedMutationVariables>(UpdateQuoteApprovedNotLoggedDocument, options);
      }
export type UpdateQuoteApprovedNotLoggedMutationHookResult = ReturnType<typeof useUpdateQuoteApprovedNotLoggedMutation>;
export type UpdateQuoteApprovedNotLoggedMutationResult = Apollo.MutationResult<Types.UpdateQuoteApprovedNotLoggedMutation>;
export type UpdateQuoteApprovedNotLoggedMutationOptions = Apollo.BaseMutationOptions<Types.UpdateQuoteApprovedNotLoggedMutation, Types.UpdateQuoteApprovedNotLoggedMutationVariables>;
export const RegisterLandingPageLeadDocument = gql`
    mutation registerLandingPageLead($data: RegisterLandingPageLeadInput!) {
  registerLandingPageLead(data: $data)
}
    `;
export type RegisterLandingPageLeadMutationFn = Apollo.MutationFunction<Types.RegisterLandingPageLeadMutation, Types.RegisterLandingPageLeadMutationVariables>;

/**
 * __useRegisterLandingPageLeadMutation__
 *
 * To run a mutation, you first call `useRegisterLandingPageLeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterLandingPageLeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerLandingPageLeadMutation, { data, loading, error }] = useRegisterLandingPageLeadMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegisterLandingPageLeadMutation(baseOptions?: Apollo.MutationHookOptions<Types.RegisterLandingPageLeadMutation, Types.RegisterLandingPageLeadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RegisterLandingPageLeadMutation, Types.RegisterLandingPageLeadMutationVariables>(RegisterLandingPageLeadDocument, options);
      }
export type RegisterLandingPageLeadMutationHookResult = ReturnType<typeof useRegisterLandingPageLeadMutation>;
export type RegisterLandingPageLeadMutationResult = Apollo.MutationResult<Types.RegisterLandingPageLeadMutation>;
export type RegisterLandingPageLeadMutationOptions = Apollo.BaseMutationOptions<Types.RegisterLandingPageLeadMutation, Types.RegisterLandingPageLeadMutationVariables>;
export const GetCycleRouteDetailDocument = gql`
    query GetCycleRouteDetail($data: GetChallengeDetailInput!) {
  getCycleRouteDetail(data: $data) {
    id
    name
    description
    image_avatar
    image_cover
    slug_name
    short_id
    count_comments
    isFavorite
    claps_count
    location_city
    location_state
    location_country
    start_longitude
    start_latitude
    claps {
      count
      profile_id
    }
    cycle_route_summary {
      count_subscribe
      count_comments
      count_claps
    }
    configuration {
      distance_goal_value
      altimetry_goal_value
    }
    user_challenges {
      id
      challenge_id
    }
    company {
      id
      fantasy_name
      email
      phone_number
      site
      description
      created_at
      profile {
        profile_avatar
        username
        description
      }
      zip_code
      company_addresses {
        is_billing_address
        is_shipping_address
        address {
          zip_code
        }
      }
    }
    challenge_images {
      id
      link
      order
    }
    event_routes {
      id
      route_thumbnail_image
      altimetry_thumbnail_image
      active
      name
      distance
      altimetry
      polyline
      min_bounds
      max_bounds
      location_city
      location_state
      location_country
    }
  }
}
    `;

/**
 * __useGetCycleRouteDetailQuery__
 *
 * To run a query within a React component, call `useGetCycleRouteDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCycleRouteDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCycleRouteDetailQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetCycleRouteDetailQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCycleRouteDetailQuery, Types.GetCycleRouteDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCycleRouteDetailQuery, Types.GetCycleRouteDetailQueryVariables>(GetCycleRouteDetailDocument, options);
      }
export function useGetCycleRouteDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCycleRouteDetailQuery, Types.GetCycleRouteDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCycleRouteDetailQuery, Types.GetCycleRouteDetailQueryVariables>(GetCycleRouteDetailDocument, options);
        }
export type GetCycleRouteDetailQueryHookResult = ReturnType<typeof useGetCycleRouteDetailQuery>;
export type GetCycleRouteDetailLazyQueryHookResult = ReturnType<typeof useGetCycleRouteDetailLazyQuery>;
export type GetCycleRouteDetailQueryResult = Apollo.QueryResult<Types.GetCycleRouteDetailQuery, Types.GetCycleRouteDetailQueryVariables>;
export const GetCycleRouteDetailNotLoggedDocument = gql`
    query GetCycleRouteDetailNotLogged($data: GetChallengeDetailNotLoggedInput!) {
  getCycleRouteDetailNotLogged(data: $data) {
    id
    name
    description
    image_avatar
    image_cover
    slug_name
    short_id
    count_comments
    isFavorite
    claps_count
    location_city
    location_state
    location_country
    start_longitude
    start_latitude
    claps {
      count
      profile_id
    }
    cycle_route_summary {
      count_subscribe
      count_comments
      count_claps
    }
    configuration {
      distance_goal_value
      altimetry_goal_value
    }
    user_challenges {
      id
      challenge_id
    }
    company {
      id
      fantasy_name
      email
      phone_number
      site
      description
      created_at
      profile {
        profile_avatar
        username
        description
      }
      zip_code
      company_addresses {
        is_billing_address
        is_shipping_address
        address {
          zip_code
        }
      }
    }
    challenge_images {
      id
      link
      order
    }
    event_routes {
      id
      route_thumbnail_image
      altimetry_thumbnail_image
      active
      name
      distance
      altimetry
      polyline
      min_bounds
      max_bounds
      location_city
      location_state
      location_country
    }
  }
}
    `;

/**
 * __useGetCycleRouteDetailNotLoggedQuery__
 *
 * To run a query within a React component, call `useGetCycleRouteDetailNotLoggedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCycleRouteDetailNotLoggedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCycleRouteDetailNotLoggedQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetCycleRouteDetailNotLoggedQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCycleRouteDetailNotLoggedQuery, Types.GetCycleRouteDetailNotLoggedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCycleRouteDetailNotLoggedQuery, Types.GetCycleRouteDetailNotLoggedQueryVariables>(GetCycleRouteDetailNotLoggedDocument, options);
      }
export function useGetCycleRouteDetailNotLoggedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCycleRouteDetailNotLoggedQuery, Types.GetCycleRouteDetailNotLoggedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCycleRouteDetailNotLoggedQuery, Types.GetCycleRouteDetailNotLoggedQueryVariables>(GetCycleRouteDetailNotLoggedDocument, options);
        }
export type GetCycleRouteDetailNotLoggedQueryHookResult = ReturnType<typeof useGetCycleRouteDetailNotLoggedQuery>;
export type GetCycleRouteDetailNotLoggedLazyQueryHookResult = ReturnType<typeof useGetCycleRouteDetailNotLoggedLazyQuery>;
export type GetCycleRouteDetailNotLoggedQueryResult = Apollo.QueryResult<Types.GetCycleRouteDetailNotLoggedQuery, Types.GetCycleRouteDetailNotLoggedQueryVariables>;
export const ListAllCycleRoutesDocument = gql`
    query ListAllCycleRoutes($coordinates: CoordinatesInput, $pagination: PaginationInput!, $data: ListAllNearCycleRoutesInput!) {
  listAllCycleRoutes(
    coordinates: $coordinates
    pagination: $pagination
    data: $data
  ) {
    items {
      name
      id
      short_id
      slug_name
      challenge_format_slug
      image_cover
      location_city
      location_state
      location_country
    }
  }
}
    `;

/**
 * __useListAllCycleRoutesQuery__
 *
 * To run a query within a React component, call `useListAllCycleRoutesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllCycleRoutesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllCycleRoutesQuery({
 *   variables: {
 *      coordinates: // value for 'coordinates'
 *      pagination: // value for 'pagination'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useListAllCycleRoutesQuery(baseOptions: Apollo.QueryHookOptions<Types.ListAllCycleRoutesQuery, Types.ListAllCycleRoutesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListAllCycleRoutesQuery, Types.ListAllCycleRoutesQueryVariables>(ListAllCycleRoutesDocument, options);
      }
export function useListAllCycleRoutesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListAllCycleRoutesQuery, Types.ListAllCycleRoutesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListAllCycleRoutesQuery, Types.ListAllCycleRoutesQueryVariables>(ListAllCycleRoutesDocument, options);
        }
export type ListAllCycleRoutesQueryHookResult = ReturnType<typeof useListAllCycleRoutesQuery>;
export type ListAllCycleRoutesLazyQueryHookResult = ReturnType<typeof useListAllCycleRoutesLazyQuery>;
export type ListAllCycleRoutesQueryResult = Apollo.QueryResult<Types.ListAllCycleRoutesQuery, Types.ListAllCycleRoutesQueryVariables>;
export const ListSimplifiedCycleRoutesDocument = gql`
    query ListSimplifiedCycleRoutes {
  listSimplifiedCycleRoutes {
    short_id
    slug_name
    company {
      profile {
        username
      }
    }
  }
}
    `;

/**
 * __useListSimplifiedCycleRoutesQuery__
 *
 * To run a query within a React component, call `useListSimplifiedCycleRoutesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSimplifiedCycleRoutesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSimplifiedCycleRoutesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListSimplifiedCycleRoutesQuery(baseOptions?: Apollo.QueryHookOptions<Types.ListSimplifiedCycleRoutesQuery, Types.ListSimplifiedCycleRoutesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListSimplifiedCycleRoutesQuery, Types.ListSimplifiedCycleRoutesQueryVariables>(ListSimplifiedCycleRoutesDocument, options);
      }
export function useListSimplifiedCycleRoutesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListSimplifiedCycleRoutesQuery, Types.ListSimplifiedCycleRoutesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListSimplifiedCycleRoutesQuery, Types.ListSimplifiedCycleRoutesQueryVariables>(ListSimplifiedCycleRoutesDocument, options);
        }
export type ListSimplifiedCycleRoutesQueryHookResult = ReturnType<typeof useListSimplifiedCycleRoutesQuery>;
export type ListSimplifiedCycleRoutesLazyQueryHookResult = ReturnType<typeof useListSimplifiedCycleRoutesLazyQuery>;
export type ListSimplifiedCycleRoutesQueryResult = Apollo.QueryResult<Types.ListSimplifiedCycleRoutesQuery, Types.ListSimplifiedCycleRoutesQueryVariables>;
export const GetDetailedSubscriptionDocument = gql`
    query GetDetailedSubscription($data: GetDetailedSubscriptionInput!) {
  getDetailedSubscription(data: $data) {
    challenge {
      id
      name
      short_id
      slug_name
      end_date
      company {
        profile {
          username
        }
      }
    }
    user {
      id
      name
      email
      pre_registration
      profile {
        profile_avatar
      }
    }
  }
}
    `;

/**
 * __useGetDetailedSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetDetailedSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDetailedSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDetailedSubscriptionQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetDetailedSubscriptionQuery(baseOptions: Apollo.QueryHookOptions<Types.GetDetailedSubscriptionQuery, Types.GetDetailedSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetDetailedSubscriptionQuery, Types.GetDetailedSubscriptionQueryVariables>(GetDetailedSubscriptionDocument, options);
      }
export function useGetDetailedSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetDetailedSubscriptionQuery, Types.GetDetailedSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetDetailedSubscriptionQuery, Types.GetDetailedSubscriptionQueryVariables>(GetDetailedSubscriptionDocument, options);
        }
export type GetDetailedSubscriptionQueryHookResult = ReturnType<typeof useGetDetailedSubscriptionQuery>;
export type GetDetailedSubscriptionLazyQueryHookResult = ReturnType<typeof useGetDetailedSubscriptionLazyQuery>;
export type GetDetailedSubscriptionQueryResult = Apollo.QueryResult<Types.GetDetailedSubscriptionQuery, Types.GetDetailedSubscriptionQueryVariables>;
export const GetDetailedSubscriptionNotLoggedDocument = gql`
    query GetDetailedSubscriptionNotLogged($data: GetDetailedSubscriptionInput!) {
  getDetailedSubscriptionNotLogged(data: $data) {
    id
    amount_to_pay
    confirmed_subscription
    is_current
    challenge_category_id
    payment_split_id
    payment_split {
      user_payment_splits {
        user_id
        value
      }
    }
    event_subscription_price {
      id
      event_subscription_id
      event_subscription {
        name
        id
      }
    }
    products_bought {
      id
      award {
        name
      }
    }
    challenge {
      id
      name
      image_avatar
      short_id
      slug_name
      end_date
      company {
        profile {
          username
        }
      }
      challenge_categories {
        id
        name
        challenge_category_parent_id
        challenge_category_children {
          id
          name
          challenge_category_parent_id
          challenge_category_children {
            id
            name
            challenge_category_parent_id
          }
        }
      }
    }
    user {
      id
      name
      email
      phone
      pre_registration
      profile {
        profile_avatar
      }
    }
    subscribed_persons {
      riderize_user {
        id
        name
      }
    }
    last_payment {
      is_paid
      paypal_link
      status
      value
    }
  }
}
    `;

/**
 * __useGetDetailedSubscriptionNotLoggedQuery__
 *
 * To run a query within a React component, call `useGetDetailedSubscriptionNotLoggedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDetailedSubscriptionNotLoggedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDetailedSubscriptionNotLoggedQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetDetailedSubscriptionNotLoggedQuery(baseOptions: Apollo.QueryHookOptions<Types.GetDetailedSubscriptionNotLoggedQuery, Types.GetDetailedSubscriptionNotLoggedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetDetailedSubscriptionNotLoggedQuery, Types.GetDetailedSubscriptionNotLoggedQueryVariables>(GetDetailedSubscriptionNotLoggedDocument, options);
      }
export function useGetDetailedSubscriptionNotLoggedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetDetailedSubscriptionNotLoggedQuery, Types.GetDetailedSubscriptionNotLoggedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetDetailedSubscriptionNotLoggedQuery, Types.GetDetailedSubscriptionNotLoggedQueryVariables>(GetDetailedSubscriptionNotLoggedDocument, options);
        }
export type GetDetailedSubscriptionNotLoggedQueryHookResult = ReturnType<typeof useGetDetailedSubscriptionNotLoggedQuery>;
export type GetDetailedSubscriptionNotLoggedLazyQueryHookResult = ReturnType<typeof useGetDetailedSubscriptionNotLoggedLazyQuery>;
export type GetDetailedSubscriptionNotLoggedQueryResult = Apollo.QueryResult<Types.GetDetailedSubscriptionNotLoggedQuery, Types.GetDetailedSubscriptionNotLoggedQueryVariables>;
export const ListSubscribedPersonsByCreatorDocument = gql`
    query ListSubscribedPersonsByCreator($creator_id: String!) {
  listSubscribedPersonsByCreator(creator_id: $creator_id) {
    id
    name
    first_name
    last_name
    name
    gender
    email
    date_of_birth
    document_type
    legal_registry_number
    phone
    ddi
    team_name
    country_id
    zip_code
    address_line_one
    address_line_two
    city
    state
    street_address
    street_number
    complement
    neighborhood
    reference_point
    user_challenges {
      challenge_id
    }
  }
}
    `;

/**
 * __useListSubscribedPersonsByCreatorQuery__
 *
 * To run a query within a React component, call `useListSubscribedPersonsByCreatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSubscribedPersonsByCreatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSubscribedPersonsByCreatorQuery({
 *   variables: {
 *      creator_id: // value for 'creator_id'
 *   },
 * });
 */
export function useListSubscribedPersonsByCreatorQuery(baseOptions: Apollo.QueryHookOptions<Types.ListSubscribedPersonsByCreatorQuery, Types.ListSubscribedPersonsByCreatorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListSubscribedPersonsByCreatorQuery, Types.ListSubscribedPersonsByCreatorQueryVariables>(ListSubscribedPersonsByCreatorDocument, options);
      }
export function useListSubscribedPersonsByCreatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListSubscribedPersonsByCreatorQuery, Types.ListSubscribedPersonsByCreatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListSubscribedPersonsByCreatorQuery, Types.ListSubscribedPersonsByCreatorQueryVariables>(ListSubscribedPersonsByCreatorDocument, options);
        }
export type ListSubscribedPersonsByCreatorQueryHookResult = ReturnType<typeof useListSubscribedPersonsByCreatorQuery>;
export type ListSubscribedPersonsByCreatorLazyQueryHookResult = ReturnType<typeof useListSubscribedPersonsByCreatorLazyQuery>;
export type ListSubscribedPersonsByCreatorQueryResult = Apollo.QueryResult<Types.ListSubscribedPersonsByCreatorQuery, Types.ListSubscribedPersonsByCreatorQueryVariables>;
export const GetCityDocument = gql`
    query GetCity($data: CitiesInput!, $pagination: PaginationInput!) {
  getCity(data: $data, pagination: $pagination) {
    id
    name
    slug
    status
  }
}
    `;

/**
 * __useGetCityQuery__
 *
 * To run a query within a React component, call `useGetCityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCityQuery({
 *   variables: {
 *      data: // value for 'data'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetCityQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCityQuery, Types.GetCityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCityQuery, Types.GetCityQueryVariables>(GetCityDocument, options);
      }
export function useGetCityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCityQuery, Types.GetCityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCityQuery, Types.GetCityQueryVariables>(GetCityDocument, options);
        }
export type GetCityQueryHookResult = ReturnType<typeof useGetCityQuery>;
export type GetCityLazyQueryHookResult = ReturnType<typeof useGetCityLazyQuery>;
export type GetCityQueryResult = Apollo.QueryResult<Types.GetCityQuery, Types.GetCityQueryVariables>;
export const GetCountriesDocument = gql`
    query GetCountries($pagination: PaginationInput!) {
  getCountries(pagination: $pagination) {
    abreviation
    dial_code
    emoji
    emojiU
    id
    iso_code_long
    iso_code_short
    name
  }
}
    `;

/**
 * __useGetCountriesQuery__
 *
 * To run a query within a React component, call `useGetCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountriesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetCountriesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCountriesQuery, Types.GetCountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCountriesQuery, Types.GetCountriesQueryVariables>(GetCountriesDocument, options);
      }
export function useGetCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCountriesQuery, Types.GetCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCountriesQuery, Types.GetCountriesQueryVariables>(GetCountriesDocument, options);
        }
export type GetCountriesQueryHookResult = ReturnType<typeof useGetCountriesQuery>;
export type GetCountriesLazyQueryHookResult = ReturnType<typeof useGetCountriesLazyQuery>;
export type GetCountriesQueryResult = Apollo.QueryResult<Types.GetCountriesQuery, Types.GetCountriesQueryVariables>;
export const GetStatesDocument = gql`
    query GetStates($pagination: PaginationInput!) {
  getStates(pagination: $pagination) {
    id
    name
    abbreviation
    slug
  }
}
    `;

/**
 * __useGetStatesQuery__
 *
 * To run a query within a React component, call `useGetStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetStatesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetStatesQuery, Types.GetStatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetStatesQuery, Types.GetStatesQueryVariables>(GetStatesDocument, options);
      }
export function useGetStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStatesQuery, Types.GetStatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetStatesQuery, Types.GetStatesQueryVariables>(GetStatesDocument, options);
        }
export type GetStatesQueryHookResult = ReturnType<typeof useGetStatesQuery>;
export type GetStatesLazyQueryHookResult = ReturnType<typeof useGetStatesLazyQuery>;
export type GetStatesQueryResult = Apollo.QueryResult<Types.GetStatesQuery, Types.GetStatesQueryVariables>;
export const AssociateCustomMonitoringsToUserBikeDocument = gql`
    mutation AssociateCustomMonitoringsToUserBike($data: AssociateCustomMonitoringsToUserBikeInput!) {
  associateCustomMonitoringsToUserBike(data: $data)
}
    `;
export type AssociateCustomMonitoringsToUserBikeMutationFn = Apollo.MutationFunction<Types.AssociateCustomMonitoringsToUserBikeMutation, Types.AssociateCustomMonitoringsToUserBikeMutationVariables>;

/**
 * __useAssociateCustomMonitoringsToUserBikeMutation__
 *
 * To run a mutation, you first call `useAssociateCustomMonitoringsToUserBikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssociateCustomMonitoringsToUserBikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [associateCustomMonitoringsToUserBikeMutation, { data, loading, error }] = useAssociateCustomMonitoringsToUserBikeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAssociateCustomMonitoringsToUserBikeMutation(baseOptions?: Apollo.MutationHookOptions<Types.AssociateCustomMonitoringsToUserBikeMutation, Types.AssociateCustomMonitoringsToUserBikeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AssociateCustomMonitoringsToUserBikeMutation, Types.AssociateCustomMonitoringsToUserBikeMutationVariables>(AssociateCustomMonitoringsToUserBikeDocument, options);
      }
export type AssociateCustomMonitoringsToUserBikeMutationHookResult = ReturnType<typeof useAssociateCustomMonitoringsToUserBikeMutation>;
export type AssociateCustomMonitoringsToUserBikeMutationResult = Apollo.MutationResult<Types.AssociateCustomMonitoringsToUserBikeMutation>;
export type AssociateCustomMonitoringsToUserBikeMutationOptions = Apollo.BaseMutationOptions<Types.AssociateCustomMonitoringsToUserBikeMutation, Types.AssociateCustomMonitoringsToUserBikeMutationVariables>;
export const CompanyWorkshopBikesDocument = gql`
    query CompanyWorkshopBikes($id: String!) {
  companyWorkshopBikes(id: $id) {
    active
    id
    name
    bike_id
    user {
      name
      profile {
        profile_avatar
      }
    }
    bike {
      id
      model_name
      brand_name
      total_distance
      bike_type {
        avatar
      }
      repairs {
        date
        cost
      }
    }
    user_bike_workshops {
      last_service
      total_amount_spent
    }
  }
}
    `;

/**
 * __useCompanyWorkshopBikesQuery__
 *
 * To run a query within a React component, call `useCompanyWorkshopBikesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyWorkshopBikesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyWorkshopBikesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyWorkshopBikesQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyWorkshopBikesQuery, Types.CompanyWorkshopBikesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyWorkshopBikesQuery, Types.CompanyWorkshopBikesQueryVariables>(CompanyWorkshopBikesDocument, options);
      }
export function useCompanyWorkshopBikesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyWorkshopBikesQuery, Types.CompanyWorkshopBikesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyWorkshopBikesQuery, Types.CompanyWorkshopBikesQueryVariables>(CompanyWorkshopBikesDocument, options);
        }
export type CompanyWorkshopBikesQueryHookResult = ReturnType<typeof useCompanyWorkshopBikesQuery>;
export type CompanyWorkshopBikesLazyQueryHookResult = ReturnType<typeof useCompanyWorkshopBikesLazyQuery>;
export type CompanyWorkshopBikesQueryResult = Apollo.QueryResult<Types.CompanyWorkshopBikesQuery, Types.CompanyWorkshopBikesQueryVariables>;
export const CompanyWorkshopFeesDocument = gql`
    query CompanyWorkshopFees($company_workshop_id: String!) {
  companyWorkshopFees(company_workshop_id: $company_workshop_id) {
    credit_card_fixed_fee
    first_withdrawal_fee
    pix_fee
    pix_qr_code_fee
    subscriptions_fee
    up_to_1_installments_percentage
    up_to_12_installments_percentage
    up_to_6_installments_percentage
    withdrawal_fee
  }
}
    `;

/**
 * __useCompanyWorkshopFeesQuery__
 *
 * To run a query within a React component, call `useCompanyWorkshopFeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyWorkshopFeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyWorkshopFeesQuery({
 *   variables: {
 *      company_workshop_id: // value for 'company_workshop_id'
 *   },
 * });
 */
export function useCompanyWorkshopFeesQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyWorkshopFeesQuery, Types.CompanyWorkshopFeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyWorkshopFeesQuery, Types.CompanyWorkshopFeesQueryVariables>(CompanyWorkshopFeesDocument, options);
      }
export function useCompanyWorkshopFeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyWorkshopFeesQuery, Types.CompanyWorkshopFeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyWorkshopFeesQuery, Types.CompanyWorkshopFeesQueryVariables>(CompanyWorkshopFeesDocument, options);
        }
export type CompanyWorkshopFeesQueryHookResult = ReturnType<typeof useCompanyWorkshopFeesQuery>;
export type CompanyWorkshopFeesLazyQueryHookResult = ReturnType<typeof useCompanyWorkshopFeesLazyQuery>;
export type CompanyWorkshopFeesQueryResult = Apollo.QueryResult<Types.CompanyWorkshopFeesQuery, Types.CompanyWorkshopFeesQueryVariables>;
export const CompanyWorkshopIndicatorsDocument = gql`
    query CompanyWorkshopIndicators($id: String!) {
  companyWorkshopIndicators(id: $id) {
    clients
    services
    services_value
  }
}
    `;

/**
 * __useCompanyWorkshopIndicatorsQuery__
 *
 * To run a query within a React component, call `useCompanyWorkshopIndicatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyWorkshopIndicatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyWorkshopIndicatorsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyWorkshopIndicatorsQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyWorkshopIndicatorsQuery, Types.CompanyWorkshopIndicatorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyWorkshopIndicatorsQuery, Types.CompanyWorkshopIndicatorsQueryVariables>(CompanyWorkshopIndicatorsDocument, options);
      }
export function useCompanyWorkshopIndicatorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyWorkshopIndicatorsQuery, Types.CompanyWorkshopIndicatorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyWorkshopIndicatorsQuery, Types.CompanyWorkshopIndicatorsQueryVariables>(CompanyWorkshopIndicatorsDocument, options);
        }
export type CompanyWorkshopIndicatorsQueryHookResult = ReturnType<typeof useCompanyWorkshopIndicatorsQuery>;
export type CompanyWorkshopIndicatorsLazyQueryHookResult = ReturnType<typeof useCompanyWorkshopIndicatorsLazyQuery>;
export type CompanyWorkshopIndicatorsQueryResult = Apollo.QueryResult<Types.CompanyWorkshopIndicatorsQuery, Types.CompanyWorkshopIndicatorsQueryVariables>;
export const CompanyWorkshopServicesByPeriodDocument = gql`
    query CompanyWorkshopServicesByPeriod($data: GetCompanyWorkshopIndicatorsInput!) {
  companyWorkshopServicesByPeriod(data: $data) {
    cost
    label
    quantity
  }
}
    `;

/**
 * __useCompanyWorkshopServicesByPeriodQuery__
 *
 * To run a query within a React component, call `useCompanyWorkshopServicesByPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyWorkshopServicesByPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyWorkshopServicesByPeriodQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCompanyWorkshopServicesByPeriodQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyWorkshopServicesByPeriodQuery, Types.CompanyWorkshopServicesByPeriodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyWorkshopServicesByPeriodQuery, Types.CompanyWorkshopServicesByPeriodQueryVariables>(CompanyWorkshopServicesByPeriodDocument, options);
      }
export function useCompanyWorkshopServicesByPeriodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyWorkshopServicesByPeriodQuery, Types.CompanyWorkshopServicesByPeriodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyWorkshopServicesByPeriodQuery, Types.CompanyWorkshopServicesByPeriodQueryVariables>(CompanyWorkshopServicesByPeriodDocument, options);
        }
export type CompanyWorkshopServicesByPeriodQueryHookResult = ReturnType<typeof useCompanyWorkshopServicesByPeriodQuery>;
export type CompanyWorkshopServicesByPeriodLazyQueryHookResult = ReturnType<typeof useCompanyWorkshopServicesByPeriodLazyQuery>;
export type CompanyWorkshopServicesByPeriodQueryResult = Apollo.QueryResult<Types.CompanyWorkshopServicesByPeriodQuery, Types.CompanyWorkshopServicesByPeriodQueryVariables>;
export const CreateCustomWorkshopMonitoringsDocument = gql`
    mutation CreateCustomWorkshopMonitorings($alerts: [CreateCustomWorkshopMonitoringAlertInput!], $data: CreateCustomWorkshopMonitoringInput!) {
  createCustomWorkshopMonitorings(alerts: $alerts, data: $data) {
    id
  }
}
    `;
export type CreateCustomWorkshopMonitoringsMutationFn = Apollo.MutationFunction<Types.CreateCustomWorkshopMonitoringsMutation, Types.CreateCustomWorkshopMonitoringsMutationVariables>;

/**
 * __useCreateCustomWorkshopMonitoringsMutation__
 *
 * To run a mutation, you first call `useCreateCustomWorkshopMonitoringsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomWorkshopMonitoringsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomWorkshopMonitoringsMutation, { data, loading, error }] = useCreateCustomWorkshopMonitoringsMutation({
 *   variables: {
 *      alerts: // value for 'alerts'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCustomWorkshopMonitoringsMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateCustomWorkshopMonitoringsMutation, Types.CreateCustomWorkshopMonitoringsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateCustomWorkshopMonitoringsMutation, Types.CreateCustomWorkshopMonitoringsMutationVariables>(CreateCustomWorkshopMonitoringsDocument, options);
      }
export type CreateCustomWorkshopMonitoringsMutationHookResult = ReturnType<typeof useCreateCustomWorkshopMonitoringsMutation>;
export type CreateCustomWorkshopMonitoringsMutationResult = Apollo.MutationResult<Types.CreateCustomWorkshopMonitoringsMutation>;
export type CreateCustomWorkshopMonitoringsMutationOptions = Apollo.BaseMutationOptions<Types.CreateCustomWorkshopMonitoringsMutation, Types.CreateCustomWorkshopMonitoringsMutationVariables>;
export const DeleteCustomWorkshopMonitoringDocument = gql`
    mutation DeleteCustomWorkshopMonitoring($id: String!) {
  deleteCustomWorkshopMonitoring(id: $id)
}
    `;
export type DeleteCustomWorkshopMonitoringMutationFn = Apollo.MutationFunction<Types.DeleteCustomWorkshopMonitoringMutation, Types.DeleteCustomWorkshopMonitoringMutationVariables>;

/**
 * __useDeleteCustomWorkshopMonitoringMutation__
 *
 * To run a mutation, you first call `useDeleteCustomWorkshopMonitoringMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomWorkshopMonitoringMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomWorkshopMonitoringMutation, { data, loading, error }] = useDeleteCustomWorkshopMonitoringMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomWorkshopMonitoringMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCustomWorkshopMonitoringMutation, Types.DeleteCustomWorkshopMonitoringMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCustomWorkshopMonitoringMutation, Types.DeleteCustomWorkshopMonitoringMutationVariables>(DeleteCustomWorkshopMonitoringDocument, options);
      }
export type DeleteCustomWorkshopMonitoringMutationHookResult = ReturnType<typeof useDeleteCustomWorkshopMonitoringMutation>;
export type DeleteCustomWorkshopMonitoringMutationResult = Apollo.MutationResult<Types.DeleteCustomWorkshopMonitoringMutation>;
export type DeleteCustomWorkshopMonitoringMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCustomWorkshopMonitoringMutation, Types.DeleteCustomWorkshopMonitoringMutationVariables>;
export const DeleteUserBikeCustomMonitoringDocument = gql`
    mutation DeleteUserBikeCustomMonitoring($data: DeleteUserBikeCustomMonitoringInput!) {
  deleteUserBikeCustomMonitoring(data: $data)
}
    `;
export type DeleteUserBikeCustomMonitoringMutationFn = Apollo.MutationFunction<Types.DeleteUserBikeCustomMonitoringMutation, Types.DeleteUserBikeCustomMonitoringMutationVariables>;

/**
 * __useDeleteUserBikeCustomMonitoringMutation__
 *
 * To run a mutation, you first call `useDeleteUserBikeCustomMonitoringMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserBikeCustomMonitoringMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserBikeCustomMonitoringMutation, { data, loading, error }] = useDeleteUserBikeCustomMonitoringMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteUserBikeCustomMonitoringMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteUserBikeCustomMonitoringMutation, Types.DeleteUserBikeCustomMonitoringMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteUserBikeCustomMonitoringMutation, Types.DeleteUserBikeCustomMonitoringMutationVariables>(DeleteUserBikeCustomMonitoringDocument, options);
      }
export type DeleteUserBikeCustomMonitoringMutationHookResult = ReturnType<typeof useDeleteUserBikeCustomMonitoringMutation>;
export type DeleteUserBikeCustomMonitoringMutationResult = Apollo.MutationResult<Types.DeleteUserBikeCustomMonitoringMutation>;
export type DeleteUserBikeCustomMonitoringMutationOptions = Apollo.BaseMutationOptions<Types.DeleteUserBikeCustomMonitoringMutation, Types.DeleteUserBikeCustomMonitoringMutationVariables>;
export const DetailCompanyWorkshopBankAccountAnticipationDocument = gql`
    query DetailCompanyWorkshopBankAccountAnticipation($data: DetailCompanyWorkshopBankAccountAnticipationInput!) {
  detailCompanyWorkshopBankAccountAnticipation(data: $data) {
    request_date
    id
    anticipation_days
    fee
    net_value
    due_date
    value
    total_value
    status
    payment
    anticipation_payment {
      origin_payment {
        origin_payments_translation {
          name
        }
      }
      user_workshop_subscription_plan_payment {
        user_workshop_subscription_plan {
          user {
            id
            name
          }
          workshop_subscription_plan {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useDetailCompanyWorkshopBankAccountAnticipationQuery__
 *
 * To run a query within a React component, call `useDetailCompanyWorkshopBankAccountAnticipationQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetailCompanyWorkshopBankAccountAnticipationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetailCompanyWorkshopBankAccountAnticipationQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDetailCompanyWorkshopBankAccountAnticipationQuery(baseOptions: Apollo.QueryHookOptions<Types.DetailCompanyWorkshopBankAccountAnticipationQuery, Types.DetailCompanyWorkshopBankAccountAnticipationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DetailCompanyWorkshopBankAccountAnticipationQuery, Types.DetailCompanyWorkshopBankAccountAnticipationQueryVariables>(DetailCompanyWorkshopBankAccountAnticipationDocument, options);
      }
export function useDetailCompanyWorkshopBankAccountAnticipationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DetailCompanyWorkshopBankAccountAnticipationQuery, Types.DetailCompanyWorkshopBankAccountAnticipationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DetailCompanyWorkshopBankAccountAnticipationQuery, Types.DetailCompanyWorkshopBankAccountAnticipationQueryVariables>(DetailCompanyWorkshopBankAccountAnticipationDocument, options);
        }
export type DetailCompanyWorkshopBankAccountAnticipationQueryHookResult = ReturnType<typeof useDetailCompanyWorkshopBankAccountAnticipationQuery>;
export type DetailCompanyWorkshopBankAccountAnticipationLazyQueryHookResult = ReturnType<typeof useDetailCompanyWorkshopBankAccountAnticipationLazyQuery>;
export type DetailCompanyWorkshopBankAccountAnticipationQueryResult = Apollo.QueryResult<Types.DetailCompanyWorkshopBankAccountAnticipationQuery, Types.DetailCompanyWorkshopBankAccountAnticipationQueryVariables>;
export const DetailContractedWorkshopPlanDocument = gql`
    query DetailContractedWorkshopPlan($id: String!) {
  detailContractedWorkshopPlan(id: $id) {
    id
    finished
    created_at
    contract_date
    current
    billing_day
    billing_mail
    payment_value
    workshop_plan_id
    payment_frequency_in_months
    contract_plan_time
    total_contract_value
    contract_plan_time
    custom_plan
    digital_acceptance
    payment_method {
      name
      slug
    }
    contracting_user_object {
      id
      name
      profile {
        profile_avatar
      }
    }
    contracted_workshop_plan_terms {
      term_of_workshop {
        id
        published
        publication_date
        html_content
        version
      }
      user {
        id
        name
        profile {
          profile_avatar
        }
      }
      ip_address
      device
      browser
      user_id
      created_at
    }
    contracted_workshop_plan_payments {
      contracted_workshop_plan_id
      id
      invoice_link
      payment_id
      payment {
        id
        bill_expiration_date
        bill_link
        created_at
        processed
        status
        value
        origin_payment {
          name
          slug
          origin_payments_translation {
            name
          }
        }
      }
    }
    workshop_plan {
      active
      id
      name
      translations {
        annual_contract_value
        annual_plan_monthly_fee
        month_plan_monthly_fee
        language_code
        name
        workshop_plan_id
      }
    }
  }
}
    `;

/**
 * __useDetailContractedWorkshopPlanQuery__
 *
 * To run a query within a React component, call `useDetailContractedWorkshopPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetailContractedWorkshopPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetailContractedWorkshopPlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDetailContractedWorkshopPlanQuery(baseOptions: Apollo.QueryHookOptions<Types.DetailContractedWorkshopPlanQuery, Types.DetailContractedWorkshopPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DetailContractedWorkshopPlanQuery, Types.DetailContractedWorkshopPlanQueryVariables>(DetailContractedWorkshopPlanDocument, options);
      }
export function useDetailContractedWorkshopPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DetailContractedWorkshopPlanQuery, Types.DetailContractedWorkshopPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DetailContractedWorkshopPlanQuery, Types.DetailContractedWorkshopPlanQueryVariables>(DetailContractedWorkshopPlanDocument, options);
        }
export type DetailContractedWorkshopPlanQueryHookResult = ReturnType<typeof useDetailContractedWorkshopPlanQuery>;
export type DetailContractedWorkshopPlanLazyQueryHookResult = ReturnType<typeof useDetailContractedWorkshopPlanLazyQuery>;
export type DetailContractedWorkshopPlanQueryResult = Apollo.QueryResult<Types.DetailContractedWorkshopPlanQuery, Types.DetailContractedWorkshopPlanQueryVariables>;
export const DetailWorkshopClientDocument = gql`
    query DetailWorkshopClient($id: String!) {
  detailWorkshopClient(id: $id) {
    id
    address_id
    firstname
    lastname
    email
    ddi_phone
    phone_number
    gender
    date_of_birth
    document_type
    legal_registry_number
    user {
      id
      name
      email
      ddi
      phone
      team_name
      stature
      weight
      total_spent
      activities {
        id
        name
        distance
        total_elevation_gain
        start_date
        third_party_data_source_slug
        url_original_file
      }
      profile {
        profile_avatar
        official
        username
        description
      }
      city {
        name
        state {
          name
          abbreviation
        }
      }
      user_third_party_data_source {
        third_party_data_source {
          name
          slug
        }
      }
    }
    user_bikes {
      id
      name
      created_at
      bike {
        model_name
        brand_name
        bike_type {
          avatar
          name
        }
      }
      user_bike_workshops {
        company_workshop_id
        total_amount_spent
      }
    }
    address {
      zip_code
      street_address
      street_number
      complement
      neighborhood
      reference_point
      city_address
      state_address
    }
  }
}
    `;

/**
 * __useDetailWorkshopClientQuery__
 *
 * To run a query within a React component, call `useDetailWorkshopClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetailWorkshopClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetailWorkshopClientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDetailWorkshopClientQuery(baseOptions: Apollo.QueryHookOptions<Types.DetailWorkshopClientQuery, Types.DetailWorkshopClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DetailWorkshopClientQuery, Types.DetailWorkshopClientQueryVariables>(DetailWorkshopClientDocument, options);
      }
export function useDetailWorkshopClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DetailWorkshopClientQuery, Types.DetailWorkshopClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DetailWorkshopClientQuery, Types.DetailWorkshopClientQueryVariables>(DetailWorkshopClientDocument, options);
        }
export type DetailWorkshopClientQueryHookResult = ReturnType<typeof useDetailWorkshopClientQuery>;
export type DetailWorkshopClientLazyQueryHookResult = ReturnType<typeof useDetailWorkshopClientLazyQuery>;
export type DetailWorkshopClientQueryResult = Apollo.QueryResult<Types.DetailWorkshopClientQuery, Types.DetailWorkshopClientQueryVariables>;
export const EnableOrDisableCustomWorkshopMonitoringsDocument = gql`
    mutation EnableOrDisableCustomWorkshopMonitorings($id: String!) {
  enableOrDisableCustomWorkshopMonitorings(id: $id)
}
    `;
export type EnableOrDisableCustomWorkshopMonitoringsMutationFn = Apollo.MutationFunction<Types.EnableOrDisableCustomWorkshopMonitoringsMutation, Types.EnableOrDisableCustomWorkshopMonitoringsMutationVariables>;

/**
 * __useEnableOrDisableCustomWorkshopMonitoringsMutation__
 *
 * To run a mutation, you first call `useEnableOrDisableCustomWorkshopMonitoringsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableOrDisableCustomWorkshopMonitoringsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableOrDisableCustomWorkshopMonitoringsMutation, { data, loading, error }] = useEnableOrDisableCustomWorkshopMonitoringsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnableOrDisableCustomWorkshopMonitoringsMutation(baseOptions?: Apollo.MutationHookOptions<Types.EnableOrDisableCustomWorkshopMonitoringsMutation, Types.EnableOrDisableCustomWorkshopMonitoringsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EnableOrDisableCustomWorkshopMonitoringsMutation, Types.EnableOrDisableCustomWorkshopMonitoringsMutationVariables>(EnableOrDisableCustomWorkshopMonitoringsDocument, options);
      }
export type EnableOrDisableCustomWorkshopMonitoringsMutationHookResult = ReturnType<typeof useEnableOrDisableCustomWorkshopMonitoringsMutation>;
export type EnableOrDisableCustomWorkshopMonitoringsMutationResult = Apollo.MutationResult<Types.EnableOrDisableCustomWorkshopMonitoringsMutation>;
export type EnableOrDisableCustomWorkshopMonitoringsMutationOptions = Apollo.BaseMutationOptions<Types.EnableOrDisableCustomWorkshopMonitoringsMutation, Types.EnableOrDisableCustomWorkshopMonitoringsMutationVariables>;
export const GetCompanyWorkshopBankAccountAnticipationLimitsDocument = gql`
    query getCompanyWorkshopBankAccountAnticipationLimits($company_workshop_id: String!) {
  getCompanyWorkshopBankAccountAnticipationLimits(
    company_workshop_id: $company_workshop_id
  ) {
    credit_card_anticipation_limit {
      available
      total
    }
  }
}
    `;

/**
 * __useGetCompanyWorkshopBankAccountAnticipationLimitsQuery__
 *
 * To run a query within a React component, call `useGetCompanyWorkshopBankAccountAnticipationLimitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyWorkshopBankAccountAnticipationLimitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyWorkshopBankAccountAnticipationLimitsQuery({
 *   variables: {
 *      company_workshop_id: // value for 'company_workshop_id'
 *   },
 * });
 */
export function useGetCompanyWorkshopBankAccountAnticipationLimitsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCompanyWorkshopBankAccountAnticipationLimitsQuery, Types.GetCompanyWorkshopBankAccountAnticipationLimitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCompanyWorkshopBankAccountAnticipationLimitsQuery, Types.GetCompanyWorkshopBankAccountAnticipationLimitsQueryVariables>(GetCompanyWorkshopBankAccountAnticipationLimitsDocument, options);
      }
export function useGetCompanyWorkshopBankAccountAnticipationLimitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCompanyWorkshopBankAccountAnticipationLimitsQuery, Types.GetCompanyWorkshopBankAccountAnticipationLimitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCompanyWorkshopBankAccountAnticipationLimitsQuery, Types.GetCompanyWorkshopBankAccountAnticipationLimitsQueryVariables>(GetCompanyWorkshopBankAccountAnticipationLimitsDocument, options);
        }
export type GetCompanyWorkshopBankAccountAnticipationLimitsQueryHookResult = ReturnType<typeof useGetCompanyWorkshopBankAccountAnticipationLimitsQuery>;
export type GetCompanyWorkshopBankAccountAnticipationLimitsLazyQueryHookResult = ReturnType<typeof useGetCompanyWorkshopBankAccountAnticipationLimitsLazyQuery>;
export type GetCompanyWorkshopBankAccountAnticipationLimitsQueryResult = Apollo.QueryResult<Types.GetCompanyWorkshopBankAccountAnticipationLimitsQuery, Types.GetCompanyWorkshopBankAccountAnticipationLimitsQueryVariables>;
export const GetCompanyWorkshopExternalBankAccountDetailDocument = gql`
    query GetCompanyWorkshopExternalBankAccountDetail($data: GetCompanyWorkshopExternalBankAccountDetailInput!) {
  getCompanyWorkshopExternalBankAccountDetail(data: $data) {
    account_digit
    account_number
    active
    agency_number
    bank {
      active
      compe_brazil
      country_id
      created_at
      id
      ispb_brazil
      name_long
      name_short
      swift_code
    }
    bank_id
    brazilian_pix_key_registered
    company_workshop_id
    creator {
      id
      name
    }
    creator_id
    id
    main
    name
    validated
  }
}
    `;

/**
 * __useGetCompanyWorkshopExternalBankAccountDetailQuery__
 *
 * To run a query within a React component, call `useGetCompanyWorkshopExternalBankAccountDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyWorkshopExternalBankAccountDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyWorkshopExternalBankAccountDetailQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetCompanyWorkshopExternalBankAccountDetailQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCompanyWorkshopExternalBankAccountDetailQuery, Types.GetCompanyWorkshopExternalBankAccountDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCompanyWorkshopExternalBankAccountDetailQuery, Types.GetCompanyWorkshopExternalBankAccountDetailQueryVariables>(GetCompanyWorkshopExternalBankAccountDetailDocument, options);
      }
export function useGetCompanyWorkshopExternalBankAccountDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCompanyWorkshopExternalBankAccountDetailQuery, Types.GetCompanyWorkshopExternalBankAccountDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCompanyWorkshopExternalBankAccountDetailQuery, Types.GetCompanyWorkshopExternalBankAccountDetailQueryVariables>(GetCompanyWorkshopExternalBankAccountDetailDocument, options);
        }
export type GetCompanyWorkshopExternalBankAccountDetailQueryHookResult = ReturnType<typeof useGetCompanyWorkshopExternalBankAccountDetailQuery>;
export type GetCompanyWorkshopExternalBankAccountDetailLazyQueryHookResult = ReturnType<typeof useGetCompanyWorkshopExternalBankAccountDetailLazyQuery>;
export type GetCompanyWorkshopExternalBankAccountDetailQueryResult = Apollo.QueryResult<Types.GetCompanyWorkshopExternalBankAccountDetailQuery, Types.GetCompanyWorkshopExternalBankAccountDetailQueryVariables>;
export const GetDueDateChangeProportionalValuesDocument = gql`
    query GetDueDateChangeProportionalValues($data: GetDueDateChangeProportionalValuesInput!) {
  getDueDateChangeProportionalValues(data: $data) {
    days_to_be_paid
    fixed_due_date
    value_to_be_paid
  }
}
    `;

/**
 * __useGetDueDateChangeProportionalValuesQuery__
 *
 * To run a query within a React component, call `useGetDueDateChangeProportionalValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDueDateChangeProportionalValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDueDateChangeProportionalValuesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetDueDateChangeProportionalValuesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetDueDateChangeProportionalValuesQuery, Types.GetDueDateChangeProportionalValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetDueDateChangeProportionalValuesQuery, Types.GetDueDateChangeProportionalValuesQueryVariables>(GetDueDateChangeProportionalValuesDocument, options);
      }
export function useGetDueDateChangeProportionalValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetDueDateChangeProportionalValuesQuery, Types.GetDueDateChangeProportionalValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetDueDateChangeProportionalValuesQuery, Types.GetDueDateChangeProportionalValuesQueryVariables>(GetDueDateChangeProportionalValuesDocument, options);
        }
export type GetDueDateChangeProportionalValuesQueryHookResult = ReturnType<typeof useGetDueDateChangeProportionalValuesQuery>;
export type GetDueDateChangeProportionalValuesLazyQueryHookResult = ReturnType<typeof useGetDueDateChangeProportionalValuesLazyQuery>;
export type GetDueDateChangeProportionalValuesQueryResult = Apollo.QueryResult<Types.GetDueDateChangeProportionalValuesQuery, Types.GetDueDateChangeProportionalValuesQueryVariables>;
export const GetUserActivitiesV2Document = gql`
    query GetUserActivitiesV2($user_id: String!, $pagination: PaginationInput!) {
  getUserActivitiesV2(user_id: $user_id, pagination: $pagination) {
    activities {
      id
      name
      distance
      total_elevation_gain
      start_date
      third_party_data_source_slug
      url_original_file
      user_bike_activities {
        user_bike {
          name
          bike {
            bike_type {
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetUserActivitiesV2Query__
 *
 * To run a query within a React component, call `useGetUserActivitiesV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetUserActivitiesV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserActivitiesV2Query({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetUserActivitiesV2Query(baseOptions: Apollo.QueryHookOptions<Types.GetUserActivitiesV2Query, Types.GetUserActivitiesV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserActivitiesV2Query, Types.GetUserActivitiesV2QueryVariables>(GetUserActivitiesV2Document, options);
      }
export function useGetUserActivitiesV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserActivitiesV2Query, Types.GetUserActivitiesV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserActivitiesV2Query, Types.GetUserActivitiesV2QueryVariables>(GetUserActivitiesV2Document, options);
        }
export type GetUserActivitiesV2QueryHookResult = ReturnType<typeof useGetUserActivitiesV2Query>;
export type GetUserActivitiesV2LazyQueryHookResult = ReturnType<typeof useGetUserActivitiesV2LazyQuery>;
export type GetUserActivitiesV2QueryResult = Apollo.QueryResult<Types.GetUserActivitiesV2Query, Types.GetUserActivitiesV2QueryVariables>;
export const GetUserWorkshopPlanSubscriptionDetailDocument = gql`
    query GetUserWorkshopPlanSubscriptionDetail($data: GetUserWorkshopPlanSubscriptionDetailInput!) {
  getUserWorkshopPlanSubscriptionDetail(data: $data) {
    active
    billing_day
    canceled_by_workshop
    cancellation_comment
    cancellation_request_date
    cancellation_reversed_by_user
    cancellation_schedule_date
    contract_cancellation_period
    created_at
    current
    fidelity
    id
    origin_payment_id
    origin_payment {
      description
      name
      id
      origin_payments_translation {
        name
      }
    }
    other_reason_cancellation
    payment_value
    reason_for_cancellation_plan_id
    subscription_asaas_id
    user_card_id
    user_id
    workshop_subscription_plan_id
    user {
      id
      name
      email
      ddi
      phone
      team_name
      stature
      weight
      total_spent
      profile {
        profile_avatar
        official
        username
        description
      }
      city {
        name
        state {
          abbreviation
        }
      }
    }
    workshop_subscription_plan {
      fidelity
      id
      name
      price
    }
    user_workshop_subscription_plan_advantage {
      id
      advantage
      details
      unlimited
      units
      renewal_period
      type_period
      subscription_plan_advantage_period {
        initial_period
        end_period
        units
        unlimited
        type_period
        user_workshop_subscription_plan_advantage_register {
          delivery_date
          extra
          id
        }
      }
    }
    user_workshop_subscription_plan_payment {
      payment_id
      created_at
      payment {
        status
        value
        origin_payment {
          description
          name
          id
          origin_payments_translation {
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetUserWorkshopPlanSubscriptionDetailQuery__
 *
 * To run a query within a React component, call `useGetUserWorkshopPlanSubscriptionDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserWorkshopPlanSubscriptionDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserWorkshopPlanSubscriptionDetailQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetUserWorkshopPlanSubscriptionDetailQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUserWorkshopPlanSubscriptionDetailQuery, Types.GetUserWorkshopPlanSubscriptionDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserWorkshopPlanSubscriptionDetailQuery, Types.GetUserWorkshopPlanSubscriptionDetailQueryVariables>(GetUserWorkshopPlanSubscriptionDetailDocument, options);
      }
export function useGetUserWorkshopPlanSubscriptionDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserWorkshopPlanSubscriptionDetailQuery, Types.GetUserWorkshopPlanSubscriptionDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserWorkshopPlanSubscriptionDetailQuery, Types.GetUserWorkshopPlanSubscriptionDetailQueryVariables>(GetUserWorkshopPlanSubscriptionDetailDocument, options);
        }
export type GetUserWorkshopPlanSubscriptionDetailQueryHookResult = ReturnType<typeof useGetUserWorkshopPlanSubscriptionDetailQuery>;
export type GetUserWorkshopPlanSubscriptionDetailLazyQueryHookResult = ReturnType<typeof useGetUserWorkshopPlanSubscriptionDetailLazyQuery>;
export type GetUserWorkshopPlanSubscriptionDetailQueryResult = Apollo.QueryResult<Types.GetUserWorkshopPlanSubscriptionDetailQuery, Types.GetUserWorkshopPlanSubscriptionDetailQueryVariables>;
export const GetWorkshopBankAccountFinancialInfosDocument = gql`
    query GetWorkshopBankAccountFinancialInfos($company_workshop_id: String!) {
  getWorkshopBankAccountFinancialInfos(company_workshop_id: $company_workshop_id) {
    balance
    total_subscribers
    expected_for_next_month
    balance_receivable {
      payments_amount
      payments_net_value
      payments_total_value
    }
    received_in_past_thirty_days {
      payments_amount
      payments_net_value
      payments_total_value
    }
  }
}
    `;

/**
 * __useGetWorkshopBankAccountFinancialInfosQuery__
 *
 * To run a query within a React component, call `useGetWorkshopBankAccountFinancialInfosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkshopBankAccountFinancialInfosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkshopBankAccountFinancialInfosQuery({
 *   variables: {
 *      company_workshop_id: // value for 'company_workshop_id'
 *   },
 * });
 */
export function useGetWorkshopBankAccountFinancialInfosQuery(baseOptions: Apollo.QueryHookOptions<Types.GetWorkshopBankAccountFinancialInfosQuery, Types.GetWorkshopBankAccountFinancialInfosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetWorkshopBankAccountFinancialInfosQuery, Types.GetWorkshopBankAccountFinancialInfosQueryVariables>(GetWorkshopBankAccountFinancialInfosDocument, options);
      }
export function useGetWorkshopBankAccountFinancialInfosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetWorkshopBankAccountFinancialInfosQuery, Types.GetWorkshopBankAccountFinancialInfosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetWorkshopBankAccountFinancialInfosQuery, Types.GetWorkshopBankAccountFinancialInfosQueryVariables>(GetWorkshopBankAccountFinancialInfosDocument, options);
        }
export type GetWorkshopBankAccountFinancialInfosQueryHookResult = ReturnType<typeof useGetWorkshopBankAccountFinancialInfosQuery>;
export type GetWorkshopBankAccountFinancialInfosLazyQueryHookResult = ReturnType<typeof useGetWorkshopBankAccountFinancialInfosLazyQuery>;
export type GetWorkshopBankAccountFinancialInfosQueryResult = Apollo.QueryResult<Types.GetWorkshopBankAccountFinancialInfosQuery, Types.GetWorkshopBankAccountFinancialInfosQueryVariables>;
export const GetWorkshopIntegrationDepositDocument = gql`
    query GetWorkshopIntegrationDeposit($data: SearchWorkshopIntegrationDepositInput!) {
  getWorkshopIntegrationDeposit(data: $data) {
    external_id
    name
  }
}
    `;

/**
 * __useGetWorkshopIntegrationDepositQuery__
 *
 * To run a query within a React component, call `useGetWorkshopIntegrationDepositQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkshopIntegrationDepositQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkshopIntegrationDepositQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetWorkshopIntegrationDepositQuery(baseOptions: Apollo.QueryHookOptions<Types.GetWorkshopIntegrationDepositQuery, Types.GetWorkshopIntegrationDepositQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetWorkshopIntegrationDepositQuery, Types.GetWorkshopIntegrationDepositQueryVariables>(GetWorkshopIntegrationDepositDocument, options);
      }
export function useGetWorkshopIntegrationDepositLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetWorkshopIntegrationDepositQuery, Types.GetWorkshopIntegrationDepositQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetWorkshopIntegrationDepositQuery, Types.GetWorkshopIntegrationDepositQueryVariables>(GetWorkshopIntegrationDepositDocument, options);
        }
export type GetWorkshopIntegrationDepositQueryHookResult = ReturnType<typeof useGetWorkshopIntegrationDepositQuery>;
export type GetWorkshopIntegrationDepositLazyQueryHookResult = ReturnType<typeof useGetWorkshopIntegrationDepositLazyQuery>;
export type GetWorkshopIntegrationDepositQueryResult = Apollo.QueryResult<Types.GetWorkshopIntegrationDepositQuery, Types.GetWorkshopIntegrationDepositQueryVariables>;
export const ListAllOwnStandardBikesSetupsDocument = gql`
    query ListAllOwnStandardBikesSetups($data: ListAllStandardBikesSetupsInput!) {
  listAllOwnStandardBikesSetups(data: $data) {
    id
    active
    color_main
    created_at
    frame_material
    frame_size
    frame_year
    icon_color
    name
    weight
    wheel_size
    bike_type {
      active
      avatar
      description
      eletric
      name
      slug
    }
    standard_bike_setup_gears {
      altimetry_goal
      brand
      date_goal
      distance_goal
      days_goal
      model
      seconds_goal
      gear_configurations {
        gear_type_configuration {
          id
        }
      }
      gear_type {
        active
        alternation
        avatar
        description
        highlighted
        name
        slug
        gear_type_configuration {
          id
          name
          free_field
        }
      }
    }
  }
}
    `;

/**
 * __useListAllOwnStandardBikesSetupsQuery__
 *
 * To run a query within a React component, call `useListAllOwnStandardBikesSetupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllOwnStandardBikesSetupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllOwnStandardBikesSetupsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useListAllOwnStandardBikesSetupsQuery(baseOptions: Apollo.QueryHookOptions<Types.ListAllOwnStandardBikesSetupsQuery, Types.ListAllOwnStandardBikesSetupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListAllOwnStandardBikesSetupsQuery, Types.ListAllOwnStandardBikesSetupsQueryVariables>(ListAllOwnStandardBikesSetupsDocument, options);
      }
export function useListAllOwnStandardBikesSetupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListAllOwnStandardBikesSetupsQuery, Types.ListAllOwnStandardBikesSetupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListAllOwnStandardBikesSetupsQuery, Types.ListAllOwnStandardBikesSetupsQueryVariables>(ListAllOwnStandardBikesSetupsDocument, options);
        }
export type ListAllOwnStandardBikesSetupsQueryHookResult = ReturnType<typeof useListAllOwnStandardBikesSetupsQuery>;
export type ListAllOwnStandardBikesSetupsLazyQueryHookResult = ReturnType<typeof useListAllOwnStandardBikesSetupsLazyQuery>;
export type ListAllOwnStandardBikesSetupsQueryResult = Apollo.QueryResult<Types.ListAllOwnStandardBikesSetupsQuery, Types.ListAllOwnStandardBikesSetupsQueryVariables>;
export const ListBikesWithoutUserOfAWorkshopDocument = gql`
    query ListBikesWithoutUserOfAWorkshop($company_workshop_id: String!) {
  listBikesWithoutUserOfAWorkshop(company_workshop_id: $company_workshop_id) {
    active
    id
    name
    bike_id
    created_at
    name
    description
    user {
      name
      profile {
        profile_avatar
      }
    }
    bike {
      id
      color_main
      model_name
      brand_name
      frame_material
      frame_size
      weight
      wheel_size
      year
      bike_gears {
        fixed_on_top
        addition_date
        gear_type_slug
        model
        brand
        creator_id
        bike_gear_monitoring {
          altimetry_goal
          old_monitoring {
            id
          }
          date_goal
          distance_goal
          initial_date
          seconds_goal
        }
        gear_type {
          slug
          name
          description
          avatar
        }
      }
      total_distance
      bike_type_slug
      bike_type {
        name
        slug
        avatar
      }
      repairs {
        date
        cost
      }
    }
    user_bike_workshops {
      last_service
      total_amount_spent
    }
  }
}
    `;

/**
 * __useListBikesWithoutUserOfAWorkshopQuery__
 *
 * To run a query within a React component, call `useListBikesWithoutUserOfAWorkshopQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBikesWithoutUserOfAWorkshopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBikesWithoutUserOfAWorkshopQuery({
 *   variables: {
 *      company_workshop_id: // value for 'company_workshop_id'
 *   },
 * });
 */
export function useListBikesWithoutUserOfAWorkshopQuery(baseOptions: Apollo.QueryHookOptions<Types.ListBikesWithoutUserOfAWorkshopQuery, Types.ListBikesWithoutUserOfAWorkshopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListBikesWithoutUserOfAWorkshopQuery, Types.ListBikesWithoutUserOfAWorkshopQueryVariables>(ListBikesWithoutUserOfAWorkshopDocument, options);
      }
export function useListBikesWithoutUserOfAWorkshopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListBikesWithoutUserOfAWorkshopQuery, Types.ListBikesWithoutUserOfAWorkshopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListBikesWithoutUserOfAWorkshopQuery, Types.ListBikesWithoutUserOfAWorkshopQueryVariables>(ListBikesWithoutUserOfAWorkshopDocument, options);
        }
export type ListBikesWithoutUserOfAWorkshopQueryHookResult = ReturnType<typeof useListBikesWithoutUserOfAWorkshopQuery>;
export type ListBikesWithoutUserOfAWorkshopLazyQueryHookResult = ReturnType<typeof useListBikesWithoutUserOfAWorkshopLazyQuery>;
export type ListBikesWithoutUserOfAWorkshopQueryResult = Apollo.QueryResult<Types.ListBikesWithoutUserOfAWorkshopQuery, Types.ListBikesWithoutUserOfAWorkshopQueryVariables>;
export const ListCompanyWorkshopBankAccountAnticipationsDocument = gql`
    query ListCompanyWorkshopBankAccountAnticipations($pagination: PaginationInput!, $data: ListCompanyWorkshopBankAccountAnticipationsInput!) {
  listCompanyWorkshopBankAccountAnticipations(
    pagination: $pagination
    data: $data
  ) {
    data {
      request_date
      id
      anticipation_days
      fee
      net_value
      due_date
      status
      payment
    }
    limit
    object
    offset
    total_count
  }
}
    `;

/**
 * __useListCompanyWorkshopBankAccountAnticipationsQuery__
 *
 * To run a query within a React component, call `useListCompanyWorkshopBankAccountAnticipationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompanyWorkshopBankAccountAnticipationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompanyWorkshopBankAccountAnticipationsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useListCompanyWorkshopBankAccountAnticipationsQuery(baseOptions: Apollo.QueryHookOptions<Types.ListCompanyWorkshopBankAccountAnticipationsQuery, Types.ListCompanyWorkshopBankAccountAnticipationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListCompanyWorkshopBankAccountAnticipationsQuery, Types.ListCompanyWorkshopBankAccountAnticipationsQueryVariables>(ListCompanyWorkshopBankAccountAnticipationsDocument, options);
      }
export function useListCompanyWorkshopBankAccountAnticipationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListCompanyWorkshopBankAccountAnticipationsQuery, Types.ListCompanyWorkshopBankAccountAnticipationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListCompanyWorkshopBankAccountAnticipationsQuery, Types.ListCompanyWorkshopBankAccountAnticipationsQueryVariables>(ListCompanyWorkshopBankAccountAnticipationsDocument, options);
        }
export type ListCompanyWorkshopBankAccountAnticipationsQueryHookResult = ReturnType<typeof useListCompanyWorkshopBankAccountAnticipationsQuery>;
export type ListCompanyWorkshopBankAccountAnticipationsLazyQueryHookResult = ReturnType<typeof useListCompanyWorkshopBankAccountAnticipationsLazyQuery>;
export type ListCompanyWorkshopBankAccountAnticipationsQueryResult = Apollo.QueryResult<Types.ListCompanyWorkshopBankAccountAnticipationsQuery, Types.ListCompanyWorkshopBankAccountAnticipationsQueryVariables>;
export const ListCompanyWorkshopExternalBankAccountsDocument = gql`
    query ListCompanyWorkshopExternalBankAccounts($data: ListCompanyWorkshopExternalBankAccountsInput!) {
  listCompanyWorkshopExternalBankAccounts(data: $data) {
    account_digit
    account_number
    active
    agency_number
    bank {
      active
      compe_brazil
      country_id
      created_at
      id
      ispb_brazil
      name_long
      name_short
      swift_code
    }
    bank_id
    brazilian_pix_key_registered
    company_workshop_id
    creator {
      id
      name
    }
    creator_id
    id
    main
    name
    validated
  }
}
    `;

/**
 * __useListCompanyWorkshopExternalBankAccountsQuery__
 *
 * To run a query within a React component, call `useListCompanyWorkshopExternalBankAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompanyWorkshopExternalBankAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompanyWorkshopExternalBankAccountsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useListCompanyWorkshopExternalBankAccountsQuery(baseOptions: Apollo.QueryHookOptions<Types.ListCompanyWorkshopExternalBankAccountsQuery, Types.ListCompanyWorkshopExternalBankAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListCompanyWorkshopExternalBankAccountsQuery, Types.ListCompanyWorkshopExternalBankAccountsQueryVariables>(ListCompanyWorkshopExternalBankAccountsDocument, options);
      }
export function useListCompanyWorkshopExternalBankAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListCompanyWorkshopExternalBankAccountsQuery, Types.ListCompanyWorkshopExternalBankAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListCompanyWorkshopExternalBankAccountsQuery, Types.ListCompanyWorkshopExternalBankAccountsQueryVariables>(ListCompanyWorkshopExternalBankAccountsDocument, options);
        }
export type ListCompanyWorkshopExternalBankAccountsQueryHookResult = ReturnType<typeof useListCompanyWorkshopExternalBankAccountsQuery>;
export type ListCompanyWorkshopExternalBankAccountsLazyQueryHookResult = ReturnType<typeof useListCompanyWorkshopExternalBankAccountsLazyQuery>;
export type ListCompanyWorkshopExternalBankAccountsQueryResult = Apollo.QueryResult<Types.ListCompanyWorkshopExternalBankAccountsQuery, Types.ListCompanyWorkshopExternalBankAccountsQueryVariables>;
export const ListCompanyWorkshopFinancialTransactionsDocument = gql`
    query ListCompanyWorkshopFinancialTransactions($data: ListCompanyWorkshopFinancialTransactionsInput!) {
  listCompanyWorkshopFinancialTransactions(data: $data) {
    data {
      value
      description
      balance
      transfer_id
      date
      id
    }
    has_more
    limit
    object
    offset
    total_count
  }
}
    `;

/**
 * __useListCompanyWorkshopFinancialTransactionsQuery__
 *
 * To run a query within a React component, call `useListCompanyWorkshopFinancialTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompanyWorkshopFinancialTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompanyWorkshopFinancialTransactionsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useListCompanyWorkshopFinancialTransactionsQuery(baseOptions: Apollo.QueryHookOptions<Types.ListCompanyWorkshopFinancialTransactionsQuery, Types.ListCompanyWorkshopFinancialTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListCompanyWorkshopFinancialTransactionsQuery, Types.ListCompanyWorkshopFinancialTransactionsQueryVariables>(ListCompanyWorkshopFinancialTransactionsDocument, options);
      }
export function useListCompanyWorkshopFinancialTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListCompanyWorkshopFinancialTransactionsQuery, Types.ListCompanyWorkshopFinancialTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListCompanyWorkshopFinancialTransactionsQuery, Types.ListCompanyWorkshopFinancialTransactionsQueryVariables>(ListCompanyWorkshopFinancialTransactionsDocument, options);
        }
export type ListCompanyWorkshopFinancialTransactionsQueryHookResult = ReturnType<typeof useListCompanyWorkshopFinancialTransactionsQuery>;
export type ListCompanyWorkshopFinancialTransactionsLazyQueryHookResult = ReturnType<typeof useListCompanyWorkshopFinancialTransactionsLazyQuery>;
export type ListCompanyWorkshopFinancialTransactionsQueryResult = Apollo.QueryResult<Types.ListCompanyWorkshopFinancialTransactionsQuery, Types.ListCompanyWorkshopFinancialTransactionsQueryVariables>;
export const ListCompanyWorkshopPhotosDocument = gql`
    query listCompanyWorkshopPhotos($company_workshop_id: String!) {
  listCompanyWorkshopPhotos(company_workshop_id: $company_workshop_id) {
    active
    id
    link
    order
    updated_at
  }
}
    `;

/**
 * __useListCompanyWorkshopPhotosQuery__
 *
 * To run a query within a React component, call `useListCompanyWorkshopPhotosQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompanyWorkshopPhotosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompanyWorkshopPhotosQuery({
 *   variables: {
 *      company_workshop_id: // value for 'company_workshop_id'
 *   },
 * });
 */
export function useListCompanyWorkshopPhotosQuery(baseOptions: Apollo.QueryHookOptions<Types.ListCompanyWorkshopPhotosQuery, Types.ListCompanyWorkshopPhotosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListCompanyWorkshopPhotosQuery, Types.ListCompanyWorkshopPhotosQueryVariables>(ListCompanyWorkshopPhotosDocument, options);
      }
export function useListCompanyWorkshopPhotosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListCompanyWorkshopPhotosQuery, Types.ListCompanyWorkshopPhotosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListCompanyWorkshopPhotosQuery, Types.ListCompanyWorkshopPhotosQueryVariables>(ListCompanyWorkshopPhotosDocument, options);
        }
export type ListCompanyWorkshopPhotosQueryHookResult = ReturnType<typeof useListCompanyWorkshopPhotosQuery>;
export type ListCompanyWorkshopPhotosLazyQueryHookResult = ReturnType<typeof useListCompanyWorkshopPhotosLazyQuery>;
export type ListCompanyWorkshopPhotosQueryResult = Apollo.QueryResult<Types.ListCompanyWorkshopPhotosQuery, Types.ListCompanyWorkshopPhotosQueryVariables>;
export const ListCompanyWorkshopRepairsDocument = gql`
    query ListCompanyWorkshopRepairs($pagination: PaginationInput!, $data: ListCompanyWorkshopRepairsInput!) {
  listCompanyWorkshopRepairs(pagination: $pagination, data: $data) {
    page_info {
      current_page
      has_next_page
      has_previous_page
      offset
      total_item_count
    }
    counts_by_status {
      count
      repair_status_slug
    }
    repairs {
      id
      cost
      date
      description
      internal_description
      link_avatar_client
      accepted_repair_information
      informed_by_the_workshop
      company_workshop_id
      initial_date
      end_date
      order_number
      client_name
      repair_status_slug
      general_discount
      mechanic_name
      order_number
      payment_condition
      general_discount
      integration_seller_id
      integration_seller_name
      workshop_receipt_method {
        name
        id
      }
      repair_attached_files {
        id
        link
      }
      repair_item_integration_products {
        amount
        external_id
        external_reference
        name
        type
        unit_cost
      }
      bike {
        id
        brand_name
        model_name
        weight
        wheel_size
        color_main
        frame_size
        frame_material
        created_at
        year
        bike_type_slug
        bike_type {
          name
          avatar
        }
        bike_model {
          id
          name
          image_main
          bike_manufacturer {
            id
            image_logo
            name
          }
        }
      }
      repair_items {
        id
        amount
        cost
        cost_without_discount
        discount_percentage
        repair_type_slug
        bike_gear {
          id
          gear_type {
            avatar
            name
          }
        }
        workshop_service {
          id
          name
        }
      }
      workshop_user {
        name
        profile {
          profile_avatar
        }
      }
      user_bike {
        name
        user {
          name
          profile {
            profile_avatar
          }
        }
        workshop_client {
          id
          firstname
          lastname
        }
      }
      gears_json {
        add_bike_gear {
          amount
          cost
          cost_without_discount
          discount_percentage
          gear_type {
            avatar
            slug
            name
          }
        }
        repair_items {
          amount
          cost
          cost_without_discount
          discount_percentage
          workshop_service_id
          workshop_service {
            name
          }
          gear_type {
            avatar
            slug
            name
            alternation
          }
        }
      }
    }
  }
}
    `;

/**
 * __useListCompanyWorkshopRepairsQuery__
 *
 * To run a query within a React component, call `useListCompanyWorkshopRepairsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompanyWorkshopRepairsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompanyWorkshopRepairsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useListCompanyWorkshopRepairsQuery(baseOptions: Apollo.QueryHookOptions<Types.ListCompanyWorkshopRepairsQuery, Types.ListCompanyWorkshopRepairsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListCompanyWorkshopRepairsQuery, Types.ListCompanyWorkshopRepairsQueryVariables>(ListCompanyWorkshopRepairsDocument, options);
      }
export function useListCompanyWorkshopRepairsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListCompanyWorkshopRepairsQuery, Types.ListCompanyWorkshopRepairsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListCompanyWorkshopRepairsQuery, Types.ListCompanyWorkshopRepairsQueryVariables>(ListCompanyWorkshopRepairsDocument, options);
        }
export type ListCompanyWorkshopRepairsQueryHookResult = ReturnType<typeof useListCompanyWorkshopRepairsQuery>;
export type ListCompanyWorkshopRepairsLazyQueryHookResult = ReturnType<typeof useListCompanyWorkshopRepairsLazyQuery>;
export type ListCompanyWorkshopRepairsQueryResult = Apollo.QueryResult<Types.ListCompanyWorkshopRepairsQuery, Types.ListCompanyWorkshopRepairsQueryVariables>;
export const ListCouponLimitTypesDocument = gql`
    query ListCouponLimitTypes {
  listCouponLimitTypes {
    id
    slug
    description
    active
    created_at
    updated_at
    coupon_limit_type_translations {
      language_code
      coupon_limit_type_id
      description
      created_at
      updated_at
    }
  }
}
    `;

/**
 * __useListCouponLimitTypesQuery__
 *
 * To run a query within a React component, call `useListCouponLimitTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCouponLimitTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCouponLimitTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListCouponLimitTypesQuery(baseOptions?: Apollo.QueryHookOptions<Types.ListCouponLimitTypesQuery, Types.ListCouponLimitTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListCouponLimitTypesQuery, Types.ListCouponLimitTypesQueryVariables>(ListCouponLimitTypesDocument, options);
      }
export function useListCouponLimitTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListCouponLimitTypesQuery, Types.ListCouponLimitTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListCouponLimitTypesQuery, Types.ListCouponLimitTypesQueryVariables>(ListCouponLimitTypesDocument, options);
        }
export type ListCouponLimitTypesQueryHookResult = ReturnType<typeof useListCouponLimitTypesQuery>;
export type ListCouponLimitTypesLazyQueryHookResult = ReturnType<typeof useListCouponLimitTypesLazyQuery>;
export type ListCouponLimitTypesQueryResult = Apollo.QueryResult<Types.ListCouponLimitTypesQuery, Types.ListCouponLimitTypesQueryVariables>;
export const ListCustomWorkshopMonitoringsDocument = gql`
    query ListCustomWorkshopMonitorings($pagination: PaginationInput, $data: ListCustomWorkshopMonitoringInput!) {
  listCustomWorkshopMonitorings(data: $data, pagination: $pagination) {
    company_workshop_id
    created_at
    enable
    id
    name
    quick_inclusion
    restart_new_order
    custom_workshop_monitoring_alerts {
      altimetry_goal
      created_at
      custom_workshop_monitoring_id
      date_goal
      days_goal
      distance_goal
      id
      note
      seconds_goal
      updated_at
    }
  }
}
    `;

/**
 * __useListCustomWorkshopMonitoringsQuery__
 *
 * To run a query within a React component, call `useListCustomWorkshopMonitoringsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCustomWorkshopMonitoringsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCustomWorkshopMonitoringsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useListCustomWorkshopMonitoringsQuery(baseOptions: Apollo.QueryHookOptions<Types.ListCustomWorkshopMonitoringsQuery, Types.ListCustomWorkshopMonitoringsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListCustomWorkshopMonitoringsQuery, Types.ListCustomWorkshopMonitoringsQueryVariables>(ListCustomWorkshopMonitoringsDocument, options);
      }
export function useListCustomWorkshopMonitoringsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListCustomWorkshopMonitoringsQuery, Types.ListCustomWorkshopMonitoringsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListCustomWorkshopMonitoringsQuery, Types.ListCustomWorkshopMonitoringsQueryVariables>(ListCustomWorkshopMonitoringsDocument, options);
        }
export type ListCustomWorkshopMonitoringsQueryHookResult = ReturnType<typeof useListCustomWorkshopMonitoringsQuery>;
export type ListCustomWorkshopMonitoringsLazyQueryHookResult = ReturnType<typeof useListCustomWorkshopMonitoringsLazyQuery>;
export type ListCustomWorkshopMonitoringsQueryResult = Apollo.QueryResult<Types.ListCustomWorkshopMonitoringsQuery, Types.ListCustomWorkshopMonitoringsQueryVariables>;
export const ListIntegrationsDocument = gql`
    query ListIntegrations($data: ListIntegrationsInput!) {
  listIntegrations(data: $data) {
    able_for_workshops
    active
    available_settings {
      connects_with {
        form_field
        instructions
        label
      }
      functionalities {
        available
        description
        function
      }
      sync_stock
    }
    created_at
    description
    image_logo
    integration_type {
      active
      description
      name
      slug
    }
    integration_type_slug
    last_update
    name
    oauth_integration
    slug
    updated_at
  }
}
    `;

/**
 * __useListIntegrationsQuery__
 *
 * To run a query within a React component, call `useListIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListIntegrationsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useListIntegrationsQuery(baseOptions: Apollo.QueryHookOptions<Types.ListIntegrationsQuery, Types.ListIntegrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListIntegrationsQuery, Types.ListIntegrationsQueryVariables>(ListIntegrationsDocument, options);
      }
export function useListIntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListIntegrationsQuery, Types.ListIntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListIntegrationsQuery, Types.ListIntegrationsQueryVariables>(ListIntegrationsDocument, options);
        }
export type ListIntegrationsQueryHookResult = ReturnType<typeof useListIntegrationsQuery>;
export type ListIntegrationsLazyQueryHookResult = ReturnType<typeof useListIntegrationsLazyQuery>;
export type ListIntegrationsQueryResult = Apollo.QueryResult<Types.ListIntegrationsQuery, Types.ListIntegrationsQueryVariables>;
export const ListIntegrationsFromCompanyWorkshopDocument = gql`
    query ListIntegrationsFromCompanyWorkshop($data: ListIntegrationsFromCompanyWorkshopInput!) {
  listIntegrationsFromCompanyWorkshop(data: $data) {
    company_workshop {
      id
      name
    }
    company_workshop_id
    created_at
    completed
    integration {
      able_for_workshops
      active
      available_settings {
        sync_stock
        functionalities {
          available
          description
          function
        }
        connects_with {
          form_field
          instructions
          label
        }
      }
      created_at
      description
      image_logo
      integration_type {
        active
        description
        name
        slug
      }
      integration_type_slug
      last_update
      name
      oauth_integration
      slug
      updated_at
    }
    integration_slug
    settings {
      deposit {
        deposit_external_id
        deposit_name
      }
      sale_order {
        time_to_create_sale_order
        bank_account_external_account_agency
        bank_account_external_account_number
        bank_account_external_id
        bank_account_external_name
        sale_order_for_service
        time_to_finish_sale_order
        time_to_post_sale_order_bills
        time_to_post_sale_order_stock
      }
    }
    updated_at
    user
  }
}
    `;

/**
 * __useListIntegrationsFromCompanyWorkshopQuery__
 *
 * To run a query within a React component, call `useListIntegrationsFromCompanyWorkshopQuery` and pass it any options that fit your needs.
 * When your component renders, `useListIntegrationsFromCompanyWorkshopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListIntegrationsFromCompanyWorkshopQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useListIntegrationsFromCompanyWorkshopQuery(baseOptions: Apollo.QueryHookOptions<Types.ListIntegrationsFromCompanyWorkshopQuery, Types.ListIntegrationsFromCompanyWorkshopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListIntegrationsFromCompanyWorkshopQuery, Types.ListIntegrationsFromCompanyWorkshopQueryVariables>(ListIntegrationsFromCompanyWorkshopDocument, options);
      }
export function useListIntegrationsFromCompanyWorkshopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListIntegrationsFromCompanyWorkshopQuery, Types.ListIntegrationsFromCompanyWorkshopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListIntegrationsFromCompanyWorkshopQuery, Types.ListIntegrationsFromCompanyWorkshopQueryVariables>(ListIntegrationsFromCompanyWorkshopDocument, options);
        }
export type ListIntegrationsFromCompanyWorkshopQueryHookResult = ReturnType<typeof useListIntegrationsFromCompanyWorkshopQuery>;
export type ListIntegrationsFromCompanyWorkshopLazyQueryHookResult = ReturnType<typeof useListIntegrationsFromCompanyWorkshopLazyQuery>;
export type ListIntegrationsFromCompanyWorkshopQueryResult = Apollo.QueryResult<Types.ListIntegrationsFromCompanyWorkshopQuery, Types.ListIntegrationsFromCompanyWorkshopQueryVariables>;
export const ListUserBikeCustomMonitoringsDocument = gql`
    query ListUserBikeCustomMonitorings($data: ListUserBikeCustomMonitoringsInput!) {
  listUserBikeCustomMonitorings(data: $data) {
    active
    company_workshop_id
    created_at
    custom_workshop_monitoring {
      enable
      id
      name
      quick_inclusion
      restart_new_order
    }
    custom_workshop_monitoring_id
    id
    removed_date
    total_usage_percentage
    updated_at
    user_bike_custom_monitoring_alerts {
      active
      actual_altimetry
      actual_days
      actual_distance
      actual_seconds
      altimetry_goal
      date_goal
      days_goal
      description
      distance_goal
      id
      initial_date
      percentage
      seconds_goal
      solved
    }
    user_bike_id
  }
}
    `;

/**
 * __useListUserBikeCustomMonitoringsQuery__
 *
 * To run a query within a React component, call `useListUserBikeCustomMonitoringsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserBikeCustomMonitoringsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserBikeCustomMonitoringsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useListUserBikeCustomMonitoringsQuery(baseOptions: Apollo.QueryHookOptions<Types.ListUserBikeCustomMonitoringsQuery, Types.ListUserBikeCustomMonitoringsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListUserBikeCustomMonitoringsQuery, Types.ListUserBikeCustomMonitoringsQueryVariables>(ListUserBikeCustomMonitoringsDocument, options);
      }
export function useListUserBikeCustomMonitoringsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListUserBikeCustomMonitoringsQuery, Types.ListUserBikeCustomMonitoringsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListUserBikeCustomMonitoringsQuery, Types.ListUserBikeCustomMonitoringsQueryVariables>(ListUserBikeCustomMonitoringsDocument, options);
        }
export type ListUserBikeCustomMonitoringsQueryHookResult = ReturnType<typeof useListUserBikeCustomMonitoringsQuery>;
export type ListUserBikeCustomMonitoringsLazyQueryHookResult = ReturnType<typeof useListUserBikeCustomMonitoringsLazyQuery>;
export type ListUserBikeCustomMonitoringsQueryResult = Apollo.QueryResult<Types.ListUserBikeCustomMonitoringsQuery, Types.ListUserBikeCustomMonitoringsQueryVariables>;
export const ListUserBikesGearsAssociatedToWorkshopDocument = gql`
    query ListUserBikesGearsAssociatedToWorkshop($filter: String, $data: WorkshopUserAssociationInput!) {
  listUserBikesGearsAssociatedToWorkshop(filter: $filter, data: $data) {
    id
    bike_id
    gear_type {
      avatar
      name
    }
    repair_items {
      repair_type {
        name
        slug
      }
      repair {
        date
        cost
      }
    }
  }
}
    `;

/**
 * __useListUserBikesGearsAssociatedToWorkshopQuery__
 *
 * To run a query within a React component, call `useListUserBikesGearsAssociatedToWorkshopQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserBikesGearsAssociatedToWorkshopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserBikesGearsAssociatedToWorkshopQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useListUserBikesGearsAssociatedToWorkshopQuery(baseOptions: Apollo.QueryHookOptions<Types.ListUserBikesGearsAssociatedToWorkshopQuery, Types.ListUserBikesGearsAssociatedToWorkshopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListUserBikesGearsAssociatedToWorkshopQuery, Types.ListUserBikesGearsAssociatedToWorkshopQueryVariables>(ListUserBikesGearsAssociatedToWorkshopDocument, options);
      }
export function useListUserBikesGearsAssociatedToWorkshopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListUserBikesGearsAssociatedToWorkshopQuery, Types.ListUserBikesGearsAssociatedToWorkshopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListUserBikesGearsAssociatedToWorkshopQuery, Types.ListUserBikesGearsAssociatedToWorkshopQueryVariables>(ListUserBikesGearsAssociatedToWorkshopDocument, options);
        }
export type ListUserBikesGearsAssociatedToWorkshopQueryHookResult = ReturnType<typeof useListUserBikesGearsAssociatedToWorkshopQuery>;
export type ListUserBikesGearsAssociatedToWorkshopLazyQueryHookResult = ReturnType<typeof useListUserBikesGearsAssociatedToWorkshopLazyQuery>;
export type ListUserBikesGearsAssociatedToWorkshopQueryResult = Apollo.QueryResult<Types.ListUserBikesGearsAssociatedToWorkshopQuery, Types.ListUserBikesGearsAssociatedToWorkshopQueryVariables>;
export const ListUserWorkshopSubscriptionPlanAdvantageRegistersByMonthDocument = gql`
    query ListUserWorkshopSubscriptionPlanAdvantageRegistersByMonth($pagination: PaginationInput!, $data: ListUserWorkshopSubscriptionPlanAdvantagesRegistersInput!) {
  listUserWorkshopSubscriptionPlanAdvantageRegistersByMonth(
    pagination: $pagination
    data: $data
  ) {
    pagination_info {
      current_page
      has_next_page
      has_previous_page
      offset
      total_item_count
    }
    user_workshop_subscription_plan_advantage_registers {
      created_at
      delivery_date
      extra
      id
      marked_by_user
      note
      subscription_plans_advantages_period_id
      updated_at
      user_workshop_subscription_plans_advantage_id
      subscription_plans_advantages_period {
        end_period
        id
        initial_period
        unlimited
      }
      user_workshop_subscription_plans_advantage {
        advantage
        advantage_percentage
        created_at
        details
        id
        renewal_period
        type_period
        units
        unlimited
        updated_at
        user_workshop_subscription_plan_id
      }
    }
  }
}
    `;

/**
 * __useListUserWorkshopSubscriptionPlanAdvantageRegistersByMonthQuery__
 *
 * To run a query within a React component, call `useListUserWorkshopSubscriptionPlanAdvantageRegistersByMonthQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserWorkshopSubscriptionPlanAdvantageRegistersByMonthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserWorkshopSubscriptionPlanAdvantageRegistersByMonthQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useListUserWorkshopSubscriptionPlanAdvantageRegistersByMonthQuery(baseOptions: Apollo.QueryHookOptions<Types.ListUserWorkshopSubscriptionPlanAdvantageRegistersByMonthQuery, Types.ListUserWorkshopSubscriptionPlanAdvantageRegistersByMonthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListUserWorkshopSubscriptionPlanAdvantageRegistersByMonthQuery, Types.ListUserWorkshopSubscriptionPlanAdvantageRegistersByMonthQueryVariables>(ListUserWorkshopSubscriptionPlanAdvantageRegistersByMonthDocument, options);
      }
export function useListUserWorkshopSubscriptionPlanAdvantageRegistersByMonthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListUserWorkshopSubscriptionPlanAdvantageRegistersByMonthQuery, Types.ListUserWorkshopSubscriptionPlanAdvantageRegistersByMonthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListUserWorkshopSubscriptionPlanAdvantageRegistersByMonthQuery, Types.ListUserWorkshopSubscriptionPlanAdvantageRegistersByMonthQueryVariables>(ListUserWorkshopSubscriptionPlanAdvantageRegistersByMonthDocument, options);
        }
export type ListUserWorkshopSubscriptionPlanAdvantageRegistersByMonthQueryHookResult = ReturnType<typeof useListUserWorkshopSubscriptionPlanAdvantageRegistersByMonthQuery>;
export type ListUserWorkshopSubscriptionPlanAdvantageRegistersByMonthLazyQueryHookResult = ReturnType<typeof useListUserWorkshopSubscriptionPlanAdvantageRegistersByMonthLazyQuery>;
export type ListUserWorkshopSubscriptionPlanAdvantageRegistersByMonthQueryResult = Apollo.QueryResult<Types.ListUserWorkshopSubscriptionPlanAdvantageRegistersByMonthQuery, Types.ListUserWorkshopSubscriptionPlanAdvantageRegistersByMonthQueryVariables>;
export const ListUserWorkshopSubscriptionPlanAdvantageRegistersHistoryDocument = gql`
    query ListUserWorkshopSubscriptionPlanAdvantageRegistersHistory($data: ListUserWorkshopSubscriptionPlanAdvantagesRegistersHistoryInput!) {
  listUserWorkshopSubscriptionPlanAdvantageRegistersHistory(data: $data) {
    total_usage
    usage_history {
      months {
        month
        month_number
        usage
      }
      year
    }
  }
}
    `;

/**
 * __useListUserWorkshopSubscriptionPlanAdvantageRegistersHistoryQuery__
 *
 * To run a query within a React component, call `useListUserWorkshopSubscriptionPlanAdvantageRegistersHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserWorkshopSubscriptionPlanAdvantageRegistersHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserWorkshopSubscriptionPlanAdvantageRegistersHistoryQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useListUserWorkshopSubscriptionPlanAdvantageRegistersHistoryQuery(baseOptions: Apollo.QueryHookOptions<Types.ListUserWorkshopSubscriptionPlanAdvantageRegistersHistoryQuery, Types.ListUserWorkshopSubscriptionPlanAdvantageRegistersHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListUserWorkshopSubscriptionPlanAdvantageRegistersHistoryQuery, Types.ListUserWorkshopSubscriptionPlanAdvantageRegistersHistoryQueryVariables>(ListUserWorkshopSubscriptionPlanAdvantageRegistersHistoryDocument, options);
      }
export function useListUserWorkshopSubscriptionPlanAdvantageRegistersHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListUserWorkshopSubscriptionPlanAdvantageRegistersHistoryQuery, Types.ListUserWorkshopSubscriptionPlanAdvantageRegistersHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListUserWorkshopSubscriptionPlanAdvantageRegistersHistoryQuery, Types.ListUserWorkshopSubscriptionPlanAdvantageRegistersHistoryQueryVariables>(ListUserWorkshopSubscriptionPlanAdvantageRegistersHistoryDocument, options);
        }
export type ListUserWorkshopSubscriptionPlanAdvantageRegistersHistoryQueryHookResult = ReturnType<typeof useListUserWorkshopSubscriptionPlanAdvantageRegistersHistoryQuery>;
export type ListUserWorkshopSubscriptionPlanAdvantageRegistersHistoryLazyQueryHookResult = ReturnType<typeof useListUserWorkshopSubscriptionPlanAdvantageRegistersHistoryLazyQuery>;
export type ListUserWorkshopSubscriptionPlanAdvantageRegistersHistoryQueryResult = Apollo.QueryResult<Types.ListUserWorkshopSubscriptionPlanAdvantageRegistersHistoryQuery, Types.ListUserWorkshopSubscriptionPlanAdvantageRegistersHistoryQueryVariables>;
export const ListUserWorkshopSubscriptionPlansDocument = gql`
    query ListUserWorkshopSubscriptionPlans($data: ListUserWorkshopSubscriptionPlansInput!) {
  listUserWorkshopSubscriptionPlans(data: $data) {
    id
    workshop_subscription_plan_id
    workshop_subscription_plan {
      id
      name
      company_workshop_id
    }
  }
}
    `;

/**
 * __useListUserWorkshopSubscriptionPlansQuery__
 *
 * To run a query within a React component, call `useListUserWorkshopSubscriptionPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserWorkshopSubscriptionPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserWorkshopSubscriptionPlansQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useListUserWorkshopSubscriptionPlansQuery(baseOptions: Apollo.QueryHookOptions<Types.ListUserWorkshopSubscriptionPlansQuery, Types.ListUserWorkshopSubscriptionPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListUserWorkshopSubscriptionPlansQuery, Types.ListUserWorkshopSubscriptionPlansQueryVariables>(ListUserWorkshopSubscriptionPlansDocument, options);
      }
export function useListUserWorkshopSubscriptionPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListUserWorkshopSubscriptionPlansQuery, Types.ListUserWorkshopSubscriptionPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListUserWorkshopSubscriptionPlansQuery, Types.ListUserWorkshopSubscriptionPlansQueryVariables>(ListUserWorkshopSubscriptionPlansDocument, options);
        }
export type ListUserWorkshopSubscriptionPlansQueryHookResult = ReturnType<typeof useListUserWorkshopSubscriptionPlansQuery>;
export type ListUserWorkshopSubscriptionPlansLazyQueryHookResult = ReturnType<typeof useListUserWorkshopSubscriptionPlansLazyQuery>;
export type ListUserWorkshopSubscriptionPlansQueryResult = Apollo.QueryResult<Types.ListUserWorkshopSubscriptionPlansQuery, Types.ListUserWorkshopSubscriptionPlansQueryVariables>;
export const ListUserWorkshopsDocument = gql`
    query ListUserWorkshops {
  listUserWorkshops {
    id
    address_line_one
    latitude
    longitude
    rating
    name
    postal_code
    location_city
    location_state
    location_country
    address_line_two
    image_main
    company_id
    created_at
    ddi
    phone_number
    whatsapp_number
    mail_address
    country_id
    contracted_plan
    plan_subscription_activated
    company_workshop_stamps {
      workshop_stamp {
        id
        name
        link_image
        updated_at
        created_at
      }
    }
    contracted_workshop_plan {
      id
      finished
      created_at
      current
      billing_day
      billing_mail
      workshop_plan_id
      custom_plan
      contract_plan_time
      payment_value
      payment_method {
        name
        slug
      }
      workshop_plan {
        name
        translations {
          name
          language_code
          month_plan_monthly_fee
          annual_contract_value
          annual_plan_monthly_fee
        }
      }
    }
    company {
      fantasy_name
      id
      profile {
        profile_avatar
      }
    }
    user_bike_workshops {
      user_bike {
        id
        name
        bike {
          model_name
          brand_name
          bike_type_slug
          bike_type {
            name
            avatar
          }
        }
        user {
          name
          profile {
            profile_avatar
          }
        }
      }
    }
  }
}
    `;

/**
 * __useListUserWorkshopsQuery__
 *
 * To run a query within a React component, call `useListUserWorkshopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserWorkshopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserWorkshopsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListUserWorkshopsQuery(baseOptions?: Apollo.QueryHookOptions<Types.ListUserWorkshopsQuery, Types.ListUserWorkshopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListUserWorkshopsQuery, Types.ListUserWorkshopsQueryVariables>(ListUserWorkshopsDocument, options);
      }
export function useListUserWorkshopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListUserWorkshopsQuery, Types.ListUserWorkshopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListUserWorkshopsQuery, Types.ListUserWorkshopsQueryVariables>(ListUserWorkshopsDocument, options);
        }
export type ListUserWorkshopsQueryHookResult = ReturnType<typeof useListUserWorkshopsQuery>;
export type ListUserWorkshopsLazyQueryHookResult = ReturnType<typeof useListUserWorkshopsLazyQuery>;
export type ListUserWorkshopsQueryResult = Apollo.QueryResult<Types.ListUserWorkshopsQuery, Types.ListUserWorkshopsQueryVariables>;
export const ListWorkshopBankWithdrawalsDocument = gql`
    query listWorkshopBankWithdrawals($company_workshop_id: String!) {
  listWorkshopBankWithdrawals(company_workshop_id: $company_workshop_id) {
    completed
    completion_date
    created_at
    status
    method
    id
    net_value
  }
}
    `;

/**
 * __useListWorkshopBankWithdrawalsQuery__
 *
 * To run a query within a React component, call `useListWorkshopBankWithdrawalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWorkshopBankWithdrawalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWorkshopBankWithdrawalsQuery({
 *   variables: {
 *      company_workshop_id: // value for 'company_workshop_id'
 *   },
 * });
 */
export function useListWorkshopBankWithdrawalsQuery(baseOptions: Apollo.QueryHookOptions<Types.ListWorkshopBankWithdrawalsQuery, Types.ListWorkshopBankWithdrawalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListWorkshopBankWithdrawalsQuery, Types.ListWorkshopBankWithdrawalsQueryVariables>(ListWorkshopBankWithdrawalsDocument, options);
      }
export function useListWorkshopBankWithdrawalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListWorkshopBankWithdrawalsQuery, Types.ListWorkshopBankWithdrawalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListWorkshopBankWithdrawalsQuery, Types.ListWorkshopBankWithdrawalsQueryVariables>(ListWorkshopBankWithdrawalsDocument, options);
        }
export type ListWorkshopBankWithdrawalsQueryHookResult = ReturnType<typeof useListWorkshopBankWithdrawalsQuery>;
export type ListWorkshopBankWithdrawalsLazyQueryHookResult = ReturnType<typeof useListWorkshopBankWithdrawalsLazyQuery>;
export type ListWorkshopBankWithdrawalsQueryResult = Apollo.QueryResult<Types.ListWorkshopBankWithdrawalsQuery, Types.ListWorkshopBankWithdrawalsQueryVariables>;
export const ListWorkshopDocumentsDocument = gql`
    query ListWorkshopDocuments($company_workshop_id: String!) {
  listWorkshopDocuments(company_workshop_id: $company_workshop_id) {
    account_status
    reject_reason
    document_list {
      description
      link
      status
      title
      type
    }
  }
}
    `;

/**
 * __useListWorkshopDocumentsQuery__
 *
 * To run a query within a React component, call `useListWorkshopDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWorkshopDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWorkshopDocumentsQuery({
 *   variables: {
 *      company_workshop_id: // value for 'company_workshop_id'
 *   },
 * });
 */
export function useListWorkshopDocumentsQuery(baseOptions: Apollo.QueryHookOptions<Types.ListWorkshopDocumentsQuery, Types.ListWorkshopDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListWorkshopDocumentsQuery, Types.ListWorkshopDocumentsQueryVariables>(ListWorkshopDocumentsDocument, options);
      }
export function useListWorkshopDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListWorkshopDocumentsQuery, Types.ListWorkshopDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListWorkshopDocumentsQuery, Types.ListWorkshopDocumentsQueryVariables>(ListWorkshopDocumentsDocument, options);
        }
export type ListWorkshopDocumentsQueryHookResult = ReturnType<typeof useListWorkshopDocumentsQuery>;
export type ListWorkshopDocumentsLazyQueryHookResult = ReturnType<typeof useListWorkshopDocumentsLazyQuery>;
export type ListWorkshopDocumentsQueryResult = Apollo.QueryResult<Types.ListWorkshopDocumentsQuery, Types.ListWorkshopDocumentsQueryVariables>;
export const ListWorkshopMutedUserBikesDocument = gql`
    query listWorkshopMutedUserBikes($pagination_data: PaginationInput, $data: ListMutedWorkshopUserBikesInput!) {
  listWorkshopMutedUserBikes(pagination_data: $pagination_data, data: $data) {
    muted_user_bikes {
      company_workshop_id
      created_at
      silent_days_gears_near_to_repair
      silent_days_most_delayed_gears_repair
      silent_days_most_delayed_gears_repairs_with_user
      unmute_date_gears_near_to_repair
      unmute_date_most_delayed_gears_repair
      unmute_date_most_delayed_gears_repairs_with_user
      updated_at
      user_bike_id
      user_bike {
        id
        bike_id
        name
        purchase_date
        description
        user {
          id
          name
          profile {
            profile_avatar
          }
        }
        bike {
          id
          brand_name
          model_name
          bike_type_slug
          bike_type {
            name
            avatar
          }
        }
      }
    }
    pagination_info {
      current_page
      has_next_page
      has_previous_page
      offset
      total_item_count
    }
    workshop_opportunity_key
  }
}
    `;

/**
 * __useListWorkshopMutedUserBikesQuery__
 *
 * To run a query within a React component, call `useListWorkshopMutedUserBikesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWorkshopMutedUserBikesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWorkshopMutedUserBikesQuery({
 *   variables: {
 *      pagination_data: // value for 'pagination_data'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useListWorkshopMutedUserBikesQuery(baseOptions: Apollo.QueryHookOptions<Types.ListWorkshopMutedUserBikesQuery, Types.ListWorkshopMutedUserBikesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListWorkshopMutedUserBikesQuery, Types.ListWorkshopMutedUserBikesQueryVariables>(ListWorkshopMutedUserBikesDocument, options);
      }
export function useListWorkshopMutedUserBikesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListWorkshopMutedUserBikesQuery, Types.ListWorkshopMutedUserBikesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListWorkshopMutedUserBikesQuery, Types.ListWorkshopMutedUserBikesQueryVariables>(ListWorkshopMutedUserBikesDocument, options);
        }
export type ListWorkshopMutedUserBikesQueryHookResult = ReturnType<typeof useListWorkshopMutedUserBikesQuery>;
export type ListWorkshopMutedUserBikesLazyQueryHookResult = ReturnType<typeof useListWorkshopMutedUserBikesLazyQuery>;
export type ListWorkshopMutedUserBikesQueryResult = Apollo.QueryResult<Types.ListWorkshopMutedUserBikesQuery, Types.ListWorkshopMutedUserBikesQueryVariables>;
export const ListWorkshopPlansDocument = gql`
    query ListWorkshopPlans {
  listWorkshopPlans {
    active
    id
    name
    translations {
      annual_contract_value
      annual_plan_monthly_fee
      semester_plan_monthly_fee
      quarterly_plan_monthly_fee
      month_plan_monthly_fee
      language_code
      name
      workshop_plan_id
    }
  }
}
    `;

/**
 * __useListWorkshopPlansQuery__
 *
 * To run a query within a React component, call `useListWorkshopPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWorkshopPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWorkshopPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useListWorkshopPlansQuery(baseOptions?: Apollo.QueryHookOptions<Types.ListWorkshopPlansQuery, Types.ListWorkshopPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListWorkshopPlansQuery, Types.ListWorkshopPlansQueryVariables>(ListWorkshopPlansDocument, options);
      }
export function useListWorkshopPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListWorkshopPlansQuery, Types.ListWorkshopPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListWorkshopPlansQuery, Types.ListWorkshopPlansQueryVariables>(ListWorkshopPlansDocument, options);
        }
export type ListWorkshopPlansQueryHookResult = ReturnType<typeof useListWorkshopPlansQuery>;
export type ListWorkshopPlansLazyQueryHookResult = ReturnType<typeof useListWorkshopPlansLazyQuery>;
export type ListWorkshopPlansQueryResult = Apollo.QueryResult<Types.ListWorkshopPlansQuery, Types.ListWorkshopPlansQueryVariables>;
export const ListWorkshopReceiptMethodsDocument = gql`
    query ListWorkshopReceiptMethods($data: ListWorkshopReceiptMethodsInput!) {
  listWorkshopReceiptMethods(data: $data) {
    active
    company_workshop_id
    created_at
    enable
    name
    id
    updated_at
  }
}
    `;

/**
 * __useListWorkshopReceiptMethodsQuery__
 *
 * To run a query within a React component, call `useListWorkshopReceiptMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWorkshopReceiptMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWorkshopReceiptMethodsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useListWorkshopReceiptMethodsQuery(baseOptions: Apollo.QueryHookOptions<Types.ListWorkshopReceiptMethodsQuery, Types.ListWorkshopReceiptMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListWorkshopReceiptMethodsQuery, Types.ListWorkshopReceiptMethodsQueryVariables>(ListWorkshopReceiptMethodsDocument, options);
      }
export function useListWorkshopReceiptMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListWorkshopReceiptMethodsQuery, Types.ListWorkshopReceiptMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListWorkshopReceiptMethodsQuery, Types.ListWorkshopReceiptMethodsQueryVariables>(ListWorkshopReceiptMethodsDocument, options);
        }
export type ListWorkshopReceiptMethodsQueryHookResult = ReturnType<typeof useListWorkshopReceiptMethodsQuery>;
export type ListWorkshopReceiptMethodsLazyQueryHookResult = ReturnType<typeof useListWorkshopReceiptMethodsLazyQuery>;
export type ListWorkshopReceiptMethodsQueryResult = Apollo.QueryResult<Types.ListWorkshopReceiptMethodsQuery, Types.ListWorkshopReceiptMethodsQueryVariables>;
export const ListWorkshopSubscriptionPlansDocument = gql`
    query ListWorkshopSubscriptionPlans($data: ListWorkshopSubscriptionPlansInput!) {
  listWorkshopSubscriptionPlans(data: $data) {
    id
    selling
    name
    price
    fidelity
    subscriptions_amount
    order
    price_outside_plan
    is_private
    workshop_subscription_plan_advantages {
      id
      advantage
      details
      order
      renewal_period
      type_period
      units
      unlimited
    }
    company_workshop {
      id
      name
      image_main
    }
  }
}
    `;

/**
 * __useListWorkshopSubscriptionPlansQuery__
 *
 * To run a query within a React component, call `useListWorkshopSubscriptionPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWorkshopSubscriptionPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWorkshopSubscriptionPlansQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useListWorkshopSubscriptionPlansQuery(baseOptions: Apollo.QueryHookOptions<Types.ListWorkshopSubscriptionPlansQuery, Types.ListWorkshopSubscriptionPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListWorkshopSubscriptionPlansQuery, Types.ListWorkshopSubscriptionPlansQueryVariables>(ListWorkshopSubscriptionPlansDocument, options);
      }
export function useListWorkshopSubscriptionPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListWorkshopSubscriptionPlansQuery, Types.ListWorkshopSubscriptionPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListWorkshopSubscriptionPlansQuery, Types.ListWorkshopSubscriptionPlansQueryVariables>(ListWorkshopSubscriptionPlansDocument, options);
        }
export type ListWorkshopSubscriptionPlansQueryHookResult = ReturnType<typeof useListWorkshopSubscriptionPlansQuery>;
export type ListWorkshopSubscriptionPlansLazyQueryHookResult = ReturnType<typeof useListWorkshopSubscriptionPlansLazyQuery>;
export type ListWorkshopSubscriptionPlansQueryResult = Apollo.QueryResult<Types.ListWorkshopSubscriptionPlansQuery, Types.ListWorkshopSubscriptionPlansQueryVariables>;
export const ListWorkshopTermsDocument = gql`
    query ListWorkshopTerms($published_only: Boolean) {
  listWorkshopTerms(published_only: $published_only) {
    id
    version
    html_content
    published
    publication_date
    active
    creator_id
    created_at
    publisher_id
    creator {
      name
    }
    publisher {
      name
    }
  }
}
    `;

/**
 * __useListWorkshopTermsQuery__
 *
 * To run a query within a React component, call `useListWorkshopTermsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWorkshopTermsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWorkshopTermsQuery({
 *   variables: {
 *      published_only: // value for 'published_only'
 *   },
 * });
 */
export function useListWorkshopTermsQuery(baseOptions?: Apollo.QueryHookOptions<Types.ListWorkshopTermsQuery, Types.ListWorkshopTermsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListWorkshopTermsQuery, Types.ListWorkshopTermsQueryVariables>(ListWorkshopTermsDocument, options);
      }
export function useListWorkshopTermsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListWorkshopTermsQuery, Types.ListWorkshopTermsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListWorkshopTermsQuery, Types.ListWorkshopTermsQueryVariables>(ListWorkshopTermsDocument, options);
        }
export type ListWorkshopTermsQueryHookResult = ReturnType<typeof useListWorkshopTermsQuery>;
export type ListWorkshopTermsLazyQueryHookResult = ReturnType<typeof useListWorkshopTermsLazyQuery>;
export type ListWorkshopTermsQueryResult = Apollo.QueryResult<Types.ListWorkshopTermsQuery, Types.ListWorkshopTermsQueryVariables>;
export const SearchBankAccountInCompanyWorkshopIntegrationStockDocument = gql`
    query SearchBankAccountInCompanyWorkshopIntegrationStock($data: SearchBankAccountInCompanyWorkshopIntegrationStockInput!) {
  searchBankAccountInCompanyWorkshopIntegrationStock(data: $data) {
    account_agency
    account_number
    external_id
    name
  }
}
    `;

/**
 * __useSearchBankAccountInCompanyWorkshopIntegrationStockQuery__
 *
 * To run a query within a React component, call `useSearchBankAccountInCompanyWorkshopIntegrationStockQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchBankAccountInCompanyWorkshopIntegrationStockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBankAccountInCompanyWorkshopIntegrationStockQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSearchBankAccountInCompanyWorkshopIntegrationStockQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchBankAccountInCompanyWorkshopIntegrationStockQuery, Types.SearchBankAccountInCompanyWorkshopIntegrationStockQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchBankAccountInCompanyWorkshopIntegrationStockQuery, Types.SearchBankAccountInCompanyWorkshopIntegrationStockQueryVariables>(SearchBankAccountInCompanyWorkshopIntegrationStockDocument, options);
      }
export function useSearchBankAccountInCompanyWorkshopIntegrationStockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchBankAccountInCompanyWorkshopIntegrationStockQuery, Types.SearchBankAccountInCompanyWorkshopIntegrationStockQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchBankAccountInCompanyWorkshopIntegrationStockQuery, Types.SearchBankAccountInCompanyWorkshopIntegrationStockQueryVariables>(SearchBankAccountInCompanyWorkshopIntegrationStockDocument, options);
        }
export type SearchBankAccountInCompanyWorkshopIntegrationStockQueryHookResult = ReturnType<typeof useSearchBankAccountInCompanyWorkshopIntegrationStockQuery>;
export type SearchBankAccountInCompanyWorkshopIntegrationStockLazyQueryHookResult = ReturnType<typeof useSearchBankAccountInCompanyWorkshopIntegrationStockLazyQuery>;
export type SearchBankAccountInCompanyWorkshopIntegrationStockQueryResult = Apollo.QueryResult<Types.SearchBankAccountInCompanyWorkshopIntegrationStockQuery, Types.SearchBankAccountInCompanyWorkshopIntegrationStockQueryVariables>;
export const SearchMultipleWorkshopIntegrationClientDocument = gql`
    query SearchMultipleWorkshopIntegrationClient($data: SearchWorkshopIntegrationClientInput!) {
  searchMultipleWorkshopIntegrationClient(data: $data) {
    city
    id
    integration_slug
    name
    state
    external_id
    legal_registry_number
    user {
      id
      name
      profile {
        profile_avatar
      }
    }
  }
}
    `;

/**
 * __useSearchMultipleWorkshopIntegrationClientQuery__
 *
 * To run a query within a React component, call `useSearchMultipleWorkshopIntegrationClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchMultipleWorkshopIntegrationClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchMultipleWorkshopIntegrationClientQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSearchMultipleWorkshopIntegrationClientQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchMultipleWorkshopIntegrationClientQuery, Types.SearchMultipleWorkshopIntegrationClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchMultipleWorkshopIntegrationClientQuery, Types.SearchMultipleWorkshopIntegrationClientQueryVariables>(SearchMultipleWorkshopIntegrationClientDocument, options);
      }
export function useSearchMultipleWorkshopIntegrationClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchMultipleWorkshopIntegrationClientQuery, Types.SearchMultipleWorkshopIntegrationClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchMultipleWorkshopIntegrationClientQuery, Types.SearchMultipleWorkshopIntegrationClientQueryVariables>(SearchMultipleWorkshopIntegrationClientDocument, options);
        }
export type SearchMultipleWorkshopIntegrationClientQueryHookResult = ReturnType<typeof useSearchMultipleWorkshopIntegrationClientQuery>;
export type SearchMultipleWorkshopIntegrationClientLazyQueryHookResult = ReturnType<typeof useSearchMultipleWorkshopIntegrationClientLazyQuery>;
export type SearchMultipleWorkshopIntegrationClientQueryResult = Apollo.QueryResult<Types.SearchMultipleWorkshopIntegrationClientQuery, Types.SearchMultipleWorkshopIntegrationClientQueryVariables>;
export const SearchWorkshopDiscountCouponsDocument = gql`
    query SearchWorkshopDiscountCoupons($pagination: PaginationInput!, $data: SearchWorkshopDiscountCouponsInput!) {
  searchWorkshopDiscountCoupons(pagination: $pagination, data: $data) {
    page_info {
      total_item_count
    }
    workshop_discount_coupons {
      active
      code
      company_workshop_id
      coupon_limit_type_id
      coupon_limit_type {
        active
        id
        slug
        coupon_limit_type_translations {
          description
          language_code
        }
      }
      discount_type {
        active
        description
        id
        slug
        discount_type_translations {
          description
          language_code
          discount_type_id
        }
      }
      coupon_subscription_plan_restrictions {
        workshop_discount_coupon_id
        workshop_subscription_plan_id
      }
      created_at
      id
      enable
      discounted_installments
      description
      discount_type_id
      maximum_uses
      minimum_purchase_amount
      total_uses
      valid_from
      valid_until
      value
    }
  }
}
    `;

/**
 * __useSearchWorkshopDiscountCouponsQuery__
 *
 * To run a query within a React component, call `useSearchWorkshopDiscountCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchWorkshopDiscountCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchWorkshopDiscountCouponsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSearchWorkshopDiscountCouponsQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchWorkshopDiscountCouponsQuery, Types.SearchWorkshopDiscountCouponsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchWorkshopDiscountCouponsQuery, Types.SearchWorkshopDiscountCouponsQueryVariables>(SearchWorkshopDiscountCouponsDocument, options);
      }
export function useSearchWorkshopDiscountCouponsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchWorkshopDiscountCouponsQuery, Types.SearchWorkshopDiscountCouponsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchWorkshopDiscountCouponsQuery, Types.SearchWorkshopDiscountCouponsQueryVariables>(SearchWorkshopDiscountCouponsDocument, options);
        }
export type SearchWorkshopDiscountCouponsQueryHookResult = ReturnType<typeof useSearchWorkshopDiscountCouponsQuery>;
export type SearchWorkshopDiscountCouponsLazyQueryHookResult = ReturnType<typeof useSearchWorkshopDiscountCouponsLazyQuery>;
export type SearchWorkshopDiscountCouponsQueryResult = Apollo.QueryResult<Types.SearchWorkshopDiscountCouponsQuery, Types.SearchWorkshopDiscountCouponsQueryVariables>;
export const SearchWorkshopServicesDocument = gql`
    query SearchWorkshopServices($data: SearchWorkshopServicesInput!) {
  searchWorkshopServices(data: $data) {
    active
    code
    commercial_unit
    company_workshop_id
    created_at
    deleted
    description
    enable
    id
    name
    price
    updated_at
  }
}
    `;

/**
 * __useSearchWorkshopServicesQuery__
 *
 * To run a query within a React component, call `useSearchWorkshopServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchWorkshopServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchWorkshopServicesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSearchWorkshopServicesQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchWorkshopServicesQuery, Types.SearchWorkshopServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchWorkshopServicesQuery, Types.SearchWorkshopServicesQueryVariables>(SearchWorkshopServicesDocument, options);
      }
export function useSearchWorkshopServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchWorkshopServicesQuery, Types.SearchWorkshopServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchWorkshopServicesQuery, Types.SearchWorkshopServicesQueryVariables>(SearchWorkshopServicesDocument, options);
        }
export type SearchWorkshopServicesQueryHookResult = ReturnType<typeof useSearchWorkshopServicesQuery>;
export type SearchWorkshopServicesLazyQueryHookResult = ReturnType<typeof useSearchWorkshopServicesLazyQuery>;
export type SearchWorkshopServicesQueryResult = Apollo.QueryResult<Types.SearchWorkshopServicesQuery, Types.SearchWorkshopServicesQueryVariables>;
export const SimulateWorkshopAntecipationDocument = gql`
    query simulateWorkshopAntecipation($company_workshop_id: String!) {
  simulateWorkshopAntecipation(company_workshop_id: $company_workshop_id) {
    asaas_payment_ids
    fee_value
    total_of_subscriptions
    total_value_of_subscriptions
    value_to_antecipate
    antecipation_value_available
    antecipation_value_of_account
  }
}
    `;

/**
 * __useSimulateWorkshopAntecipationQuery__
 *
 * To run a query within a React component, call `useSimulateWorkshopAntecipationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimulateWorkshopAntecipationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimulateWorkshopAntecipationQuery({
 *   variables: {
 *      company_workshop_id: // value for 'company_workshop_id'
 *   },
 * });
 */
export function useSimulateWorkshopAntecipationQuery(baseOptions: Apollo.QueryHookOptions<Types.SimulateWorkshopAntecipationQuery, Types.SimulateWorkshopAntecipationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SimulateWorkshopAntecipationQuery, Types.SimulateWorkshopAntecipationQueryVariables>(SimulateWorkshopAntecipationDocument, options);
      }
export function useSimulateWorkshopAntecipationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SimulateWorkshopAntecipationQuery, Types.SimulateWorkshopAntecipationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SimulateWorkshopAntecipationQuery, Types.SimulateWorkshopAntecipationQueryVariables>(SimulateWorkshopAntecipationDocument, options);
        }
export type SimulateWorkshopAntecipationQueryHookResult = ReturnType<typeof useSimulateWorkshopAntecipationQuery>;
export type SimulateWorkshopAntecipationLazyQueryHookResult = ReturnType<typeof useSimulateWorkshopAntecipationLazyQuery>;
export type SimulateWorkshopAntecipationQueryResult = Apollo.QueryResult<Types.SimulateWorkshopAntecipationQuery, Types.SimulateWorkshopAntecipationQueryVariables>;
export const UpdateCompanyWorkshopIntegrationSettingsDocument = gql`
    mutation UpdateCompanyWorkshopIntegrationSettings($data: UpdateCompanyWorkshopIntegrationSettingsInput!) {
  updateCompanyWorkshopIntegrationSettings(data: $data) {
    integration_slug
  }
}
    `;
export type UpdateCompanyWorkshopIntegrationSettingsMutationFn = Apollo.MutationFunction<Types.UpdateCompanyWorkshopIntegrationSettingsMutation, Types.UpdateCompanyWorkshopIntegrationSettingsMutationVariables>;

/**
 * __useUpdateCompanyWorkshopIntegrationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyWorkshopIntegrationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyWorkshopIntegrationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyWorkshopIntegrationSettingsMutation, { data, loading, error }] = useUpdateCompanyWorkshopIntegrationSettingsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCompanyWorkshopIntegrationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCompanyWorkshopIntegrationSettingsMutation, Types.UpdateCompanyWorkshopIntegrationSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCompanyWorkshopIntegrationSettingsMutation, Types.UpdateCompanyWorkshopIntegrationSettingsMutationVariables>(UpdateCompanyWorkshopIntegrationSettingsDocument, options);
      }
export type UpdateCompanyWorkshopIntegrationSettingsMutationHookResult = ReturnType<typeof useUpdateCompanyWorkshopIntegrationSettingsMutation>;
export type UpdateCompanyWorkshopIntegrationSettingsMutationResult = Apollo.MutationResult<Types.UpdateCompanyWorkshopIntegrationSettingsMutation>;
export type UpdateCompanyWorkshopIntegrationSettingsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCompanyWorkshopIntegrationSettingsMutation, Types.UpdateCompanyWorkshopIntegrationSettingsMutationVariables>;
export const UpdateCustomWorkshopMonitoringsDocument = gql`
    mutation UpdateCustomWorkshopMonitorings($delete_alerts: [String!], $update_alerts: [UpdateCustomWorkshopMonitoringAlertInput!], $create_alerts: [CreateCustomWorkshopMonitoringAlertInput!], $data: UpdateCustomWorkshopMonitoringInput!) {
  updateCustomWorkshopMonitorings(
    delete_alerts: $delete_alerts
    update_alerts: $update_alerts
    create_alerts: $create_alerts
    data: $data
  ) {
    id
  }
}
    `;
export type UpdateCustomWorkshopMonitoringsMutationFn = Apollo.MutationFunction<Types.UpdateCustomWorkshopMonitoringsMutation, Types.UpdateCustomWorkshopMonitoringsMutationVariables>;

/**
 * __useUpdateCustomWorkshopMonitoringsMutation__
 *
 * To run a mutation, you first call `useUpdateCustomWorkshopMonitoringsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomWorkshopMonitoringsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomWorkshopMonitoringsMutation, { data, loading, error }] = useUpdateCustomWorkshopMonitoringsMutation({
 *   variables: {
 *      delete_alerts: // value for 'delete_alerts'
 *      update_alerts: // value for 'update_alerts'
 *      create_alerts: // value for 'create_alerts'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCustomWorkshopMonitoringsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCustomWorkshopMonitoringsMutation, Types.UpdateCustomWorkshopMonitoringsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCustomWorkshopMonitoringsMutation, Types.UpdateCustomWorkshopMonitoringsMutationVariables>(UpdateCustomWorkshopMonitoringsDocument, options);
      }
export type UpdateCustomWorkshopMonitoringsMutationHookResult = ReturnType<typeof useUpdateCustomWorkshopMonitoringsMutation>;
export type UpdateCustomWorkshopMonitoringsMutationResult = Apollo.MutationResult<Types.UpdateCustomWorkshopMonitoringsMutation>;
export type UpdateCustomWorkshopMonitoringsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCustomWorkshopMonitoringsMutation, Types.UpdateCustomWorkshopMonitoringsMutationVariables>;
export const UpdateUserBikeCustomMonitoringDocument = gql`
    mutation UpdateUserBikeCustomMonitoring($data: UpdateUserBikeCustomMonitoringInput!) {
  updateUserBikeCustomMonitoring(data: $data)
}
    `;
export type UpdateUserBikeCustomMonitoringMutationFn = Apollo.MutationFunction<Types.UpdateUserBikeCustomMonitoringMutation, Types.UpdateUserBikeCustomMonitoringMutationVariables>;

/**
 * __useUpdateUserBikeCustomMonitoringMutation__
 *
 * To run a mutation, you first call `useUpdateUserBikeCustomMonitoringMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserBikeCustomMonitoringMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserBikeCustomMonitoringMutation, { data, loading, error }] = useUpdateUserBikeCustomMonitoringMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserBikeCustomMonitoringMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserBikeCustomMonitoringMutation, Types.UpdateUserBikeCustomMonitoringMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateUserBikeCustomMonitoringMutation, Types.UpdateUserBikeCustomMonitoringMutationVariables>(UpdateUserBikeCustomMonitoringDocument, options);
      }
export type UpdateUserBikeCustomMonitoringMutationHookResult = ReturnType<typeof useUpdateUserBikeCustomMonitoringMutation>;
export type UpdateUserBikeCustomMonitoringMutationResult = Apollo.MutationResult<Types.UpdateUserBikeCustomMonitoringMutation>;
export type UpdateUserBikeCustomMonitoringMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserBikeCustomMonitoringMutation, Types.UpdateUserBikeCustomMonitoringMutationVariables>;
export const UsersAssociatedToWorkshopDocument = gql`
    query usersAssociatedToWorkshop($company_workshop_id: String!) {
  usersAssociatedToWorkshop(company_workshop_id: $company_workshop_id) {
    id
    name
    email
    total_spent
    profile {
      profile_avatar
    }
    city {
      name
      state {
        abbreviation
      }
    }
    user_bikes {
      bike {
        bike_type {
          avatar
        }
      }
    }
  }
}
    `;

/**
 * __useUsersAssociatedToWorkshopQuery__
 *
 * To run a query within a React component, call `useUsersAssociatedToWorkshopQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersAssociatedToWorkshopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersAssociatedToWorkshopQuery({
 *   variables: {
 *      company_workshop_id: // value for 'company_workshop_id'
 *   },
 * });
 */
export function useUsersAssociatedToWorkshopQuery(baseOptions: Apollo.QueryHookOptions<Types.UsersAssociatedToWorkshopQuery, Types.UsersAssociatedToWorkshopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UsersAssociatedToWorkshopQuery, Types.UsersAssociatedToWorkshopQueryVariables>(UsersAssociatedToWorkshopDocument, options);
      }
export function useUsersAssociatedToWorkshopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UsersAssociatedToWorkshopQuery, Types.UsersAssociatedToWorkshopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UsersAssociatedToWorkshopQuery, Types.UsersAssociatedToWorkshopQueryVariables>(UsersAssociatedToWorkshopDocument, options);
        }
export type UsersAssociatedToWorkshopQueryHookResult = ReturnType<typeof useUsersAssociatedToWorkshopQuery>;
export type UsersAssociatedToWorkshopLazyQueryHookResult = ReturnType<typeof useUsersAssociatedToWorkshopLazyQuery>;
export type UsersAssociatedToWorkshopQueryResult = Apollo.QueryResult<Types.UsersAssociatedToWorkshopQuery, Types.UsersAssociatedToWorkshopQueryVariables>;
export const WorkshopAntecipationDocument = gql`
    mutation workshopAntecipation($data: WorkshopAntecipationInput!) {
  workshopAntecipation(data: $data)
}
    `;
export type WorkshopAntecipationMutationFn = Apollo.MutationFunction<Types.WorkshopAntecipationMutation, Types.WorkshopAntecipationMutationVariables>;

/**
 * __useWorkshopAntecipationMutation__
 *
 * To run a mutation, you first call `useWorkshopAntecipationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkshopAntecipationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workshopAntecipationMutation, { data, loading, error }] = useWorkshopAntecipationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useWorkshopAntecipationMutation(baseOptions?: Apollo.MutationHookOptions<Types.WorkshopAntecipationMutation, Types.WorkshopAntecipationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WorkshopAntecipationMutation, Types.WorkshopAntecipationMutationVariables>(WorkshopAntecipationDocument, options);
      }
export type WorkshopAntecipationMutationHookResult = ReturnType<typeof useWorkshopAntecipationMutation>;
export type WorkshopAntecipationMutationResult = Apollo.MutationResult<Types.WorkshopAntecipationMutation>;
export type WorkshopAntecipationMutationOptions = Apollo.BaseMutationOptions<Types.WorkshopAntecipationMutation, Types.WorkshopAntecipationMutationVariables>;
export const WorkshopDetailDocument = gql`
    query WorkshopDetail($id: String!) {
  workshopDetail(id: $id) {
    id
    address_line_one
    address_line_two
    latitude
    longitude
    rating
    name
    ddi
    phone_number
    ddi_mobile_phone
    mobile_phone_number
    whatsapp_number
    mail_address
    postal_code
    location_city
    location_state
    location_country
    register_number
    image_main
    company_id
    created_at
    country_id
    contracted_plan
    monthly_income_value
    plan_subscription_activated
    fee_subscription_plans
    last_service_order_number
    website_address
    training_done
    company_workshop_settings {
      stock_sync_integration {
        integration_type_slug
        name
        image_logo
        available_settings {
          shows_product_type_on_search
        }
      }
      stock_sync_integration_slug
      time_stock_synchronization_order_service
      show_product_variation_parent
    }
    company_workshop_cards {
      asaas_id
      brand
      company_workshop_id
      expiration_month
      expiration_year
      first_digits
      holder_name
      id
      last_digits
      legal_holder_number
      main
      name
    }
    company_workshop_stamps {
      workshop_stamp {
        id
        name
        link_image
        updated_at
        created_at
      }
    }
    company_workshop_bank_account {
      asaas_id
      company_workshop_id
      open
      status_general
      first_transfer
    }
    contracted_workshop_plan {
      id
      finished
      created_at
      current
      billing_day
      billing_mail
      workshop_plan_id
      custom_plan
      contract_plan_time
      payment_value
      payment_method {
        name
        slug
      }
      workshop_plan {
        name
        translations {
          name
          language_code
          month_plan_monthly_fee
          annual_contract_value
          annual_plan_monthly_fee
        }
      }
    }
    company {
      id
      fantasy_name
      business_name
      register_number
      register_number_region
      ddi
      phone_number
      email
      site
      profile {
        profile_avatar
      }
      company_addresses {
        address {
          id
          country_id
          line_two
          line_one
          state_address
          city_address
          zip_code
        }
      }
    }
    user_bike_workshops {
      user_bike {
        id
        name
        bike {
          model_name
          brand_name
          bike_type_slug
          bike_type {
            name
            avatar
          }
        }
        user {
          name
          profile {
            profile_avatar
          }
        }
      }
    }
    company_workshop_configuration_finance {
      company_workshop_external_bank_account_id
      company_workshop_id
      created_at
      withdrawal_pix
      withdrawal_ted
      company_workshop_external_bank_account {
        account_digit
        account_number
        active
        agency_number
        bank {
          active
          compe_brazil
          country_id
          created_at
          id
          ispb_brazil
          name_long
          name_short
          swift_code
        }
        bank_id
        brazilian_pix_key_registered
        company_workshop_id
        creator {
          id
          name
        }
        creator_id
        id
        main
        name
        validated
      }
    }
  }
}
    `;

/**
 * __useWorkshopDetailQuery__
 *
 * To run a query within a React component, call `useWorkshopDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkshopDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkshopDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWorkshopDetailQuery(baseOptions: Apollo.QueryHookOptions<Types.WorkshopDetailQuery, Types.WorkshopDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WorkshopDetailQuery, Types.WorkshopDetailQueryVariables>(WorkshopDetailDocument, options);
      }
export function useWorkshopDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WorkshopDetailQuery, Types.WorkshopDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WorkshopDetailQuery, Types.WorkshopDetailQueryVariables>(WorkshopDetailDocument, options);
        }
export type WorkshopDetailQueryHookResult = ReturnType<typeof useWorkshopDetailQuery>;
export type WorkshopDetailLazyQueryHookResult = ReturnType<typeof useWorkshopDetailLazyQuery>;
export type WorkshopDetailQueryResult = Apollo.QueryResult<Types.WorkshopDetailQuery, Types.WorkshopDetailQueryVariables>;
export const WorkshopDiscountCouponDetailDocument = gql`
    query WorkshopDiscountCouponDetail($id: String!) {
  workshopDiscountCouponDetail(id: $id) {
    active
    code
    company_workshop_id
    coupon_limit_type_id
    coupon_limit_type {
      active
      id
      slug
      coupon_limit_type_translations {
        description
        language_code
      }
    }
    discount_type {
      active
      description
      id
      slug
      discount_type_translations {
        description
        language_code
        discount_type_id
      }
    }
    coupon_subscription_plan_restrictions {
      workshop_discount_coupon_id
      workshop_subscription_plan_id
    }
    created_at
    id
    enable
    discounted_installments
    description
    discount_type_id
    maximum_uses
    minimum_purchase_amount
    total_uses
    valid_from
    valid_until
    value
  }
}
    `;

/**
 * __useWorkshopDiscountCouponDetailQuery__
 *
 * To run a query within a React component, call `useWorkshopDiscountCouponDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkshopDiscountCouponDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkshopDiscountCouponDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWorkshopDiscountCouponDetailQuery(baseOptions: Apollo.QueryHookOptions<Types.WorkshopDiscountCouponDetailQuery, Types.WorkshopDiscountCouponDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WorkshopDiscountCouponDetailQuery, Types.WorkshopDiscountCouponDetailQueryVariables>(WorkshopDiscountCouponDetailDocument, options);
      }
export function useWorkshopDiscountCouponDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WorkshopDiscountCouponDetailQuery, Types.WorkshopDiscountCouponDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WorkshopDiscountCouponDetailQuery, Types.WorkshopDiscountCouponDetailQueryVariables>(WorkshopDiscountCouponDetailDocument, options);
        }
export type WorkshopDiscountCouponDetailQueryHookResult = ReturnType<typeof useWorkshopDiscountCouponDetailQuery>;
export type WorkshopDiscountCouponDetailLazyQueryHookResult = ReturnType<typeof useWorkshopDiscountCouponDetailLazyQuery>;
export type WorkshopDiscountCouponDetailQueryResult = Apollo.QueryResult<Types.WorkshopDiscountCouponDetailQuery, Types.WorkshopDiscountCouponDetailQueryVariables>;
export const WorkshopOpportunitiesDocument = gql`
    query WorkshopOpportunities($period: PeriodInput, $data: WorkshopOpportunitiesInput!) {
  workshopOpportunities(period: $period, data: $data) {
    clients_birth_date_data {
      date_of_birth
      user_id
      username
      profile_avatar
      ddi
      phone_number
    }
    clients_long_time_without_repair {
      profile_avatar
      user_bike_name
      user_id
      username
      last_repair_date
    }
    clients_with_most_repairs {
      profile_avatar
      quantity
      user_id
      username
    }
    clients_without_gears {
      profile_avatar
      user_bike_name
      user_id
      username
    }
    clients_without_repair {
      profile_avatar
      user_bike_name
      user_id
      username
    }
    gears_near_to_repair {
      amount_usage_percentage
      profile_avatar
      user_bike_id
      user_bike_name
      bike_type_avatar
      brand
      model
      user_id
      username
      first_name
      user_phone
      gears {
        usage_percentage
        gear_type {
          slug
          avatar
          name
        }
      }
      workshop_client {
        ddi
        firstname
        lastname
        name
        phone
      }
    }
    gears_without_monitoring {
      profile_avatar
      user_bike_name
      user_id
      username
    }
    latest_ratings {
      profile_avatar
      rating
      review_message
      review_title
      username
      review_date
    }
    most_delayed_gear_repairs {
      quantity
      gear_type {
        avatar
        name
        slug
      }
    }
    most_delayed_gears_with_users {
      quantity
      gear_type {
        avatar
        name
        slug
      }
      users {
        percentage
        profile_avatar
        user_id
        username
      }
    }
    most_repaired_gears {
      quantity
      gear_type {
        avatar
        name
        slug
      }
    }
    recent_clients {
      profile_avatar
      user_bike_name
      user_bike_id
      user_id
      username
      created_at
      bike {
        bike_type_slug
        model_name
        brand_name
        total_distance
        bike_type {
          avatar
          name
        }
        repairs {
          date
          cost
        }
      }
    }
    total_of_clients_without_third_party
    clients_without_third_party {
      profile_avatar
      user_bike_name
      user_id
      username
    }
    bike_types_most_used {
      percentage
      quantity
      bike_type {
        slug
        name
        avatar
      }
    }
  }
}
    `;

/**
 * __useWorkshopOpportunitiesQuery__
 *
 * To run a query within a React component, call `useWorkshopOpportunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkshopOpportunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkshopOpportunitiesQuery({
 *   variables: {
 *      period: // value for 'period'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useWorkshopOpportunitiesQuery(baseOptions: Apollo.QueryHookOptions<Types.WorkshopOpportunitiesQuery, Types.WorkshopOpportunitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WorkshopOpportunitiesQuery, Types.WorkshopOpportunitiesQueryVariables>(WorkshopOpportunitiesDocument, options);
      }
export function useWorkshopOpportunitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WorkshopOpportunitiesQuery, Types.WorkshopOpportunitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WorkshopOpportunitiesQuery, Types.WorkshopOpportunitiesQueryVariables>(WorkshopOpportunitiesDocument, options);
        }
export type WorkshopOpportunitiesQueryHookResult = ReturnType<typeof useWorkshopOpportunitiesQuery>;
export type WorkshopOpportunitiesLazyQueryHookResult = ReturnType<typeof useWorkshopOpportunitiesLazyQuery>;
export type WorkshopOpportunitiesQueryResult = Apollo.QueryResult<Types.WorkshopOpportunitiesQuery, Types.WorkshopOpportunitiesQueryVariables>;
export const WorkshopTopClientsDocument = gql`
    query WorkshopTopClients($pagination: PaginationInput!, $id: String!) {
  workshopTopClients(pagination: $pagination, id: $id) {
    data {
      profile_avatar
      total_services
      user_id
      username
    }
    total_count
  }
}
    `;

/**
 * __useWorkshopTopClientsQuery__
 *
 * To run a query within a React component, call `useWorkshopTopClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkshopTopClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkshopTopClientsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWorkshopTopClientsQuery(baseOptions: Apollo.QueryHookOptions<Types.WorkshopTopClientsQuery, Types.WorkshopTopClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WorkshopTopClientsQuery, Types.WorkshopTopClientsQueryVariables>(WorkshopTopClientsDocument, options);
      }
export function useWorkshopTopClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WorkshopTopClientsQuery, Types.WorkshopTopClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WorkshopTopClientsQuery, Types.WorkshopTopClientsQueryVariables>(WorkshopTopClientsDocument, options);
        }
export type WorkshopTopClientsQueryHookResult = ReturnType<typeof useWorkshopTopClientsQuery>;
export type WorkshopTopClientsLazyQueryHookResult = ReturnType<typeof useWorkshopTopClientsLazyQuery>;
export type WorkshopTopClientsQueryResult = Apollo.QueryResult<Types.WorkshopTopClientsQuery, Types.WorkshopTopClientsQueryVariables>;
export const WorkshopUserAssociationDocument = gql`
    query WorkshopUserAssociation($data: WorkshopUserAssociationInput!) {
  workshopUserAssociation(data: $data) {
    id
    name
    email
    ddi
    phone
    team_name
    stature
    weight
    total_spent
    user_third_party_data_source {
      third_party_data_source {
        name
        slug
      }
    }
    user_bikes {
      id
      name
      created_at
      bike {
        model_name
        brand_name
        bike_type {
          avatar
          name
        }
      }
    }
    profile {
      profile_avatar
      official
      username
      description
    }
    city {
      name
      state {
        name
        abbreviation
      }
    }
  }
}
    `;

/**
 * __useWorkshopUserAssociationQuery__
 *
 * To run a query within a React component, call `useWorkshopUserAssociationQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkshopUserAssociationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkshopUserAssociationQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useWorkshopUserAssociationQuery(baseOptions: Apollo.QueryHookOptions<Types.WorkshopUserAssociationQuery, Types.WorkshopUserAssociationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WorkshopUserAssociationQuery, Types.WorkshopUserAssociationQueryVariables>(WorkshopUserAssociationDocument, options);
      }
export function useWorkshopUserAssociationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WorkshopUserAssociationQuery, Types.WorkshopUserAssociationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WorkshopUserAssociationQuery, Types.WorkshopUserAssociationQueryVariables>(WorkshopUserAssociationDocument, options);
        }
export type WorkshopUserAssociationQueryHookResult = ReturnType<typeof useWorkshopUserAssociationQuery>;
export type WorkshopUserAssociationLazyQueryHookResult = ReturnType<typeof useWorkshopUserAssociationLazyQuery>;
export type WorkshopUserAssociationQueryResult = Apollo.QueryResult<Types.WorkshopUserAssociationQuery, Types.WorkshopUserAssociationQueryVariables>;