import Head from 'next/head';

import ResponsiveAppBar from '@source/components/ResponsiveAppBar';
import Footer from '@source/components/Footer';

type BaseTemplateProps = {
  children: React.ReactNode;
  title?: string;
  steps?: { label: string; step: number; status: string }[];
  routesPage?: boolean;
  voucherValue?: number;
  hideResponsiveAppBar?: boolean;
  hideResponsiveFooter?: boolean;
  renderErrorPage?: boolean; 
};

/*
  Esta funçao recebe a array steps, de forma a customizar a navbar do header da página. Algumas páginas apresentam steps diferentes, logo é necessário implementar esta funcionalidade de forma a não fazer tudo hard-coded.

  {steps} é passado adiante para ResponsiveAppBar e em seguida para o seu componente <Steps />.
*/

const BaseTemplate: React.FC<BaseTemplateProps> = ({
  children,
  title,
  steps,
  routesPage,
  voucherValue,
  renderErrorPage,
  hideResponsiveAppBar = false,
  hideResponsiveFooter = false
}): JSX.Element => {
  return (
    <>
      {title && (
        <Head>
          <title>{`Riderize - Conectados pelo Ciclismo`}</title>
        </Head>
      )}

      {!hideResponsiveAppBar && (
        <ResponsiveAppBar
          routesPage={routesPage}
          steps={steps}
          voucherValue={voucherValue}
          renderErrorPage={renderErrorPage}
        />
      )}

      {children}

      {!hideResponsiveFooter && <Footer />}
    </>
  );
};

export default BaseTemplate;
