import React, { Component, ErrorInfo, ReactNode } from 'react';
import ErrorPage from '@source/components/ErrorPage';
import Head from 'next/head';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
    this.resetError = this.resetError.bind(this);
  }

  resetError() {
    this.setState({ hasError: false });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
    throw new Error(error.message);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <>
          <Head>
            <title>Uh oh... Algo inesperado ocorreu</title>
          </Head>
            <ErrorPage
              errorMessage="Aconteceu algo de errado"
              handleErrorBoundary={this.resetError}
            />
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
